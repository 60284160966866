import {
  AccountCircle,
  ExitToApp,
  Home,
  LocalFlorist,
  Menu as MenuIcon,
  MoreVert,
  NetworkWifi,
  PersonAdd,
  Info,
} from '@material-ui/icons';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Select,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory } from 'react-router-dom';
// import packageJson from '../../../package.json';
import logoBlue from '../../assets/logo_blue.png';
import logoWhite from '../../assets/logo_all_white.png';
import styles from './Style';
import { withStyles } from '@material-ui/core/styles';
import { useSessionContext } from '../../contexts/SessionContext';
import { useAlertContext } from '../AlertContext';

function SiteWrapper(props) {
  // console.log(props);
  let history = useHistory();
  const alertContext = useAlertContext();

  const { entityList, selectedEntity, selectEntity, setUserType } = useSessionContext();
  const [open, setOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [manageFacility, setManageFacility] = useState(false)
  // const [online, setOnline] = useState(true)
  // const [lastUpdated, setLastUpdated] = useState(null)
  const [online] = useState(true);
  const [lastUpdated] = useState(null);

  const admin = props.user.idToken.payload.admin === 'true';
  const agronomist = props.user.idToken.payload.agronomist === 'true';
  setUserType(admin ? 'admin' : agronomist ? 'agronomist' : null);
  // const admin = false;
  // const agronomist = false;

  // const admin =
  //   props.children.props.childProps.user?.idToken?.payload?.admin === "true";

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  // const { userd } = useEntityContext();
  // console.log(userd);

  // handleDrawerOpen = () => {
  //   setOpen(true)
  // };

  // handleDrawerClose = () => {
  //   setOpen(false)
  // };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMobileMenu = event => { setMobileMoreAnchorEl(event?.currentTarget || null) };

  // handleMobileMenuOpen = event => {
  //   setMobileMoreAnchoerEl(event.currentTarget)
  // };

  // handleMobileMenuClose = () => {
  //   setMobileMoreAnchoerEl(null)
  // };

  // handleLogOut= () => {
  //   Auth.signOut()
  //   .then(() => {
  //     console.log('logout')
  //   })
  // }
  const handleLogOut = () => {
    Auth.signOut().then((data) => {
      window.location = '/login';
    });
  };

  const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink
      to={props.to}
      {...itemProps}
      activeclassname={props.classes.active}
      ref={ref}
      // innerRef={ref}
    />
  ));

  const { classes } = props;

  // const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  //Right Menu Desktop
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={() => handleProfileMenuClose()}
    >
      {/* <MenuItem component={renderLink} to="/profile">
        My Profile
      </MenuItem> */}
      <MenuItem onClick={() => openInNewTab('http://support.landhub.com.au/')}>
        Help Desk
      </MenuItem>
      <MenuItem onClick={() => window.location.reload(true)}>Hard Refresh</MenuItem>
      <MenuItem>Version: {process.env.REACT_APP_VERSION}</MenuItem>
      {/* <MenuItem onClick={() => handleLogOut()}>Sign Out</MenuItem> */}
    </Menu>
  );

  //Right Menu Mobile
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={() => handleMobileMenuClose()}
    >
      <MenuItem component={renderLink} to='/profile'>
        <IconButton>
          <AccountCircle />
        </IconButton>
        <p>My Profile</p>
      </MenuItem>
      <MenuItem onClick={() => handleLogOut()}>
        <IconButton>
          <ExitToApp />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>
    </Menu>
  );

  const changeFarmSelection = (e) => {
    selectEntity(e.target.value);
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <Box displayPrint='none'>
        <AppBar position='fixed' color={'secondary'} className={classes.appBar}>
          <Toolbar>
            <IconButton
              id='menuButton'
              aria-label='Open drawer'
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon id='menuIcon' className={classes.white} />
            </IconButton>
            {online === false ? (
              <Typography variant='body1' color='error' noWrap>
                Last updated: {lastUpdated}
              </Typography>
            ) : (
              <>
                <img id='logo' src={logoWhite} alt='logo' style={{ maxWidth: 160 }} />
                {/*
                      <Typography variant="h6" className={classes.white} noWrap>{this.props.title}</Typography>
                    */}
              </>
            )}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {(agronomist || admin) && entityList && (
                <Select
                  labelId='entity-select-label'
                  id='entity-select'
                  value={selectedEntity}
                  label='Entity'
                  onChange={(e) => changeFarmSelection(e)}
                  style={{
                    minWidth: 200,
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 900,
                  }}
                >
                  <MenuItem key={'All Farms'} value={'All Farms'}>
                    All Farms
                  </MenuItem>
                  {entityList.sort().map((entity, index) => {
                    return (
                      <MenuItem key={index} value={entity}>
                        {entity}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              {/* <IconButton>
                <NetworkWifi className={classes.white} />
              </IconButton>
              <IconButton onClick={() => {
                alertContext.success(`Version: ${process.env.REACT_APP_VERSION}`);
              }}>
                <Info className={classes.white} />
              </IconButton> */}

              <IconButton
                aria-owns={anchorEl ? 'material-appbar' : undefined}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
              >
                <MoreVert className={classes.white} />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup='true' disabled>
                <NetworkWifi color={online ? 'secondary' : 'error'} />
              </IconButton>
              <IconButton aria-haspopup='true' onClick={handleMobileMenuOpen}>
                <MoreVert color='secondary' />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        <SwipeableDrawer
          className={classes.drawer}
          anchor='left'
          open={Boolean(open)}
          onClose={() => handleDrawerOpen()}
          onOpen={() => setOpen(true)}
        >
          <div className={classes.drawerList}>
            <div style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
              {
                <img
                  id='menu_logo'
                  src={logoBlue}
                  alt='img_logo'
                  style={{ width: '95%', marginTop: 10, marginBottom: 10 }}
                />
              }
              {/*
                  <Typography color='secondary' variant='h6'>Land</Typography>
                  <Typography style={{color: '#FFFFFF'}} variant="caption">version: {version}</Typography>
                */}
            </div>
            <Divider />
            <List dense={true}>
              <ListItem
                button
                key={'home'}
                component={renderLink}
                to='/'
                onClick={() => handleDrawerOpen()}
              >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={'Home'} className={classes.drawerText} />
              </ListItem>
              {/* <ListItem
                button
                key={"reports"}
                component={renderLink}
                to="/dashboard"
                onClick={() => handleDrawerOpen()}
              >
                <ListItemIcon>
                  <Poll />
                </ListItemIcon>
                <ListItemText
                  primary={"Reports"}
                  className={classes.drawerText}
                />
              </ListItem> */}
              <ListItem
                button
                key={'profile'}
                component={renderLink}
                to='/profile'
                onClick={() => handleDrawerOpen()}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={'My Profile'} className={classes.drawerText} />
              </ListItem>
              <ListItem
                button
                key={'products'}
                component={renderLink}
                to='/products'
                onClick={() => handleDrawerOpen()}
              >
                <ListItemIcon>
                  <LocalFlorist />
                </ListItemIcon>
                <ListItemText primary={'Products'} className={classes.drawerText} />
              </ListItem>
              {/* <ListItem button key={"file"} component={renderLink} to="/FileRepository" disabled>
                <ListItemIcon>
                  <InsertDriveFile  />
                </ListItemIcon>
                <ListItemText primary={"File Repository"} className={classes.drawerText}/>
              </ListItem> */}
            </List>
            {admin && (
              <div>
                <Divider className={classes.drawerText} />
                <List dense={true}>
                  <ListItem
                    button
                    key={'accounts'}
                    component={renderLink}
                    to='/accounts'
                    onClick={() => handleDrawerOpen()}
                  >
                    <ListItemIcon>
                      <PersonAdd id='accountsIcon' />
                    </ListItemIcon>
                    <ListItemText primary={'Manage Accounts'} className={classes.drawerText} />
                  </ListItem>
                  {/*
                    
                      <ListItem button key={"settings"} component={this.renderLink} to="/Settings" >
                        <ListItemIcon>
                          <Settings color='secondary' />
                        </ListItemIcon>
                        <ListItemText primary={"Settings"} className={classes.drawerText}/>
                      </ListItem>
                    
                  */}
                </List>
              </div>
            )}
            <Divider />
            <List dense={true}>
              <ListItem
                id='logoutButton'
                button
                key={'logout'}
                activeclassname={classes.active}
                onClick={() => handleLogOut()}
              >
                <ListItemIcon>
                  <ExitToApp id='logoutIcon' />
                </ListItemIcon>
                <ListItemText primary={'Logout'} className={classes.drawerText} />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </Box>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

SiteWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SiteWrapper);
