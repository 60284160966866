import * as React from "react";
import { useContext, useState } from "react";

export const SessionContext = React.createContext();

export function useSessionContext() {
  return useContext(SessionContext);
}

const SessionContextProvider = (props) => {
  const [entityList, setEntityList] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState("All Farms");
  const [userType, setUserType] = useState(null);
  //   const [entitySelectDisabled, setEntitySelectDisabled] = useState(false);

  const loadEntityList = (newEntityList) => {
    setEntityList(newEntityList);
  };

  const selectEntity = (farmName) => {
    setSelectedEntity(farmName);
  };

  //   const disableEntitySelect = (val) => {
  //     setSelectedEntity(val);
  //   };

  return (
    <SessionContext.Provider
      value={{
        entityList,
        loadEntityList,
        selectedEntity,
        selectEntity,
        userType,
        setUserType,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
