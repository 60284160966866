import { useEffect, useState } from 'react';
import L from 'leaflet';
import './ProductLegend.css';

function ProductLegend({ map, products, legendPosition   }) {
  const [legendView] = useState(L.control({ position: legendPosition }));

  useEffect(() => {
    const appString = products.apprates
      .map(
        (product) =>
          `<p class="legend-item" style="background: ${product.color};"> ${product.rate} ${
            products.name.charAt(0) === 'L' ? 'L/ha' : 'Kg/ha'
          }</p>`
      )
      .join('');
    if (map) {
      legendView.remove();

      legendView.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        div.innerHTML = `<h4>${products.name}</h4>${appString}`;

        return div;
      };

      legendView.addTo(map);
    }
  }, [map, products]);
  return null;
}

export default ProductLegend;
