export const requirementPlant = {
    mutation: `mutation requirementPlant($requirement: Requirement) {
        requirementPlant(requirement: $requirement) 
    }`,
};

export const requirementHistory = {
    mutation: `mutation requirementHistory($requirement: Requirement) {
        requirementHistory(requirement: $requirement) 
    }`
};

export const requirementHarvest = {
    mutation: `mutation requirementHarvest($requirement: Requirement) {
        requirementHarvest(requirement: $requirement) 
    }`
};

export const requirementToFallow = {
    mutation: `mutation requirementToFallow($requirement: Requirement) {
        requirementToFallow(requirement: $requirement) 
    }`
};

export const requirementFallow = {
    mutation: `mutation requirementFallow($requirement: Requirement) {
        requirementFallow(requirement: $requirement) 
    }`
};

export const requirementCycle = {
    mutation: `mutation requirementCycle($requirement: Requirement) {
        requirementCycle(requirement: $requirement) 
    }`
};

export const requirementConfirmed = {
    mutation: `mutation requirementConfirmed($requirement: Requirement) {
        requirementConfirmed(requirement: $requirement) 
    }`,
};

export const requirementApplicationMillmud = {
    mutation: `mutation requirementApplicationMillmud($requirement: Requirement) {
        requirementApplicationMillmud(requirement: $requirement) 
    }`
};

export const getRequirements = { 
    query: `query getRequirements($farmId: String) {
        getRequirements(farmId: $farmId)
    }`
};

export const getRequirement = { 
    query: `query getRequirement($requirementId: String) {
        getRequirement(requirementId: $requirementId)
    }`
};

export const createRequirements = {
    mutation: `mutation createRequirements($requirementId: String)  {
        createRequirements(requirementId: $requirementId)
    }`
};




  




  