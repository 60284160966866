import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';

import { get6ESdata } from '../../graphQL/LookupTables';
import { getLabSampleFromSoilSampleId } from '../../graphQL/SoilSample';

import { formatDate } from '../../functions/formatDate';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

//ICONS
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// COMPONENTS
import AmeliorantTables from './AmeliorantTables';
import NTable from './NTable';
import KTable from './KTable';
import PTable from './PTable';
import STable from './STable';
import DataTable from './DataTable';
import MicroTables from './MicroTables';

export default function ViewSoilSampleDialog(props) {
  const { blockName, sampleId, farmId, requirement, handleClose } = props;

  // TODO Swap region_id for farm_id
  const { data: tableData, isLoading } = useQuery({
    queryKey: ['tableData', farmId],
    queryFn: async () => {
      const result = await API.graphql(graphqlOperation(get6ESdata.query, { farmId: farmId }));
      return JSON.parse(result.data.get6ESdata)[0];
    },
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: soilSample, isLoading: sampleLoading } = useQuery({
    queryKey: ['labSample', sampleId],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getLabSampleFromSoilSampleId.query, { sampleId: sampleId })
      );
      const data = JSON.parse(result.data.getLabSampleFromSoilSampleId)[0];
      return { ...data, sampling_date: formatDate(data.sampling_date) };
    },
  });

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth='xl'
      aria-labelledby='SoilSample-dialog-title'
      aria-describedby='SoilSample-dialog-description'
    >
      <DialogTitle
        style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}
        id='SoilSample-dialog-title'
      >{`Lab Sample: ${soilSample && soilSample.lab_sample_number} - Block ${blockName} (${
        requirement && requirement.crop_class_name
      })`}</DialogTitle>
      <DialogContent>
        {!sampleLoading && !isLoading && (
          <>
          <Typography style={{ marginBottom: 10 }}>
              SIX EASY STEPS® Nutrient guildlines for {tableData.name}
            </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Ameliorants {requirement && `(L Ca) ${requirement.lime_calcium || 0}t/ha (L Ph) ${requirement.lime_acidic || 0}t/ha (Mg) ${requirement.magnesium || 0}kg/ha (Gp) ${requirement.gypsum || 0}t/ha`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AmeliorantTables
                sample={soilSample}
                tables={tableData}
                requirement={requirement}
              />
            </AccordionDetails>
            </Accordion>
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Nitrogen (N) {requirement && `${requirement.nitrogen || 0}kg/ha`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <NTable sample={soilSample} tableData={tableData} requirement={requirement} />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Phosphorus (P) {requirement && `${requirement.phosphorus || 0}kg/ha`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PTable sample={soilSample} tableData={tableData} requirement={requirement} />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Potassium (K) {requirement && `${requirement.potassium || 0}kg/ha`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KTable sample={soilSample} tableData={tableData} requirement={requirement} />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Sulphur (S) {requirement && `${requirement.sulphur || 0}kg/ha`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <STable sample={soilSample} tableData={tableData} requirement={requirement} />
            </AccordionDetails>
          </Accordion>

          <Accordion  style={{marginBottom: '20px'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel2a-content'
              id='panel2a-header'
              style={{ backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
            >
              <Typography>Micronutrients {requirement && `(Cu) ${requirement.copper || 0}kg/ha`} {requirement && `(Zn) ${requirement.zinc || 0}kg/ha`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MicroTables sample={soilSample} tableData={tableData} requirement={requirement} />
            </AccordionDetails>
          </Accordion>

          <Typography style={{ marginBottom: 10 }}>
              Soil Test Results
            </Typography>

          <DataTable sample={soilSample} />
          </>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
