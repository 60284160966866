import { APIFetch } from '../../fetch.js';

export const getRequirement = async ({ requirementId, token }) => {
  const response = await APIFetch({
    route: `requirements/getRequirement?requirementId=${requirementId}`,
    method: 'GET',
    authToken: token,
  });

  if (!response.ok) {
    console.error(await response.json());
    throw new Error('An error occured during GetRequirement request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
