import React, { useState } from "react";
import { resetPassword, editAccount, createAccount } from '../graphQL/Account';
import { API, graphqlOperation } from 'aws-amplify';
import { useAlertContext } from "../components/AlertContext";
import { useFormik } from "formik";
import LoadingBackground from "../components/LoadingBackground";
import PageHeader from "../components/PageHeader";
import FormikForm from "../components/FormikForm";
import FormText from "../components/FormText";
import FormCheckbox from "../components/FormCheckbox";
import FormButton from "../components/FormButton";
import AlertDialog from "../components/AlertDialog";
import { UserSchema } from "../components/ValidationSchema";
import LogoCard from "../components/LogoCard";
import { useHistory } from "react-router-dom";
import useUser from "../functions/useUser";

const _isTrue = (value) => value === 'true' || value === true;

export default function ManageAccount(props) {
    const cognitoId = props.match.params.id
    const userDetails = props.location.state || {} //Cognito details
    const { user } = useUser(cognitoId)

    const [ loading, setLoading ] = useState(false)
    const [ dialogOpen, setDialogOpen ] = useState(false)
    const history = useHistory();

    const alertContext = useAlertContext()

    const formik = useFormik({
        initialValues: {
            username: userDetails?.username || '',
            email: user?.email || '',
            firstName: userDetails['custom:first_name'] || '',
            lastName: userDetails['custom:last_name'] || '',
            admin: _isTrue(userDetails['custom:admin']),
            agronomist: _isTrue(userDetails['custom:agronomist']),
            phoneNumber: user?.phoneNumber?.substr(4) || '',
            companyName: user?.companyName || '',
            address: user?.address || "",
            postalAddress: user?.postalAddress || '',
            abn: user?.abn || '',
            agriculturalAdvisor: user?.agriculturalAdvisor || '',
            fertcareAdvisorType: user?.fertcareAdvisorType || '',
            fertcareAdvisor: user?.fertcareAdvisor || '',
            fertcareAdvisorExpiry: user?.fertcareAdvisorExpiry || '',
        },
        enableReinitialize: true,
        validationSchema: UserSchema,
        onSubmit: (values, actions) => {
            setLoading(true)
            const data = {
                userId: user?.id,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                username: values.username,
                admin: values.admin,
                agronomist: values.agronomist,
                phoneNumber: values.phoneNumber ? `+614${values.phoneNumber}` : "",
                companyName: values.companyName,
                abn: values.abn,
                address: values.address,
                postalAddress: values.postalAddress,
                agriculturalAdvisor: values.agriculturalAdvisor,
                fertcareAdvisorType: !!values.fertcareAdvisorType ? values.fertcareAdvisorType : null,
                fertcareAdvisor: !!values.fertcareAdvisor ? values.fertcareAdvisor : null,
                fertcareAdvisorExpiry: !!values.fertcareAdvisorExpiry ? values.fertcareAdvisorExpiry : null,
            }
            API.graphql(graphqlOperation(cognitoId ? editAccount.mutation : createAccount.mutation, { account: data }))
            .then(async (result) => {
                actions.setSubmitting(false);
                setLoading(false)
                if(user) {
                    alertContext.success(`User Updated`)
                }
                else {
                    alertContext.success('User Created')
                    history.goBack()
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                actions.setSubmitting(false);
                alertContext.error(err, `Error ${user ? 'updating' : 'creating'} the user`)
            })
        }
    });

    const handleResetPassword = () => {
        setLoading(true)
        setDialogOpen(false)
        API.graphql(graphqlOperation(resetPassword.mutation, { username: userDetails.username }))
        .then(async (result) => {
            setLoading(false)
            alertContext.success(`Password Reset`)
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
            setDialogOpen(false)
            alertContext.error(err, `Error resetting password`)
        })
    };

    const userValues = formik.values
    console.log(formik)
    return (
        <div>
            { loading &&
                <LoadingBackground visible={loading} />
            }
            <AlertDialog visible={dialogOpen} title={`Reset Password for ${userValues?.username}?`}
                 subtitle={`Are you sure you want to reset ${userValues?.firstName} ${userValues?.lastName}'s password?`}
                 handleAccept={handleResetPassword} handleClose={()=> setDialogOpen(false)}/>
            <LogoCard margin={20}>
                <LoadingBackground visible={loading} />
                <PageHeader title={`${user ? 'Update' : 'Create'} User`}
                    subheader={`Use the below form to '${user ? 'Update the user:' + userDetails?.username : 'Create a new User'}`}
                    backPath={'back'} resetPassword={user ? ()=>setDialogOpen(true) : null}/>
                <div style={{ padding: 20 }}>
                    <FormikForm formik={formik}>
                        <FormText name="firstName" label="First Name:" />
                        <FormText name="lastName" label="Last Name:" />
                        <FormText name="username" label="Username:" />
                        <FormText name="email" label="Email:" />
                        <FormText name="phoneNumber" label="Mobile Number:" prefix={"+614"} />
                        <FormCheckbox name="admin" label="Admin:" />
                        <FormCheckbox name="agronomist" label="Agronomist:" />
                        {
                            Boolean(formik.values.agronomist) === true &&
                                <>
                                    <FormText name="companyName" label="Company Name:" />
                                    <FormText name="abn" label="Company ABN:" />
                                    <FormText name="address" label="Company Address:" />
                                    <FormText name="postalAddress" label="Company Postal:" />
                                    <FormText name="agriculturalAdvisor" label="Accredited Agricultural Advisor:" />
                                    <FormText name="fertcareAdvisorType" label="Accredited Advisor Type:" />
                                    <FormText name="fertcareAdvisor" label="Accredited Advisor Number:" />
                                    <FormText name="fertcareAdvisorExpiry" label="Accredited Advisor Expiry:" />
                                </>
                        }
                        <FormButton text={user ? 'Update User' : 'Create User'} />
                    </FormikForm>
                </div>
            </LogoCard>
        </div>
    );
}
