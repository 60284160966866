import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: "#09bd2f" },
    secondary: { main: "#019BDC" },
    action: { main: "#747474" },
    error: { main: "#E4002B" },
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):not($error):not($focused):before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
        },
      },
    },
  },
});

export default theme;
