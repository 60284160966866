import React from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";

import { Create, Delete, Adjust } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function FarmList(props) {
  const classes = style();
  const theme = useTheme();
  const {
    farms,
    online,
    agronomist,
    admin,
    handleOpenDroneDialog,
    handleOpenDeleteDialog,
  } = props;
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <List className={classes.root}>
      {farms.map((farm, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            alignItems="flex-start"
            key={index}
            component={Link}
            to={`/view-farm-overview/${farm.name}/${farm.id}`}
          >
            <ListItemText
              primary={farm.name}
              secondary={
                isDesktop
                  ? `Description: ${farm.description} - Region: ${farm.region_name}`
                  : `Region: ${farm.region_name}`
              }
            />
            <ListItemSecondaryAction>
              {
                // farm.blocks &&
                <>
                  {(admin || agronomist) && (
                    <>
                      {admin && (
                        <Tooltip
                          title={`Delete Farm ${farm.name}`}
                          className={classes.actionIcon}
                        >
                          <IconButton
                            // disabled={!(agronomist || admin)}
                            edge="end"
                            aria-label="View"
                            onClick={() => handleOpenDeleteDialog(farm, index)}
                          >
                            <Delete
                              id={`icon_${farm.name}`}
                              style={{ color: "red" }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip
                        title="Drone deploy links"
                        className={classes.actionIcon}
                      >
                        <IconButton
                          edge="end"
                          aria-label="View"
                          onClick={() => handleOpenDroneDialog(farm, index)}
                        >
                          <Adjust
                            id={`droneicon_${farm.name}`}
                            color="primary"
                          />
                        </IconButton>
                      </Tooltip>

                      {/* <Tooltip
                        title="View Soil Samples"
                        className={classes.actionIcon}
                      >
                        <IconButton
                          style={{ flexDirection: "column" }}
                          disabled={!online}
                          edge="end"
                          aria-label="View"
                          component={Link}
                          to={`/farm-soil-samples/${farm.name}/${farm.id}`}
                        >
                          <SvgIcon id={`icon_${farm.name}`} color="primary">
                            <path d="M3,3V5A2,2 0 0,1 5,7V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V7A2,2 0 0,1 21,5V3H3M7,9H10V10H7V9M7,11H10V12H7V11M10,16H7V15H10V16M12,14H7V13H12V14M12,8H7V7H12V8Z" />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip> */}
                    </>
                  )}
                  <Tooltip title="Manage Farm" className={classes.actionIcon}>
                    <IconButton
                      style={{ flexDirection: "column" }}
                      disabled={!online}
                      edge="end"
                      aria-label="View"
                      component={Link}
                      to={`/manage-farm/${farm.name}/${farm.id}`}
                    >
                      <Create id={`icon_${farm.name}`} color="primary" />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title='View Requirements' className={classes.actionIcon}>
                            <IconButton edge="end" disabled={!online} aria-label="View" component={Link} to={`/farm-requirements/${farm.name}/${farm.id}`}>
                                <SvgIcon id={`icon_${farm.name}`} color='primary'>
                                    <path d="M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z" />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip> */}
                </>
              }
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginLeft: 25,
  },
  root: {
    height: "100%",
  },
}));
