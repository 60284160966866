import React, { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
// CSS
import './main.css';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
// Queries
import { reportUploader } from '../../graphQL/Report';
import { getFarm } from '../../graphQL/Farm';
import { updateBlocksCycle } from '../../graphQL/Blocks';

// Material
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';

// Components
import FormButton from '../../components/FormButton';
import FormCheckbox from '../../components/FormCheckbox.js';
import FormText from '../../components/FormText';
import FormikForm from '../../components/FormikForm';
import LabSampleTable from '../../components/Reports/LabSampleTable';
import PageHeader from '../../components/PageHeader';
import ProductSelectedTable from '../../components/Reports/ProductSelected';
import FertTotalsTable from '../../components/Reports/FertTotalsTable';
import ReportMap from '../../components/Reports/reportMap';

// Contexts
import { useAlertContext } from '../../components/AlertContext';

// Assets
import logoBlue from '../../assets/logo_blue.png';
import { Button, IconButton, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// FUNCTIONS
import { farmFertTotals } from '../../functions/reports/totalsTableCalcs';

// States
import useUser from '../../functions/useUser';

// Generate Report Page

export default function Reports(props) {
  const { fertYear, blocks, mapData } = props.location.state
    ? props.location.state
    : { blocks: null };
  if (blocks === null) {
    window.location.href = '/';
  }
  // console.log(fertYear, blocks, mapData);
  const farmId = props.match.params.id;
  const farmName = props.match.params.name;
  const [farm, setFarm] = useState(null);
  const [mapImages, setMapImages] = useState([]);
  const userSub = props.user.idToken.payload.sub;
  const { user } = useUser(userSub);
  // console.log('USER', user);

  const calculatedFarmFertTotals = farmFertTotals(blocks);

  // const name =
  //   user.idToken.payload["custom:first_name"] +
  //   " " +
  //   user.idToken.payload["custom:last_name"];
  const date = null;

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  // console.log(loading);
  // const [ soilSamples, setSoilSamples ] = useState([] )
  const alertContext = useAlertContext();

  const allBlocksFinalised = blocks.every((block) =>
    block.requirements?.length === 1 ? block.requirements[0].to_fallow !== null : true
  );

  const [comments, setComments] = useState([]);
  const [commentTitle, setCommentTitle] = useState();
  const [commentText, setCommentText] = useState();

  const fetchFarm = useCallback(async () => {
    setLoading(true);
    await API.graphql(graphqlOperation(getFarm.query, { farmId: farmId }))
      .then((result) => {
        const res = JSON.parse(result.data.getFarm)[0];
        setFarm(res);
      })
      .catch(async (err) => {
        console.log(err);
        alertContext.error(err, 'Error fetching farm');
      });
  }, [farmId, alertContext]);

  useEffect(() => {
    if (farmId) fetchFarm().then(() => setLoading(false));
  }, [fetchFarm, farmId]);

  const [finalReport, setFinalReport] = useState(false);
  const handleReportChange = (event) => {
    setFinalReport(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      propertyAddress: farm && farm.address ? farm.address : '',
      growerName: farm && farm.grower_name ? farm.grower_name : '',
      companyABN: farm && farm.abn ? farm.abn : '',
      companyName: farm && farm.company_name ? farm.company_name : '',
      postalAddress: farm && farm.postal_address ? farm.postal_address : '',
      companyEmail: farm?.email ?? '',
      companyPhone: farm?.phone_number ?? '',
      farmId: farmName,

      recordProducerName: user?.name || '',
      recordProducerCompanyName: user?.companyName || '',
      recordProducerCompanyABN: user?.abn || '',
      recordProducerCompanyAddress: user?.address || '',
      recordProducerCompanyPostal: user?.postalAddress || '',
      recordProducerCompanyEmail: user?.email || '',
      recordProducerCompanyPhone: user?.phoneNumber || '',
      agriculturalAdvisor: user?.agriculturalAdvisor || '',
      fertcareAdvisor: user?.fertcareAdvisor || '',
      fertcareAdvisorType: user?.fertcareAdvisorType || '',
      fertcareAdvisorExpiry: user?.fertcareAdvisorExpiry || '',
      attachmentsUploaded: false,
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      // setLoading(true);
      let today = new Date();
      let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
      const data = {
        fertYear: fertYear,
        finalReport: finalReport,
        sugarcaneProducerName: values.growerName,
        companyABN: values.companyABN,
        companyName: values.companyName,
        propertyAddress: values.propertyAddress,
        postalAddress: values.postalAddress,
        farmId: values.farmId,
        regionName: farm?.region_name,
        potentialYield: farm?.potential_yield,
        companyEmail: values.companyEmail,
        companyPhone: values.companyPhone,

        recordProducerName: values.recordProducerName,
        recordProducerCompanyName: values.recordProducerCompanyName,
        recordProducerCompanyABN: values.recordProducerCompanyABN,
        recordProducerCompanyAddress: values.recordProducerCompanyAddress,
        recordProducerCompanyPostal: values.recordProducerCompanyPostal,
        recordProducerCompanyEmail: values.recordProducerCompanyEmail,
        recordProducerCompanyPhone: values.recordProducerCompanyPhone,

        fertcareAdvisor: values.fertcareAdvisor,
        fertcareAdvisorType: values.fertcareAdvisorType,
        fertcareAdvisorExpiry: values.fertcareAdvisorExpiry,

        dateEffective: date,
        comments: comments,
        totalFarmRequirements: {
          title: 'Total Fertiliser Requirements',
          totalRequirementsData: { ...calculatedFarmFertTotals },
        },
        plantFertRecommendedTable: {
          title: 'Block Fertiliser Recommendation',
          subTitle: 'Recommended',
          plantProductSelection: [...plantProductSelection],
          ratoonProductSelection: [...ratoonProductSelection],
        },
        labSampleTable: {
          title: 'Lab Samples',
          labSamples: [...samples],
        },
        areaFallowed: areaFallowed,
        areaStandOver: areaStandOver,
        mapData: { ...mapData },
        mapImages: [...mapImages],
        attachmentsUploaded: values.attachmentsUploaded,
      };

      API.graphql(graphqlOperation(reportUploader.mutation, { data: JSON.stringify(data) }))
        .then(async (result) => {
          alertContext.success(`Farm report saved`);
          if (finalReport) {
            const data = {
              farm_id: parseInt(farmId),
              nextcycle: parseInt(fertYear) + 1,
            };
            API.graphql(
              graphqlOperation(updateBlocksCycle.mutation, {
                cycleBlocks: data,
              })
            )
              .then(async (res) => {
                alertContext.success(`Moved to next cycle`);
                actions.setSubmitting(false);
                setLoading(false);
                history.push(`/reportslist/${farmName}/${farmId}`);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                actions.setSubmitting(false);
                alertContext.error(err, `Error`);
              });
          }
          actions.setSubmitting(false);
          setLoading(false);
          history.push(`/reportslist/${farmName}/${farmId}`);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          actions.setSubmitting(false);
          alertContext.error(err, `Error saving the report for the farm: ${farmName}`);
        });
    },
  });

  const productsSelected = blocks
    .filter((block) => block.requirementCount) // Remove out of cycle blocks
    .map((block) => ({
      block_name: block.name,
      block_area: block.hectares,
      crop_class_id:
        block.requirements && block.requirements[0] && block.requirements[0].crop_class_id,
      crop_class_name:
        block.requirements && block.requirements[0] && block.requirements[0].crop_class_name,
      to_fallow:
        block.requirements && block.requirements[0] && block.requirements[0].to_fallow,
      to_stand_over:
        block.requirements && block.requirements[0] && block.requirements[0].to_stand_over,
      productsselected: block.requirements && block.requirements[0] && block.productsselected,
      n: block.requirements && block.requirements[0] && block.requirements[0].nitrogen,
      p: block.requirements && block.requirements[0] && block.requirements[0].phosphorus,
      k: block.requirements && block.requirements[0] && block.requirements[0].potassium,
      s: block.requirements && block.requirements[0] && block.requirements[0].sulphur,
    }))
    .filter((block) => block.productsselected !== null);

  // console.log('*********HERE************');
  // console.log(productsSelected);

  //Partition function
  function partition(array, filter) {
    let pass = [],
      fail = [];
    array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
    return [pass, fail];
  }

  // Split Plant and Ratoon
  const [plantProductSelection, ratoonProductSelection] = partition(
    productsSelected,
    (e) => e.crop_class_id <= 2
  );

  //Output
  // console.log(plantProductSelection);
  // console.log(ratoonProductSelection);

  // const listBlocks = blocks.flatMap((block) => ({
  //   block_name: block.name,
  //   block_hectares: block.hectares,
  // }));
  // console.log(blocks);
  // TODO: Question: Does this need to be filtered based on active soil sample?
  const samples = blocks.flatMap((block) =>
    (block.soil_samples || []).map((ss) => ({
      ...ss,
      active: parseInt(block.primary_sample_id) === ss.id ? true : false,
      block_name: block.name,
      block_hectares: block.hectares,
    }))
  );

  const calculateArea = (attrib) => {
    return Math.round(
      blocks.reduce(
        (prev, cur) =>
          prev +
          (cur.requirements?.length === 1
            ? parseFloat(cur.requirements[0][attrib]) || 0
            : 0) *
            100,
        0
      ),
      0
    ) / 100;
  }

  const areaFallowed = calculateArea('to_fallow');
  const areaStandOver = calculateArea('to_stand_over');
    

  // console.log(blocks);

  return (
    <>
      <PageHeader
        title={'Farm NMP Report'}
        subheader={`Viewing Report for ${farmName}`}
        backPath={'back'}
      />
      <div class='generateReportPage'>
        <Box display='flex' component='span' justifyContent='center' alignItems='center'>
          <Typography>Report Type: Preliminary</Typography>
          <Switch
            checked={finalReport}
            onChange={handleReportChange}
            name='checkedB'
            color='primary'
          />
          <Typography>Final</Typography>
        </Box>
        {finalReport && !allBlocksFinalised && (
          <Box display='flex' component='span' justifyContent='center' alignItems='center'>
            <Typography color='error'>Not all blocks have been Finalised</Typography>
          </Box>
        )}
      </div>

      <div class='generateReportPage'>
        <img src={logoBlue} class='center' alt='logo'></img>
        <FormikForm formik={formik}>
          <p>Date Effective: {date ? date : 'Not Yet Effective'}</p>
          <h3 class='header'>Grower Details</h3>
          <FormText name='growerName' label='Name of Sugarcane Producer: ' />
          <FormText name='companyName' label='Company Name: ' />
          <FormText name='companyABN' label='ABN: ' />
          <FormText name='propertyAddress' label='Property Address(es): ' />
          <FormText name='postalAddress' label='Postal Address: ' />
          <FormText name='companyEmail' label='Email: ' />
          <FormText name='companyPhone' label='Phone: ' />
          <FormText name='farmId' label='Farm Identification Number: ' disabled={true} />

          <h3 class='header'>Agronomist Details</h3>
          <FormText name='recordProducerName' label='Name of Record Producer: ' />
          <FormText name='recordProducerCompanyName' label='Company Name: ' />
          <FormText name='recordProducerCompanyABN' label='Company ABN: ' />
          <FormText name='fertcareAdvisorType' label='Accredited Advisor Type: ' />
          <FormText name='fertcareAdvisor' label='Accredited Advisor: ' />
          <FormText name='fertcareAdvisorExpiry' label='Accredited Advisor Expiry: ' />
          <FormText name='recordProducerCompanyAddress' label='Company Address: ' />
          <FormText name='recordProducerCompanyPostal' label='Company Postal: ' />
          <FormText name='recordProducerCompanyEmail' label='Email: ' />
          <FormText name='recordProducerCompanyPhone' label='Phone: ' />

          {/* <p>
            List of Lots included in all the farms within the Agricultural
            Enterprise:
          </p> */}
          {/* {listBlocks && <BlockTable listBlocks={listBlocks} />} */}

          <h3 class='header'>Total Fertiliser Requirements</h3>
          <FertTotalsTable
            totalRequirementsData={calculatedFarmFertTotals}
            areaFallowed={areaFallowed}
            areaStandOver={areaStandOver}
            finalReport={finalReport}
          />

          {/* <FormText name='comments' multiline label='Notes / Environmental risk mitigation strategies: ' /> */}
          <h3 class='header'>Notes / Environmental risk mitigation strategies</h3>
          {comments.length > 0 &&
            comments.map((el, index) => (
              <Box style={{ marginBottom: '20px' }} display='flex' justifyContent='space-between'>
                <Box >
                <Typography variant="h6">{`${index + 1}) ${el.title}`}</Typography>
                <Typography variant="body2">{el.text}</Typography>
                </Box>
                <IconButton
                  onClick={() => {
                    setComments((prevState) => prevState.filter((_, i) => i !== index));
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
          <TextField
            value={commentTitle}
            onChange={(e) => setCommentTitle(e.target.value)}
            index={`Note-title`}
            fullWidth
            label='Title'
            multiline
          />
          <TextField
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            index={`Note-comment`}
            fullWidth
            label='Note'
            multiline
          />

          <Button
            style={{ marginTop: '10px' }}
            variant='contained'
            fullWidth={true}
            color={'secondary'}
            onClick={() => {
              setComments((prevState) => [
                ...prevState,
                { title: commentTitle, text: commentText },
              ]);
              setCommentTitle('');
              setCommentText('');
            }}
          >
            Save Note
          </Button>

          {plantProductSelection && (
            <>
              <h3 class='header'>Plant Fertiliser Requirements</h3>
              <ProductSelectedTable
                productsSelected={plantProductSelection}
                finalReport={finalReport}
                producePDF={false}
              />
            </>
          )}

          {ratoonProductSelection && (
            <>
              <h3 class='header'>Ratoon Fertiliser Requirements</h3>
              <ProductSelectedTable
                productsSelected={ratoonProductSelection}
                finalReport={finalReport}
                producePDF={false}
              />
            </>
          )}

          <h3 class='header'>Lab Sample and Block Data</h3>
          {samples && <LabSampleTable labSamples={samples} />}
          <br />
          <ReportMap
            mapData={mapData}
            onCapture={(dataURL) => {
              setMapImages((arr) => [...arr, dataURL]);
            }}
          />

          <h3 class='header'>Captured Images</h3>
          <ImageList rowHeight='auto' cols={3}>
            {mapImages.map((dataURL, index) => (
              <ImageListItem key={dataURL}>
                <img
                  src={dataURL}
                  alt={`Map Capture #${index + 1}`}
                  style={{ maxWidth: '100%', display: 'block' }}
                />
                <ImageListItemBar
                  position='top'
                  style={{ background: 'none' }}
                  actionIcon={
                    <IconButton
                      onClick={() => {
                        setMapImages((images) => {
                          const newMapImages = [...images];
                          newMapImages.splice(index, 1);
                          return newMapImages;
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          
          <FormCheckbox name="attachmentsUploaded" label="Available sampling plans and lab results have been uploaded and are retrievable through LandHub."/>
          <FormButton text='Generate Report' />
        </FormikForm>
      </div>
    </>
  );
}
