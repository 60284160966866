import React from "react";
import {
  CardHeader,
  IconButton,
  InputBase,
  Tooltip,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Add,
  ArrowBack,
  Search as SearchIcon,
  Lock,
  PinDrop,
} from "@material-ui/icons";
// import { fade } from "@material-ui/core/styles/colorManipulator";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

export default function PageHeader(props) {
  const classes = style();
  const history = useHistory();
  const {
    title,
    subheader,
    showSearch,
    setQuery,
    backPath,
    addPath,
    resetPassword,
    placeholder,
    pinDrop,
    addFarm
  } = props;

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleBack = () => {
    console.log("backPath", backPath);
    history.goBack();
    // if (backPath === "back") history.goBack();
    // else history.push(backPath);
  };

  return (
    <Box displayPrint="none">
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <>
            {pinDrop && (
              <Tooltip title="Open Map">
                <IconButton
                  aria-label="View"
                  variant="link"
                  target={"_t"}
                  href={`https://www.google.com/maps/place/${pinDrop.lat},${pinDrop.lng}/@${pinDrop.lat},${pinDrop.lng},466m/data=!3m1!1e3!4m5!3m4!7e2!8m2!3d${pinDrop.lat}!4d${pinDrop.lng}`}
                >
                  <PinDrop color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {backPath && (
              <Tooltip title="Back" aria-label="list">
                <IconButton aria-label="arrow_back" onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            )}
            {addFarm && (
              <Tooltip title="Add Farm" aria-label="list">
                <IconButton aria-label="arrow_back" onClick={() => addFarm()}>
                  <Add />
                </IconButton>
              </Tooltip>
            )}
            {resetPassword && (
              <Tooltip title="Reset Password" aria-label="reset_password">
                <IconButton aria-label="reset_password" onClick={resetPassword}>
                  <Lock />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        
      </CardHeader>
      {showSearch && (
        <div className={classes.headSearch}>
          <IconButton className={classes.iconButton} aria-label="Search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            onChange={handleQuery}
            placeholder={placeholder ? placeholder : "Search..."}
          />
          {addPath && (
            <div style={{ float: "right" }}>
              <Tooltip title="Create New">
                <IconButton
                  edge="end"
                  aria-label="View"
                  color="primary"
                  component={Link}
                  to={addPath}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </Box>
  );
}

const style = makeStyles((theme) => ({
  header: {
    margin: 10,
  },
  headSearch: {
    width: "100%",
    backgroundColor: alpha("rgba(228, 233, 237, 50)", 0.15),
    "&:hover": {
      backgroundColor: alpha("rgba(228, 233, 237, 1)", 0.25),
    },
    flexDirection: "row",
  },
  input: {
    width: "50%",
  },
}));
