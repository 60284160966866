import { IconButton, Tooltip } from '@material-ui/core';
import { ExploreOutlined } from '@material-ui/icons';
import { useCallback } from 'react';
import { useCallbackRef } from './useCallbackRef';

export const NavigateShapeButton = (props) => {
  const { disabled, onClick, ...rest } = props;

  const invokeOnClick = useCallbackRef(onClick);

  const handleClick = useCallback(() => {
    invokeOnClick();
  }, [invokeOnClick]);

  return (
    <Tooltip title={disabled ? '' : 'Show in map'}>
      <div>
        <IconButton disabled={disabled} onClick={handleClick} {...rest}>
          <ExploreOutlined color={disabled ? 'disabled' : 'secondary'} />
        </IconButton>
      </div>
    </Tooltip>
  );
};
