import React, { useState, useContext, useCallback } from "react";

// Queries
import { requirementPlant } from "../../api/mutations/requirements/requirementPlant.js";

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from "../../contexts/UserContext.js";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";

import { varieties } from "../../functions/offlineQueries";

// function addDays(date, days) {
//   var result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result;
// }

function getDifferenceInDays(fromDate, ToDate) {
  return (
    (new Date(fromDate).getTime() - new Date(ToDate).getTime()) /
    (1000 * 3600 * 24)
  );
}

export default function PlantDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const { visible, fertYear, block, handleClose } = props;
  
  const soilSampleDate =
    block.primary_sample_id == null
      ? undefined
      : block.soil_samples?.filter(
          (ss) => ss.id?.toString() === block.primary_sample_id?.toString()
        )[0].sampling_date;

  const currentDate = new Date();
  let currentDateStr = currentDate.toISOString().split("T")[0];

  const [estimatePlantDate, setEstimatePlantDate] = useState(currentDateStr);

  const soilDate = new Date(soilSampleDate);
  const soilDateStr =
    soilDate.getDate() +
    "/" +
    (soilDate.getMonth() + 1) +
    "/" +
    soilDate.getFullYear();

  let soilSampleTime = soilDate.getTime();
  let differenceInDays =
    (new Date(estimatePlantDate).getTime() - soilSampleTime) /
    (1000 * 3600 * 24);

  let daysSincePloughout = block.requirements[0].ploughout_date
    ? getDifferenceInDays(
        estimatePlantDate,
        block.requirements[0].ploughout_date
      )
    : 365;
  // console.log(daysSincePloughout);
  const [possibleReplant, setPossibleReplant] = useState(daysSincePloughout < 182 ? true : false);

  const [replant, setReplant] = useState(false);

  const handleDateChange = (event) => {
    setEstimatePlantDate(event.target.value);
    let daysSincePloughout = block.requirements[0].ploughout_date
      ? getDifferenceInDays(
          event.target.value,
          block.requirements[0].ploughout_date
        )
      : 365;
      setPossibleReplant(daysSincePloughout < 182 ? true : false);
  };
  // let ploughOutDays =
  //   new Date(block.requirements[0].ploughout_date).getTime() /
  //   (1000 * 3600 * 24);

  // console.log(soilSampleDate);
  // let daysSincePloughout = block.requirements[0].ploughout_date
  //   ? (new Date(estimatePlantDate).getTime() -
  //       new Date(block.requirements[0].ploughout_date).getTime()) /
  //     (1000 * 3600 * 24)
  //   : 365;
  
  const [variety, setVariety] = useState(1);
  const handleVarietyChange = (event) => {
    setVariety(event.target.value);
  };

  const acceptDisabled =
    soilSampleDate == null ? true : differenceInDays > 365 ? true : false;

  /* 
  If a block enters a cycles it should generate the requirements(or assign the cane requirements to the blocks requirements) , fert year become current year and cropClass is now fallow.
  If a block gets planted, the date and variety is recorded, Block become Plant (R0)
  If a block has mill mud applied the deduction come from the following years requirements
    */

  // input Requirement {
  //   requirementId: String
  //   requirement_state_id: String
  //   plant_date: String
  //   block_id: String
  // }

  // handleAccept={(plantDate, variety) =>
  //   handlePlantDate(plantDate, variety)
  // }
  const [newCrop, setNewCrop] = useState(false);
  const [newCropType, setNewCropType] = useState("");

  const handlePlantDate = useCallback(async () => {
    // console.log("Plant on ", estimatePlantDate, " Variety: ", variety);

    try {
      const result = await requirementPlant({
        token: user?.idToken?.jwtToken,
        blockId: block.id,
        primarySampleId: block.primary_sample_id,
        fertYear,
        plantDate: estimatePlantDate,
        varietyId: variety,
        newCrop,
        newCropType,
        replant,
        requirementId:
          block.requirements && block.requirements.length > 0
            ? block.requirements[0].id
            : null,
      });

      alertContext.success(`Requirement updated`);
      console.log(result);
      dispatchBlock({
        type: "MODIFY_REQUIREMENT",
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            plant_date: estimatePlantDate,
            variety_id: variety,
            variety_name: newCrop
              ? newCropType
              : varieties.find((item) => item.value === variety).name,
            nitrogen: replant
              ? block.requirements[0].nitrogen + 20
              : block.requirements[0].nitrogen,
            crop_class_id: replant ? 17 : block.requirements[0].crop_class_id,
            crop_class_name: replant
              ? "PORP"
              : block.requirements[0].crop_class_name,
          },
        },
      });
      handleClose(null);
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block.id, block.primary_sample_id, block.requirements, dispatchBlock, estimatePlantDate, fertYear, handleClose, newCrop, newCropType, replant, user?.idToken?.jwtToken, variety]);

  return (
    <Dialog
      open={visible}
      onClose={() => handleClose(null)}
      aria-labelledby="plant-dialog-title"
      aria-describedby="plant-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="plant-dialog-title"
      >
        {possibleReplant ? "Replant" : "Plant"} Block {block.name}
      </DialogTitle>
      <DialogContent>
        {soilSampleDate == null ? null : (
          <Typography>Soil Sample Date: {soilDateStr}</Typography>
        )}
        {soilSampleDate == null ? (
          <Typography color="error">
            A valid soil sample will have to be selected
          </Typography>
        ) : differenceInDays > 365 ? (
          <Typography color="error">
            Planting must occur within 365 days
          </Typography>
        ) : null}

        <TextField
          id="date"
          label={"Plant date"}
          type="date"
          defaultValue={estimatePlantDate} //"2021-02-24" //
          fullWidth
          onChange={handleDateChange}
        />

        <FormControl fullWidth>
          {newCrop ? (
            <TextField
              id="newCropType"
              type="text"
              label={"Crop type"}
              // defaultValue={"Type here"}
              // fullWidth
              onChange={(e) => setNewCropType(e.target.value)}
            />
          ) : (
            <>
              <InputLabel id="variety_label">Crop Type</InputLabel>
              <Select
                name="fallow_crop_id"
                label="Crop type"
                value={variety}
                // fullWidth
                onChange={handleVarietyChange}
              >
                {varieties.map((variety, index) => (
                  <MenuItem value={variety.value} key={index}>
                    {variety.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="New Crop type?"
            onChange={(e) => setNewCrop(e.target.checked)}
          />
        </FormGroup>
        {possibleReplant && (
          <>
            <Typography>Planting within 6 months of Plough out</Typography>
            <Typography>Allows you to change this to PORP</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="PORP Block?"
                onChange={(e) => setReplant(e.target.checked)}
              />
            </FormGroup>
          </>
        )}
        <Button
        onClick={() => handleClose('ToFallow')}
        variant='contained'
        color={"secondary"}

      >
          SKIP TO FINALISE
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(null)} color="primary">
          Cancel
        </Button>
        <Button
          disabled={acceptDisabled}
          onClick={() => handlePlantDate()}
          color="primary"
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
