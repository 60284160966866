import React, { createContext, useReducer } from "react";
import { BlockReducer } from "../reducers/BlocksReducer"; //"../reducers/BlockReducer";

export const BlockContext = createContext();

const BlockContextProvider = (props) => {
  const [blocks, dispatchBlock] = useReducer(BlockReducer, []);

  return (
    <BlockContext.Provider value={{ blocks, dispatchBlock }}>
      {props.children}
    </BlockContext.Provider>
  );
};

export default BlockContextProvider;
