import React, {useCallback, useEffect, useState} from "react";
import {API, graphqlOperation} from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { getSugarPrices, getFertPrices } from "../graphQL/Product";
//Table Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

//Tab Components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';

function TabPanel(props){
    const {children, value, index, ...other} = props;

    return (
        <div
            role = "tabpanel"
            hidden = {value !== index}
            id = {`simple-tabpanel-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index){
    return{
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
}));

export default function SugarPrices(props) {
    const classes = useStyles();

    const[items, setItems] = useState([])
    const[fert, setFert] = useState([])
    const[DataisLoaded, setDataisLoaded] = useState(false)
    const[value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const getSugarDetails = useCallback(() => {
        API.graphql(graphqlOperation(getSugarPrices.query, {}))
            .then(async (result) => {
                //console.log('RESULT', result)
                const sugarPrices = JSON.parse(result.data.getSugarPrices)
                console.log('SUGAR', sugarPrices)
                setItems(sugarPrices)
                setDataisLoaded(true)

            })
            .catch(err => {
                console.log('Error', err);
            })
    }, [])

    const getFertDetails = useCallback(() => {
        API.graphql(graphqlOperation(getFertPrices.query, {}))
            .then(async (result) => {
                //console.log('RESULT', result)
                const fertPrices = JSON.parse(result.data.getFertPrices)
                console.log('FERT', fertPrices)
                setFert(fertPrices)
            })
            .catch(err => {
                console.log('Error', err);
            })
    }, [])

    useEffect(()=> {
        getSugarDetails();

    }, [getSugarDetails]);

    useEffect(() => {
        getFertDetails();
    }, [getFertDetails]);

    return(
        <div>
            {
                DataisLoaded === false ?
                    <div>
                        <h1> Pleses wait some time.... </h1>
                    </div>
                    :
                    <div className = "App">

                        <div className={classes.root}>
                            <AppBar position="static">
                                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                    <Tab label="Sugar Prices" {...a11yProps(0)}/>
                                    <Tab label="Fertlizer Prices" {...a11yProps(1)}/>
                                </Tabs>
                            </AppBar>
                        </div>

                        <TabPanel value={value} index={0}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date Updated</TableCell>
                                            <TableCell>USD Exchange Rate</TableCell>
                                            <TableCell>mt_otc</TableCell>
                                            <TableCell>mt_ips_otc</TableCell>
                                            <TableCell>us_clb_prompt</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.updateddate}</TableCell>
                                                <TableCell>{item.aud_usa_spot}</TableCell>
                                                <TableCell>{item.mt_otc}</TableCell>
                                                <TableCell>{item.mt_ips_otc}</TableCell>
                                                <TableCell>{item.us_clb_prompt}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size ="small" areia-aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Month Updated</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Change from Last Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fert.map((ferts) => (
                                            <TableRow key={ferts.id}>
                                                <TableCell>{ferts.month}</TableCell>
                                                <TableCell>{ferts.price}</TableCell>
                                                <TableCell>{ferts.change_percentage}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </div>
            }
        </div>
    )

}
