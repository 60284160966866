import { API, graphqlOperation } from 'aws-amplify';
// import { Link, useHistory } from "react-router-dom";
import React, {useCallback, useEffect, useState} from 'react'
import FormButton from "../components/FormButton";
// import FormCheckbox from "../components/FormCheckbox";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormSelect from "../components/FormSelect";
import FormText from "../components/FormText";
import FormikForm from "../components/FormikForm";
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
//import { RequirementSchema } from "../components/ValidationSchema";
//import getProducts from "../graphQL/GetProducts";
import { getManufacturers } from "../graphQL/Product";
import { useAlertContext } from '../components/AlertContext'
import { createProduct } from '../graphQL/Product'
import {useFormik} from "formik";
import { useHistory } from "react-router-dom";

export default function ManageSoilSample(props) {
    
//    const [ products, setProducts] = useState(null);
    // const [ product, setProduct ] = useState(null)
    const [ loading, setLoading ] = useState(false);
    const history = useHistory();

    const alertContext = useAlertContext();
    const [manufacturers, setManufacturers] = useState([]);

  // const handleChange = (event) => {
  //   setManufacturer(event.target.value);
  // };


// const fetchProducts = useCallback(() => {
//     setLoading(true);
//     //2 is General fertilzer, 1 is Ameliorants
//     API.graphql(graphqlOperation(getProducts.query, { productTypeId: 2 }))
//       .then(async (result) => {
//         setProducts(JSON.parse(result.data.getProducts)); 
//         setLoading(false);
//       })
//       .catch(async (err) => {
//         console.log(err);
//         setLoading(false);
//         alertContext.error(
//           err,
//           "Error fetching Products - please check your network connection and try again"
//         );
//       });
//   }, [alertContext]);

//   useEffect(() => {

//     fetchProducts();
      
//   }, [fetchProducts, props]);

  const fetchManufacturers = useCallback(() => {
    setLoading(true);
    API.graphql(graphqlOperation(getManufacturers.query))
    .then(async (result) => {
      setManufacturers(JSON.parse(result.data.getManufacturers))
      setLoading(false);
    })
    .catch(async (err) => {
      console.log(err)
      setLoading(false);
      alertContext.error(err, 'Error Fetching Manufacturers - Please check your network connection and try again');
    });
  }, [alertContext]);

  useEffect(() => {
    fetchManufacturers();
      
  }, [fetchManufacturers, props])

  const formik = useFormik({
    initialValues: {product_name: "",manufacturer: "", n: 0, p: 0, k: 0, s: 0, zn: 0, si: 0, ca: 0, cu: 0, mg: 0, oc: 0},
    enableReinitialize: true,
    onSubmit: (values, actions) => {
        setLoading(true)
        const data = {
            product_name: values.product_name,
            product_manufacturer_id: values.manufacturer,
            n: values.n,
            p: values.p,
            k: values.k,
            s: values.s,
            zn: values.zn,
            si: values.si,
            ca: values.ca,
            cu: values.cu,
            mg: values.mg,
            oc: values.oc
        }
        API.graphql(graphqlOperation(createProduct.mutation, { data } ))
            .then(async (result) => {
                    alertContext.success(`Product updated`);
                    actions.setSubmitting(false);
                    setLoading(false)
                    history.push(`/products/`)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                actions.setSubmitting(false);
                alertContext.error(err, `Error creating the product`)
            })
        }
    });

    const manufacturerOptions = manufacturers.map((m) => ({
        name: m.name,
        value: m.id
    }))

    return (
        <LogoCard margin={20}>
            <LoadingBackground visible={loading} />
            <PageHeader title="Add Product:"
                subheader="Use the below form to add a product"
                        backPath={`/Products`}/>
            <div style={{ padding: 20 }}>
                {
                  <FormikForm formik={formik}>
                    <FormText name="product_name" label="Product Name:" type="text" />
                    <FormSelect name="manufacturer" label="Manufacturers:" options={manufacturerOptions} />
                    <FormText name="n" label="Nitrogen:" type="text" />
                    <FormText name="p" label="Phosphorus:" type="text" />
                    <FormText name="k" label="Potassium:" type="text" />
                    <FormText name="s" label="Sulfur:" type="text" />
                    <FormText name="zn" label="Zinc:" type="text" />
                    <FormText name="si" label="Silicon:" type="text" />
                    <FormText name="ca" label="Calcium:" type="text" />
                    <FormText name="cu" label="Copper:" type="text" />
                    <FormText name="mg" label="Magnesium:" type="text" />
                    <FormText name="oc" label="Organic Carbon:" type="text" />
                    <FormButton text={'Add Product'} />
                  </FormikForm>
                }
            </div>
        </LogoCard>
    );
}
