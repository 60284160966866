import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function Silicate(props) {
  const { sample, tableData, requirement } = props;

  return (
    <div>
      <Typography
        variant='h6'
        style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Silicate guidelines based on reserves and available soil silicon (Si) (${requirement && requirement.silicate})`}
      </Typography>
      <Typography style={{ marginBottom: 10 }}>Si (BSES): {sample.silicon_bses_mg_kg}</Typography>
      <Typography style={{ marginBottom: 10 }}>Si (CaCl): {sample.silicon_cacl2_mg_kg}</Typography>
      <TableContainer style={{ marginBottom: 20 }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Si (BSES/sulphuric acid)</TableCell>
              <TableCell></TableCell>
              <TableCell>Si (CaCl)</TableCell>
              <TableCell>Suggested application rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell>
                    Si (mg/kg)
                </TableCell>
                <TableCell>
                    {`< ${tableData.silicate[0].silicate_bses_sulphuric_acid_max}`}
                </TableCell>
                <TableCell>
                    and
                </TableCell>
                <TableCell>
                {`< ${tableData.silicate[0].silicate_cacl_max}`}
                </TableCell>
                <TableCell style={{
                    backgroundColor: sample.silicon_bses_mg_kg < tableData.silicate[0].silicate_bses_sulphuric_acid_max && sample.silicon_cacl2_mg_kg < tableData.silicate[0].silicate_cacl_max ? 'rgba(65, 172, 210, 0.6)' : '#FFF',
                  }}>
                  {tableData.silicate[0].silicate_suggested_application_rate}
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
