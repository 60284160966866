import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphQL/Account";

const useUser = (cognitoId) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(getUser.query, { cognitoId })
        );
        console.log(result)
        setUser(JSON.parse(result.data.getUser));
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, [cognitoId]);

  return { user };
};

export default useUser;