import EnvConfig from './EnvConfig'
import { Auth } from "aws-amplify";

export const awsConfig = {
	Auth: {
		region: 'ap-southeast-2',
		userPoolId: EnvConfig.terraform.user_pool_id,
		userPoolWebClientId: EnvConfig.terraform.web_client_id,
		identityPoolId: EnvConfig.terraform.identity_pool_id
	},
	'aws_appsync_graphqlEndpoint': EnvConfig.terraform.appsync_graphql_endpoint,
	'aws_appsync_region': 'ap-southeast-2',
	'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
	'graphql_headers':  async () => { return { Authorization: (await Auth.currentSession()).idToken.jwtToken } },
	Storage: {
		AWSS3: {
			bucket: EnvConfig.terraform.user_bucket,
			region: 'ap-southeast-2',
			identityPoolId: EnvConfig.terraform.identity_pool_id
		}
	}
}

// const aws_config_test = {
//     Auth: {
//         region: "ap-southeast-2",
//         userPoolId: "ap-southeast-2_ZHDbppcyg",
//         userPoolWebClientId: "4m40oogd4ps1fg5t35m375co6f",
//         identityPoolId: 'ap-southeast-2:b4b0816c-8887-408d-a134-dde2a383abd7',
//     },
//     'aws_appsync_graphqlEndpoint': "https://pfi7cusxdber3emtejzy5gkr4i.appsync-api.ap-southeast-2.amazonaws.com/graphql",
//     'aws_appsync_region': 'ap-southeast-2',
//     'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
//     'graphql_headers':  async () => { return { Authorization: (await Auth.currentSession()).idToken.jwtToken } },
//     Storage: {
//         AWSS3: {
//             bucket: 'land-user-bucket-test',
//             region: 'ap-southeast-2',
//             identityPoolId: 'ap-southeast-2:b4b0816c-8887-408d-a134-dde2a383abd7'
//         }
//     },
// };

// const aws_config_prod = {
//     Auth: {
//         region: "ap-southeast-2",
//         userPoolId: "ap-southeast-2_GP4S0X6Ze",
//         userPoolWebClientId: "7bn2neja44g51q7t5lsm9c3a9k",
//         identityPoolId: 'ap-southeast-2:2061f062-8ea6-48fc-a05f-f77db930c63e',
//     },
//     'aws_appsync_graphqlEndpoint': 'https://bslhlfrzo5bfnlqzowadwzmcem.appsync-api.ap-southeast-2.amazonaws.com/graphql',
//     'aws_appsync_region': 'ap-southeast-2',
//     'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
//     'graphql_headers':  async () => { return { Authorization: (await Auth.currentSession()).idToken.jwtToken } },
//     Storage: {
//         AWSS3: {
//             bucket: 'land-user-bucket',
//             region: 'ap-southeast-2',
//             identityPoolId: 'ap-southeast-2:2061f062-8ea6-48fc-a05f-f77db930c63e'
//         }
//     },
// };

// export { aws_config_test, aws_config_prod };




