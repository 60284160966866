export const resetPassword = {
    mutation: `mutation resetPassword($username: String) {
        resetPassword(username: $username) 
    }`
};

export const listAccounts = { 
    query: `query listAccounts {
        listAccounts
    }`
};

export const getFarmUsers = { 
    query: `query getFarmUsers($farmId: String) {
        getFarmUsers(farmId: $farmId)
    }`
};
  
export const getDBUsers = {
    query: `query getDBUsers {
      getDBUsers
    }`
  }

export const addFarmUsers = {
    mutation: `mutation addFarmUsers($farmId: String, $userIds: [String]) {
        addFarmUsers(farmId: $farmId, userIds: $userIds)
    }`,
};

export const editAccount = {
    mutation: `mutation editAccount($account: User) {
        editAccount(account: $account)
    }`
};

export const deleteAccount = {
    mutation: `mutation deleteAccount($username: String, $sub: String) {
        deleteAccount(username: $username, sub: $sub) {
            success
            result
        }
    }`
};

export const resendAccountInvite = {
    mutation: `mutation resendAccountInvite($username: String) {
        deleteAccount(username: $username) {
            success
            result
        }
    }`
};

export const createAccount = {
    mutation: `mutation createAccount($account: User) {
        createAccount(account: $account)
    }`
};

export const getUser = { 
    query: `query getUser($cognitoId: String) {
        getUser(cognitoId: $cognitoId)
    }`
};


  
  
  