import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function UnAuthRoute({ render: C, props: childProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={rProps =>
        !childProps.isLoggedIn ? (
          <C {...rProps} {...childProps} />
        ) : (
          <Redirect
            to={`/`}
          />
        )
      }
    />
  );
}
