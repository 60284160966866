import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// import createPalette from '@material-ui/core/styles/createPalette';
// FUNCTIONS
import { sortProductsByAppTiming } from "../../functions/sortProducts";
import { Notes, SubdirectoryArrowRight } from "@material-ui/icons";

export default function ProductSelectedTable(props) {
  const classes = style();
  const {productsSelected: selectedProducts, finalReport, producePDF} = props;
  // const finalReport = props.finalReport;
  // console.log(selectedProducts);

  const columns = [
    { id: "apptiming", label: "Timing", minWidth: "13%" },
    { id: "appmethod", label: "Method", minWidth: "9%" },
    { id: "product", label: "Product", minWidth: "19%" },
    { id: "applicationRate", label: "Rate (kg/L ha)", minWidth: "9%" },
    { id: "suppliedN", label: "N (kg/ha)", minWidth: "2%" },
    { id: "suppliedP", label: "P (kg/ha)", minWidth: "2%" },
    { id: "suppliedK", label: "K (kg/ha)", minWidth: "2%" },
    { id: "suppliedS", label: "S (kg/ha)", minWidth: "2%" },
    { id: "applied", label: "Date Applied", minWidth: "10%" },
    { id: "areaApplied", label: "Area Applied", minWidth: "3%" },
    { id: "recordedDate", label: "Recorded Date", minWidth: "10%" },
    {
      id: "comments",
      label: finalReport ? "Recorded By" : "Comments",
      minWidth: "18%",
    },
  ];
  return (
    <>
      <TableContainer className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellHeaderText} style={{padding: 0}} />
              {columns.map((column, index) => (
                <TableCell
                  className={classes.cellHeaderText}
                  key={index} //column.id
                  align={"center"}
                  style={{
                    width: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProducts &&
              selectedProducts.map((sP, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell className={classes.cellSubHeaderText} style={{padding: 0}}/>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      colSpan={2}
                    >
                      Block: {sP.block_name}
                    </TableCell>
                    <TableCell className={classes.cellSubHeaderText}>
                      {sP.crop_class_name}
                    </TableCell>
                    <TableCell className={classes.cellSubHeaderText}>
                      {sP.n ? "Allocated" : null}
                    </TableCell>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      align={"center"}
                    >
                      {sP.n}
                    </TableCell>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      align={"center"}
                    >
                      {sP.p}
                    </TableCell>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      align={"center"}
                    >
                      {sP.k}
                    </TableCell>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      align={"center"}
                    >
                      {sP.s}
                    </TableCell>
                    <TableCell
                      className={classes.cellSubHeaderText}
                      colSpan={columns.length - 9}
                      align={"center"}
                    >{(sP.to_fallow != null || sP.to_stand_over != null) ? `(${sP.to_fallow}ha Fallowed, ${sP.to_stand_over || 0}ha Stand Over)` : producePDF ? `` : `(Not Finalised)`}</TableCell>
                    <TableCell className={classes.cellSubHeaderText}>
                      {sP.block_area}ha
                    </TableCell>
                  </TableRow>
                  {sP.productsselected && sortProductsByAppTiming(sP.productsselected).map((product, index) => (
                    <React.Fragment key={`product-${product.id}`}>  
                      <TableRow
                        style={{
                          backgroundColor:
                            finalReport &&
                            product.applied !== parseFloat(sP.block_area)
                              ? "rgba(160, 0, 0, 0.1)"
                              : null,
                        }}
                      >
                        <TableCell style={{padding: 0, border: 0}}>
                          {product.comment !== null && (
                            <Notes color='action' />
                          )}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                        >
                          {product.apptiming}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                        >
                          {product.appmethod}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                        >
                          {product.name}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {product.apprate}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {Math.round((product.n * product.apprate) / 100)}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {Math.round((product.p * product.apprate) / 100)}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {Math.round((product.k * product.apprate) / 100)}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {Math.round((product.s * product.apprate) / 100)}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {finalReport && product.applieddate
                            ? `${product.applieddate.split("-")[2]}/${
                                product.applieddate.split("-")[1]
                              } ${product.applieddate.split("-")[0]}`
                            : null}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {finalReport && product.applied
                            ? `${product.applied}ha`
                            : null}
                        </TableCell>
                        <TableCell
                          className={[classes.cellText, classes.borderless].join(' ')}
                          component="th"
                          scope="row"
                          align={"center"}
                        >
                          {finalReport && product.recordeddate
                            ? `${product.recordeddate.split("-")[2]}/${
                                product.recordeddate.split("-")[1]
                              } ${product.recordeddate.split("-")[0]}`
                            : null}
                        </TableCell>
                        {
                          finalReport ? (
                              <TableCell
                              className={[classes.cellText, classes.borderless].join(' ')}
                              component="th"
                              scope="row"
                              align={"center"}>
                                {product?.applied_by_user_name}
                            </TableCell>
                          ) : (
                            <TableCell
                              className={[classes.cellText, classes.borderless].join(' ')}
                              component="th"
                              scope="row"
                              align={"center"}
                            >
                              {/* NOTE: This will never show */}
                              {finalReport &&
                              product.applied !== parseFloat(sP.block_area)
                                ? `${
                                    Math.round((parseFloat(sP.block_area) - product.applied)*100)/100
                                  }ha Not Applied`
                                : null}
                            </TableCell>
                          )
                        }
                      </TableRow>
                      <TableRow
                        style={{
                          backgroundColor:
                            finalReport &&
                            product.applied !== parseFloat(sP.block_area)
                              ? "rgba(160, 0, 0, 0.1)"
                              : null,
                        }}
                      >
                        <TableCell style={{padding: 0}} align="right">
                          {product.comment !== null &&
                            <SubdirectoryArrowRight color="action" style={{width: 18}}/>

                          }
                        </TableCell>
                        <TableCell colSpan={15} className={classes.cellText} style={{paddingTop: 0, paddingBottom: 0}}>
                          {product.comment}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: "100%",
  },
  cellHeaderText: {
    fontSize: "12px",
    backgroundColor: "rgba(0, 161, 201, 0.1)",
  },
  cellSubHeaderText: {
    fontSize: "12px",
    backgroundColor: "rgba(0, 157, 72, 0.05)",
  },
  cellText: {
    fontSize: "12px",
  },
  borderless: {
    border: 0,
  },
}));
