import React, { useMemo } from 'react';
import { validateFarm } from '../FarmsTabContent';
import { Overview } from './Overview';

export const FarmsOverview = (props) => {
  const { farms, data } = props;

  const farmItems = useMemo(
    () =>
      farms.map((farm) => {
        const { isValid, errors } = validateFarm(farm, data);
        const item = { value: farm.name };
        return isValid ? item : { ...item, errors };
      }),
    [farms, data]
  );

  return <Overview itemLabel={'Farm'} items={farmItems} />;
};
