//@format
import React, {useState, useEffect, useRef, useMemo, useReducer, forwardRef} from 'react';
import {Marker, GeoJSON} from 'react-leaflet';
import leaflet from 'leaflet';

const DraggableMarker = ({position, newPosition, iconSize, deletedMarker = () => {}}) => {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dblclick() {
                deletedMarker();
            },
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    newPosition(marker.getLatLng());
                }
            },
        }),
        [newPosition, deletedMarker],
    );

    const svgIcon = leaflet.divIcon({
        html: `
<svg width="${iconSize * 4}" height="${iconSize * 4}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="${iconSize * 2}" cy="${
            iconSize * 2
        }" r="${iconSize}" fill="rgba(0,0,255,0.2)" stroke="rgba(0,0,255,0.6)" stroke-width="3"/>
</svg>`,
        className: 'svg-icon',
        iconSize: [iconSize * 4, iconSize * 4],
        iconAnchor: [iconSize * 2, iconSize * 2],
    });
    return <Marker icon={svgIcon} draggable={true} eventHandlers={eventHandlers} position={position} ref={markerRef} />;
};

const multiPolygonControls = (geometry, update) => {
    const polyControls = [];
    const pointArrays = (
        geometry.type === 'Polygon'
          ? [geometry.coordinates]
          : geometry.type === 'MultiPolygon'
          ? geometry.coordinates
          : []
      ).flat();

    pointArrays.forEach((points, polygonIndex) => {
        let last = null;
        for (let k = 0; k < points.length; k++) {
            const point = points[k];
            if (k === 0) {
                last = point;
                continue;
            }

            polyControls.push(
                <DraggableMarker
                    iconSize={8}
                    key={`${polygonIndex}-${k}`}
                    position={[point[1], point[0]]}
                    deletedMarker={() => {
                        if (points.length <= 4) return;
                        if (k === 0 || k === points.length - 1) {
                            points.splice(0, 1);
                            points[points.length - 1] = points[0];
                        } else {
                            points.splice(k, 1);
                        }
                        update(geometry);
                    }}
                    newPosition={np => {
                        points[k] = [np.lng, np.lat];
                        if (k === points.length - 1) {
                            points[0] = [np.lng, np.lat];
                        }
                        update(geometry);
                    }}
                />,
            );
            // Add the control half way between "last" and this position
            polyControls.push(
                <DraggableMarker
                    iconSize={4}
                    key={`${polygonIndex}-${k}-mid`}
                    position={[points[k][1] + (last[1] - points[k][1]) / 2, points[k][0] + (last[0] - points[k][0]) / 2]}
                    newPosition={np => {
                        points.splice(k, 0, [np.lng, np.lat]);
                        update(geometry);
                    }}
                />,
            );
            last = points[k];
        }
        });
    return polyControls;
};

const featureControls = (f, update) => {
    return geojsonControls(f.geometry, updated => {
        f.geometry = updated;
        update(f);
    });
};

const featureCollectionControls = (fc, update) => {
    const controls = [];
    fc.features?.forEach((f, i) => {
        const fcontrols = geojsonControls(f, updated => {
            fc.features[i] = updated;
            update(fc);
        });
        controls.push(...fcontrols);
    });
    return controls;
};

const geojsonControls = (gj, update) => {
    if (gj.type === 'Polygon' || gj.type === 'MultiPolygon') {
        return multiPolygonControls(gj, updated => {
            update(updated);
        });
    } else if (gj.type === 'FeatureCollection') {
        return featureCollectionControls(gj, updated => {
            update(updated);
        });
    } else if (gj.type === 'Feature') {
        return featureControls(gj, updated => {
            update(updated);
        });
    } else {
        return [];
    }
};

export const GeojsonEdit = forwardRef(({edit = false, data, onEdit, ...rest}, ref) => {
    const [dataState, dataDispatch] = useReducer(
        (_, action) => {
            if (ref != null) {
                ref.current = action;
            }
            return {geojson: JSON.parse(JSON.stringify(action)), key: Math.ceil(Math.random() * 1000)};
        },
        {geojson: data, key: Math.ceil(Math.random() * 1000)},
    );
    const [controls, setControls] = useState([]);
    const onEditRef = useRef(onEdit);
    useEffect(() => {
        onEditRef.current = onEdit;
    }, [onEdit]);

    useEffect(() => {
        setControls(geojsonControls(dataState.geojson, updated => {
            onEditRef.current?.(updated);
            return dataDispatch(updated);
        }));
    }, [dataState.geojson]);

    return (
        <>
            {edit === true && controls}
            <GeoJSON key={dataState.key} data={dataState.geojson} {...rest} />
        </>
    );
});
