import React, {useEffect, useState, useCallback} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import LoadingBackground from "../components/LoadingBackground";
import { getDashboard } from "../graphQL/Report";

const useStyles = makeStyles(theme => ({
    iframe: {
        height: '90vh',
        width: '100vw'
    }
}));

function Dashboard(props) {
    const classes = useStyles();
    const [ dashboard, setDashboard ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const getDashboardUrl = useCallback( () => {
        setLoading(true);
        const parameters = JSON.stringify({
            auth: props.user.idToken,
        });

        API.graphql(graphqlOperation(getDashboard.query, { data: parameters }))
            .then(async (result) => {
                setDashboard(JSON.parse(result.data.getDashboard));
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }, [props]);

    useEffect( () => {
        getDashboardUrl()
    }, [getDashboardUrl]);

    return (
        <div id="dashboardContainer">
            <LoadingBackground visible={loading}/>
            <iframe
                frameBorder="0"
                title="Reports"
                className={classes.iframe}
                src={dashboard}
            />
        </div>
    );
}

export default Dashboard;
