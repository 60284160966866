function desc(a, b, orderBy) {
    switch (typeof a[orderBy]) {
        case 'string':
            if(b[orderBy] && a[orderBy]) {
                if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
                    return -1;
                }
                if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
                    return 1;
                }
            }
            return 0;
        case 'number':
            if(b[orderBy] && a[orderBy]) {
                return b[orderBy] - a[orderBy];
            }
            return 0;
        case 'date':
            if(b[orderBy] && a[orderBy]) {
                return new Date(b[orderBy]) - new Date(a[orderBy]);
            }
            return 0;
        default:
            break;
    }
}




function stableSort(array, cmp) {
    const stabilizedThis = array.map((el) => JSON.parse(el));
    stabilizedThis.sort((a, b) => {
        const order = cmp(a, b);
        if (order !== 0) return order;
        return a - b;
    });
    return stabilizedThis;
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export { getSorting, stableSort, desc }
