import { APIFetch } from '../../fetch.js';

export const deleteAttachment = async ({ token, sampleId, attachmentKey }) => {
  const response = await APIFetch({
    route: `soil-sample/deleteAttachment`,
    method: 'POST',
    authToken: token,
    body: {
      sampleId,
      attachmentKey,
    },
  });

  if (!response.ok) {
    throw new Error('An error occured during DeleteAttachment request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
