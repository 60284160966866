import { APIFetch } from '../../fetch.js';

export const requirementFallow = async ({
  token,
  requirementId,
  fallowYield,
  fallowCropId,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementFallow',
    method: 'POST',
    authToken: token,
    body: {
      requirementId,
      fallowYield,
      fallowCropId,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementFallow request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
