import { List } from '@material-ui/core';
import React, { useMemo } from 'react';
import { TabContent } from '../TabContent';
import { FarmsValidator } from '../FarmsTabContent';
import { BlocksValidator } from '../BlocksTabContent';
import { SamplesValidator } from '../SamplesTabContent';
import { FarmsOverview } from './FarmsOverview';
import { BlocksOverview } from './BlocksOverview';
import { SamplesOverview } from './SamplesOverview';

export const ReviewTabValue = 'review';

export const ReviewValidator = (data) => {
  const farmsResult = FarmsValidator(data);
  const blocksResult = BlocksValidator(data);
  const samplesResult = SamplesValidator(data);

  if (farmsResult.success && blocksResult.success && samplesResult.success) {
    return { success: true };
  }

  const errors = [
    ...(farmsResult.errors ?? []),
    ...(blocksResult.errors ?? []),
    ...(samplesResult.errors ?? []),
  ];
  return {
    success: false,
    errors: errors,
  };
};

export const ReviewTabContent = ({ value, data }) => {
  const farms = useMemo(
    () => data.map(({ blocks, samples, ...farm }) => farm),
    [data]
  );

  const blocks = useMemo(
    () =>
      data.reduce((blocks, farm) => {
        blocks.push(...farm.blocks);
        return blocks;
      }, []),
    [data]
  );

  return (
    <TabContent value={value} tabValue={ReviewTabValue}>
      <List>
        <FarmsOverview farms={farms} data={data} />
        <BlocksOverview blocks={blocks} data={data} />
        <SamplesOverview data={data} />
      </List>
    </TabContent>
  );
};
