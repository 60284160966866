import React, {useCallback, useEffect, useState} from 'react'
import { API, graphqlOperation } from 'aws-amplify';

import { getSoilSample, getLabSample } from '../graphQL/SoilSample'
import LabSampleTable from '../components/LabSampleTable';
import { useAlertContext } from '../components/AlertContext'
import LoadingBackground from "../components/LoadingBackground";
import PageHeader from "../components/PageHeader";
import LogoCard from "../components/LogoCard";

export default function ViewLabSample(props) {
    const labSampleId = props.match.params.id
    const sampleId = props.match.params.sampleId
    

    const [ loading, setLoading ] = useState(false)
    const [ labSample, setLabSample ] = useState(null)
    const [ soilSample, setSoilSample ] = useState(null)

    const alertContext = useAlertContext()



    const fetchSoilSample = useCallback(async () => {
        setLoading(true)
        await API.graphql(graphqlOperation(getSoilSample.query, { sampleId: sampleId }))
            .then(result => {
                let sample = JSON.parse(result.data.getSoilSample)
                setSoilSample(sample)
                console.log(sample)
            })
            .catch(async err => {
                console.log(err)
                alertContext.error(err, 'Error fetching soil samples - please check your network connection and try again')
            })
    }, [sampleId, alertContext])

    useEffect(() => {
        if (sampleId)
            fetchSoilSample().then(() => setLoading(false))
    }, [fetchSoilSample, sampleId]);

    const fetchLabSample = useCallback(async () => {
        setLoading(true)
            await API.graphql(graphqlOperation(getLabSample.query, { labSampleId: labSampleId }))
            .then(result => {
                let sample = JSON.parse(result.data.getLabSample)
                console.log(sample);
                setLabSample(sample)
            })
            .catch(async err => {
                console.log(err)
                alertContext.error(err, 'Error fetching lab sample - please check your network connection and try again')
            })
    }, [labSampleId, alertContext])

    useEffect( () => {
       if(labSampleId)
            fetchLabSample().then(() => setLoading(false))
    }, [fetchLabSample, labSampleId]);


    return (
        
        <LogoCard margin={20}>
            <LoadingBackground visible={loading} />
            <PageHeader title={'Lab Sample'}
                subheader={ labSample?.lab_sample_number }
                        backPath={ 'back' } pinDrop={soilSample} />
            <div style={{ padding: 20 }}>
                {
                    labSample &&
                     <LabSampleTable labSample={labSample} />
                    // Object.keys(labSample).map(ls => {
                    //     if(labSample[ls] !== null)
                    //         return <p><b>{`${ls}:`}</b> {`${labSample[ls]}`}</p>
                    //     else 
                    //         return null
                    // })
                }
            </div>
        </LogoCard>
    );
}


