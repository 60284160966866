import React, {useEffect, useRef, useState} from 'react'
import { Auth } from "aws-amplify";
import { Grid, Container, Button } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {useFormik} from 'formik'
import FormikForm from '../components/FormikForm'
import {LoginFormSchema} from '../components/ValidationSchema'
import FormText from '../components/FormText'
import FormButton from '../components/FormButton'
import {useAlertContext} from '../components/AlertContext'
import logoBlue from '../assets/logo_blue.png'

function Login(props) {
    const classes = useStyles();

    const [ step, setStep ] = useState('login');
    const [ user, setUser ] = useState(null);

    const alertContext = useAlertContext()

    const passwordPolicy = "Password Policy: minimum 8 characters, at least 1 upper and Lower case letter and at least 1 number";

    const focusRef = useRef(null)

    useEffect(()=>{
        const focusElement = focusRef.current
        if (focusElement) {
            focusElement.focus()
        }
    }, [step]);

    const formikLogin = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema: LoginFormSchema,
        onSubmit: (values, actions) => {
            console.log('handle login')
            Auth.signIn(values.username.trim(), values.password)
                .then(user => {
                    setUser(user);
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setStep('newPassword')
                    }
                    else {
                        props.userHasAuthenticated();
                    }
                    actions.setSubmitting(false)
                })
                .catch(err => {
                    actions.setSubmitting(false);
                    if(err.code === "PasswordResetRequiredException") {
                        setStep('resetPassword')
                    }
                    else {
                        alertContext.error(err, err.message)
                    }
                })
        },
    })

    const formikForgotPassword = useFormik({
        initialValues: {
            username: ""
        },
        validationSchema: LoginFormSchema,
        onSubmit: (values, actions) => {
            Auth.forgotPassword(values.username.trim())
                .then(user => {
                    setUser(user);
                    alertContext.success("You will be sent an email with a verification code")
                    setStep('resetPassword')
                    actions.setSubmitting(false)
                })
                .catch(err => {
                    actions.setSubmitting(false);
                    alertContext.error(err, err.message)
                })
        },
    })

    const formikNewPassword = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        onSubmit: (values, actions) => {
            console.log('NEW PASSWORD')
            if(values.password === values.confirmPassword) {
                Auth.completeNewPassword(user, values.password)
                    .then(user => {
                        props.userHasAuthenticated();
                        actions.setSubmitting(false)
                    })
                    .catch(err => {
                        actions.setSubmitting(false);
                        alertContext.error(err.message ? (err, err.message) : (err, 'Error setting new password'))
                    });
            }
            else {
                alertContext.error('err', "Passwords do not match")
                actions.setSubmitting(false)
            }
        },
    })


    const formikResetPassword = useFormik({
        initialValues: {
            username: "",
            password: "",
            confirmPassword: "",
            verify: ""
        },
        validationSchema: LoginFormSchema,
        onSubmit: (values, actions) => {
            if (values.password === values.confirmPassword) {
                Auth.forgotPasswordSubmit(values.username.trim(), values.verify, values.password)
                    .then(data => {
                        actions.setSubmitting(false)
                        setStep('login')
                        alertContext.success('Password reset success')
                    })
                    .catch(err => {
                        actions.setSubmitting(false)
                        alertContext.error(err.message ? (err, err.message) :  (err, 'Error resetting password'))
                    });
            }
            else {
                actions.setSubmitting(false)
                alertContext.error('err', 'Passwords do not match')
            }
        },
    })

    const handleResetPassword  = () =>  { setStep('forgotPassword')};
    const handleLogin  = () =>  { setStep('login')};

    return (
        <div>
            <Container component="main" maxWidth="sm" className={classes.container}>
                    <div className={classes.paper}>
                        <img src={logoBlue} alt="img_logo" className={classes.logo} />
                        <br/>
                        {
                            step === 'login' ?
                                <FormikForm formik={formikLogin}>
                                    <FormText name="username" label="Username" inputRef={focusRef}/>
                                    <FormText inputType='password' name="password" label="Password" />
                                    <FormButton text={"Login"} loading={formikLogin.isSubmitting}/>
                                </FormikForm>
                                :
                            step === 'newPassword' ?
                                <FormikForm formik={formikNewPassword}>
                                    <FormText name="password" label="New Password" inputType='password' inputRef={focusRef}/>
                                    <FormText name="confirmPassword" label="Confirm Password" inputType='password' helpText={passwordPolicy}/>
                                    <FormButton text={"Set Password"} loading={formikNewPassword.isSubmitting}/>
                                </FormikForm>
                                :
                            step === 'forgotPassword' ?
                                <FormikForm formik={formikForgotPassword}>
                                    <p>A Verification Code will be sent to your email</p>
                                    <FormText name="username" label="Username" inputRef={focusRef}/>
                                    <FormButton text={"Send Verification Code"} loading={formikForgotPassword.isSubmitting}/>
                                </FormikForm>
                                :
                            step === 'resetPassword' ?
                                <FormikForm formik={formikResetPassword}>
                                    <FormText name="username" label="Username" inputRef={focusRef}/>
                                    <FormText name="password" label="New Password" inputType='password' />
                                    <FormText name="confirmPassword" label="Confirm Password" inputType='password' helpText={passwordPolicy} />
                                    <FormText name="verify" label="Verify Code" helpText={"A 6 digit code sent to your email address"} />
                                    <FormButton text={"Reset Password"} loading={formikResetPassword.isSubmitting}/>
                                </FormikForm>
                                :
                            null
                        }
                        <Grid container>
                            <Grid item xs />
                            <Grid item>
                                {
                                    step === 'login' ?
                                        <Button
                                            color="secondary"
                                            className={classes.button}
                                            onClick={handleResetPassword}
                                            startIcon={<Lock />}
                                        >
                                            Reset Password
                                        </Button>
                                        :
                                        step !== 'login' ?
                                            <Button
                                                color="secondary"
                                                className={classes.button}
                                                onClick={handleLogin}
                                                startIcon={<Lock />}
                                            >
                                                Login
                                            </Button>
                                            :
                                            null
                                }
                            </Grid>
                        </Grid>
                    </div>
            </Container>
        </div>
    );
}

export default Login;

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: '#f5f7fb',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        padding: 40,
        'border-style': 'solid',
        'border-color': '#e0e5ec',
        'border-width': 1
    },
    button: {
        marginTop: 5
    },
    logo: {
        [theme.breakpoints.up('md')]: {
            width: '15rem'
        },
        width: '10rem'
    }
}));
