import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Table,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useCallback, useMemo, useState } from 'react';
import { cropClasses } from '../../../functions/offlineQueries';
import { TabContent } from './TabContent';

export const BlocksTabValue = 'blocks';

const validateBlockSample = (block, sample) => {
  const errors = [];

  const cropClassName = sample['Crop class at sampling']?.trim();
  if (cropClassName === undefined || cropClassName.length === 0) {
    errors.push('Missing Crop Class');
  } else {
    const cropClassId = cropClasses?.find(
      (cropClass) => cropClass.name === cropClassName
    )?.value;
    if (cropClassId === undefined) {
      errors.push(`Unknown Crop Class '${cropClassName}'`);
    }
  }

  const varietyName = sample['Variety']?.trim();
  if (varietyName === undefined || varietyName.length === 0) {
    errors.push('Missing Variety');
  }

  return errors.length === 0
    ? { isValid: true }
    : { isValid: false, errors: errors };
};

export const validateBlock = (block, data) => {
  const errors = [];

  const farmName = block.farm.trim();
  if (farmName.length === 0) {
    errors.push('Missing Farm');
  }

  const farm = data.find((farm) => farm.name === farmName);
  if (farm === undefined) {
    errors.push(`Unknown Farm '${block.farm}'`);
  }

  const sample = farm?.samples.find((sample) => sample.block === block.name);
  if (sample !== undefined) {
    const blockSampleResult = validateBlockSample(block, sample);
    if (!blockSampleResult.isValid) {
      errors.push(...blockSampleResult.errors);
    }
  }

  return errors.length === 0
    ? { isValid: true }
    : { isValid: false, errors: errors };
};

export const BlocksValidator = (data) => {
  const blocks = data.reduce((blocks, farm) => {
    blocks.push(...farm.blocks);
    return blocks;
  }, []);
  const errors = blocks.flatMap(
    (block) => validateBlock(block, data).errors ?? []
  );
  return errors.length > 0
    ? { success: false, errors: errors }
    : { success: true };
};

export const BlocksTabContent = ({ value, data }) => {
  const displayData = useMemo(() => {
    return data.map((farm) => ({
      name: farm.name,
      blocks: farm.blocks.map((block) => {
        let blockSample = farm.samples.find(
          (sample) => sample.block === block.name
        );
        const plantDateParts =
          blockSample &&
          blockSample.cleanPlantDate &&
          blockSample.cleanPlantDate.split('/');
        const sampleDateParts =
          blockSample &&
          blockSample.cleanSampleDate &&
          blockSample.cleanSampleDate.split('/');
        let differenceInDays =
          blockSample &&
          blockSample.cleanPlantDate &&
          blockSample.cleanSampleDate &&
          (new Date(
            plantDateParts[2],
            plantDateParts[1] - 1,
            plantDateParts[0]
          ).getTime() -
            new Date(
              sampleDateParts[2],
              sampleDateParts[1] - 1,
              sampleDateParts[0]
            ).getTime()) /
            (1000 * 3600 * 24);

        return {
          name: block.name,
          hectares: block.hectares,
          sampleNumber: blockSample && blockSample['Soil test number'],
          sampleDate: blockSample && blockSample.cleanSampleDate,
          plantDate: blockSample && blockSample.cleanPlantDate,
          sampleError:
            blockSample && (differenceInDays || 0) > 365 ? true : false,
          cropClassId:
            blockSample &&
            blockSample.cleanPlantDate &&
            new Date().getFullYear() -
              new Date(
                plantDateParts[2],
                plantDateParts[1] - 1,
                plantDateParts[0]
              ).getFullYear() +
              1,
          cropClassAtSample: blockSample?.['Crop class at sampling'],
        };
      }),
    }));
  }, [data]);

  return (
    <TabContent value={value} tabValue={BlocksTabValue}>
      <List>
        {displayData.map((farm) => (
          <FarmBlocksSection key={farm.name} farm={farm} />
        ))}
      </List>
    </TabContent>
  );
};

const FarmBlocksSection = ({ farm }) => {
  const [isOpen, setIsOpen] = useState(true);

  const totalHectares = useMemo(
    () =>
      farm.blocks.reduce((sum, block) => sum + parseFloat(block.hectares), 0),
    [farm]
  );

  const handleClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <ListSubheader style={{ backgroundColor: '#F5FBFD', padding: 0 }}>
        <ListItem
          button
          style={{ paddingTop: 0, paddingBottom: 0 }}
          onClick={handleClick}
        >
          <ListItemText
            primaryTypographyProps={{ color: 'textPrimary' }}
            primary={farm.name}
            secondary={`${farm.blocks.length ?? 0} Blocks (${totalHectares}ha)`}
          />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </ListSubheader>
      <ListItem>
        <Collapse in={isOpen}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Block</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Sample</TableCell>
                <TableCell>Sample Date</TableCell>
                <TableCell>Date Planted</TableCell>
                <TableCell>Crop Class</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {farm.blocks.map((block) => (
                <TableRow key={block.name}>
                  <TableCell>{block.name}</TableCell>
                  <TableCell>{`${block.hectares}ha`}</TableCell>
                  <TableCell>{block.sampleNumber}</TableCell>
                  <TableCell
                    style={{ color: block.sampleError ? 'Red' : null }}
                  >
                    {block.sampleDate}
                  </TableCell>
                  <TableCell
                    style={{ color: block.sampleError ? 'Red' : null }}
                  >
                    {block.plantDate}
                  </TableCell>
                  <TableCell>
                    {block.cropClassId &&
                      block.cropClassId > 0 &&
                      block.cropClassAtSample &&
                      cropClasses[block.cropClassId]?.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </ListItem>
    </>
  );
};
