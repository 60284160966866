//@format

import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { getMapLayers } from '../../graphQL/Farm';
import { GeoJSON, LayersControl, LayerGroup } from 'react-leaflet';
// import L from 'leaflet';
// import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
const { Overlay } = LayersControl;

export const EMMapDataLayer = ({ farmId, setLayerOptions, setEMLegend, displayLayer }) => {

  const emMapLegend = () => {
    return displayLayer ? (
      <Grid container>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{
                fill: 'Green',
              }}
            />
          </svg>{' '}
          {'Low EC'}
        </Grid>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{
                fill: 'Lime',
              }}
            />
          </svg>{' '}
          {'Medium Low EC'}
        </Grid>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{
                fill: 'Yellow',
              }}
            />
          </svg>{' '}
          {'Medium High EC'}
        </Grid>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{
                fill: 'Brown',
              }}
            />
          </svg>{' '}
          {'High EC'}
        </Grid>
      </Grid>
    ) : null;
  };

  useEffect(() => {
    setEMLegend(emMapLegend);
  }, [displayLayer]);

  const { data: mapDataLayer, isLoading: loadingDataLayer } = useQuery({
    queryKey: ['getMapLayers', farmId],
    queryFn: async () => {
      const result = await API.graphql(graphqlOperation(getMapLayers.query, { farmId }));
      const d = JSON.parse(result.data.getMapLayers);

      // console.log("d", d)

      if (d.length > 0) {
        setLayerOptions((prev) => [...new Set([...prev, 'EM Data'])]);
      }


      return {
        type: 'FeatureCollection',
        farmId: farmId,
        features: d.map((zone) => {
          let geo = {
            ...zone.geometry,
            bbox: [
              zone.bbox.coordinates[0][0][0],
              zone.bbox.coordinates[0][0][1],
              zone.bbox.coordinates[0][2][0],
              zone.bbox.coordinates[0][2][1],
            ],
          };
          const res = {
            type: 'Feature',
            geometry: geo,
            properties: {
              id: farmId,
              zoneID: zone.zoneID,
            },
          };
          return res;
        }),
      };
    },
  });


  return (
    <>
      {displayLayer && (
            <GeoJSON
            key={Math.random()}
            data={mapDataLayer}
            style={(feature) => {
                return {
                weight: 2,
                opacity: 0.8,
                color: feature.properties.zoneID === 1 ? "green" : feature.properties.zoneID === 2 ? "lime" : feature.properties.zoneID === 3 ? "yellow" : "brown",
                //   fillColor:
                //     sequentialColorArray[[...filterSet].indexOf(getProp(feature, filter))],
                dashArray: "0",
                fillOpacity: 0.5,
                };
            }}
            onEachFeature={(feature, layer) => {
                const zoneName = feature.properties.zoneID === 1 ? "Low EC" : feature.properties.zoneID === 2 ? "Medium Low" : feature.properties.zoneID === 3 ? "Medium High" : "High EC"   
                layer.on('click', function (e) {
                layer.bindPopup(zoneName).openPopup(); // here add openPopup()
                });
            }}
            />
      )}
    </>
  );
};
