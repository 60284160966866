import "./index.css";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";
import { unregister } from "./serviceWorker";

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import p from '../package.json';

Sentry.init({
    dsn: "https://0bc254023c974e9db2630c4f637b61ae@sentry.refactor.com.au/10",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `${p.name}@${p.version}`,
    enabled: process.env.NODE_ENV !== 'development',
});



// import * as serviceWorker from './serviceWorker';

// import { aws_config_test, aws_config_prod } from './config/aws_config';
// import Amplify from 'aws-amplify';
// import swConfig from './swConfig'

// const aws_config = process.env.REACT_APP_ENV === 'prod' ? aws_config_prod : aws_config_test

// Amplify.configure(aws_config)

ReactDOM.render(
  // <BrowserRouter>
  <App />,
  // {/* </BrowserRouter>, */}
  document.getElementById("root")
);

unregister();
//swConfig
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
