import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { Assignment, Edit, EventNote, PinDrop, Timeline } from '@material-ui/icons';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getSorting, stableSort } from '../functions/tableActions';
import { Link } from 'react-router-dom';
import LoadingBackground from '../components/LoadingBackground';
import LogoCard from '../components/LogoCard';
import PageHeader from '../components/PageHeader';
import { getSoilSamples } from '../graphQL/SoilSample';
import { useAlertContext } from '../components/AlertContext';
import UploadLabSamples from '../components/UploadLabSamples';
import { formatDate } from '../functions/formatDate';
import { APIFetch } from '../api/fetch.js';

const rows = [
  { id: 'lab_sample_number', label: 'Lab Sample Number' },
  { id: 'lat', label: 'Latitude' },
  { id: 'lng', label: 'Longitude' },
  { id: 'sampling_date', label: 'Sampling Date' },
  { id: 'blocks', label: 'Block Names' },
  { id: 'sample_state_id', label: 'State Id' },
  { id: 'view', label: '' },
  { id: 'action', label: '' },
];

export default function ViewSoilSamples(props) {
  if (!props.match.params) {
    window.location.href = '/';
  }
  const farmId = props.match.params.id;
  const farmName = props.match.params.name ? props.match.params.name : farmId;

  const alertContext = useAlertContext();
  const agronomist = props.user.idToken.payload.agronomist === 'true';
  const userAuthToken = props.user.idToken.jwtToken;

  // const [soilSamples, setSoilSamples] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState('sampling_date');
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  // const fetchSoilSamples = useCallback(() => {
  //   setLoading(true);
  //   API.graphql(graphqlOperation(getSoilSamples.query, { farmId: farmId }))
  //     .then((result) => {
  //       console.log(result);
  //       setSoilSamples(result.data.getSoilSamples);
  //       setLoading(false);
  //     })
  //     .catch(async (err) => {
  //       console.log(err);
  //       setLoading(false);
  //       alertContext.error(
  //         err,
  //         "Error fetching soil samples - please check your network connection and try again"
  //       );
  //     });
  // }, [farmId, alertContext]);

  // useEffect(() => {
  //   fetchSoilSamples();
  // }, [fetchSoilSamples]);

  const { data: soilSamples, isLoading: loading } = useQuery({
    queryKey: ['soilSamples', farmId],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getSoilSamples.query, { farmId: farmId })
      );
      const ssamples = result.data.getSoilSamples;
      console.log(ssamples);
      return ssamples;
    },
  });

  const openSampleAttachment = useCallback(
    async (sample, key) => {
      try {
        const result = await APIFetch({
          route: 'soil-sample/getAttachmentSignedURL',
          authToken: userAuthToken,
          body: {
            sampleId: parseInt(sample.id),
            attachmentKey: key,
            operation: 'get',
          },
        });
        const data = await result.json();
        window.open(data.url, '_blank').focus();
      } catch (err) {
        alertContext.error(
          err,
          'Error downloading attachment - please check your network connection and try again'
        );
      }
    },
    [alertContext, userAuthToken]
  );

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    setOrder(orderBy === property && order === 'desc' ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (soilSamples?.length ?? 0) - page * rowsPerPage);

  let filteredSearch;
  filteredSearch = soilSamples?.filter((item) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });

  return (
    <div>
      <LogoCard>
        <PageHeader
          title={`Farm: ${farmName}`}
          subheader={`Soil samples for ${farmName}`}
          showSearch={true}
          setQuery={(q) => setQuery(q)}
          backPath={'/'}
          addPath={`/request-soil-sample/${farmName}/${farmId}/`}
        />
        <div>
          {loading ? (
            <LoadingBackground visible={loading} />
          ) : (
            <>
              <UploadLabSamples farmId={farmId} farmName={farmName} />

              <Table aria-labelledby='tableTitle'>
                <TableHead>
                  <TableRow>
                    {rows.map((row, index) => {
                      return [
                        <TableCell
                          key={index}
                          align={row.id === 'location' ? 'center' : 'left'}
                          padding='normal'
                          sortDirection={orderBy === row.id ? order : false}
                        >
                          <Tooltip title='Sort' placement='bottom-start' enterDelay={300}>
                            <TableSortLabel
                              active={orderBy === row.id}
                              direction={order}
                              onClick={createSortHandler(row.id)}
                            >
                              {row.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>,
                      ];
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    stableSort(filteredSearch, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((sample) => {
                        // const sample = JSON.parse(item);
                        return [
                          <TableRow hover tabIndex={-1} key={sample.id}>
                            <Tooltip
                              title={`Lab Sample Id: ${sample.lab_sample_id}`}
                              enterDelay={300}
                            >
                              <TableCell component='th' scope='row' padding='normal'>
                                {sample.lab_sample_number}
                              </TableCell>
                            </Tooltip>
                            <TableCell component='th' scope='row' padding='normal'>
                              {sample.lat}
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              {sample.lng}
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              {formatDate(sample.sampling_date)}
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              {sample.blocks &&
                                sample.blocks
                                  .map((block) => {
                                    return block.name;
                                  })
                                  .sort()
                                  .toString()}
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              {String(sample.sample_state_id) === '1'
                                ? 'Requested'
                                : String(sample.sample_state_id) === '2'
                                ? 'Sent'
                                : String(sample.sample_state_id) === '3'
                                ? 'Received'
                                : String(sample.sample_state_id) === '4'
                                ? 'Rejected'
                                : String(sample.sample_state_id) === '5'
                                ? 'Accepted'
                                : 'Unknown'}{' '}
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              <Tooltip title='Download Lab Results'>
                                <span>
                                  <IconButton
                                    disabled={sample.lab_results_key === null}
                                    edge='end'
                                    aria-label='Lab Results'
                                    color='primary'
                                    onClick={() =>
                                      openSampleAttachment(
                                        sample,
                                        `lab-results/${sample.lab_results_key}`
                                      )
                                    }
                                  >
                                    <Assignment />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              <Tooltip title='Download Sampling Plan'>
                                <span>
                                  <IconButton
                                    disabled={sample.sampling_plan_key === null}
                                    edge='end'
                                    aria-label='Sampling Plan'
                                    color='primary'
                                    onClick={() =>
                                      openSampleAttachment(
                                        sample,
                                        `sampling-plan/${sample.sampling_plan_key}`
                                      )
                                    }
                                  >
                                    <EventNote />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              <Tooltip title='View Lab Sample'>
                                <IconButton
                                  disabled={!(!agronomist || sample.lab_sample_id)}
                                  edge='end'
                                  aria-label='View'
                                  color='primary'
                                  component={Link}
                                  to={`/lab-sample/${sample.id}/${sample.lab_sample_id}`}
                                >
                                  <Timeline />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              <Tooltip title='Update Soil Sample'>
                                <IconButton
                                  disabled={!agronomist}
                                  edge='end'
                                  aria-label='View'
                                  color='primary'
                                  component={Link}
                                  to={`/view-soil-sample/${farmName}/${farmId}/${sample.id}`}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell component='th' scope='row' padding='normal'>
                              <Tooltip title='Open Map'>
                                <IconButton
                                  aria-label='View'
                                  variant='link'
                                  target={'_t'}
                                  href={`https://www.google.com/maps/place/${sample.lat},${sample.lng}/@${sample.lat},${sample.lng},466m/data=!3m1!1e3!4m5!3m4!7e2!8m2!3d${sample.lat}!4d${sample.lng}`}
                                >
                                  <PinDrop color='primary' />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>,
                        ];
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={rows.length} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          )}
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={soilSamples?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </LogoCard>
    </div>
  );
}
