import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import { useCallback, useMemo, useState } from 'react';
import { EditShapeButton } from './EditShapeButton';
import { NavigateShapeButton } from './NavigateShapeButton';
import { useCallbackRef } from './useCallbackRef';

export const ModifiedShapeCard = (props) => {
  const {
    blockId,
    blockName,
    blockHectars,
    shape,
    isEditing,
    disableEditing,
    disableNavigating,
    onNavigate,
    onStartEditing,
    onStopEditing,
    onDiscard,
  } = props;

  const invokeOnNavigate = useCallbackRef(onNavigate);
  const invokeOnStartEditing = useCallbackRef(onStartEditing);
  const invokeOnStopEditing = useCallbackRef(onStopEditing);
  const invokeOnDiscard = useCallbackRef(onDiscard);

  const state = useMemo(
    () => ({ blockId, blockName, shape, isEditing }),
    [blockId, blockName, shape, isEditing]
  );

  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);

  const handleClickNavigate = useCallback(() => {
    invokeOnNavigate(state);
  }, [invokeOnNavigate, state]);

  const handleClickStartEditing = useCallback(() => {
    invokeOnStartEditing(state);
  }, [invokeOnStartEditing, state]);

  const handleClickStopEditing = useCallback(() => {
    invokeOnStopEditing(state);
  }, [invokeOnStopEditing, state]);

  const handleClickDiscard = useCallback(() => {
    setIsDiscardDialogOpen(true);
  }, []);

  const handleAcceptDiscard = useCallback(() => {
    invokeOnDiscard(state);
    setIsDiscardDialogOpen(false);
  }, [invokeOnDiscard, state]);

  const handleCancelDiscard = useCallback(() => {
    setIsDiscardDialogOpen(false);
  }, []);

  return (
    <>
      <Paper elevation={0}>
        <ListItem>
          <Grid container alignItems="center">
            <Grid item>
              <Typography>{blockName} ({blockHectars}ha)</Typography>
            </Grid>
            <Grid item>
              <NavigateShapeButton
                size="small"
                disabled={disableNavigating}
                onClick={handleClickNavigate}
              />
            </Grid>
            <Grid item>
              <EditShapeButton
                size="small"
                editing={isEditing}
                disabled={disableEditing}
                onClick={
                  isEditing ? handleClickStopEditing : handleClickStartEditing
                }
              />
            </Grid>
            <Grid item>
              <Tooltip title="Discard Changes">
                <IconButton size="small" onClick={handleClickDiscard}>
                  <Restore color="error" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
      {isDiscardDialogOpen && (
        <Dialog open onClose={handleCancelDiscard}>
          <DialogTitle style={{ backgroundColor: 'red' }}>
            {`Are you sure you want to discard changes for ${blockName}?`}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {`This will discard all changes made to ${blockName}'s geometry.`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" autoFocus onClick={handleCancelDiscard}>
              Cancel
            </Button>
            <Button style={{ color: 'red' }} onClick={handleAcceptDiscard}>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
