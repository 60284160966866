import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

// LIBRARIES
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GeoJSON, LayersControl, MapContainer, TileLayer } from 'react-leaflet';
import shpjs from 'shpjs';

// MATERIAL
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

// COMPONENTS
import { GeojsonEdit } from '../../components/FarmMap/GeojsonEdit';
import { MapCenterControl } from '../../components/FarmMap/MapCenterControl';
import { ListItemError } from '../../components/FarmOverview/UploadCSVDialog/ReviewTabContent/ListItemError';
import { FileDropZone } from '../../components/FileDropZone';
import RolloverDialog from '../../components/FarmMap/RolloverDialog';
import LogoCard from '../../components/LogoCard';
import PageHeader from '../../components/PageHeader';
import { ModifiedShapeCard } from './ModifiedShapeCard';
import { NewShapeCard } from './NewShapeCard';
import { OldShapeCard } from './OldShapeCard';
import { useShapeStore } from './useShapeStore';

// FUNCTIONS
import { extractFilesFromZip } from '../../functions/zip';
import { getFilePathInfo } from '../../functions/filePath';
import { filterFertYear } from '../../functions/filterDataForFertYear';

// GRAPHQL
import { updateBlocksGeometries } from '../../graphQL/Blocks';
import { getFarmBlocks } from '../../graphQL/Farm';
import { Query } from '@tanstack/react-query';
import { set } from 'date-fns';
import { filter } from 'jszip';

const ZIP_MIME_TYPES = [
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
  'application/zip-compressed',
];

const useStyles = makeStyles((theme) => ({
  blockLabel: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    border: 0,
    color: 'white',
  },
  oldBlockShape: {
    fill: 'red',
    stroke: 'red',
    '&:hover': {
      fill: 'orange',
      stroke: 'orange',
    },
  },
  newBlockShape: {
    fill: 'blue',
    stroke: 'blue',
    '&:hover': {
      fill: 'green',
      stroke: 'green',
    },
  },
  modifiedBlockShape: {
    fill: 'orange',
    stroke: 'orange',
    '&:hover': {
      fill: 'yellow',
      stroke: 'yellow',
    },
  },
}));

const cloneShape = (shape) => JSON.parse(JSON.stringify(shape));

const calculateGeomCenter = (geometry) => {
  if (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon')
    throw new Error(`Calculating center of '${geometry.type}' not supported!`);

  const points = (
    geometry.type === 'Polygon'
      ? [geometry.coordinates]
      : geometry.type === 'MultiPolygon'
      ? geometry.coordinates
      : []
  ).flat(2);

  // Point Arrays start and end with the same Point.
  // To avoid bias for the First / Last Point, remove either.
  points.splice(0, 1);

  const { latSum, longSum } = points.reduce(
    ({ latSum, longSum }, [long, lat]) => ({
      latSum: latSum + lat,
      longSum: longSum + long,
    }),
    { latSum: 0, longSum: 0 }
  );
  return {
    lat: latSum / points.length,
    long: longSum / points.length,
  };
};

export const ManageBlocks = (props) => {
  const { farmId } = useParams();
  const { farmName } = props.location.state;
  const queryClient = useQueryClient();
  // console.log(junk)
  const [suggestModifications, setSuggestModifications] = useState(false);

  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const [farmName, setFarmName] = useState('');
  const [fertYear, setFertYear] = useState(localStorage.getItem(`fertYear`));

  const [blocks, setBlocks] = useState([]);
  // Can this be changed to the function?
  // const filteredBlocks = useMemo(() => {
  //   return blocks.flatMap((block) => {
  //     return (block.decommissioned_cycle || fertYear + 1) > fertYear &&
  //       block.commissioned_cycle <= fertYear
  //       ? block
  //       : [];
  //   });
  // }, [fertYear, blocks]);

  const filteredBlocks = filterFertYear(blocks, fertYear);

  const fertYears = useMemo(() => {
    const fertYearSet = new Set(
      blocks.flatMap((block) => block.requirements?.map((item) => item.fertilizer_year) || [])
    );
    for (let i = 0; i < 4; i++) fertYearSet.add(new Date().getFullYear() + i);
    return [...fertYearSet].sort();
  }, [blocks]);

  // useEffect(() => {
  //   const minFertYear = blocks.reduce((min, block) => {
  //     const currentCycle = parseInt(block.current_cycle);
  //     if (isNaN(currentCycle)) return min;
  //     return Math.min(min, currentCycle);
  //   }, Infinity);
  //   setFertYear(Math.min(minFertYear, new Date().getFullYear()));
  // }, [blocks]);

  const [errorState, setErrorState] = useState();
  const showErrorDialog = useCallback((errors, isPermanent = false) => {
    setErrorState({ errors, isPermanent });
  }, []);
  const closeErrorDialog = useCallback(() => {
    setErrorState();
  }, []);

  const [blockShapeStates, setBlockShapeStates] = useState({});
  const setBlockShapeState = useCallback((blockId, state) => {
    if (typeof blockId !== 'number' || isNaN(blockId))
      throw new Error(`Parameter 'blockId' must be a number. Received (${blockId})`);
    if (typeof state !== 'boolean')
      throw new Error(`Parameter 'state' must be a Boolean. Received (${state})`);
    setBlockShapeStates((prev) => {
      return { ...prev, [blockId]: state };
    });
  }, []);

  const {
    shapes: newShapes,
    add: addNewShape,
    get: getNewShape,
    remove: removeNewShape,
  } = useShapeStore({
    onAdded: (shapes) => {
      shapes.forEach(({ blockId }) => setBlockShapeState(blockId, true));
    },
    onRemoved: (shapes) => {
      shapes.forEach(({ blockId }) => setBlockShapeState(blockId, false));
    },
  });

  const {
    shapes: modifiedShapes,
    get: getModifiedShape,
    add: addModifiedShape,
    remove: removeModifiedShape,
    clear: clearModifiedShapes,
  } = useShapeStore();

  const [editingBlockId, setEditingBlockId] = useState();

  const startEditing = useCallback((blockId, shape) => {
    if (typeof blockId !== 'number' || isNaN(blockId))
      throw new Error(`Parameter 'blockId' must be a number. Received (${blockId})`);
    if (typeof shape !== 'object')
      throw new Error(`Parameter 'shape' must be an Object. Received (${shape})`);

    console.log('Editing: ', blockId);
    setEditingBlockId(blockId);
    const { lat, long } = shape.properties.center;
    setMapCenter([lat, long]);
  }, []);

  const stopEditing = useCallback(() => {
    setEditingBlockId();
  }, []);

  const isEditingDisabled = useMemo(() => {
    return newShapes.length > 0;
  }, [newShapes]);

  const isSavingDisabled = useMemo(
    () => modifiedShapes.length === 0 || newShapes.length > 0,
    [modifiedShapes, newShapes]
  );

  const isFertYearFixed = useMemo(
    () => modifiedShapes.length > 0 || newShapes.length > 0,
    [modifiedShapes, newShapes]
  );

  const [mapCenter, setMapCenter] = useState([0, 0]);
  // console.log('MAP: ', mapCenter);

  const oldShapes = useMemo(() => {
    return filteredBlocks.reduce((shapes, block) => {
      const [long, lat] = block.centroid.coordinates;
      shapes[block.id] = {
        type: 'Feature',
        geometry: block.geometry,
        properties: {
          blockId: block.id,
          blockName: block.name,
          center: { lat, long },
        },
      };
      return shapes;
    }, {});
  }, [filteredBlocks]);

  const { data: ngblocks, isLoading: loading } = useQuery({
    queryKey: ['maps', farmId],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getFarmBlocks.query, { farmId: farmId })
      );
      const ngBlock = JSON.parse(result.data.getFarmBlocks);

      // console.log('BOOM!!! GOT THIS AGAIN...');
      setBlocks(ngBlock);
      // console.log(ngBlock);
      if (ngBlock.length > 0) {
        // const [long, lat] = ngBlock[0].centroid.coordinates;
        // setMapCenter([lat, long]);
        setMapCenter([ngBlock[0].lat, ngBlock[0].lng]);
      }
      // setIsLoading(false);

      return ngBlock;
    },
  });

  const handleFileDrop = useCallback(
    async (fileList) => {
      const files = [...fileList];
      console.log(files);
      const { validFiles, invalidFiles } = files.reduce(
        ({ validFiles, invalidFiles }, file) => {
          if (ZIP_MIME_TYPES.includes(file.type)) {
            validFiles.push(file);
          } else {
            console.error(`Unknown MIME Type uploaded: '${file.type}'`);
            console.error(file);
            invalidFiles.push(file);
          }
          return { validFiles, invalidFiles };
        },
        { validFiles: [], invalidFiles: [] }
      );

      if (invalidFiles.length > 0) {
        showErrorDialog(['Only Zip archives are supported at the moment.']);
        return;
      }

      try {
        const getShapefileGeoJSON = async (zipFile) => {
          const files = await extractFilesFromZip(zipFile, { recursive: true });

          console.log(files);

          const shpFiles = [];
          const dbfFiles = [];

          files.forEach((file) => {
            const { name, extension } = getFilePathInfo(file.filePath);
            const entry = { file, name };
            if (extension.toLowerCase() === 'shp') shpFiles.push(entry);
            else if (extension.toLowerCase() === 'dbf') dbfFiles.push(entry);
          });

          const combineShapeFiles = async (shpFile, dbfFile) => {
            const shpFileData = await shpFile.read();
            const dbfFileData = await dbfFile.read();

            // console.log(shpFileData);

            const geometry = shpjs.parseShp(shpFileData);
            const properties = shpjs.parseDbf(dbfFileData);

            console.log(geometry);

            return shpjs.combine([geometry, properties]);
          };

          const combinePromises = shpFiles.flatMap((shpFile) => {
            const dbfFile = dbfFiles.find(({ name }) => name === shpFile.name);
            if (dbfFile === undefined) return [];

            return combineShapeFiles(shpFile.file, dbfFile.file);
          });

          const featureCollections = await Promise.all(combinePromises);
          return featureCollections;
        };

        const getFeatures = (geojson) => {
          if (geojson.type !== 'Feature' && geojson.type !== 'FeatureCollection') {
            showErrorDialog([
              `Unsupported ShapeFile (${validFiles[0].name}).\nMust be a 'Feature' or 'FeatureCollection'.`,
            ]);
            return;
          }

          const features = geojson.type === 'FeatureCollection' ? geojson.features : [geojson];
          const goodFeatures = [];
          const badFeatures = [];
          const recommendations = [];
          const errorSet = new Set();

          const getProperty = (object, key) => {
            const keys = Array.isArray(key) ? [...key] : [key];
            const lowerCaseKeys = keys.map((key) => key.toLowerCase());
            return object[
              Object.keys(object).find((k) => lowerCaseKeys.includes(k.toLowerCase()))
            ];
          };

          features.forEach((feature) => {
            const { geometry, properties } = feature;

            // console.log(properties);

            if (geometry.type !== 'Polygon' && geometry.type !== 'MultiPolygon') {
              errorSet.add(
                `Unsupported Feature Geometry (${geometry.type}). Must be either 'Polygon' or 'MultiPolygon'.`
              );
              badFeatures.push(feature);
              return;
            }

            const id = getProperty(properties, 'ID');
            const name = getProperty(properties, ['FIELD', 'NAME']);
            const farmNo = getProperty(properties, 'FARM_NO');
            const blockNo = getProperty(properties, 'BLOCK_NO');
            const paddockNo = getProperty(properties, 'PADDOCK_NO');
            const cropClass = getProperty(properties, 'ClassCode');
            const area = getProperty(properties, 'AREA_HA');

            if (
              id === undefined &&
              name === undefined &&
              blockNo === undefined &&
              paddockNo === undefined
            ) {
              errorSet.add(
                `No identifying properties found.\nExpected any of ('ID', 'FIELD', 'NAME', 'BLOCK_NO', 'PADDOCK_NO').`
              );
              badFeatures.push(feature);
              return;
            }

            let matchedBlock;
            let errorString;

            // const identifiedBlocks = [];

            if (id !== undefined) {
              const searchBlocks = filteredBlocks.filter((block) => block.id === id);
              if (searchBlocks.length === 1) {
                matchedBlock = searchBlocks[0];
              } else {
                errorString = `No Block matches the ID property: (${id}).`;
              }
            }

            if (!matchedBlock && name !== undefined) {
              const searchBlocks = filteredBlocks.filter((block) => block.name === name);
              if (searchBlocks.length === 1) {
                matchedBlock = searchBlocks[0];
              } else {
                errorString = `No Block matches the FIELD or NAME property: (${id}).`;
              }
            }

            if (!matchedBlock && blockNo !== undefined && paddockNo !== undefined) {
              const blockPaddock = `${blockNo}-${paddockNo}`;
              const searchBlocks = filteredBlocks.filter(
                (block) => block.name === blockPaddock
              );
              if (searchBlocks.length === 1) {
                matchedBlock = searchBlocks[0];
              } else {
                errorString = `No Block matches the Feature's Block and Paddock Numbers: (${blockPaddock}).`;
              }
            }

            if (!matchedBlock) {
              errorSet.add(errorString ? errorString : 'No Matching Block Found.');
              recommendations.push({
                mod: cropClass === 'F' ? 'New' : 'Split',
                name: name ? name : `${blockNo}-${paddockNo}`,
                cropClass,
                area,
                farmNo,
                blockNo,
                paddockNo,
              });
              badFeatures.push(feature);
              return;
            }

            // if (id !== undefined) {
            //   const matchedBlocks = filteredBlocks.filter((block) => block.id === id);
            //   if (matchedBlocks.length === 0) {
            //     errorSet.add(`No Block matches the ID property: (${id}).`);
            //     badFeatures.push(feature);
            //     recommendations.push({mod: cropClass.slice(-1) === 'R' ? 'Split' : 'Clone', id, name, cropClass, area, farmNo, blockNo, paddockNo});
            //     return;
            //   }
            //   identifiedBlocks.push(...matchedBlocks);
            // }

            // if (name !== undefined) {
            //   const matchedBlocks = filteredBlocks.filter((block) => block.name === name);
            //   if (matchedBlocks.length === 0) {
            //     errorSet.add(`No Block matches the FIELD or NAME property: (${name})`);
            //     badFeatures.push(feature);
            //     recommendations.push({mod: cropClass.slice(-1) === 'R' ? 'Split' : 'Clone', id, name, cropClass, area, farmNo, blockNo, paddockNo});
            //     return;
            //   }
            //   identifiedBlocks.push(...matchedBlocks);
            // }

            // if (blockNo !== undefined || paddockNo !== undefined) {
            //   if (blockNo === undefined) {
            //     errorSet.add(`Missing BLOCK_NO property.`);
            //     badFeatures.push(feature);
            //     return;
            //   }
            //   if (paddockNo === undefined) {
            //     errorSet.add('Missing PADDOCK_NO property.');
            //     badFeatures.push(feature);
            //     return;
            //   }
            //   const blockPaddock = `${blockNo}-${paddockNo}`;
            //   const matchedBlocks = filteredBlocks.filter(
            //     (block) => block.name === blockPaddock
            //   );
            //   if (matchedBlocks.length === 0) {
            //     errorSet.add(
            //       `No Block matches the Feature's Block and Paddock Numbers: (${blockPaddock}).`
            //     );
            //     recommendations.push({mod: cropClass.slice(-1) === 'R' ? 'Split' : 'Clone', id, name, cropClass, area, farmNo, blockNo, paddockNo});
            //     badFeatures.push(feature);
            //     return;
            //   }
            //   identifiedBlocks.push(...matchedBlocks);
            // }

            // if (identifiedBlocks.length === 0) {
            //   errorSet.add('No Matching Block Found.');
            //   // recommendations.push({mod: cropClass.slice(-1) === 'R' ? 'Split' : 'Clone', id, name, cropClass, area, farmNo, blockNo, paddockNo});
            //   badFeatures.push(feature);
            //   return;
            // }

            // const identifiedBlockIds = new Set(identifiedBlocks.map((block) => block.id));
            // if (identifiedBlockIds.length > 1) {
            //   errorSet.add('Feature targets multiple Blocks.');
            //   badFeatures.push(feature);
            //   return;
            // }

            //Loop through the identified blocks and check if the area has changed
            // identifiedBlocks.forEach((block) => {
            //   console.log(block.name, block, area, cropClass, farmNo);
            //   if (block.hectares !== area) {
            //     console.log(
            //       `${block.name} has changed area. From ${block.hectares} to ${area}`
            //     );
            //   }
            // });

            // const matchedBlock = identifiedBlocks[0];

            const center = calculateGeomCenter(geometry);
            // console.log(geometry);
            // if(center.lat < -90 || center.lat > 90 || center.long < -180 || center.long > 180) {
            //   errorSet.add('Not "EPSG:4326" - WGS84 Coordinates Reference System');
            // }
            // console.log(matchedBlock)

            if (area !== matchedBlock.hectares) {
              // Check crop class
              recommendations.push({
                mod: 'Resize',
                id: matchedBlock.id,
                name: matchedBlock.name,
                cropClass,
                oldCropClass: matchedBlock.requirements[0]?.crop_class_name,
                area,
                oldArea: matchedBlock.hectares,
                blockNo,
                paddockNo,
              });
            } else {
              recommendations.push({
                mod: 'NO CHANGE',
                id: matchedBlock.id,
                name: matchedBlock.name,
                cropClass,
                oldCropClass: matchedBlock.requirements[0]?.crop_class_name,
                area,
                oldArea: matchedBlock.hectares,
                blockNo,
                paddockNo,
              });
            }

            goodFeatures.push({
              type: 'Feature',
              geometry: geometry,
              properties: {
                blockId: matchedBlock.id,
                blockName: matchedBlock.name,
                // newArea: area,
                // newCropClass: cropClass,
                // oldArea: matchedBlock.hectares,
                // oldCropClass: matchedBlock.requirements[0]?.crop_class_name,
                center,
                // oldCenter: {lat: matchedBlock.lat, lng: matchedBlock.lng},
              },
            });
          });

          const errors = [...errorSet];
          return { goodFeatures, badFeatures, errors, recommendations };
        };

        const allGoodFeatures = [];
        const allBadFeatures = [];
        const allErrors = [];
        const allRecommendations = [];

        const geojsonPromises = validFiles.map((zipFile) => getShapefileGeoJSON(zipFile));
        const geojsons = (await Promise.all(geojsonPromises)).flat();

        geojsons.forEach((geojson) => {
          const { goodFeatures, badFeatures, errors, recommendations } = getFeatures(geojson);
          allGoodFeatures.push(...goodFeatures);
          allBadFeatures.push(...badFeatures);
          allErrors.push(...errors);
          allRecommendations.push(...recommendations);
        });

        allGoodFeatures.forEach((feature) => addNewShape(feature.properties.blockId, feature));

        // Extract an array of block names
        const blockNames = allRecommendations.flatMap((block) =>
          block.name ? [block.name] : []
        );
        let diff = filteredBlocks.flatMap((x) =>
          !blockNames.includes(x.name)
            ? [
                {
                  mod: 'Decommission',
                  id: x.id,
                  name: x.name,
                  hectares: x.hectares,
                  crop_class_name: x.requirements[0]?.crop_class_name,
                },
              ]
            : []
        );
        allRecommendations.push(...diff);

        const blockModifications = allRecommendations
          .filter((x) => x.mod !== 'NO CHANGE')
          .map((x) => {
            return x.mod === 'Split'
              ? {
                  mod: x.mod,
                  from: allRecommendations.filter(
                    (y) => y.cropClass === x.cropClass && y.mod === 'Resize'
                  )[0].name,
                  name: x.name,
                  cropClass: x.cropClass,
                  area: x.area,
                  farmNo: x.farmNo,
                  blockNo: x.blockNo,
                  paddockNo: x.paddockNo,
                }
              : x;
          });

        console.log(blockModifications);

        // console.log("BAD: ", allBadFeatures);
        // console.log("GOOD: ", allGoodFeatures);
        // console.log("ERRORS: ", allErrors);

        if (allGoodFeatures.length >= 1) stopEditing();

        if (allBadFeatures.length > 0) {
          showErrorDialog([...new Set(allErrors)]);
        } else if (allGoodFeatures.length === 0) {
          showErrorDialog(['Zip contained no shape files.']);
        }
      } catch (err) {
        console.error(err);
        showErrorDialog(['An error occured while processing shape files.']);
      }
    },
    [filteredBlocks, addNewShape, stopEditing, showErrorDialog]
  );

  const saveBlockGeometryPairs = useCallback(async (blockGeometryPairs) => {
    return await API.graphql(
      graphqlOperation(updateBlocksGeometries.mutation, {
        pairs: blockGeometryPairs,
      })
    );
  }, []);

  const handleClickSave = useCallback(async () => {
    stopEditing();

    try {
      const blockGeometryPairs = modifiedShapes.map(({ blockId, shape }) => {
        return { blockId, geojson: JSON.stringify(shape.geometry) };
      });

      await saveBlockGeometryPairs(blockGeometryPairs);
    } catch (err) {
      console.error('An error occured while saving block shapes:', err);
      showErrorDialog(['An error occured while saving changes!']);
      return;
    }

    // setIsLoading(true);

    queryClient.invalidateQueries(['maps', farmId]);
    // try {
    //   const data = await fetchData();
    //   setBlocks(data.blocks);
    // } catch (err) {
    //   console.error('An error occured while reloading farm data:', err);
    //   showErrorDialog(['An occured while loading.'], true);
    // } finally {
    //   setIsLoading(false);
    // }

    clearModifiedShapes();
  }, [
    stopEditing,
    queryClient,
    farmId,
    clearModifiedShapes,
    modifiedShapes,
    saveBlockGeometryPairs,
    showErrorDialog,
  ]);

  const handleClick = useCallback(
    (e) => {
      const feature = e.target.feature;
      console.log(feature);
      if (editingBlockId === feature.properties.blockId) {
        stopEditing();
        return;
      }
      const cloned = cloneShape(feature);
      addModifiedShape(feature.properties.blockId, cloned);
      startEditing(feature.properties.blockId, feature);
    },
    [addModifiedShape, editingBlockId, startEditing, stopEditing]
  );

  const handleShapeLayer = useCallback(
    (feature, layer) => {
      layer.on({
        click: handleClick,
      });
      layer
        .bindTooltip(feature.properties.blockName, {
          permanent: true,
          direction: 'center',
          className: classes.blockLabel,
        })
        .openTooltip();
    },
    [classes]
  );

  const handleShapeEdit = useCallback(
    (editedShape) => {
      const editedBlockId = editedShape.properties.blockId;
      editedShape.properties.center = calculateGeomCenter(editedShape.geometry);
      addModifiedShape(editedBlockId, editedShape);
    },
    [addModifiedShape]
  );

  return (
    <>
      {
        errorState !== undefined ? (
          <Dialog open onClose={errorState.isPermanent ? undefined : closeErrorDialog}>
            <DialogTitle>Something went wrong...</DialogTitle>
            <DialogContent>
              <List>
                {errorState.errors.map((error, index) => (
                  <ListItemError key={`${index}-${error}`} message={error} />
                ))}
              </List>
            </DialogContent>
            {!errorState.isPermanent && (
              <DialogActions>
                <Button onClick={closeErrorDialog}>Close</Button>
              </DialogActions>
            )}
          </Dialog>
        ) : null
        // isLoading && (
        //   <Backdrop open style={{ color: 'white', zIndex: 100000 }}>
        //     <CircularProgress color='inherit' />
        //   </Backdrop>
        // )
      }
      <LogoCard>
        <PageHeader
          title={'Manage Blocks'}
          subheader={`Managing Blocks for ${farmName}`}
          backPath={`/view-farm-overview/${farmName}/${farmId}`}
        />
        <FormControl style={{ padding: '0 0 8px 16px' }}>
          <Select
            value={fertYear}
            onChange={(ev) => {
              setFertYear(ev.target.value);
              localStorage.setItem(`fertYear`, ev.target.value);
            }}
            disabled={isFertYearFixed}
          >
            {fertYears.map((year, index) => (
              <MenuItem key={`${index}-${year}`} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Select Fert Year</FormHelperText>
        </FormControl>
      </LogoCard>
      {!loading && blocks.length > 0 && (
        <Grid container style={{ height: '70%', overflowY: 'hidden' }}>
          <Grid item xs={8}>
            <MapContainer
              center={mapCenter}
              zoom={16}
              zoomControl={true}
              style={{ height: '100%' }}
            >
              <MapCenterControl center={mapCenter} />
              <LayersControl position='topright'>
                <LayersControl.BaseLayer checked name='Satellite View'>
                  <TileLayer url='http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}' />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name='Street Map'>
                  <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                </LayersControl.BaseLayer>
              </LayersControl>
              {filteredBlocks.map((block) => {
                const oldShape = oldShapes[block.id];
                const newShape = getNewShape(block.id);
                const modifiedShape = getModifiedShape(block.id);
                if (oldShape === undefined && newShape === undefined) return undefined;

                const isNewSelected = blockShapeStates[block.id];
                const isEditing = editingBlockId === block.id;

                return newShape !== undefined && isNewSelected ? (
                  <GeoJSON
                    key={`new-${block.id}`}
                    data={newShape}
                    style={{
                      className: classes.newBlockShape,
                    }}
                    onEachFeature={handleShapeLayer}
                  />
                ) : modifiedShape !== undefined ? (
                  <GeojsonEdit
                    key={`modified-${block.id}`}
                    data={modifiedShape}
                    edit={isEditing}
                    style={{ className: classes.modifiedBlockShape }}
                    onEdit={handleShapeEdit}
                    onEachFeature={handleShapeLayer}
                  />
                ) : (
                  oldShape !== undefined && (
                    <GeoJSON
                      key={`old-${block.id}`}
                      data={oldShape}
                      style={{
                        className: classes.oldBlockShape,
                      }}
                      onEachFeature={handleShapeLayer}
                    />
                  )
                );
              })}
            </MapContainer>
          </Grid>
          <Grid item xs={4}>
            <FormGroup style={{ marginLeft: '20px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={suggestModifications}
                    onChange={(ev) => setSuggestModifications(ev.target.checked)}
                  />
                }
                label='Suggest Modifications'
              />
            </FormGroup>

            <FileDropZone label={'Drag Shape Zip Here'} onDrop={handleFileDrop} />
            <Paper
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '20px',
                paddingLeft: '10px',
              }}
            >
              <Typography variant='h6' style={{ marginTop: '10px' }}>
                Legend
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    color: 'red',
                    backgroundColor: 'red',
                    height: 5,
                    width: 25,
                    marginRight: 10,
                  }}
                />
                <Typography>Unmodified Blocks</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    color: 'orange',
                    backgroundColor: 'orange',
                    height: 5,
                    width: 25,
                    marginRight: 10,
                  }}
                />
                <Typography>Modified Block</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: 6,
                    marginRight: 12,
                    borderRadius: '50%',
                    border: '2px solid blue',
                    height: '15px',
                    width: '15px',
                  }}
                />
                <Typography>Corner (dbl click to delete)</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: 9,
                    marginRight: 15,
                    borderRadius: '50%',
                    border: '2px solid blue',
                    height: '9px',
                    width: '9px',
                  }}
                />
                <Typography>Midpoint (drag to create corner)</Typography>
              </Box>
            </Paper>
            <div style={{ height: '60%', overflowY: 'scroll' }}>
              <List dense>
                <ListSubheader disableGutters>
                  <Paper
                    elevation={0}
                    style={{ padding: '5px 10px', backgroundColor: '#F5FBFD' }}
                  >
                    <Typography variant='h6' color='textPrimary'>
                      New Shapes
                    </Typography>
                  </Paper>
                </ListSubheader>

                {/* New Shapes - Start */}
                {filteredBlocks.map((block) => {
                  const oldShape = oldShapes[block.id];
                  const newShape = getNewShape(block.id);
                  const modifiedShape = getModifiedShape(block.id);

                  const isNewSelected = blockShapeStates[block.id];

                  if (newShape === undefined) return undefined;

                  return (
                    <NewShapeCard
                      key={block.id}
                      blockId={block.id}
                      blockName={block.name}
                      isNewSelected={isNewSelected}
                      oldShape={modifiedShape || oldShape}
                      newShape={newShape}
                      onNavigate={({ selectedShape }) => {
                        const { lat, long } = selectedShape.properties.center;
                        setMapCenter([lat, long]);
                      }}
                      onSelectChange={({ blockId, isNewSelected, selectedShape }) => {
                        setBlockShapeState(blockId, isNewSelected);
                        const { lat, long } = selectedShape.properties.center;
                        setMapCenter([lat, long]);
                      }}
                      onAccept={({ blockId, isNewSelected, selectedShape }) => {
                        if (!isNewSelected) return;
                        removeNewShape(blockId);
                        addModifiedShape(blockId, selectedShape);
                      }}
                      onReject={({ blockId }) => {
                        removeNewShape(blockId);
                      }}
                    />
                  );
                })}
                {/* New Shapes - End */}

                <ListSubheader disableGutters>
                  <Paper
                    elevation={0}
                    style={{ padding: '5px 10px', backgroundColor: '#F5FBFD' }}
                  >
                    <Typography variant='h6'>Modified Shapes</Typography>
                  </Paper>
                </ListSubheader>

                {/* Modified Shapes - Start */}
                {filteredBlocks.map((block) => {
                  const modifiedShape = getModifiedShape(block.id);
                  const isNewSelected = blockShapeStates[block.id];
                  return (
                    modifiedShape && (
                      <ModifiedShapeCard
                        key={block.id}
                        blockId={block.id}
                        blockName={block.name}
                        blockHectars={block.hectares}
                        shape={modifiedShape}
                        isEditing={editingBlockId === block.id}
                        disableEditing={isEditingDisabled}
                        disableNavigating={isNewSelected}
                        onNavigate={({ shape }) => {
                          const { lat, long } = shape.properties.center;
                          setMapCenter([lat, long]);
                        }}
                        onStartEditing={({ blockId, shape }) => {
                          startEditing(blockId, shape);
                          // const { lat, long } = shape.properties.center;
                          // setMapCenter([lat, long]);
                        }}
                        onStopEditing={() => stopEditing()}
                        onDiscard={({ blockId, isEditing }) => {
                          removeModifiedShape(blockId);
                          if (isEditing) stopEditing();
                        }}
                      />
                    )
                  );
                })}
                {/* Modified Shapes - End */}

                <ListSubheader disableGutters>
                  <Paper
                    elevation={0}
                    style={{ padding: '5px 10px', backgroundColor: '#F5FBFD' }}
                  >
                    <Typography variant='h6'>Old Shapes</Typography>
                  </Paper>
                </ListSubheader>

                {/* Old Shapes - Start */}
                {filteredBlocks.map((block) => {
                  const oldShape = oldShapes[block.id];
                  const modifiedShape = getModifiedShape(block.id);
                  const isNewSelected = blockShapeStates[block.id];
                  return (
                    !modifiedShape && (
                      <OldShapeCard
                        key={block.id}
                        blockId={block.id}
                        blockName={block.name}
                        shape={oldShape}
                        disableEditing={isEditingDisabled}
                        disableNavigating={isNewSelected}
                        onNavigate={({ shape }) => {
                          const { lat, long } = shape.properties.center;
                          setMapCenter([lat, long]);
                        }}
                        onStartEditing={({ blockId, shape }) => {
                          console.log('Start Editing', shape);
                          const cloned = cloneShape(shape);
                          addModifiedShape(blockId, cloned);
                          startEditing(blockId, shape);
                          // const { lat, long } = shape.properties.center;
                          // setMapCenter([lat, long]);
                        }}
                      />
                    )
                  );
                })}
                {/* Old Shapes - End */}
              </List>
            </div>
            <Grid item></Grid>
          </Grid>
        </Grid>
      )}
      <div style={{ width: '100%', padding: '16px 8px', display: 'flex' }}>
        <Button
          variant='contained'
          color='secondary'
          style={{ flex: '1' }}
          disabled={isSavingDisabled}
          onClick={handleClickSave}
        >
          Save Changes
        </Button>
        <Button
          color='secondary'
          variant='contained'
          style={{ flex: '1', marginLeft: '16px' }}
          disabled={!(editingBlockId && editingBlockId > 0)}
          onClick={() => setOpenDialog('ROLLOVER')}
        >
          Modify
        </Button>
      </div>
      {openDialog === 'ROLLOVER' && editingBlockId > 0 && (
        <RolloverDialog
          visible={openDialog === 'ROLLOVER'}
          blockId={editingBlockId}
          fertYear={fertYear}
          // fetchBlocks={fetchBlocks}
          handleClose={(nextPage) => setOpenDialog(nextPage)}
          blocks={filteredBlocks}
        />
      )}
    </>
  );
};
