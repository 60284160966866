import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import LogoCard from '../components/LogoCard';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { API, graphqlOperation } from 'aws-amplify';
// import {addLabSample} from '../graphQL/SoilSample';
import { useAlertContext } from './AlertContext';
import { useSnackbar } from 'notistack';
import UploadSoilSampleDialog from './FarmOverview/UploadSoilSampleDialog';
import * as excel from '../functions/excel';

const _sanitiseDate = (dateStr) => {
  try {
    const regex =
      /^\s*(?<day>[0-9]+)\s*\/\s*(?<month>[0-9]+)\s*\/\s*(?<year>[0-9]+)\s*$/;
    const match = regex.exec(dateStr);
    if (match === null || match.groups === undefined)
      return undefined;

    const { day, month, year } = match.groups;
    const date = new Date(`${month}/${day}/${year}`);
    // NOTE: `isNaN()` will return true if Date is invalid.
    if (isNaN(date)) return undefined;

    return date.toLocaleDateString('EN-AU');
  } catch (err) {
    // TODO: Sentry?
    console.error(
      `An error occured during Sample upload, while parsing the Date ('${dateStr}').`,
      err
    );
    return undefined;
  }
};

export default function UploadLabSamples(props) {
  const classes = style();
  const alertContext = useAlertContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [uploadedSamples, setUploadedSamples] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setHover(false);
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setHover(true);
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setLoading(true);
    setHover(false);
    if (e.dataTransfer.files) {
      const files = e.dataTransfer.files;
      const filesArray = Array.from(files);
      Promise.all(
        filesArray.map((file, index) => {
          if (file.type === 'application/vnd.ms-excel') {
            const reader = new FileReader();
            reader.onload = async function (e) {
              const labSamples = excel.parse(e.target.result).nthSheet(0);
              labSamples.forEach((sample) => {
                sample['Sample date'] = _sanitiseDate(sample['Sample date']) || sample['Sample date'];
                sample['Soil test number'] = sample['Soil test number']?.trim();
                sample['Farm no.'] = sample['Farm no.']?.trim();
                sample['Block no.'] = sample['Block no.']?.trim();
                sample['Soil texture'] = sample['Soil texture']?.trim();
                sample['Sample Description'] = sample['Sample Description']?.trim();
                sample['Crop class at sampling'] = sample['Crop class at sampling']?.trim();
              });
              console.log(labSamples);
              setUploadedSamples([...labSamples]);
              setOpenDialog(true);
            };
            return reader.readAsBinaryString(files[index]);
          } else {
            console.log('Incorrect file type');
            return enqueueSnackbar(`${file.name} is the Incorrect file type`, {
              variant: 'error',
            });
          }
        })
      )
        .then(() => {
          console.log('DONE');
          setLoading(false);
        })
        .catch((err) => {
          console.log('Error', err);
          setLoading(false);
          enqueueSnackbar(`Error uploading lab samples`, {
            variant: 'error',
          });
        });
    } else {
      alertContext.error(`Invalid files`);
      setLoading(false);
    }
    e.stopPropagation();
  };

  return (
    <LogoCard>
      <div
        className={clsx(classes.dropZone, hover && classes.hover)}
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      >
        {loading === true ? (
          <div>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <Typography variant='h6' gutterBottom>
            Drag files here to upload lab samples
          </Typography>
        )}
      </div>
      {openDialog && uploadedSamples?.length > 0 && (
        <UploadSoilSampleDialog
          samples={uploadedSamples}
          farmId={props.farmId}
          farmName={props.farmName}
          handleClose={() => {
            queryClient.invalidateQueries(['soilSamples', props.farmId]);
            setOpenDialog(false);
          }}
        />
      )}
    </LogoCard>
  );
}

const style = makeStyles((theme) => ({
  dropZone: {
    height: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'dashed',
    outlineOffset: -10,
    outlineColor: '#019BDC',
  },
  hover: {
    backgroundColor: 'rgba(9, 189, 47, 0.1)',
  },
}));
