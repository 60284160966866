import { APIFetch } from '../../fetch.js';

export const getRequirements = async ({ farmId, token }) => {
  const response = await APIFetch({
    route: `requirements/getRequirements?farmId=${farmId}`,
    method: 'GET',
    authToken: token,
  });

  if (!response.ok) {
    console.error(await response.json());
    throw new Error('An error occured during GetRequirements request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return [];
};
