var getTitle = (location) => {
    location = location.substring(location.lastIndexOf('/')+1)
    switch (location) {
        case 'ManageAccount':
            return 'Manage User Account';
        case 'Profile':
            return 'My Account'
        case 'Accounts':
            return 'User Accounts'
        case 'ViewFarm':
            return 'View Farm Blocks'
        case 'FileRepository':
            return 'File Repository'
        case 'SoilSamples':
            return 'Soil Samples'
        case 'ViewSoilSamples':
            return 'Soil Samples'
        case 'ManageSoilSample':
            return 'Manage Soil Sample'
        default:
          return 'Land';
    }
}

export default getTitle;