import React, { useState, useContext, useCallback } from "react";

// Queries
import { requirementCycle } from "../../api/mutations/requirements/requirementCycle.js";

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from "../../contexts/UserContext.js";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

export default function CycleDialog(props) {
  const alertContext = useAlertContext();
  const { visible, fertYear, block, handleClose } = props;
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();

  const [estimatePlantDate, setEstimatePlantDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleDateChange = (event) => {
    setEstimatePlantDate(event.target.value);
  };

  /* 
  If a block enters a cycles it should generate the requirements(or assign the cane requirements to the blocks requirements) , fert year become current year and cropClass is now fallow.
    */

  // input Requirement {
  //   requirementId: String
  //   requirement_state_id: String
  //   plant_date: String
  //   block_id: String
  // }

  const handleCycle = useCallback(async () => {
    console.log("Estimate to plant on ", estimatePlantDate);

    try {
      const result = await requirementCycle({
        token: user?.idToken?.jwtToken,
        fertYear,
        plantDate: estimatePlantDate,
        blockId: block.id,
      });

      alertContext.success(`Requirement updated`);
      dispatchBlock({
        type: "ADD_REQUIREMENT",
        block: {
          id: block.id,
          requirement: {
            id: result?.requirementId,
            approx_plant_date: estimatePlantDate,
            block_id: block.id,
            fertilizer_year: fertYear,
            crop_class_id: 1,
            from_date: new Date(),
            crop_class_name: "Fallow",
          },
        },
      });

      handleClose();
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block.id, dispatchBlock, estimatePlantDate, fertYear, handleClose, user?.idToken?.jwtToken]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="cycle-dialog-title"
      aria-describedby="cycle-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="cycle-dialog-title"
      >
        {`Enter Block ${block.name} into Cane Cycle`}
      </DialogTitle>
      <DialogContent>
        <TextField
          id="date"
          label={"Approximate plant date"}
          type="date"
          defaultValue={estimatePlantDate} //"2021-02-24" //
          fullWidth
          onChange={handleDateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleCycle()} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
