export const createVarieties = {
  mutation: `mutation createVarieties($varieties: [String]) {
        createVarieties(varieties: $varieties)
    }`,
};

export const getVarieties = {
  query: `query getVarieties {
    getVarieties
  }`,
};
