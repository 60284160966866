import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tab,
} from '@material-ui/core';

export default function AmeliorantMods(props) {
  const { sample, tableData, requirement } = props;

  return (
    <div>
      <Typography
        variant='h6'
        style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Modifications to ameliorant application rates where mill by-products have been applied`}
      </Typography>
      {requirement && requirement.mill_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_ash_rate} t/ha Mill Ash applied</Typography>}
      {requirement && requirement.mill_mud_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_rate} t/ha Mill Mud applied</Typography>}
      {requirement && requirement.mill_mud_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_ash_rate} t/ha Mill Mud Ash applied</Typography>}
      {requirement && !requirement.mill_mud_ash_rate && !requirement.mill_mud_rate && !requirement.mill_ash_rate && <Typography style={{ marginBottom: 10 }}>No Mill byproduct applied</Typography>}
      <TableContainer style={{ marginBottom: 20 }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Application rate</TableCell>
              <TableCell>Reduce the next lime application by:</TableCell>
              <TableCell>Magnesium (Mg)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.ameliorant_mod.map((el) => (
              <TableRow>
                <TableCell>
                  {el.byproduct}
                </TableCell>
                <TableCell>
                  {`${el.application_rate_min} - ${el.application_rate_max} wet tonnes/ha`}
                </TableCell>
                <TableCell>
                  {el.lime_reduction_rate} t/ha
                </TableCell>
                <TableCell>
                  {el.magnesium_reduction_note}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
