import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { getFarmBlocks } from '../../../graphQL/Farm';

// Searches through all the requirements and returns all the fertilizer years found in a sorted list
const getFertYears = (blockArray) => {
    const fertYears = new Set(
        blockArray
        .flatMap((blockItem) =>
            blockItem.requirements
            ? blockItem.requirements.map((item) => item.fertilizer_year)
            : []
        )
        .sort((a, b) => a - b)
    );

  // NOTE: Give a few years of buffer incase no blocks on this farm have requirements
    for (let i = 0; i < 4; i++) {
        fertYears.add(new Date().getFullYear() + i);
    }

  // console.log(fertYears);
    return [...fertYears];
};

export default function useFarmBlocks(farmId) {
return useQuery({
    queryKey: ['blocks', farmId],
    queryFn: async () => {
        const result = await API.graphql(
            graphqlOperation(getFarmBlocks.query, { farmId: farmId })
        );
        const ngBlock = JSON.parse(result.data.getFarmBlocks);
      //   dispatchBlock({
      //     type: 'LOAD_BLOCKS',
      //     blocks: ngBlock,
      //   });
        console.log('GOT FARM BLOCKS AGAIN...');

        return { blocks: ngBlock, fertYears: getFertYears(ngBlock) };
        },
    });
}
