import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function LphTable(props) {
  const { sample, tableData, requirement } = props;

  const getCellColor = (min, max) => {
    if (min <= sample.cation_exchange_capacity_meq_100g && max > sample.cation_exchange_capacity_meq_100g) {
      if (sample.ph_1_5_water < 5.5) return 'rgba(65, 172, 210, 0.6)';
      return 'rgba(152, 0, 0, 0.1)'
    } 
      return '#FFF';
  }

  return (
    <div>
      <Typography
        variant='h6'
        style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Lime guidelines for acid soils (when pH water < 5.5) ${
          requirement && requirement.lime_acidic || 0
        }t/ha`}
      </Typography>
      <Typography style={{ marginBottom: 10, color: sample.ph_1_5_water < 5.5 ? 'black' : 'red' }}>pH: {sample.ph_1_5_water}</Typography>
      <Typography style={{ marginBottom: 10 }}>
        CEC: {sample.cation_exchange_capacity_meq_100g} (meq/100g)
      </Typography>
      <TableContainer style={{ marginBottom: 20 }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>CEC (meq/100g)</TableCell>
              <TableCell>Lime (t/ha)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.lime_acidic.map((el, index) => (
              <TableRow>
                <TableCell>
                  {index === tableData.lime_acidic.length - 1
                    ? `> ${el.cec_min}`
                    : index === 0 ? `< ${el.cec_max}` : `<= ${el.cec_max}`}
                </TableCell>
                <TableCell style={{ backgroundColor: getCellColor(el.cec_min, el.cec_max)}}>
                  {el.lime_acidic_application_rate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
