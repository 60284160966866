export const updateProductsSelected = {
    mutation: `mutation updateProductsSelected($data: UpdateProductsSelected) {
        updateProductsSelected(updatedProductRecords: $data)
    }`
};

export const getProducts = { 
    query: `query getProducts {
        getProducts
    }`
};

export const createProduct = {
    mutation: `mutation createProduct($data: Product) {
        createProduct(product: $data)
    }`,
};

export const deleteProduct = {
    mutation: `mutation deleteProduct($id: String) {
        deleteProduct(id: $id)
    }`
}

export const getSugarPrices = {
    query: `query getSugarPrices {
        getSugarPrices
    }`
}

export const getFertPrices = {
    query: `query getFertPrices {
        getFertPrices
  }`
}

export const getManufacturers = { 
    query: `query getManufacturers {
        getManufacturers
    }`
};

export const applyProduct = {
    mutation: `mutation applyProduct($applicationData: ApplicationData) {
      applyProduct(applicationData: $applicationData) 
      }`,
  };
  
