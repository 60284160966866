import React, { useState, useContext, useCallback } from "react";
import { useQueryClient } from '@tanstack/react-query';
// Material
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Button, Typography } from "@material-ui/core";
// Queries
import { applyProduct } from "../../api/mutations/products/apply.js";
// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from "../../contexts/UserContext.js";

const style = makeStyles((theme) => ({
  text: {
    marginLeft: 10,
    marginRight: 10,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
}));

export default function ProductApplicationLine(props) {
  const { dispatchBlock } = useContext(BlockContext);
  const alertContext = useAlertContext();
  const { user } = useUserContext();
  const classes = style();
  let { product } = props;

  const queryClient = useQueryClient();        

  const disableChanges = product.disableChanges;

  // console.log("D: ", disableChanges);

  // console.log("P: ", product);

  const [appDate, setAppDate] = useState(
    product.applied
      ? new Date(product.applieddate).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0]
  );

  const [recordedDate, setRecordedDate] = useState(
    product.recordeddate
      ? new Date(product.recordeddate).toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0]
  );

  const [area, setArea] = useState(
    product.applied &&
      parseFloat(product.applied) !== parseFloat(product.blockarea)
      ? parseFloat(product.applied)
      : parseFloat(product.blockarea)
  );

  // useEffect(() => {
  // const delayDebounceFn = setTimeout(() => {
  //   console.log(
  //     value > parseFloat(product.blockarea)
  //       ? parseFloat(product.blockarea)
  //       : value < parseFloat(product.applied)
  //       ? parseFloat(product.applied)
  //       : value
  //   );
  // }, 2000)

  //   return () => clearTimeout(delayDebounceFn)
  // }, [area])

  // const setConstrainedArea = (value) => {
  //   setArea(
  //     value > parseFloat(product.blockarea)
  //       ? parseFloat(product.blockarea)
  //       : value < parseFloat(product.applied)
  //       ? parseFloat(product.applied)
  //       : value
  //   );
  // };

  // ALLOWED TO CHANGE APPLICATIONS (ie. reduce to 0)!!!!
  const setConstrainedArea = (value) => {
    setArea(
      value > parseFloat(product.blockarea)
        ? parseFloat(product.blockarea)
        : value < 0
        ? 0
        : value
    );
  };

  const buttonText = () => {
    const blockArea = parseFloat(product.blockarea);
    const applied = parseFloat(product.applied);
    console.log("A: ", area, "B: ", blockArea, "C: ", applied)
    if(area === applied && area < blockArea) return "PARTIAL APPLIED";
    if (area < applied) return "REDUCE";
    if (area > applied && area < blockArea) return "PARTIAL APPLY";
    if (area === blockArea && applied === blockArea) return "APPLIED";
    return "APPLY";
    // {parseFloat(product.applied) !== null &&
    //   parseFloat(product.applied) === parseFloat(product.blockarea)
    //     ? "APPLIED"
    //     : parseFloat(product.applied) === area
    //     ? "PARTIAL APPLIED"
    //     : area > parseFloat(product.applied) &&
    //       area < parseFloat(product.blockarea)
    //     ? "PARTIAL APPLY"
    //     : area < parseFloat(product.applied) ? "REDUCE" : "APPLY"}
    }

  const setApplication = useCallback(async () => {
    console.log();
    try {
      await applyProduct({
        token: user?.idToken?.jwtToken,
        id: product.id,
        appliedDate: appDate,
        appliedArea: area,
        recordedDate,
      });

      alertContext.success(`Product Applied`);
      // product.applied = area;
      dispatchBlock({
        type: "APPLY_PRODUCT",
        blockid: product.blockid,
        productid: product.id,
        applied: area,
        applieddate: appDate,
        recordeddate: recordedDate,
      });
      // ADD useQuery and invalidate queries!!!!!!!!!!!!!!!!!!!!!!!!!!
      queryClient.invalidateQueries(['maps']); // Requires farmId
      console.log(area);
      setArea(area);
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error applying product`);
    }
  }, [alertContext, appDate, area, dispatchBlock, product.blockid, product.id, queryClient, recordedDate, user?.idToken?.jwtToken]);

  return (
    <Box className={classes.box}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {product.blockname && (
          <Typography className={classes.text}>
            Block {product.blockname}
          </Typography>
        )}
        <Typography className={classes.text}>{product.name}</Typography>
        <Typography className={classes.text}>{product.appmethod}</Typography>
        <Typography className={classes.text}>{product.apptiming}</Typography>
        <Typography className={classes.text}>
          {product.apprate} kg/ha
        </Typography>
      </Box>
      <TextField
        className={classes.text}
        disabled={disableChanges} //product.applied > 0 || 
        id="appDate"
        label="Application date"
        type="date"
        defaultValue={appDate}
        onChange={(event) => {
          setAppDate(event.target.value);
          setRecordedDate(event.target.value);
        }}
      />
      <Button
        className={classes.button}
        style={{ width: 160 }}
        variant="contained"
        color={parseFloat(product.applied) === parseFloat(product.blockarea) ? "primary" : "secondary"}
        onClick={() => setApplication()}
        disabled={disableChanges
          // parseFloat(product.applied) === parseFloat(product.blockarea) ||
          // parseFloat(product.applied) === area || disableChanges
        }
      >
        {buttonText()}
      </Button>
      <TextField
        disabled={disableChanges} //parseFloat(product.applied) === parseFloat(product.blockarea) || 
        id="area"
        label="Application area (ha)"
        value={area}
        onChange={(event) => setArea(event.target.value)}
        onBlur={() => setConstrainedArea(area)}
        onMouseLeave={() => setConstrainedArea(area)}
      />
      <TextField
        className={classes.text}
        disabled={disableChanges} //product.applied > 0 || 
        id="recordedDate"
        label="Recorded date"
        type="date"
        value={recordedDate}
        onChange={(event) => setRecordedDate(event.target.value)}
      />
    </Box>
  );
}
