import { useCallback, useEffect, useRef } from 'react';

export const useCallbackRef = (callback) => {
  const ref = useRef(callback);

  useEffect(() => {
    if (
      callback !== undefined &&
      callback !== null &&
      typeof callback !== 'function'
    )
      throw new Error(
        `useCallbackRef received invalid parameter 'callback': Expected (undefined, null, or function), Received (${callback})`
      );
    ref.current = callback;
  }, [callback]);

  const invoke = useCallback((...args) => {
    ref.current?.(...args);
  }, []);

  return invoke;
};
