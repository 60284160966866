import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function NTable(props) {
  const { sample, tableData, requirement } = props;

  const plant = requirement && requirement.crop_class_id <= 2;

  const getCellColor = (plantRow, min, max) => {
    if (min < sample.organic_carbon && max >= sample.organic_carbon) {
      if (plant === plantRow) return 'rgba(65, 172, 210, 0.6)';
      // return 'rgba(0, 157, 72, 0.1)'
    } else {
      // if (plant === plantRow) return 'rgba(0, 161, 201, 0.1)';
      return '#FFF';
    }
  }

  const millByprouduct = (requirement && requirement.byproduct_applied_year === requirement.fertilizer_year) ? requirement.mill_ash_rate + requirement.mill_mud_ash_rate + requirement.mill_mud_rate : 0;
  const nDiscount = millByprouduct < 100 ? 0 : millByprouduct < 200 ? 40 : 60;

  return (
        <div>
          <Typography  style={{ marginBottom: 10 }}>Organic C: {sample.organic_carbon}%</Typography>
          <TableContainer style={{ marginBottom: 20}}>
            <Table stickyHeader size='small'>
              <TableHead>
              <TableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell scope='row' colSpan={tableData.nitrogen.length}>
                  Organic C (%) range, N mineralisation index and N application rate (kg/ha)
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell colSpan={2}></TableCell>
                {tableData.nitrogen.map((el, index) => (<TableCell >{index === tableData.nitrogen.length - 1 ? `>= ${el.organic_carbon_min}` : `< ${el.organic_carbon_max}`}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>

                <TableRow>
                <TableCell style={{width: '120px'}}>District Yield Potential</TableCell>
                <TableCell >Crop</TableCell>
                {tableData.nitrogen.map((el) => (<TableCell style={{background: getCellColor(null, el.organic_carbon_min, el.organic_carbon_max)}}>{el.nitrogen_mineralisation_index}</TableCell>))}
                </TableRow>
                <TableRow>
                <TableCell rowSpan={2}>{tableData.nitrogen[0].potential_yield} tc/ha</TableCell>
                <TableCell >Plant after bare fallow</TableCell>
                {tableData.nitrogen.map((el) => (<TableCell style={{background: getCellColor(true, el.organic_carbon_min, el.organic_carbon_max)}}>{el.nitrogen_plant_application_rate}</TableCell>))}
                </TableRow>
                <TableRow>
                {/* <TableCell ></TableCell> */}
                <TableCell >Replant and Ratoon</TableCell>
                {tableData.nitrogen.map((el) => (<TableCell style={{background: getCellColor(false, el.organic_carbon_min, el.organic_carbon_max)}}>{el.nitrogen_replant_and_ratoon_application_rate}</TableCell>))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography
        variant='h6'
        style={{ paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Modifications to Nitrogen application rates where mill by-products have been applied`}
      </Typography>
      {requirement && requirement.mill_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_ash_rate} t/ha Mill Ash applied</Typography>}
      {requirement && requirement.mill_mud_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_rate} t/ha Mill Mud applied</Typography>}
      {requirement && requirement.mill_mud_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_ash_rate} t/ha Mill Mud Ash applied</Typography>}
      {requirement && !requirement.mill_mud_ash_rate && !requirement.mill_mud_rate && !requirement.mill_ash_rate && <Typography style={{ marginBottom: 10 }}>No Mill byproduct applied</Typography>}
      <TableContainer >
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Application rate (wet t/ha)</TableCell>
              <TableCell>Amount to be deducted</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          <TableRow>
              <TableCell>{`< 100`}</TableCell>
              <TableCell>No deduction</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>100 - 200</TableCell>
              <TableCell style={{backgroud: nDiscount === 40 ? 'rgba(65, 172, 210, 0.6)' : '#FFF'}}>40</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>200 - 300</TableCell>
              <TableCell style={{backgroud: nDiscount === 60 ? 'rgba(65, 172, 210, 0.6)' : '#FFF'}}>60</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        
      </TableContainer>
      <Typography variant='caption'>*Reef Water Quality guildlines</Typography>


      {requirement && !requirement.fallow_yield && <Typography >No Legume crop - 0 discount</Typography>}
        </div>
  );
}
