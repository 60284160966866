import React from "react";
import { Card } from "@material-ui/core";

function LogoCard(props) {
  return (
    <Card style={{ margin: props.margin }}>{props.children}</Card>
  );
}

export default LogoCard;
