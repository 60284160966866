import terraformAPITest from './terraform_api_test.json'
import terraformAPIProd from './terraform_api_prod.json'
import terraformDev from './terraform_dev.json'
import terraformTest from './terraform_test.json'
import terraformProd from './terraform_prod.json'

const ENV = {
    local: {
        name:'Local',
        terraform: terraformDev.aws_app.value,
    },
    dev: {
        name:'Dev',
        terraform: terraformDev.aws_app.value,
    },
    test: {
        name:'Test',
        terraform: {api: terraformAPITest.api.value, ...terraformTest.aws_app.value},
    },
    prod: {
        name:'Prod',
        terraform: {api: terraformAPIProd.api.value, ...terraformProd.aws_app.value},
    }
}

function getBaseEnvVars(env = '') {
    return ENV[env] || ENV.local
}

function getEnvVars() {
    const envName = process.env.REACT_APP_ENV
    return {
        env: envName,
        revision:process.env.REACT_APP_REVISION,
        ...getBaseEnvVars(envName)
    }
}

export default getEnvVars()
