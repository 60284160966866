import React, { useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';

// Queries
import { setPrimarySoilSample } from '../../graphQL/SoilSample';

// Context
import { useAlertContext } from '../AlertContext';
import { BlockContext } from '../../contexts/BlocksContext';

// Material
import { makeStyles } from '@material-ui/core/styles';

import {
  Checkbox,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

// Components

const style = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginRight: 20,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  boxCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function SoilSampleDialog(props) {
  const classes = style();
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { visible, block, farmName, handleClose } = props;
  const [selectedSample, setSelectedSample] = useState(null);
  const history = useHistory();

  // console.log(block.requirements[0].requirement_state_id);

  //navigate to /farm-soil-samples/F0002A/384
  const handleUploadSample = () => {
    history.push({
      pathname: `/farm-soil-samples/${farmName}/${block.farm_id}`,
    });
    // window.location.href = `/farm-soil-samples/${farmName}/${block.farm_id}`;
  };

  const handlePrimarySoilSample = () => {
    const sampleData = {
      soilSampleId: selectedSample,
      blockId: block.id,
      requirementId: block.requirements[0].id,
      fertYear: block.requirements[0].fertilizer_year,
      recalculate: block.requirements[0].requirement_state_id === 3 ? true : false,
    };

    console.log(sampleData);

    API.graphql(
      graphqlOperation(setPrimarySoilSample.mutation, {
        data: JSON.stringify(sampleData),
      })
    )
      .then(async (result) => {
        dispatchBlock({
          type: 'EDIT_BLOCK',
          block: {
            id: block.id,
            primary_sample_id: selectedSample,
          },
        });
        dispatchBlock({
          type: 'MODIFY_REQUIREMENT',
          block: {
            id: block.id,
            requirementid: block.requirements[0].id,
            requirement: {
              primary_sample_id: selectedSample,
              requirement_state_id: sampleData.recalculate
                ? 2
                : block.requirements[0].requirement_state_id,
            },
          },
        });

        alertContext.success('Primary soil sample updated');
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        alertContext.error(err, 'Error updating primary soil sample');
      });
  };

  const [plantDate, setPlantDate] = useState(
    block.requirements[0].approx_plant_date
      ? block.requirements[0].approx_plant_date
      : new Date().toISOString().split('T')[0]
  );

  // Filter those that are within 365 days of Approx Plant Date
  const filteredSamples = block.soil_samples?.flatMap((ss) => {
    return (new Date(plantDate).getTime() - new Date(ss.sampling_date)) / (1000 * 3600 * 24) <
      365
      ? ss
      : [];
  });

  // Find the result with Best chance of Nitrogen
  const lowestOrganicCarbon =
    filteredSamples && filteredSamples.length > 0
      ? filteredSamples.reduce((prev, curr) =>
          prev.organic_carbon < curr.organic_carbon ? prev : curr
        ).organic_carbon
      : 0.0;

  // organic_carbon LOWER is better
  // LS.silicon_bses_mg_kg < 70, LS.silicon_cacl2_mg_kg < 10 suggests Mill Byproduct

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      maxWidth='xl'
      aria-labelledby='SoilSample-dialog-title'
      aria-describedby='SoilSample-dialog-description'
    >
      <DialogTitle
        style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}
        id='SoilSample-dialog-title'
      >{`Select Soil Sample for Block ${block.name}`}</DialogTitle>
      <DialogContent>
        <Box>
          <Box className={classes.boxRow}>
            <Typography>Approx Plant Date</Typography>
            <TextField
              id='approxPlantDate'
              type='date'
              defaultValue={plantDate}
              onChange={(e) => setPlantDate(e.target.value)}
              style={{ width: 150 }}
            />
          </Box>
          <Typography>
            *Only samples less then 1 year old from Approx plant date are shown
          </Typography>
          <TableContainer style={{ width: '70vw' }}>
            <Table aria-labelledby='tableTitle'>
              <TableHead>
                <TableRow>
                  <TableCell>Sample</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSamples &&
                  filteredSamples.length > 0 &&
                  filteredSamples.map((sample) => (
                    <TableRow key={sample.id}>
                      <TableCell>{sample.lab_sample_number}</TableCell>
                      <TableCell>{sample.sampling_date}</TableCell>
                      <TableCell>
                        {sample.organic_carbon === lowestOrganicCarbon && 'Best for Nitrogen'}
                      </TableCell>
                      <TableCell>
                        {sample.silicon_bses_mg_kg < 70 &&
                          sample.silicon_cacl2_mg_kg < 10 &&
                          '*Mill Byproduct suggested'}
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          checked={sample.id === selectedSample}
                          color='primary'
                          onChange={(e) =>
                            setSelectedSample(e.target.checked ? sample.id : null)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography>*Notes highlight lowest Organic Carbon</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleUploadSample} color='primary'>
          Upload
        </Button>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
        <Button
          disabled={!selectedSample}
          onClick={() => handlePrimarySoilSample()}
          color='primary'
          autoFocus
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}
