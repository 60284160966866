import clsx from 'clsx';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import LogoCard from './LogoCard';
import { useCallback, useState } from 'react';

const style = makeStyles((theme) => ({
  dropZone: {
    height: 100,
    width: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'dashed',
    outlineOffset: -10,
    outlineColor: '#019BDC',
  },
  hover: {
    backgroundColor: 'rgba(9, 189, 47, 0.1)',
  },
}));

export const FileDropZone = ({ label, onDrop }) => {
  const classes = style();

  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsHovering(false);
    e.stopPropagation();
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsHovering(true);
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      setIsHovering(false);
      e.stopPropagation();

      if (isLoading) return;
      if (typeof onDrop !== 'function') return;

      try {
        setIsLoading(true);
        await onDrop(e.dataTransfer.files);
      } finally {
        setIsLoading(false);
      }
    },
    [onDrop, isLoading]
  );

  return (
    <LogoCard>
      <div
        className={clsx(
          classes.dropZone,
          isHovering && !isLoading && classes.hover
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {isLoading ? (
          <div>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Typography variant="h6" gutterBottom>
            {label || 'Drag files here'}
          </Typography>
        )}
      </div>
    </LogoCard>
  );
};
