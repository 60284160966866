// Libraries
import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

// Dialogs
import ApplicationProductDialog from '../../components/Applications/ApplicationProductDialog';
import ApplicationMillDialog from '../../components/Applications/ApplicationMillDialog';

// Components
import BlockLine from '../../components/FarmOverview/BlockLine';
import Charts from '../../components/FarmOverview/Charts';

// Contexts
// import { useAlertContext } from '../components/AlertContext';
// import { BlockContext } from '../contexts/BlocksContext';

// HOOKS
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function ListView({ farmId, farmName, admin, agronomist, fertYear, productCount, view, filteredBlocks, selectedBlocks, modifySelectedBlocks }) {
  const { width: displayWidth, divideWidth,  } = useWindowDimensions();
  // const { farmId, farmName, admin, agronomist, fertYear, productCount, view, filteredBlocks, selectedBlocks, modifySelectedBlocks } =
  //   props;

  const [openApplicationDialog, setOpenApplicationDialog] = useState(false);
  const [openMillApplicationDialog, setOpenMillApplicationDialog] = useState(false);

  // Add's them to selected stack
  function handleSelectBlock(blockId) {
    modifySelectedBlocks(blockId);

  }

  function groupApplications() {
    let selectedBlockData = filteredBlocks.flatMap((block) =>
      selectedBlocks.has(block.id)
        ? block.productsselected.map((product) => ({
            ...product,
            blockname: block.name,
            blockid: block.id,
            blockarea: block.hectares,
            disableChanges: block.requirements[0].to_fallow !== null,
          }))
        : []
    );
    return selectedBlockData;
  }

  function groupMillApplications() {
    let selectedBlockData = filteredBlocks.flatMap((block) =>
      selectedBlocks.has(block.id) && block.requirements !== undefined ? { ...block } : []
    );
    console.log(selectedBlockData);
    return selectedBlockData;
  }

  // const majorActionButton = [
  //   <>
  //     {(admin || agronomist) && selectedBlocks.size > 0 && (
  //       <>
  //         <Button
  //           style={{ marginLeft: 'auto' }}
  //           color='secondary'
  //           variant='contained'
  //           onClick={() => setOpenApplicationDialog(true)}
  //           disabled={productCount === 0}
  //         >
  //           Applications
  //         </Button>
  //         <Button
  //           style={{ marginLeft: 'auto' }}
  //           color='secondary'
  //           variant='contained'
  //           onClick={() => setOpenMillApplicationDialog(true)}
  //         >
  //           Mill Applications
  //         </Button>
  //       </>
  //     )}
  //   </>,
  // ];

  const columnNames = [
    '',
    '',
    '',
    '',
    '',
    'Nitrogen',
    'Phosphorus',
    'Potassium',
    'Sulphur',
    'Copper',
    'Zinc',
    'Lime Ca',
    'Lime Ph',
    'Gypsum',
    'Magnesium',
    'Silicon',
  ];

  return (
    <div>
      {openApplicationDialog && (
        <ApplicationProductDialog
          visible={openApplicationDialog}
          blockName={'Group'}
          products={groupApplications() || null}
          handleClose={() => setOpenApplicationDialog(false)}
        />
      )}
      {openMillApplicationDialog && (
        <ApplicationMillDialog
          visible={openMillApplicationDialog}
          blocks={groupMillApplications() || null}
          handleClose={() => setOpenMillApplicationDialog(false)}
        />
      )}

      {displayWidth > divideWidth && <Charts filteredBlocks={filteredBlocks} />}
      {/* {majorActionButton[view]} */}
      <TableContainer style={{ width: '100vw' }}>
        <Table aria-labelledby='tableTitle'>
          <TableHead>
            {view === 0 ? (
              <TableRow>
                <TableCell>Block</TableCell>
                <TableCell></TableCell>
                {/* <TableCell>Group</TableCell> */}
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Variety</TableCell>
                <TableCell>Soil Sample</TableCell>
                <TableCell colSpan={columnNames.length - 7}></TableCell>
              </TableRow>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={columnNames.length - 7} />
                  <TableCell
                    colSpan={7}
                    style={{ backgroundColor: 'rgba(0, 157, 72, 0.2)', padding: '4px 8px' }}
                  >
                    * These are crop cycle requirements (~5 years)
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columnNames.map((element, index) => (
                    <TableCell key={index}>{element}</TableCell>
                  ))}
                </TableRow>
              </>
            )}
          </TableHead>

          <TableBody>
            {filteredBlocks.map((block, index) => (
              <BlockLine
                key={index}
                block={block}
                view={view}
                fertYear={fertYear}
                agronomist={agronomist}
                admin={admin}
                handleSelectBlock={(id) => handleSelectBlock(id)}
                selected={selectedBlocks.has(block.id)}
                farmName={farmName}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
