// import { API, graphqlOperation } from 'aws-amplify';
// import { useQuery } from '@tanstack/react-query';

// const updateRequirementZonesQuery = {
//     mutation: `mutation requirementZone($data: nutrientGroups) {
//         requirementZone(nutrientGroups: $data)
//     }`
// };

export const updateRequirementZonesQuery = {
    mutation: `mutation updateRequirementZones($data: UpdateNutrientGroups) {
        updateRequirementZones(nutrientGroups: $data)
    }`
}


// export const updateProductsSelected = {
//     mutation: `mutation updateProductsSelected($data: UpdateProductsSelected) {
//         updateProductsSelected(updatedProductRecords: $data)
//     }`
// };