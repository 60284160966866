import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl
} from "react-leaflet";
// import ShapeFile from "../components/ShapeFile";

// NEW
import { GeoJSON } from "react-leaflet";
import shp from "shpjs";

const { BaseLayer, Overlay } = LayersControl;

export default function UploadShapeFile(props) {
  const [state, setState] = useState({
    geodata: null,
  });

  const handleFile = (e) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    // console.log(shp(file).then(function (geojson) {}));
    reader.readAsArrayBuffer(file);
    reader.onload = function (buffer) {
      setState({ geodata: buffer.target.result });
    };
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      layer.bindPopup(
        Object.keys(feature.properties)
          .map(function (k) {
            return k + ": " + feature.properties[k];
          })
          .join("<br />"),
        {
          maxHeight: 200,
        }
      );
    }
  };

  const style = () => {
    return {
      weight: 2,
      opacity: 1,
      color: "blue",
      dashArray: "3",
      fillOpacity: 0.5,
    };
  };

  // const style2 = () => {
  //   return {
  //     weight: 2,
  //     opacity: 1,
  //     color: "red",
  //     dashArray: "3",
  //     fillOpacity: 0.5,
  //   };
  // };

  const [mapHere, setMapHere] = useState(null);

  useEffect(() => {
    async function getShp() {
      console.log(state.geodata)
      const shpgeo = await shp(state.geodata);
      console.log(shpgeo);

      // let farms = new Set();
      let blocks = [];

      let bbox = shpgeo.features[0].geometry.bbox;
      shpgeo.features.forEach((feature) => {
        // farms.add(feature.properties.FARM_NO);
        blocks.push({
          // farm: feature.properties.FARM_NO,
          block: feature.properties.Field, //`${feature.properties.BLOCK_NO}-${feature.properties.PADDOCK_N}`,
          // area: feature.properties.AREA_HA,
        });
        // Calc bounding box max/min
        if (feature.geometry.bbox[0] < bbox[0])
          bbox[0] = feature.geometry.bbox[0];
        if (feature.geometry.bbox[1] < bbox[1])
          bbox[1] = feature.geometry.bbox[1];
        if (feature.geometry.bbox[2] > bbox[2])
          bbox[2] = feature.geometry.bbox[2];
        if (feature.geometry.bbox[3] > bbox[3])
          bbox[3] = feature.geometry.bbox[3];
      });
      // grab center of blocks
      const mapX = bbox[3] + (bbox[1] - bbox[3]) / 2;
      const mapY = bbox[0] + (bbox[2] - bbox[0]) / 2;

      console.log(mapX, mapY);

      // console.log(farms, blocks);
      console.log(blocks);

      // Just for testing 2 overlays
      var result = shpgeo.features.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 21);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      const groups = result;

      const group1 = {
        fileName: shpgeo.fileName,
        type: shpgeo.type,
        features: groups[0],
      };

      console.log(group1);

      // const group2 = {
      //   fileName: shpgeo.fileName,
      //   type: shpgeo.type,
      //   features: groups[1],
      // };

      // console.log(result);

      setMapHere(
        <MapContainer
          center={[mapX, mapY]}
          zoom={15}
          zoomControl={true}
          style={{ height: "600px" }}
        >
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap.Mapnik">
              <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            </BaseLayer>
            <Overlay checked name="Layer1">
              <GeoJSON
                key={Math.random()}
                data={group1}
                style={style}
                onEachFeature={onEachFeature}
              />
            </Overlay>
            {/* <Overlay checked name="Layer2">
              <GeoJSON
                key={Math.random()}
                data={group2}
                style={style2}
                onEachFeature={onEachFeature}
              />
            </Overlay> */}
          </LayersControl>
        </MapContainer>
      );
    }

    if (state.geodata !== null) {
      getShp();
    }
  }, [state.geodata]);

  return (
    <div>
      <div>
        <input
          type="file"
          onChange={handleFile.bind(this)}
          className="inputfile"
        />
      </div>
      {mapHere && mapHere}
      {/* <MapContainer
        center={mapCoords}
        zoom={10}
        zoomControl={true}
        style={{ height: "600px" }}
      >
        <LayersControl position="topright">
          <BaseLayer checked name="OpenStreetMap.Mapnik">
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          </BaseLayer>
          {ShapeLayers}
        </LayersControl>
      </MapContainer> */}
      {/* <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: "600px" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[51.505, -0.09]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer> */}
    </div>
  );
}
