export const updateSoilSample = {
    mutation: `mutation updateSoilSample($data: SoilSample) {
        updateSoilSample(soilSample: $data)
    }`
};

export const setPrimarySoilSample = {
    mutation: `mutation setPrimarySoilSample($data: AWSJSON) {
        setPrimarySoilSample(data: $data) 
    }`
};

export const getLabSampleFromSoilSampleId = {
    query: `query getLabSampleFromSoilSampleId($sampleId: String) {
        getLabSampleFromSoilSampleId(sampleId: $sampleId)
    }`
};

export const getSoilSample = { 
    query: `query getSoilSample($sampleId: String) {
        getSoilSample(sampleId: $sampleId)
    }`
};

export const getSoilSamples = { 
    query: `query getSoilSamples($farmId: String) {
        getSoilSamples(farmId: $farmId)
    }`
};

export const getLabSample = { 
    query: `query getLabSample($labSampleId: String) {
        getLabSample(labSampleId: $labSampleId)
    }`
};

export const addLabSample = {
    mutation: `mutation AddLabSample($labSample: AWSJSON) {
        addLabSample(labSample: $labSample)
    }`
};

export const createSoilSample =  {
    mutation: `mutation createSoilSample($data: SoilSample) {
        createSoilSample(soilSample: $data)
    }`
};


