import React, { useState, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

// Queries
import { ploughOutBlock } from "../../graphQL/Blocks";

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";

// Material
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";

export default function PloughOutDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { visible, block, fertYear, handleClose } = props;

  const [ploughDate, setPloughDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [fertYearConfirmed, setFertYearConfirmed] = useState(false);

  function handlePloughOut() {
    const data = {
      requirementId:
        block.requirements && block.requirements.length > 0
          ? block.requirements[0].id
          : null,
      plant_date: ploughDate,
      primary_sample_id: block.primary_sample_id,
      block_id: block.id,
      fertYear: fertYear,
    };

    API.graphql(
      graphqlOperation(ploughOutBlock.mutation, { requirement: data })
    )
      .then(async (result) => {
        alertContext.success(`Block cleared`);
        console.log(result);
        let requirementID = JSON.parse(result.data.ploughOutBlock)[0].id;
        // console.log(requirementID);
        dispatchBlock({
          type: "PLOUGHOUT_REQUIREMENTS",
          block: {
            id: block.id,
            requirement: {
              id: parseInt(requirementID),
              block_id: parseInt(block.id),
              primary_sample_id: parseInt(block.primary_sample_id),
              fertilizer_year: parseInt(fertYear),
              crop_class_id: 1,
              from_date: new Date().toISOString().split("T")[0],
              crop_class_name: "Fallow",
            },
          },
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        alertContext.error(err, `Error updating the block`);
      });
  }

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="ploughout-dialog-title"
      aria-describedby="ploughout-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="harvest-dialog-title"
      >{`Plough Block ${block.name}`}</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to plough this block?</Typography>
        <Typography
          variant={"caption"}
          color={"error"}
          style={{ fontWeight: "bold" }}
        >
          This will clear THIS and all future requirements and mark the block
          fallow for this fertiliser year.
        </Typography>
        <TextField
          id="date"
          label={"Plough out date"}
          type="date"
          defaultValue={ploughDate} //"2021-02-24" //
          fullWidth
          onChange={(e) => setPloughDate(e.target.value)}
        />
        <Box pt={4}>
          <Typography>
            {`To confirm Plough Out, type the Fert year ${fertYear} below.`}
          </Typography>
          <TextField
            id="fert-year"
            type="text"
            fullWidth
            placeholder={fertYear}
            onChange={(e) => setFertYearConfirmed(e.target.value === fertYear)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!fertYearConfirmed}
          onClick={() => {
            if (fertYearConfirmed) handlePloughOut();
          }}
          color="primary"
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
