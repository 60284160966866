import React from 'react'
import { FormControl, FormHelperText, FormLabel, IconButton, Input, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useField } from 'formik'
import { Visibility, VisibilityOff } from "@material-ui/icons";

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    },
    label: {
        textAlign: 'left',
        color: '#282828'
    },
    disabledLabel: {
        textAlign: 'left',
        color: '#828282'
    }
}));

export default function FormText(props) {
    const classes =  style()
    const { name, label, disabled, multiline, helpText, prefix, inputType, password, endText, ...rest } = props

    const [field, meta] = useField(props);
    const hasError = Boolean(meta.touched && meta.error)

    return (
        <FormControl className={classes.formControl} error={hasError}>
            <FormLabel className={disabled ? classes.disabledLabel : classes.label } style={{textAlign: 'left'}}>{label}</FormLabel>
            <Input
                id={`input-${name}`}
                multiline={multiline}
                disabled={disabled}
                type={inputType ? inputType : 'text'}
                startAdornment={prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null}
                endAdornment={
                    endText ? <InputAdornment position="end">{endText}</InputAdornment> :
                    password ? <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle-password-visibility"
                            onClick={props.handlePasswordVisibility} >
                            { inputType === 'text' ? <VisibilityOff /> : <Visibility />  }
                        </IconButton>
                        </InputAdornment>
                    : null
                }
                {...rest}
                {...field}
                error={hasError}
            />
            
            <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
    );
}
