import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const MapCenterControl = (props) => {
  const { center } = props;

  const map = useMap();

  useEffect(() => {
    map.setView(center);
  }, [center, map]);

  return <></>;
};
