import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { addLabSample } from '../../graphQL/SoilSample';

// Queries
// import setPrimarySoilSample from '../../graphQL/SetPrimarySoilSample';
import { LabSampleSpreadsheet } from '../ValidationSchema';
import { cropClasses } from '../../functions/offlineQueries';
import { useSnackbar } from 'notistack';  

import {getFarmBlocks} from '../../graphQL/Farm';

// Material
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import { getFarm } from '../../graphQL/Farm';

// Components

const style = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginRight: 20,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  boxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  boxCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function UploadSoilSampleDialog(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = style();

  const { samples, farmId, farmName, handleClose } = props;
  const [sampleNumber, setSampleNumber] = useState(0);

  const [errors, setErrors] = useState({});
  const [foundError, setFoundError] = useState(false);

  const sampleKeys = Object.keys(samples[0]);
  const firstColumn = sampleKeys.slice(0, sampleKeys.length / 2);
  const secondColumn = sampleKeys.slice(sampleKeys.length / 2, sampleKeys.length);

  const { data: cachedBlocks, isLoading: loading } = useQuery({
    queryKey: ['blocks'],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getFarmBlocks.query, { farmId: farmId })
      );
      const ngBlock = JSON.parse(result.data.getFarmBlocks);
      console.log('GOT THIS AGAIN...');

      return ngBlock;
    },
  });

  useEffect(() => {
    async function validateSample() {
      // const cachedBlocks = []; //await getBlocks(farmId);
      const availableBlocks = cachedBlocks.map((b) => b.name);
      const availableCropClasses = cropClasses.map((c) => c.name);

      const validationData = {
        farmName: farmName,
        blocks: availableBlocks,
        soilTextures: ['Clay', 'Loam', 'Sand'],
        cropClasses: availableCropClasses,
      };

      await LabSampleSpreadsheet(validationData)
        .validate(samples[sampleNumber], { abortEarly: false })
        .then(() => {
          setErrors({});
        })
        .catch((err) => {
          // Catch any validation errors and render them
          const newErrors = err.inner.map((el) => ({
            [el.path.charAt(0) === '[' ? el.path.slice(2, -2) : el.path]: el.message,
          }));
          console.log('Not Valid', Object.assign({}, ...newErrors));
          setErrors(Object.assign({}, ...newErrors));
          setFoundError(true);
        });
    }
    validateSample();
  }, [samples, sampleNumber, farmId, farmName]);

  const uploadSamples = async () => {
    // const cachedBlocks = []; //await getBlocks(farmId);
    Promise.all(
      samples.map(async (labSample, index) => {
        labSample.blockId = cachedBlocks.find((b) => b.name === labSample['Block no.']).id;
        labSample["Sample date"] = labSample["Sample date"].split('/').reverse().join('-');
        console.log('Sending Sample!', labSample['Soil test number'], labSample);
        await API.graphql(
          graphqlOperation(addLabSample.mutation, {
            labSample: JSON.stringify(labSample),
          })
        )
          .then(() => {
            enqueueSnackbar(`${labSample['Soil test number']} uploaded`, {
              variant: 'success',
            });
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar(
              `Error uploading lab sample number ${labSample['Soil test number']}`,
              {
                variant: 'error',
              }
            );
          });
      })
    )
      .catch((err) => {
        enqueueSnackbar(`Error uploading lab samples`, {
          variant: 'error',
        });
      })
      .then(() => {
        handleClose();
      });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth='xl'
      aria-labelledby='SoilSample-dialog-title'
      aria-describedby='SoilSample-dialog-description'
    >
      <DialogTitle
        style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}
        id='SoilSample-dialog-title'
      >{`Uploaded Soil Sample ${sampleNumber + 1} of ${samples.length}`}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex' }}>
          <TableContainer className={classes.root}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableCell component='th' scope='row'>
                  Item
                </TableCell>
                <TableCell component='th' scope='row'>
                  Value
                </TableCell>
              </TableHead>
              <TableBody>
                {firstColumn.map((el) => (
                  <>
                    <TableRow>
                      <TableCell
                        component='th'
                        scope='row'
                        style={errors[el] && { borderBottom: 'none', color: 'Red' }}
                      >
                        {el}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        style={errors[el] && { borderBottom: 'none', color: 'Red' }}
                      >
                        {samples[sampleNumber][el]}
                      </TableCell>
                    </TableRow>
                    {errors[el] && (
                      <TableRow>
                        <TableCell colSpan={2} style={{ textAlign: 'right', color: 'Red' }}>
                          {errors[el]}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer className={classes.root} style={{ marginLeft: 40 }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableCell component='th' scope='row'>
                  Item
                </TableCell>
                <TableCell component='th' scope='row'>
                  Value
                </TableCell>
              </TableHead>
              <TableBody>
                {secondColumn.map((el) => (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      {el}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {samples[sampleNumber][el]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={sampleNumber === 0}
          onClick={() =>
            setSampleNumber(sampleNumber === 0 ? samples.length - 1 : sampleNumber - 1)
          }
          color='primary'
        >
          Previous
        </Button>
        <Button
          disabled={sampleNumber === samples.length - 1}
          onClick={() =>
            setSampleNumber(sampleNumber === samples.length - 1 ? 0 : sampleNumber + 1)
          }
          color='primary'
        >
          Next
        </Button>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
        <Button
          onClick={() => uploadSamples()}
          color='primary'
          autoFocus
          disabled={sampleNumber !== samples.length - 1 || foundError === true}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
