import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";

import FormButton from "../components/FormButton";
import FormText from "../components/FormText";
import FormikForm from "../components/FormikForm";
import FormSelect from "../components/FormSelect";
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
import { updateFarm, getFarm, removeFarmUsers } from "../graphQL/Farm";
import { addFarmUsers } from "../graphQL/Account";
import { useAlertContext } from "../components/AlertContext";
import { useFormik } from "formik";
import { FarmUsersSelector } from "../components/FarmUsersSelector";
import { Divider } from "@material-ui/core";
import { getFarmUsers, getDBUsers } from "../graphQL/Account";
// import { useHistory } from "react-router-dom";

// TODO: Ensure all farms get a company when uploading!!!!
// INSERT into company (company_name) SELECT distinct description from farms F where F.company_id is null;
// UPDATE farms SET company_id = (SELECT id from company C where C.company_name = farms.description);

export default function ManageFarm(props) {
  const farmId = props.match.params.id;
  const farmName = props.match.params.name;
  const isAdmin = props.user.idToken.payload.admin === "true";
  const isAgronomist = props.user.idToken.payload.agronomist === "true";

  const [farm, setFarm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState("");

  const [storedNonFarmUsers, setStoredNonFarmUsers] = useState([]);
  const [storedFarmUsers, setStoredFarmUsers] = useState([]);

  const [addedUsers, setAddedUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  // const [ dialogOpen, setDialogOpen ] = useState(false)
  // const history = useHistory();

  const alertContext = useAlertContext();

  const fetchFarm = useCallback(async () => {
    const result = await API.graphql(
      graphqlOperation(getFarm.query, { farmId })
    );
    console.log(JSON.parse(result.data.getFarm)[0])
    setFarm(JSON.parse(result.data.getFarm)[0]);
  }, [farmId, setFarm]);

  const fetchUsers = useCallback(async () => {
    if (!isAgronomist && !isAdmin) return;

    const [allUsersResult, dbFarmUsersResult] = await Promise.all([
      API.graphql(graphqlOperation(getDBUsers.query)),
      API.graphql(graphqlOperation(getFarmUsers.query, { farmId })),
    ]);

    const allUsers = JSON.parse(allUsersResult.data.getDBUsers);
    const dbFarmUsers = JSON.parse(dbFarmUsersResult.data.getFarmUsers);

    const farmUsers = [];
    const nonFarmUsers = [];

    allUsers.forEach((user) => {
      const isFarmUser = dbFarmUsers.some(({ id }) => id === user.id);
      (isFarmUser ? farmUsers : nonFarmUsers).push(user);
    });

    setStoredNonFarmUsers(nonFarmUsers);
    setStoredFarmUsers(farmUsers);
  }, [farmId, setStoredNonFarmUsers, setStoredFarmUsers, isAdmin, isAgronomist]);

  useEffect(() => {
    setLoading(true);

    Promise.all([fetchFarm(), fetchUsers()])
      .catch((err) => {
        console.error("Error loading farm data.", err);
        alertContext.error("Error fetching farm");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [farmId, alertContext, setLoading, fetchFarm, fetchUsers]);

  const formik = useFormik({
    initialValues: {
      farmId: farmId,
      farmName: farmName,
      growerName: farm && farm.grower_name ? farm.grower_name : "",
      companyName: farm && farm.company_name ? farm.company_name : "",
      description: farm && farm.description ? farm.description : "",
      address: farm && farm.address ? farm.address : "",
      postalAddress: farm && farm.postal_address ? farm.postal_address : "",
      potentialYield: farm && farm.potential_yield ? farm.potential_yield : 0,
      abn: farm && farm.abn ? farm.abn : "",
      email: farm?.email ?? "",
      phoneNumber: farm?.phone_number?.substr(4) ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      setLoading(true);
      const data = {
        companyId: farm.company_id,
        farmId: values.farmId,
        farmName: values.farmName,
        growerName: values.growerName,
        companyName: values.companyName,
        description: values.description,
        address: values.address,
        postalAddress: values.postalAddress,
        potentialYield: values.potentialYield,
        abn: values.abn,
        email: values.email,
        phoneNumber: values.phoneNumber ? `+614${values.phoneNumber}` : '',
      };

      Promise.all([
        API.graphql(graphqlOperation(updateFarm.mutation, { data })),
        (isAgronomist || isAdmin) &&
          API.graphql(
            graphqlOperation(addFarmUsers.mutation, {
              farmId: data.farmId,
              userIds: addedUsers.map((user) => user.id),
            })
          ),
        (isAgronomist || isAdmin) &&
          API.graphql(
            graphqlOperation(removeFarmUsers.mutation, {
              farmId: data.farmId,
              userIds: removedUsers.map((user) => user.id),
            })
          ),
      ])
        .then(async () => {
          // Fetch Users again to ensure `storedNonFarmUsers` and `storedFarmUsers` are up-to-date.
          await fetchUsers();
          alertContext.success(`Farm updated`);
        })
        .catch((err) => {
          console.error(err);
          alertContext.error(err, `Error updating the farm: ${farmName}`);
        })
        .finally(() => {
          setLoading(false);
          actions.setSubmitting(false);
        });
    },
  });

  const handleOnChange = useCallback(
    ({ addedUsers, removedUsers }) => {
      setAddedUsers(addedUsers);
      setRemovedUsers(removedUsers);
    },
    [setAddedUsers, setRemovedUsers]
  );

  return (
    <div>
      {loading && <LoadingBackground visible={loading} />}
      <LogoCard margin={20}>
        <LoadingBackground visible={loading} />
        <PageHeader
          title={`${farmName} Update`}
          subheader={`Use the below form to Update the farm: ${farmName}`}
          backPath={"back"}
          showSearch={isAgronomist || isAdmin}
          setQuery={(q) => setUserFilter(q)}
        />
        <div style={{ padding: 20 }}>
          {(isAgronomist || isAdmin) && (
            <FarmUsersSelector
              farmId={farmId}
              cognitoId={props.user.idToken.payload.sub}
              nonFarmUsers={storedNonFarmUsers}
              farmUsers={storedFarmUsers}
              filter={userFilter}
              onChange={handleOnChange}
            />
          )}
          {farm && (
            <>
              <Divider />
              <FormikForm formik={formik}>
                {/* <FormText name="farmId" label="Farm ID:" disabled="true" />
                <FormText name="farmName" label="Farm Name:" disabled="true" /> */}
                <FormText
                  name="growerName"
                  label="Name of Sugarcane Producer:"
                />
                {farm && farm.region_name === 'Burdekin' ? <FormSelect name="potentialYield" label="Potential Yield:" options={[{value: 150, name : '150t/ha'}, {value: 180, name : '180t/ha'}]} /> :
                <FormText name="potentialYield" label="Potential Yield:" disabled={true}/>}
                <FormText name="companyName" label="Company Name:" />
                <FormText name="description" label="Farm Description:" />
                <FormText name="address" label="Farm Address:" />
                <FormText name="postalAddress" label="Postal Address:" />
                <FormText name="abn" label="ABN:" />
                <FormText name="email" label="Email:" />
                <FormText name="phoneNumber" label="Mobile Number:" prefix={"+614"} />
                <FormButton text="Update Farm" />
              </FormikForm>
            </>
          )}
        </div>
      </LogoCard>
    </div>
  );
}
