import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: 0,
        color: '#fffffF'
    }
}));

function LoadingBackground(props) {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={!!props.visible}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LoadingBackground;
