import { APIFetch } from '../../fetch.js';

export const requirementApplicationMillmud = async ({
  token,
  requirementId,
  byProductAppliedYear,
  millAppRate,
  millProductDbName,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementApplicationMillmud',
    method: 'POST',
    authToken: token,
    body: {
      requirementId,
      byProductAppliedYear,
      millAppRate,
      millProductDbName,
    },
  });

  if (!response.ok) {
    console.error(await response.json());
    throw new Error(
      'An error occured during RequirementApplicationMillmud request!'
    );
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
