import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import React from 'react';
// import createPalette from '@material-ui/core/styles/createPalette';

export default function FertTotalsTable(props) {
  const farmFertTotals = props.totalRequirementsData;
  const areaFallowed = props.areaFallowed || 0;
  const areaStandOver = props.areaStandOver || 0;
  const finalReport = props.finalReport;
  // console.log(props);

  // const columns = [
  //   { id: "apptiming", label: "Timing", minWidth: "13%" },
  //   { id: "appmethod", label: "Method", minWidth: "9%" },
  //   { id: "product", label: "Product", minWidth: "19%" },
  //   { id: "applicationRate", label: "Rate (kg/ha or L/ha)", minWidth: "9%" },
  //   { id: "suppliedN", label: "N (kg/ha)", minWidth: "2%" },
  //   { id: "suppliedP", label: "P (kg/ha)", minWidth: "2%" },
  //   { id: "suppliedK", label: "K (kg/ha)", minWidth: "2%" },
  //   { id: "suppliedS", label: "S (kg/ha)", minWidth: "2%" },
  //   { id: "applied", label: "Date Applied", minWidth: "10%" },
  //   { id: "areaApplied", label: "Area Applied", minWidth: "3%" },
  //   { id: "authorised", label: "Authorised by", minWidth: "10%" },
  //   { id: "comments", label: "Comments", minWidth: "18%" },
  // ];
  return (
    <TableContainer align='center'>
      <Table size='small' align='center'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align='center'>N</TableCell>
            <TableCell align='center'>P</TableCell>
            <TableCell align='center'>K</TableCell>
            <TableCell align='center'>S</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell align='right'>SES Allowance - Nutrient required (kg)</TableCell>
            <TableCell align='center'>{farmFertTotals.totalN}</TableCell>
            <TableCell align='center'>{farmFertTotals.totalP}</TableCell>
            <TableCell align='center'>{farmFertTotals.totalK}</TableCell>
            <TableCell align='center'>{farmFertTotals.totalS}</TableCell>
          </TableRow>

          {finalReport ? (
            <>
              <TableRow
                style={{
                  backgroundColor: 'rgba(160, 0, 0, 0.1)',
                }}
              >
                <TableCell align='left'>{areaFallowed}ha Fallowed</TableCell>
                <TableCell align='right'>Partial deductions (kg)</TableCell>
                <TableCell align='center'>{farmFertTotals.PartialN}</TableCell>
                <TableCell align='center'>{farmFertTotals.PartialP}</TableCell>
                <TableCell align='center'>{farmFertTotals.PartialK}</TableCell>
                <TableCell align='center'>{farmFertTotals.PartialS}</TableCell>
              </TableRow>
              <TableRow
                style={{
                  backgroundColor: 'rgba(160, 0, 0, 0.1)',
                }}
              >
                <TableCell align='left'>{areaStandOver}ha Stand Over</TableCell>
                <TableCell align='right'>Partial deductions (kg)</TableCell>
                <TableCell align='center'>{farmFertTotals.SOPartialN}</TableCell>
                <TableCell align='center'>{farmFertTotals.SOPartialP}</TableCell>
                <TableCell align='center'>{farmFertTotals.SOPartialK}</TableCell>
                <TableCell align='center'>{farmFertTotals.SOPartialS}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell />
                <TableCell align='right'>Final budget allowance (kg)</TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalN - farmFertTotals.PartialN - farmFertTotals.SOPartialN}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalP - farmFertTotals.PartialP - farmFertTotals.SOPartialP}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalK - farmFertTotals.PartialK - farmFertTotals.SOPartialK}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalS - farmFertTotals.PartialS - farmFertTotals.SOPartialS}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell />
                <TableCell align='right'>Total Applied (kg)</TableCell>
                <TableCell align='center'>{farmFertTotals.actualN}</TableCell>
                <TableCell align='center'>{farmFertTotals.actualP}</TableCell>
                <TableCell align='center'>{farmFertTotals.actualK}</TableCell>
                <TableCell align='center'>{farmFertTotals.actualS}</TableCell>
              </TableRow>

              <TableRow style={{
                  backgroundColor: "rgba(0, 161, 201, 0.1)",
                }}>
                <TableCell />
                <TableCell align='right'>Actual Difference (kg)</TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalN - farmFertTotals.PartialN - farmFertTotals.SOPartialN - farmFertTotals.actualN}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalP - farmFertTotals.PartialP - farmFertTotals.SOPartialP - farmFertTotals.actualP}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalK - farmFertTotals.PartialK - farmFertTotals.SOPartialK - farmFertTotals.actualK}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.totalS - farmFertTotals.PartialS - farmFertTotals.SOPartialS - farmFertTotals.actualS}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell />
                <TableCell align='right'>Fert plan totals (kg)</TableCell>
                <TableCell align='center'>{farmFertTotals.calculatedN}</TableCell>
                <TableCell align='center'>{farmFertTotals.calculatedP}</TableCell>
                <TableCell align='center'>{farmFertTotals.calculatedK}</TableCell>
                <TableCell align='center'>{farmFertTotals.calculatedS}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell />
                <TableCell align='right'>Planned Difference (kg)</TableCell>
                <TableCell align='center'>
                  {farmFertTotals.calculatedN - farmFertTotals.totalN}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.calculatedP - farmFertTotals.totalP}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.calculatedK - farmFertTotals.totalK}
                </TableCell>
                <TableCell align='center'>
                  {farmFertTotals.calculatedS - farmFertTotals.totalS}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
