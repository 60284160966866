//@format

import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { getMapData } from '../../graphQL/Farm';
import { GeoJSON, LayersControl, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import { Button, Typography } from '@material-ui/core';
const { Overlay } = LayersControl;

export const HarvestMapDataLayer = ({ farmId, fertYear, setDataLayerSelect, displayLayer, setHarvestLegend }) => {
  const [selection, setSelection] = useState('pleaseSelect');

  const { data: mapDataLayer, isLoading: loadingDataLayer } = useQuery({
    queryKey: ['getMapData', farmId, fertYear],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getMapData.query, { farmId: `${farmId}`, year: `${fertYear}` })
      );
      const d = JSON.parse(result.data.getMapData);

      d.properties.ranges = {};
      Object.keys(d.properties).forEach((k) => {
        if (!k.match(/^col_/)) {
          return;
        }
        const col = k.substring(4);
        const range = d.features.reduce(
          (a, v) => {
            const value = v.properties[col];
            if (!value) {
              return a;
            }
            const min = Math.min(value, a[0] || value);
            const max = Math.max(value, a[1] || value);
            return [min, max];
          },
          [undefined, undefined]
        );
        if (range[0] && range[1]) {
          d.properties.ranges[col] = range;
        }
      });
      return d;
    },
  });

  const pleaseSelectText = useCallback(() => {
    if (loadingDataLayer) {
      return 'Loading data layers...';
    }
    if (!mapDataLayer) {
      return 'No data layers to show';
    }
    return 'Please select a data layer to show...';
  }, [loadingDataLayer, mapDataLayer]);

  const col = selection.match(/^col_/) ? selection.substring(4) : null;
  const delta = col?.match(/^delta_/) ? col.substring(6) : null;
  const range = mapDataLayer?.properties.ranges[col] || [0, 0];
  const posRange = [range[0] < 0 ? 0 : range[0], range[1] < 0 ? 0 : range[1]];
  const negRange = [range[0] > 0 ? 0 : range[0], range[1] > 0 ? 0 : range[1]];
  const posResolution = (posRange[1] - posRange[0]) / 255;
  const negResolution = (negRange[1] - negRange[0]) / 255;

  const harvestLegend = () => {
    return displayLayer ? (
      <Grid container>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{ fill: getColor(range[0]).fillColor }}
            />
          </svg>{' '}
          {Math.round(range[0])}{delta === 'harvest' && ' t/ha'}
        </Grid>
        <Grid item xs={5} style={{marginLeft: '10px'}}>
          <svg width='40' height='16'>
            <rect
              width='40'
              height='16'
              style={{ fill: getColor(range[1]).fillColor }}
            />
          </svg>{' '}
          {Math.round(range[1])}{delta === 'harvest' && ' t/ha'}
        </Grid>
      </Grid>
    ) : null;
  };

  useEffect(() => {
    setHarvestLegend(harvestLegend);
  }, [displayLayer, selection]);

  console.log(range, delta, posRange, negRange, posResolution, negResolution);

  useEffect(() => {
    const menus = [];
    menus.push(
      <MenuItem key='please_select' value='pleaseSelect'>
        {pleaseSelectText()}
      </MenuItem>
    );

    Object.keys(mapDataLayer?.properties || {}).forEach((k) => {
      if (!k.match(/^col_/)) {
        return;
      }
      const col = k.substring(4);
      if (mapDataLayer.properties.ranges[col]) {
        menus.push(
          <MenuItem key={`menu_${k}`} value={k}>
            {mapDataLayer.properties[k]}
          </MenuItem>
        );
      }
    });
    setDataLayerSelect(
      <Select
        onChange={(v) => setSelection(v.target.value)}
        value={selection}
        fullWidth
        style={{
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        {menus}
      </Select>
    );
  }, [selection, mapDataLayer, loadingDataLayer]);

  const getColor = (d) => {
    const value = d;
    const newValue = Math.abs(
      value > 0
        ? Math.ceil((value - posRange[0]) / posResolution)
        : Math.ceil((value - negRange[1]) / negResolution)
    );
    const colorValue = (newValue - 255) * -1;
    if (isNaN(colorValue)) {
      return {
        opacity: 0,
        color: 'rgba(0,0,0, 0)',
        fillColor: 'rgba(0,0,0, 0)',
        fillOpacity: 0,
      };
    }
    const opacity = Math.abs(newValue / 255);
    const color =
      value > 0
        ? `rgba(${colorValue}, 255, ${colorValue}, ${opacity})`
        : `rgba(255, ${colorValue}, ${colorValue}, ${opacity})`;
    return {
      opacity: 1,
      color,
      fillColor: color,
      fillOpacity: 1,
    };
  };

  return (
    <>
      {col && mapDataLayer && displayLayer && (
        <GeoJSON
          key={Math.random()}
          data={mapDataLayer}
          style={(feature) => 
            getColor(feature.properties[col])
          }
          pointToLayer={(feature, latlng) => {
            return L.circle(latlng, { radius: 1 });
          }}
          // onEachFeature={(feature, layer) => {
          //   layer.on('click', () => {
          //     const value = feature.properties[col];
          //     const newValue = Math.abs(
          //       value > 0
          //         ? Math.ceil((value - posRange[0]) / posResolution)
          //         : Math.ceil((value - negRange[1]) / negResolution)
          //     );
          //     const colorValue = (newValue - 255) * -1;

          //     console.log(value, newValue, colorValue, feature);
          //   });
          // }}
        />
      )}
    </>
  );
};
