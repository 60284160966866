import React from "react";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

// Components
import MillApplicationLine from "./MillApplicationLine";

export default function ApplicationMillDialog(props) {
  const { visible, blocks, handleClose } = props;

  console.log(Array.isArray(blocks));

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      maxWidth="xl"
      aria-labelledby="Application-dialog-title"
      aria-describedby="Application-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="Application-dialog-title"
      >{`Applications for Block ${
        Array.isArray(blocks) ? "Group" : blocks.name
      }`}</DialogTitle>
      <DialogContent>
        {Array.isArray(blocks) ? (
          blocks.map((block) => (
            <MillApplicationLine
              isGroup={true}
              block={block}
              key={block.id}
              handleClose={null}
            />
          ))
        ) : (
          <MillApplicationLine
            isGroup={false}
            block={blocks}
            key={blocks.id}
            handleClose={handleClose}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
