import React from 'react';
import { FormControl, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useField} from 'formik'

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    },
    formControlRight: {
        marginLeft: 'auto',
        color: '#282828'
    },
    disabledLabel: {
        marginLeft: 'auto',
        color: '#828282'
    }
}));

export default function FormCheckbox(props) {
    const classes =  style()
    const { label, helpText, disabled, right } = props

    const [field, meta] = useField(props);
    const hasError = Boolean(meta.touched && meta.error)

    return (
        <FormControl className={classes.formControl} error={hasError}>
            <FormControlLabel
                className={right ? disabled ? classes.disabledLabel : classes.formControlRight : null}
                control={
                    <Checkbox {...field} checked={field.value} disabled={disabled} />
                }
                label={label}
            />
            <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
    );
}
