import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";

import React from "react";

export default function DroneDialog(props) {
  let { visible, farmData, handleAccept, handleClose } = props;

  // Map an array of block Ids, links and modified fields
  let droneDeployLink = farmData.blocks.map((block) => {
    let blockData = {
      id: block.id,
      link: block.dronedeploy_link,
      modified: false,
    };
    return blockData;
  });

  farmData.blocks.sort(
    (a, b) =>
      parseInt(a.name.match(/^(\d+)/)) * 100 +
      parseInt(a.name.match(/(\d+)$/)) -
      (parseInt(b.name.match(/^(\d+)/)) * 100 +
        parseInt(b.name.match(/(\d+)$/)))
  );

  const handleModify = (e, index) => {
    droneDeployLink[index].link = e.target.value;
    droneDeployLink[index].modified = true;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={visible}
      onClose={handleClose}
      aria-labelledby="modify-dialog-title"
      aria-describedby="modify-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="modify-dialog-title"
      >{`Update DroneDeploy links for ${farmData.name}`}</DialogTitle>
      <DialogContent>
        {farmData.blocks.map((block, index) => (
          <Box
            key={`ddlink-${block.id}`}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justify-content="center"
          >
            <Box style={{ width: 70 }}>
              <Typography variant="h6">{block.name}</Typography>
            </Box>
            <TextField
              id="droneDeployLink"
              label="Drone Deploy Link"
              type="text"
              defaultValue={block.dronedeploy_link}
              fullWidth
              multiline
              onChange={(e) => handleModify(e, index)}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleAccept(droneDeployLink)}
          color="primary"
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
