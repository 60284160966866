import {
  Box,
  Card,
  CardHeader,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Delete, Description } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { FileDropZone } from './FileDropZone.js';

const useStyles = makeStyles({
  header: {
    '& .MuiCardHeader-content': {
      overflow: 'hidden',
    },
  },
});

export const FormFileAttachment = ({ name, maxFileSize, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue, values } = useFormikContext();

  const classes = useStyles();

  const handleDelete = useCallback(() => {
    setFieldValue(name, null);
  }, [name, setFieldValue]);

  const handleOnDrop = useCallback(
    async (fileList) => {
      const files = Array.from(fileList);

      if (files.length > 1) {
        console.error(`Tried to attach ${files.length} files, maximum is 1.`);
        enqueueSnackbar('Too many files', { variant: 'error' });
        return;
      }
      const file = files[0];

      if (typeof maxFileSize === 'number' && file.size > maxFileSize) {
        console.error(
          `Tried to attach oversized file (${file.size} bytes), max size is ${maxFileSize} bytes.`
        );
        enqueueSnackbar(`File too large`, { variant: 'error' });
        return;
      }

      const attachment = {
        name: file.name,
        file,
      };

      setFieldValue(name, attachment);
    },
    [name, setFieldValue, maxFileSize, enqueueSnackbar]
  );

  return (
    <Box my={2}>
      {values[name] !== null ? (
        <>
          <Typography>{props.label}:</Typography>
          <Card>
            <CardHeader
              className={classes.header}
              avatar={<Description color="action" />}
              title={values[name].name}
              action={
                <IconButton title="Remove Attachment" onClick={handleDelete}>
                  <Delete color="error" />
                </IconButton>
              }
            />
          </Card>
        </>
      ) : (
        <FileDropZone {...props} onDrop={handleOnDrop} />
      )}
    </Box>
  );
};
