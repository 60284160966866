import { APIFetch } from '../../fetch.js';

export const getAttachmentSignedURL = async ({
  token,
  sampleId,
  attachmentKey,
  operation,
}) => {
  const response = await APIFetch({
    route: `soil-sample/getAttachmentSignedURL`,
    method: 'POST',
    authToken: token,
    body: {
      sampleId,
      attachmentKey,
      operation,
    },
  });

  if (!response.ok) {
    console.error(await response.json());
    throw new Error('An error occured during GetAttachmentSignedURL request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
