import React from "react";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from "@material-ui/core";

// Components
import ProductApplicationLine from "./ProductApplicationLine";

// FUNCTIONS
import { sortProductsByAppTiming } from "../../functions/sortProducts";

export default function ApplicationProductDialog(props) {
  const { visible, blockName, products, handleClose, productToShow } = props;

  console.log(products)

  return (
    <Dialog
      maxWidth="xl"
      open={visible}
      onClose={handleClose}
      aria-labelledby="Application-dialog-title"
      aria-describedby="Application-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="Application-dialog-title"
      >{`Recommended Applications for Block ${blockName}`}</DialogTitle>
      <DialogContent>
        {sortProductsByAppTiming(products).map((product) => {
          return `${product.name} @ ${product.apprate}kg/ha` === productToShow?.product ? (
            <Paper style={{
              backgroundColor: productToShow.color,
            }}><ProductApplicationLine product={product} key={product.id} /></Paper>) :
            <ProductApplicationLine product={product} key={product.id} />
})}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
