// ICONS
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// MATERIAL
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
// FUNCTIONS
import { qualitativeColorArray } from '../../../../../functions/colours';

export const MapViewSelectionInformation = (props) => {
    const { farmBlocks, selectedBlocks, productToShow, productGroups } = props;
    if(!farmBlocks || farmBlocks?.features.length === 0) return null;
    if (selectedBlocks.size === 0)
      return (
        <>
          <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
            Block Information
          </Typography>
          <Typography style={{ marginLeft: '10px' }}>
            Click a block to view information
          </Typography>
        </>
      );
    if (selectedBlocks.size === 1) {
      console.log(selectedBlocks, farmBlocks);
      const blockInfo = farmBlocks.features.find(
        (block) => block.properties.id === [...selectedBlocks][0]
      ).properties;
      console.log(blockInfo);
      return (
        <>
          <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
            {`Block ${blockInfo.name} Information`}
          </Typography>
          <Typography
            style={{
              flexDirection: 'row',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            {`Area: ${blockInfo.hectares}ha `}
            {blockInfo.requirements
              ? `(${blockInfo.requirements.crop_class_name})`
              : '(Not in cycle)'}
          </Typography>

          {blockInfo.products?.length > 0
            ? blockInfo.products.map((product) => {
                // console.log(product, productToShow);
                return `${product.name} @ ${product.apprate}kg/ha` === productToShow ? (
                  <Paper
                    key={product.id}
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      backgroundColor:
                        qualitativeColorArray[productGroups.indexOf(productToShow)],
                    }}
                  >
                    <Typography style={{ marginLeft: '5px' }}>
                      {product.name} @ {product.apprate}kg/ha
                      {blockInfo.hectares - product.applied > 0 ? (
                        ` = ${Math.round(
                          product.apprate * (blockInfo.hectares - product.applied || 0)
                        )}kg`
                      ) : (
                        <Tooltip title='Applied'>
                          <CheckCircleIcon fontSize='small' color='primary' />
                        </Tooltip>
                      )}
                    </Typography>
                  </Paper>
                ) : (
                  <Typography key={product.id} style={{ marginLeft: '15px' }}>
                    {product.name} @ {product.apprate}kg/ha
                    {blockInfo.hectares - product.applied > 0 ? (
                      ` = ${Math.round(
                        product.apprate * (blockInfo.hectares - product.applied || 0)
                      )}kg`
                    ) : (
                      <Tooltip title='Applied'>
                        <CheckCircleIcon fontSize='small' color='primary' />
                      </Tooltip>
                    )}
                  </Typography>
                );
              })
            : null}

        </>
      );
    }


    console.log(selectedBlocks, farmBlocks)
    // Add up the total area of the selected blocks and calculate the kg amount of fertiliser per product required
    const totalArea = 
      Math.round(
        [...selectedBlocks].reduce((acc, blockId) => {
          const block = farmBlocks.features.find((block) => block.properties.id === blockId);
          return acc + block.properties.hectares;
        }, 0) * 100
      ) / 100;
    const totalProducts = [...selectedBlocks].reduce((acc, blockId) => {
      const block = farmBlocks.features.find((block) => block.properties.id === blockId);
      return block.properties.products
        ? acc.concat(
            block.properties.products.map((product) => ({
              ...product,
              hectares: block.properties.hectares,
            }))
          )
        : acc;
    }, []);

    const totalProductsMap = totalProducts.reduce((acc, product) => {
      const key = `${product.name} @ ${product.apprate}kg/ha`;
      if (acc.has(key)) {
        acc.set(
          key,
          acc.get(key) +
            Math.round(product.apprate * (product.hectares - product.applied || 0))
        );
      } else {
        acc.set(key, Math.round(product.apprate * (product.hectares - product.applied || 0)));
      }
      return acc;
    }, new Map());

    const totalProductsArray = [...totalProductsMap];

    return (
      <>
        <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
          Selection Information
        </Typography>
        <Typography style={{ marginLeft: '10px' }}>
          {`${selectedBlocks.size} Blocks - Total Area: ${totalArea}ha`}
        </Typography>
        <Typography></Typography>
        {totalProductsArray.map((product) => {
          return product[0] === productToShow ? (
            <Paper
              key={`product_${product[0]}`}
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: qualitativeColorArray[productGroups.indexOf(productToShow)],
              }}
            >
              <Typography style={{ marginLeft: '5px' }}>
                {`${product[0]} = ${product[1]}kg`}
              </Typography>
            </Paper>
          ) : (
            <Typography key={`product_${product[0]}`} style={{ marginLeft: '15px' }}>
              {`${product[0]} = ${product[1]}kg`}
            </Typography>
          );
        })}
      </>
    );
};
