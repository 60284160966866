import React from 'react';

// COMPONENTS
import SimpleTable from './SimpleTable';
import LphTable from './LphTable';
import Silicate from './Silicate';
import AmeliorantMods from './AmeliorantMods';

// MATERIAL
import Grid from '@material-ui/core/Grid';

export default function AmeliorantTables(props) {
  const { sample, tables, requirement } = props;

  const AmeliorantSimpleTables = [
    {
      tableTitle: `Lime guidelines based on exchangeable soil calcium (Ca) ${
        requirement && (requirement.lime_calcium || 0)
      }t/ha`,
      itemLabel: 'ExCa',
      sampleItem: sample.exchangable_calcium,
      headerRow: ['Soil calcium (meq/100g)', 'Lime (t/ha)'],
      tableData: tables.lime_calcium,
      minAttribute: 'soil_calcium_min',
      maxAttribute: 'soil_calcium_max',
      applicationRateAttribute: 'lime_calcium_application_rate',
    },
    {
      tableTitle: `Magnesium (Mg) guidelines based on exchangeable Mg ${
        requirement && (requirement.magnesium || 0)
      }kg/ha`,
      itemLabel: 'ExMg',
      sampleItem: sample.magnesium_ammonium_acetate_meq_per100g,
      headerRow: ['Soil Mg (amm-acet) meq/100g', 'Mg Rate (kg/ha)'],
      tableData: tables.magnesium,
      minAttribute: 'soil_magnesium_min',
      maxAttribute: 'soil_magnesium_max',
      applicationRateAttribute: 'magnesium_application_rate',
    },
    {
      tableTitle: `Gypsum guidelines for sodic soils ${requirement && (requirement.gypsum  || 0)}t/ha`,
      itemLabel: 'ESP%',
      sampleItem: sample.exchangable_sodium_percentage,
      headerRow: ['ESP (%)', 'Gypsum Rate (t/ha)'],
      tableData: tables.gypsum,
      minAttribute: 'esp_min',
      maxAttribute: 'esp_max',
      applicationRateAttribute: 'gypsum_application_rate',
    },
  ];

  return (
    <Grid container spacing={1}>
      {tables.lime_acidic?.length > 0 && (
        <Grid item xs={12} md={6}>
          <LphTable sample={sample} tableData={tables} requirement={requirement} />
        </Grid>
      )}

      {AmeliorantSimpleTables.flatMap((el) => el.tableData?.length > 0 ? (
          <Grid item xs={12} md={6}>
            <SimpleTable {...el} />
          </Grid>
      ) : [])}


      {tables.silicate?.length > 0 && (
        <Grid item xs={12}>
        <Silicate sample={sample} tableData={tables} requirement={requirement} />
        </Grid>
      )}
      {tables.ameliorant_mod?.length > 0 && (
        <Grid item xs={12}>
        <AmeliorantMods sample={sample} tableData={tables} requirement={requirement} />
        </Grid>
      )}
    </Grid>
  );
}
