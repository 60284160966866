//function to group blocks into groups based on N and P requiremnts and return an array of arrays of block ids
export default function getNutrientGroupBlockIdArrays(blocks) {
  const _blocks = blocks;
  // Find all the unique N-P combinations
  const autoGroups = new Set(
    _blocks.flatMap((block) =>
      block.requirements?.length === 1 && block.requirements[0].nitrogen
        ? `${block.requirements[0].nitrogen}-${block.requirements[0].phosphorus}`
        : []
    )
  );
  let groupedBlocks = [];
  // For each unique N-P combination, find all the blocks that have that requirement
  autoGroups.forEach((group) => {
    groupedBlocks.push(
      blocks
        .filter(
          (block) =>
            block.requirements?.length === 1 &&
            group === `${block.requirements[0].nitrogen}-${block.requirements[0].phosphorus}`
        )
        .flatMap((block) => block.id)
    );
  });

  // Sort groups by array size, smallest first
  groupedBlocks.sort((a, b) => a.length - b.length);

  return groupedBlocks;
}
