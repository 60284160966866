import { Route, Switch } from "react-router-dom";
import Accounts from "./pages/Accounts";
// import AgronomistRecommendation from "./pages/AgronomistRecommendation";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
// import LabSamples from "./pages/LabSamples";
import Login from "./pages/Login";
import ManageAccount from "./pages/ManageAccount";
import ManageRequirement from "./pages/ManageRequirement";
import ManageSoilSample from "./pages/ManageSoilSample";
import Profile from "./pages/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import React from "react";
import UnAuthRoute from "./components/UnAuthRoute";
import ViewFarmOverview from "./pages/ViewFarmOverview";

import FarmWindow from "./pages/FarmWindow/FarmWindow";
import ViewLabSample from "./pages/ViewLabSample";
import ViewRequirements from "./pages/ViewRequirements";
import addProduct from "./pages/ManageProducts";
import manageFarm from "./pages/ManageFarm";
import productSelection from "./pages/ProductSelection";
import products from "./pages/Product";
import reports from "./pages/Reports/Report";
import reportsList from "./pages/Reports/reportList";
import viewReport from "./pages/Reports/viewReport";
import farmMap from "./pages/FarmMap";
import uploadShapefile from "./pages/UploadShapeFile";
import ViewSoilSamples from "./pages/ViewSoilSamples";
import Forecast from "./pages/Forecast";
import SugarPrices from "./pages/SugarPrices";
import { ManageBlocks } from "./pages/ManageBlocks/ManageBlocks";
// const ViewSoilSamples = React.lazy(() => import("./pages/ViewSoilSamples"));

//FUCKED

const Routes = ({ childProps }) => {
  // const Routes = (props) => {
  //   const childProps = props.childProps;
  //   console.log(props);
    return (
    <Switch>
        {/* <Route exact path="/">
        <Home />
        </Route> */}
        <ProtectedRoute exact path="/" render={Home} props={childProps} />
        <ProtectedRoute exact path="/test" render={Home} props={childProps} />
        <ProtectedRoute
            exact
            path="/farm-soil-samples/:name/:id"
            render={ViewSoilSamples}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/farmmap"
            render={farmMap}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/shape"
            render={uploadShapefile}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/view-soil-sample/:name/:farm/:id"
            render={ManageSoilSample}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/request-soil-sample/:name/:farm"
            render={ManageSoilSample}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/request-soil-sample/:farm"
            render={ManageSoilSample}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/farm-requirements/:name/:id"
            render={ViewRequirements}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/manage-farm/:name/:id"
            render={manageFarm}
            props={childProps}
        />
        <ProtectedRoute 
            exact
            path="/manage-blocks/:farmId"
            render={ManageBlocks}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/products/:farm/:id" //FUCKED
            render={productSelection}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/products" //????
            render={products}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/addProducts" //????
            render={addProduct}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/view-requirement/:name/:farm/:id"
            render={ManageRequirement}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/view-farm-overview/:name/:id"
            render={ViewFarmOverview}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/v2/farm-overview/:name/:id"  ///:view
            render={FarmWindow}
            props={childProps}
        />
        {/* <ProtectedRoute
            exact
            path="/agronomist-recommendation/:farm/:block"
            render={AgronomistRecommendation}
            props={childProps}
        /> */}
        {/* <ProtectedRoute
            exact
            path="/lab-samples/:farm/:block"
            render={LabSamples}
            props={childProps}
        /> */}
        <ProtectedRoute
            exact
            path="/lab-sample/:sampleId/:id"
            render={ViewLabSample}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/dashboard"
            render={Dashboard}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/profile"
            render={Profile}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/accounts"
            render={Accounts}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/update-user-account/:id"
            render={ManageAccount}
            props={childProps}
        />
        <UnAuthRoute exact path="/login" render={Login} props={childProps} />
        <ProtectedRoute
            exact
            path="/new-user-account"
            render={ManageAccount}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/report/:name/:id"
            render={reports}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/reportslist/:name/:id"
            render={reportsList}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/viewreport/:name/:year/:filename"
            render={viewReport}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/viewreport/:name/:year/:type/:filename"
            render={viewReport}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/forecast/:farm"
            render={Forecast}
            props={childProps}
        />
        <ProtectedRoute
            exact
            path="/sugarPrices/"
            render={SugarPrices}
            props={childProps}
        />
        <Route exact path="/error" render={() => <div> Error Page </div>} />
    </Switch>
  );
};

export default Routes;
