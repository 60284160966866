import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function SimpleTable(props) {
  const { tableTitle, itemLabel, sampleItem, headerRow, tableData, minAttribute, maxAttribute, applicationRateAttribute } = props;


  const getCellColor = (min, max) => {
    if (
      min <= sampleItem &&
      max > sampleItem
    ) {
      return 'rgba(65, 172, 210, 0.6)';
    }
    return '#FFF';
  };

  return (
    <div>
      <Typography
        variant='h6'
        style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {tableTitle}
      </Typography>
      <Typography style={{ marginBottom: 10 }}>{itemLabel}: {sampleItem}</Typography> 
      <TableContainer style={{ marginBottom: 20 }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              {headerRow.map((el) => (<TableCell>{el}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((el, index) => (
              <TableRow>
                <TableCell>
                  {index === tableData.length - 1
                    ? `> ${el[minAttribute]}`
                    : index === 0 ? `< ${el[maxAttribute]}` :  `<= ${el[maxAttribute]}`}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: getCellColor(el[minAttribute], el[maxAttribute]),
                  }}
                >
                  {el[applicationRateAttribute]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
