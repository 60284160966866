import { ListItem, ListItemText } from '@material-ui/core';

export const ListItemError = ({ message }) => (
  <ListItem
    style={{
      backgroundColor: '#ffe6e6',
      borderLeft: '4px solid #ff4d4d',
      color: '#333',
      marginTop: '4px',
    }}
  >
    <ListItemText primary={<pre>{message}</pre>} />
  </ListItem>
);
