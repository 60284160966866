import { useCallback, useMemo, useState } from 'react';
import { useCallbackRef } from './useCallbackRef';

export const useShapeStore = ({ onAdded, onRemoved } = {}) => {
  const invokeOnAdded = useCallbackRef(onAdded);
  const invokeOnRemoved = useCallbackRef(onRemoved);

  const [shapes, setShapes] = useState({});

  const shapeArray = useMemo(() => {
    return Object.entries(shapes).map(([blockId, shape]) => ({
      blockId,
      shape,
    }));
  }, [shapes]);

  const get = useCallback(
    (blockId) => {
      if (typeof blockId !== 'number' || isNaN(blockId))
        throw new Error(
          `Parameter 'blockId' must be a number. Received (${blockId})`
        );
      return shapes[blockId];
    },
    [shapes]
  );

  const add = useCallback(
    (blockId, shape, overwrite = true) => {
      if (typeof blockId !== 'number' || isNaN(blockId))
        throw new Error(
          `Parameter 'blockId' must be a number. Received (${blockId})`
        );
      if (typeof shape !== 'object')
        throw new Error(
          `Parameter 'shape' must be an Object. Received (${toString(shape)})`
        );
      if (typeof overwrite !== 'boolean')
        throw new Error(
          `Parameter 'overwrite' must be a Boolean. Received (${toString(
            overwrite
          )})`
        );

      setShapes((prev) => {
        const exists = prev[blockId];
        if (!overwrite && exists)
          throw new Error(`A Shape exists for (${blockId})`);
        return { ...prev, [blockId]: shape };
      });

      invokeOnAdded([{ blockId, shape }]);
    },
    [invokeOnAdded]
  );

  const remove = useCallback(
    (blockId) => {
      if (typeof blockId !== 'number' || isNaN(blockId))
        throw new Error(
          `Parameter 'blockId' must be a number. Received (${blockId})`
        );

      setShapes((prev) => {
        const shape = prev[blockId];
        const exists = shape !== undefined;
        if (!exists) throw new Error(`No Shape exists for (${blockId})`);
        invokeOnRemoved([{ blockId, shape }]);
        const result = { ...prev };
        delete result[blockId];
        return result;
      });
    },
    [invokeOnRemoved]
  );

  const clear = useCallback(() => {
    setShapes((prev) => {
      const prevShapes = Object.entries(prev).map(([blockId, shape]) => ({
        blockId,
        shape,
      }));
      invokeOnRemoved(prevShapes);
      return {};
    });
  }, [invokeOnRemoved]);

  return { shapes: shapeArray, add, get, remove, clear };
};
