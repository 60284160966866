import React, { useState, useContext, useCallback } from "react";

// Queries
import { requirementHarvest } from "../../api/mutations/requirements/requirementHarvest.js";

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { useUserContext } from "../../contexts/UserContext.js";

export default function HarvestDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const { visible, block, handleClose } = props;

  console.log("HarvestDialog", block)

  const [blockYield, setYield] = useState(block.requirements[0].yield || 0);
  // const [blockForcast, setBlockForcast] = useState("Ratoon");

  // const handleForcastChange = (event) => {
  //   setBlockForcast(event.target.value);
  // };
  const handleYieldChange = (event) => {
    setYield(event.target.value);
  };
  const [harvestDate, setHarvestDate] = useState(
    block.requirements[0].harvest_date || new Date().toISOString().split("T")[0]
  );

  const handleHarvestDateChange = (event) => {
    setHarvestDate(event.target.value);
  };

  const totalNkgha = block.productsselected?.reduce(
    (prevProd, curProd) =>
      prevProd +
      ((curProd.applied > 0 ? curProd.n : 0) * (curProd.apprate || 0)) / 100,
    0
  );

  // We have changed NUE to be AgronEff-N, we still store NUE in the DB
  let nue = Math.round((totalNkgha / blockYield) * 100) / 100;
  let AgronEff = Math.round((blockYield / totalNkgha) * 100) / 100;

  const handleYield = useCallback(async () => {
    console.log("Updated yield with ", blockYield);
    console.log("Do something with ", harvestDate);
    // console.log(
    //   "Calculate NUE if we get Total applied N  (totalN(kg)/yield(t/ha)"
    // );
    //console.log("Do something with ", blockForcast);
    //ie Remove remaining ratoon calcs...
    try {
      await requirementHarvest({
        token: user?.idToken?.jwtToken,
        requirementId: block.requirements?.[0].id,
        yield: blockYield,
        nue: nue,
      });

      alertContext.success(`Requirement updated`);
      dispatchBlock({
        type: "MODIFY_REQUIREMENT",
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            yield: blockYield,
            nue: nue,
          },
        },
      });

      handleClose();
      // fetchBlocks().then(setLoading(false));
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block.id, block.requirements, blockYield, dispatchBlock, handleClose, harvestDate, nue, user?.idToken?.jwtToken]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="harvest-dialog-title"
      aria-describedby="harvest-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="harvest-dialog-title"
      >{`Harvest Block ${block.name}`}</DialogTitle>
      <DialogContent>
        <TextField
          id="harvestDate"
          label="Harvest date"
          type="date"
          defaultValue={harvestDate}
          fullWidth
          onChange={handleHarvestDateChange}
        />
        {/* <Typography>Enter Yield</Typography> */}
        <TextField
          autoFocus
          id="yield"
          label="Yield (t/ha)"
          type="number"
          defaultValue={blockYield}
          fullWidth
          onChange={handleYieldChange}
        />
        {blockYield > 0 && <Typography>{`AgronEff-N: ${AgronEff}`}</Typography>}

        {/* <FormControl style={{ marginTop: 25 }} component="fieldset">
          <FormLabel component="legend">Current plan for block?</FormLabel>
          <RadioGroup
            row
            aria-label="plan"
            name="plan"
            value={blockForcast}
            onChange={handleForcastChange}
          >
            <FormControlLabel
              value="Ratoon"
              control={<Radio />}
              label="Ratoon"
            />
            <FormControlLabel value="PORP" control={<Radio />} label="PORP" />
            <FormControlLabel value="PO" control={<Radio />} label="PO" />
          </RadioGroup>
        </FormControl> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleYield()} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
