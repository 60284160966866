import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

export default function ProductTable(props) {
  const classes = style();
  const { products, onDeleteProduct } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const columns = [
    { id: 'productName', label: 'Product Name', minWidth: 170 },
    { id: 'manufacturer', label: 'Manufacturer', minWidth: 170 },
    { id: 'n', label: 'N', minWidth: 50 },
    { id: 'p', label: 'P', minWidth: 50 },
    { id: 'k', label: 'K', minWidth: 50 },
    { id: 's', label: 'S', minWidth: 50 },
    { id: 'zn', label: 'Zn', minWidth: 50 },
    { id: 'si', label: 'Si', minWidth: 50 },
    { id: 'ca', label: 'Ca', minWidth: 50 },
    { id: 'cu', label: 'Cu', minWidth: 50 },
    { id: 'mg', label: 'Mg', minWidth: 50 },
    { id: 'oc', label: 'Oc', minWidth: 50 },
    { id: 'actions', label: 'Actions' },

    // { id: 'description', align: 'left', label: 'Description', minWidth: 170 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer className={classes.root}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {product.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.manufacturer}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.n}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.p}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.k}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.s}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.zn}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.si}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.ca}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.cu}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.mg}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {product.oc}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={product.is_applied}
                      size="small"
                      onClick={() => onDeleteProduct?.(product)}
                    >
                      <Tooltip title={'Delete Product'}>
                        <Delete
                          color={product.is_applied ? 'disabled' : 'error'}
                        />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          1,
          10,
          25,
          50,
          100,
          { value: products.length, label: 'All' },
        ]}
        components="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: '100%',
  },
}));
