// Libraries
import { API, Cache, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState, useContext, useCallback } from 'react';

// Components
import FarmList from '../components/FarmList';
import LoadingBackground from '../components/LoadingBackground';
import LogoCard from '../components/LogoCard';
import PageHeader from '../components/PageHeader';
import DroneDialog from '../components/DroneDialog';
import DeleteDialog from '../components/DeleteDialog';
import UploadFarm from '../components/UploadFarm';

// Contexts
import { BlockContext } from '../contexts/BlocksContext';
import { useSessionContext } from '../contexts/SessionContext';

// Queries
import { updateDroneDeployLinks } from '../graphQL/DroneDeploy';
import { deleteFarm, getUserFarms } from '../graphQL/Farm';
import { useSnackbar } from 'notistack';
import { deleteReports } from '../graphQL/Report';

export default function Home(props) {
  const { selectedEntity, loadEntityList } = useSessionContext();
  const online = props.online;
  const setOnline = props.setOnline;
  const admin = props.user.idToken.payload.admin === 'true';
  const agronomist = props.user.idToken.payload.agronomist === 'true';


// useEffect(() => {
//   if (token !== null) {
//     postMeta({ arguments: { farmId: 385 } });
//   }
// }, [token]);


  // const admin = false;
  // const agronomist = false;

  const { dispatchBlock } = useContext(BlockContext);

  const [loading, setLoading] = useState(true);
  const [farms, setFarms] = useState([]);
  const [query, setQuery] = useState('');
  const [openDroneDialog, setOpenDroneDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const loadUserFarms = useCallback(async () => {
    setLoading(true);

    try {
      const result = await API.graphql(graphqlOperation(getUserFarms.query));
      const data = JSON.parse(result.data.getUserFarms);
      console.log('HOME', data);
      // Cache.setItem('myFarms', data ? data : []);
      Cache.setItem('lastUpdate', new Date().toLocaleDateString());
      const farmNames = [...new Set(data ? data.map((item) => item.description) : [])];
      loadEntityList([...farmNames]);
      setFarms(data ? data : []);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
    // loadEntityList is not a dependency as it causes continuous re-draws!!!
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (online === true) {
      loadUserFarms();
    } else {
      // setLoading(false);
      // setFarms(Cache.getItem('myFarms'));
      // setOnline(false);
    }
    dispatchBlock({
      type: 'LOAD_BLOCKS',
      blocks: [],
    });
    // eslint-disable-next-line
  }, [online, setOnline, loadUserFarms, dispatchBlock]);

  let currentEntitiesFarms = farms.filter((item) => {
    if (selectedEntity === 'All Farms') {
      return true;
    }
    return item.description === selectedEntity;
  });

  let filteredSearch;
  filteredSearch = currentEntitiesFarms.filter((item) => {
    return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });

  let sortedSearch = filteredSearch.sort(
    (a, b) => parseInt(a.name.match(/(\d+)/)) - parseInt(b.name.match(/(\d+)/))
  );

  const [farmToEdit, setFarmToEdit] = useState();
  const handleOpenDroneDialog = (farm, index) => {
    setFarmToEdit({ ...farm, index: index });
    console.log(farm);
    setOpenDroneDialog(true);
  };

  const handleLinkAdd = (ddlinks) => {
    console.log(farmToEdit);
    const data = ddlinks.filter((el) => el.modified);
    console.log(data);
    const params = {
      modifiedLinks: {
        modifiedLinks: data,
      },
    };
    if (online === true && data.length > 0) {
      setLoading(true);
      console.log(data);
      API.graphql(graphqlOperation(updateDroneDeployLinks.mutation, params))
        .then(async (result) => {
          // const data = JSON.parse(result);
          console.log('DDLINKS: ', result);

          const newFarms = farms.map((farm) => {
            return farm.id === farmToEdit.id
              ? {
                  ...farm,
                  blocks: farm.blocks.map((block) => {
                    const blockIndex = data.map((d) => d.id).indexOf(block.id);
                    if (blockIndex >= 0) {
                      return {
                        ...block,
                        dronedeploy_link: data[blockIndex].link,
                      };
                    }
                    return { ...block };
                  }),
                }
              : { ...farm };
          });
          setFarms(newFarms);
          // console.log(newFarms);
          enqueueSnackbar('Updated DroneDeploy Links', { variant: 'success' });
        })
        .catch(async (err) => {
          console.error(err);
          enqueueSnackbar('Failed To Update DroneDeploy Links', {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    setOpenDroneDialog(false);
  };

  const handleOpenDeleteDialog = (farm, index) => {
    setFarmToEdit({ ...farm, index: index });
    console.log(farm);
    setOpenDeleteDialog(true);
  };

  async function handleDelete(farmId) {
    // setLoading(true);

    try {
      const { data: reportData } = await API.graphql(
        graphqlOperation(deleteReports.mutation, {
          data: JSON.stringify({ farmName: farmToEdit.name }),
        })
      );
      const reportResponse = JSON.parse(reportData.deleteReports);
      const reportMessage = JSON.parse(reportResponse.body).message;
      if (reportResponse.statusCode !== 200) {
        throw new Error(reportMessage);
      }

      await API.graphql(graphqlOperation(deleteFarm.mutation, { data: { farmId: farmId } }));

      setFarms((prevState) => prevState.filter((el) => el.id !== farmId));

      enqueueSnackbar(`Deleted farm ${farmToEdit.name}`, {
        variant: 'success',
      });
      setOpenDeleteDialog(false);
    } catch (err) {
      console.error(`Failed to delete Farm ${farmToEdit.name}.`, err);
      enqueueSnackbar('Failed to delete Farm', { variant: 'error' });
    }
  }

  const [showFarmUploadDropzone, setShowFarmUploadDropzone] = useState(false);
  const handleShowFarmUploadDropzone = () => {
    setShowFarmUploadDropzone((prevState) => !prevState);
  };

  return (
    <div>
      <LoadingBackground visible={loading} />
      <LogoCard>
        <PageHeader
          title={'Your Farms:'}
          subheader={`Use the green icon to browse to farms actions`}
          showSearch={true}
          setQuery={(q) => setQuery(q)}
          back={null}
          addFarm={handleShowFarmUploadDropzone}
        />
        {showFarmUploadDropzone && (agronomist || admin) && (
          <UploadFarm onUploaded={loadUserFarms} />
        )}
        {farmToEdit && openDroneDialog && (
          <DroneDialog
            visible={openDroneDialog}
            farmData={farmToEdit}
            handleAccept={(ddlinks) => handleLinkAdd(ddlinks)}
            handleClose={() => setOpenDroneDialog(false)}
          />
        )}
        {farmToEdit && openDeleteDialog && (
          <DeleteDialog
            visible={openDeleteDialog}
            item={farmToEdit}
            handleAccept={(id) => handleDelete(id)}
            handleClose={() => setOpenDeleteDialog(false)}
          />
        )}
        {farms && (
          <FarmList
            farms={sortedSearch}
            agronomist={agronomist}
            admin={admin}
            online={online}
            handleOpenDroneDialog={handleOpenDroneDialog}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
          />
        )}
      </LogoCard>
    </div>
  );
}
