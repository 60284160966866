// Libraries
import React from 'react';


// MATERIAL
import {
  Grid,
  Card,
  Typography,
  Box,
} from '@material-ui/core';


// ICONS
import OpenWithIcon from '@material-ui/icons/OpenWith';

export default function blockCard(block) {
    return (
      <Grid item>
        <Card style={{ margin: '5px', padding: '0px', width: '160px' }} variant='outlined'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ backgroundColor: '#009d4833', padding: '0px 10px' }}
          >
            <Typography variant='h5'>{block.name}</Typography>
  
            <OpenWithIcon style={{ color: '#FFF' }} />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-evenly'>
            <Typography color='textSecondary' component='p'>
              {`N:${block.requirements[0].nitrogen}`}
            </Typography>
            <Typography color='textSecondary' component='p'>
              {`P:${block.requirements[0].phosphorus}`}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-evenly'>
            <Typography color='textSecondary' component='p'>
              {`K:${block.requirements[0].potassium}`}
            </Typography>
            <Typography color='textSecondary' component='p'>
              {`S:${block.requirements[0].sulphur}`}
            </Typography>
          </Box>
  
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-evenly'
            style={{
              backgroundColor:
                block.requirements[0].crop_class_id < 3 ? '#009d4833' : '#00a1c91a',
            }}
          >
            <Typography component='p'>{block.hectares}ha</Typography>
            <Typography component='p'>{block.requirements[0].crop_class_name}</Typography>
          </Box>
        </Card>
      </Grid>
    );
  }