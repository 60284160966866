import { APIFetch } from '../../fetch.js';

export const requirementCycle = async ({
  token,
  fertYear,
  plantDate,
  blockId,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementCycle',
    method: 'POST',
    authToken: token,
    body: {
      fertYear,
      plantDate,
      blockId,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementCycle request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
