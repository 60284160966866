import { useCallback, useEffect, useState } from 'react';
import { CreateVarietyDialog } from './CreateVarietyDialog';
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useCallbackRef } from '../../pages/ManageBlocks/useCallbackRef';
import { API, graphqlOperation } from 'aws-amplify';
import { getVarieties } from '../../graphQL/Variety';
import { useSnackbar } from 'notistack';

export const VarietyPicker = (props) => {
  const { selected, onChange } = props;

  const invokeOnChange = useCallbackRef(onChange);

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [varieties, setVarieties] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const fetchVarieties = useCallback(
    async (token = { cancelled: false }) => {
      setIsLoading(true);

      try {
        const result = await API.graphql(graphqlOperation(getVarieties.query));
        if (token.cancelled) return;
        setIsLoading(false);
        setVarieties(JSON.parse(result.data.getVarieties));
      } catch (err) {
        console.error('Error Fetching Varieties');
        console.error(err);
        enqueueSnackbar('Error Fetching Varieties', { variant: 'error' });
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    const token = { cancelled: false };
    fetchVarieties(token);
    return () => {
      token.cancelled = true;
    };
  }, [fetchVarieties]);

  const handleChange = useCallback(
    (e) => {
      invokeOnChange(e.target.value);
    },
    [invokeOnChange]
  );

  const handleOpenCreator = useCallback(() => {
    setIsCreatorOpen(true);
  }, []);

  const handleCloseCreator = useCallback(() => {
    setIsCreatorOpen(false);
  }, []);

  const handleVarietyCreated = useCallback(
    async ({ variety }) => {
      await fetchVarieties();
      invokeOnChange(variety);
    },
    [invokeOnChange, fetchVarieties]
  );

  return (
    <Box>
      {isLoading || varieties.length <= 0 ? (
        <CircularProgress size={24} style={{ color: '#999999' }} />
      ) : (
        <>
          <InputLabel id="variety-label" shrink>
            variety
          </InputLabel>
          <Select
            labelId="variety-label"
            value={selected}
            onChange={handleChange}
            style={{ minWidth: 100 }}
          >
            {varieties.map((variety) => (
              <MenuItem
                key={`${variety.id}-${variety.name}`}
                value={variety.name}
              >
                {variety.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: 8 }}
            onClick={handleOpenCreator}
          >
            New Variety
          </Button>
        </>
      )}
      {isCreatorOpen && (
        <CreateVarietyDialog
          open
          onClose={handleCloseCreator}
          onSuccess={handleVarietyCreated}
        />
      )}
    </Box>
  );
};
