import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';



import {
  GeoJSON,
  MapContainer,
  TileLayer,
  LayersControl,
  FeatureGroup,
  Popup,
  Marker,
} from 'react-leaflet';

// Material
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';



// COMPONENTS
import ApplicationProductDialog from '../../../../components/Applications/ApplicationProductDialog';
import { HarvestMapDataLayer } from '../../../../components/FarmMap/HarvestMapDataLayer';
import { EMMapDataLayer } from '../../../../components/FarmMap/EMMapDataLayer';

import { MapViewLegend } from './components/MapViewLegend';
import { MapViewSelectionInformation } from './components/MapViewSelectionInformation';

// FUNCTIONS
import { qualitativeColorArray } from '../../../../functions/colours';
import { formatDate } from '../../../../functions/formatDate';

const { BaseLayer } = LayersControl;


const useStyles = makeStyles((theme) => ({
  blockLabel: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    border: 0,
    color: 'white',
  },
  oldBlockShape: {
    fill: 'red',
    stroke: 'red',
    '&:hover': {
      fill: 'orange',
      stroke: 'orange',
    },
  },
  newBlockShape: {
    fill: 'blue',
    stroke: 'blue',
    '&:hover': {
      fill: 'green',
      stroke: 'green',
    },
  },
  modifiedBlockShape: {
    fill: 'orange',
    stroke: 'orange',
    '&:hover': {
      fill: 'yellow',
      stroke: 'yellow',
    },
  },
}));

export default function MapView(props) {
  const classes = useStyles();

  const { farmId, farmName, admin, agronomist, fertYear, filteredBlocks, selectedBlocks, modifySelectedBlocks, soilSamples } =
  props;


  const [farmBlocks, setFarmBlocks] = useState();



  useEffect(() => {
    const showMap = (farmName, farmId, filteredBlocks) => {
      const mapData = {
        type: 'FeatureCollection',
        farmName: farmName,
        farmId: farmId,
      };

      if (filteredBlocks && filteredBlocks.length > 0) {
        mapData.features = filteredBlocks.map((block) => {
          let geo = {
            ...block.geometry,
            bbox: [
              block.bbox.coordinates[0][0][0],
              block.bbox.coordinates[0][0][1],
              block.bbox.coordinates[0][2][0],
              block.bbox.coordinates[0][2][1],
            ],
            // coordinates: block.geometry.coordinates,
          };
          const res = {
            type: 'Feature',
            geometry: geo,
            properties: {
              name: block.name,
              id: block.id,
              hectares: block.hectares,
              soilSampleId: block.primary_sample_id,
              products: block.productsselected,
              // selected: false,
            },
          };
          res.properties.requirements =
            block.requirements && block.requirements ? block.requirements[0] : null;
          // res.properties.nutrient_group =
          //   block.productsselected && block.productsselected.length
          //     ? block.productsselected[0].nutrient_group
          //     : null;
          // res.properties.soil_sample =
          //   block.soil_samples && block.soil_samples ? block.soil_samples[0] : null;
          return res;
        });
      }
      // console.log(mapData);
      return mapData;
    };


      const mapData = showMap(farmName, farmId, filteredBlocks);
      console.log(mapData);
      setFarmBlocks(mapData);

  }, [farmId, farmName, fertYear, filteredBlocks]);

  console.log(farmBlocks);

  const [dataLayerSelect, setDataLayerSelect] = useState(null);

  const [layerOptions, setLayerOptions] = useState(['Soil Samples']);
  const [layerSelection, setLayerSelection] = useState(new Set());

  const [harvestLegend, setHarvestLegend] = useState(null);
  const [emMapLegend, setEMLegend] = useState(null);

  const [productToShow, setProductToShow] = useState();
  const [productGroups, setProductGroups] = useState([]);

  const [highlightSample, setHighlightSample] = useState(null);

  const style = useCallback(
    (feature) => {
      // console.log(feature.properties);
      // Calculate the area products have not been applied to
      const areaNotApplied = feature.properties.products
        ? feature.properties.products.reduce((acc, product) => {
            return acc + (feature.properties.hectares - product.applied || 0);
          }, 0) || 0
        : feature.properties.hectares;
      // return selectedBlocks.some((id) => id === feature.properties.id)

      const hasPrimarySoilSample = highlightSample && highlightSample.blocks.find((block) => block.id === feature.properties.id);

      const fillColor = qualitativeColorArray[productGroups.indexOf(productToShow)];
      const fillOpacity = (feature.properties.products || []).some(
        (product) => `${product.name} @ ${product.apprate}kg/ha` === productToShow
      )
        ? 1
        : 0;

      return selectedBlocks.has(feature.properties.id)
        ? {
            weight: 4,
            opacity: 1,
            color: hasPrimarySoilSample ? 'yellow' : feature.properties.products ? 'blue' : 'blue',
            fillColor: fillColor,
            fillOpacity: fillOpacity,
          }
        : {
            weight: 2,
            opacity: 1,
            color: hasPrimarySoilSample ? 'yellow' : (feature.properties.products && areaNotApplied) ? 'red' : 'green',
            fillColor: fillColor,
            fillOpacity: fillOpacity,
          };
    },
    [selectedBlocks, productToShow, productGroups, highlightSample]
  );



  // Add's them to selected stack
  const handleClick = useCallback(
    (e) => {

      const blockId = e.target.feature.properties.id;
      modifySelectedBlocks(blockId);
      e.target.setStyle(style(e.target.feature));
    },
    [modifySelectedBlocks, style]
  );

  const onEachFeature = useCallback(
    (feature, layer) => {
      // console.log(feature.properties);
      layer.on({
        // mouseover: highlightFeature,
        // mouseout: resetHighlight,
        click: handleClick,
      });
      layer
        .bindTooltip(feature.properties.name, {
          permanent: true,
          direction: 'center',
          className: classes.blockLabel,
        })
        .openTooltip();
    },
    [classes, handleClick] //highlightFeature, resetHighlight
  );

  const mapPosition = useMemo(() => {
    if (!farmBlocks || farmBlocks?.features.length === 0) return { x: 0, y: 0 };
    console.log(farmBlocks);
    let bbox = { ...farmBlocks.features[0].geometry.bbox };
    farmBlocks.features.forEach((feature) => {
      // Calc bounding box max/min
      if (feature.geometry.bbox[0] < bbox[0]) bbox[0] = feature.geometry.bbox[0];
      if (feature.geometry.bbox[1] < bbox[1]) bbox[1] = feature.geometry.bbox[1];
      if (feature.geometry.bbox[2] > bbox[2]) bbox[2] = feature.geometry.bbox[2];
      if (feature.geometry.bbox[3] > bbox[3]) bbox[3] = feature.geometry.bbox[3];
    });

    return {
      x: bbox[3] + (bbox[1] - bbox[3]) / 2,
      y: bbox[0] + (bbox[2] - bbox[0]) / 2,
    };
  }, [farmBlocks]);

  useEffect(() => {
    if (!farmBlocks || farmBlocks?.features.length === 0) return;
    const productGroups = new Set([]);
    farmBlocks.features.forEach((block) => {
      if (block.properties.products === undefined) return;
      block.properties.products.forEach((product) =>
        productGroups.add(`${product.name} @ ${product.apprate}kg/ha`)
      );
    });

    const sortedProductGroups = [...productGroups].sort();

    // console.log(sortedProductGroups);

    if (sortedProductGroups.length > 0) {
      setLayerOptions((prev) => [...prev, 'Products']);
    }
    setProductGroups([...sortedProductGroups]);
  }, [farmBlocks]);

  const [openDialog, setOpenDialog] = useState(null);
  const groupApplications = useCallback(() => {
    let selectedBlockData = farmBlocks?.features.flatMap((feature) => {
      if (feature.properties.products === undefined) return [];
      if (!selectedBlocks.has(feature.properties.id)) return [];
      
      return feature.properties.products.map((product) => ({
        ...product,
        blockname: feature.properties.name,
        blockid: feature.properties.id,
        blockarea: feature.properties.hectares,
        disableChanges: feature.properties.requirements.to_fallow !== null,
      }));
    });
    return selectedBlockData;
  }, [farmBlocks?.features, selectedBlocks]);

  const onCheckBoxChange = (status, layer) => {
    if (layer === 'Products') setProductToShow(status ? productGroups[0] : null);
    status
      ? setLayerSelection((prev) => new Set(prev.add(layer)))
      : setLayerSelection((prev) => new Set([...prev].filter((x) => x !== layer)));
  };



  return (

<div style={{ height: '100%', width: '100%', paddingBottom: '20px' }}>

      <Grid container style={{ height: '100%'}}>
              {openDialog === 'APPLICATIONS' && (
        <ApplicationProductDialog
          visible={openDialog === 'APPLICATIONS'}
          blockName={'Group'}
          products={groupApplications() || null}
          handleClose={() => setOpenDialog(null)}
          productToShow={{
            product: productToShow,
            color: qualitativeColorArray[productGroups.indexOf(productToShow)],
          }}
        />
      )}

        <Grid item xs={8}>
          <div style={{ height: '100%', width: '100%' }}>
          {farmBlocks?.features.length > 0 && (
            <MapContainer
              center={[mapPosition.x, mapPosition.y]}
              zoom={16}
              zoomControl={true}
              style={{ height: '100%' }}
            >
              <LayersControl position='topright'>
                <BaseLayer checked name='Satellite View'>
                  <TileLayer url='http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}' />
                </BaseLayer>
                <BaseLayer name='Street Map'>
                  <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                </BaseLayer>

                <GeoJSON
                key={Math.random()}
                data={farmBlocks}
                style={style}
                onEachFeature={onEachFeature}
              />
                <HarvestMapDataLayer
                  setDataLayerSelect={setDataLayerSelect}
                  farmId={farmId}
                  fertYear={fertYear}
                  displayLayer={layerSelection.has('Harvest Data')}
                  setHarvestLegend={setHarvestLegend}
                />

                <EMMapDataLayer
                  farmId={farmId}
                  setLayerOptions={setLayerOptions}
                  displayLayer={layerSelection.has('EM Data')}
                  setEMLegend={setEMLegend}
                />
              </LayersControl>
              {layerSelection.has('Soil Samples') && <FeatureGroup >
                {soilSamples.map((sample) => {
                  const d = JSON.parse(sample);
                  return (
                  <Marker
                    key={d.lab_sample_number}
                    position={{ lat: d.lat, lng: d.lng }}
                    eventHandlers={{
                      popupopen: (e) => {
                        setHighlightSample(d);
                      },
                      popupclose: (e) => {
                        setHighlightSample(null);
                      }
                    }}
                  >
                    <Popup>
                      <span>
                        <h4>{d.lab_sample_number}</h4>
                        <p>{formatDate(d.sampling_date)}</p>
                      </span>
                    </Popup>
                  </Marker>
                  )
                }
                  
                )}
              </FeatureGroup>}
            </MapContainer>
          )}
          </div>
        </Grid>
        <Grid item xs={4} style={{ height: '100%'}}>
        <div style={{ height: '100%', width: '100%' }}>
          <Button
            component={Link}
            to={{
              pathname: `/manage-blocks/${farmId}`,
              state: { farmName: farmName },
            }}
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
            color='primary'
            variant='contained'
          >
            Modify Farm
          </Button>
          <MapViewLegend/>

          {layerOptions && layerOptions.length > 0 && (
            <Paper
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '10px',
              }}
            >
              <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
                Map Options
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={productGroups.length === 0}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'Products')}
                />
                <Typography>Products</Typography>
                {layerSelection.has('Products') && productGroups.length > 0 && (
                  <Select
                    labelId='productOptions-label'
                    id='productOptions'
                    label='Products'
                    defaultValue={productGroups[0]}
                    onChange={(e) => setProductToShow(e.target.value)}
                    fullWidth
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  >
                    {productGroups.map((productGroup) => (
                      <MenuItem key={`menu_${productGroup}`} value={productGroup}>
                        {productGroup}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={soilSamples.length === 0}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'Soil Samples')}
                />
                <Typography>Soil Samples</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={layerOptions.find((layer) => layer === 'EM Data') === undefined}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'EM Data')}
                />
                <Typography>EM Data</Typography>
              </Box>
              {layerSelection.has('EM Data') && emMapLegend}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  // disabled={layerOptions.find((layer) => layer === 'EM Data') === undefined}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'Harvest Data')}
                />
                <Typography>Harvest Data</Typography>
                {layerSelection.has('Harvest Data') && dataLayerSelect}
              </Box>
              {layerSelection.has('Harvest Data') && harvestLegend}
            </Paper>
          )}
          <Paper style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20px' }}>
            <MapViewSelectionInformation
              selectedBlocks={selectedBlocks}
              farmBlocks={farmBlocks}
              productToShow={productToShow}
              productGroups={productGroups}/>
            <Button
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '10px',
                marginTop: '10px',
              }}
              color='secondary'
              variant='contained'
              disabled={(groupApplications() || []).length <= 0}
              onClick={() => setOpenDialog('APPLICATIONS')}
            >
              APPLICATIONS
            </Button>
          </Paper>
          </div>
        </Grid>

      </Grid>
      </div>
  );
}
