//@format
//
import * as XLSX from 'xlsx';

const parse = xl => {
    let readData = XLSX.read(xl, {type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy'});
    const sheet_name_list = readData.SheetNames;
    return {
        sheetNames: () => sheet_name_list,
        nthSheet: x => {
            const sheetName = sheet_name_list[x];
            return XLSX.utils.sheet_to_json(readData.Sheets[sheetName], {raw: false});
        },
    };
};

export {parse};
