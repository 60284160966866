import { List, ListItem, ListItemText } from '@material-ui/core';
import { TabContent } from './TabContent';

export const FarmsTabValue = 'farms';

export const validateFarm = (farm, data) => {
  const errors = [];

  const regions = ['Herbert', 'Burdekin', 'Wet Tropics', 'Mackay', 'Plane Creek'];
  if (regions.includes(farm.region) === false) {
    errors.push(`Unknown Region '${farm.region}'`);
  }

  return errors.length === 0
    ? { isValid: true }
    : { isValid: false, errors: errors };
};

export const FarmsValidator = (data) => {
  const errors = data.flatMap((farm) => validateFarm(farm, data).errors ?? []);
  return errors.length > 0
    ? { success: false, errors: errors }
    : { success: true };
};

export const FarmsTabContent = ({ value, data }) => {
  return (
    <TabContent value={value} tabValue={FarmsTabValue}>
      <List>
        {data.map((farm) => (
          <ListItem key={farm.name}>
            <ListItemText
              primary={farm.name}
              secondary={`Description: ${farm.description} - Region: ${farm.region}`}
            />
          </ListItem>
        ))}
      </List>
    </TabContent>
  );
};
