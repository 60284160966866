import { APIFetch } from '../../fetch.js';

export const requirementToFallow = async ({
  token,
  requirementId,
  toFallow,
  toStandOver,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementToFallow',
    method: 'POST',
    authToken: token,
    body: {
      requirementId,
      toFallow,
      toStandOver,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementToFallow request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
