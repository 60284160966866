import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useCallback, useState } from 'react';

export const GenericDeleteDialog = ({ id, name, type, onAccept, onClose }) => {
  const [confirmation, setConfirmation] = useState('');

  const verifyDelete = useCallback(() => {
    if (confirmation !== name) return;
    onAccept?.(id);
  }, [id, confirmation, name, onAccept]);

  const handleTextChanged = useCallback((event) => {
    setConfirmation(event.target.value);
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: 'red' }}
        id="delete-dialog-title"
      >{`Are you sure you want to delete ${name}?`}</DialogTitle>

      <DialogContent>
        <Typography>
          {`Type "${name}" to
          permanently delete${type ? ` this ${type}` : ''}.`}
        </Typography>
        <TextField
          id="itemName"
          label={type ? `${type} name` : 'Name'}
          type="text"
          value={confirmation}
          onChange={handleTextChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={verifyDelete} style={{ color: 'red' }} autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
