import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";

import React from "react";

export default function DeleteDialog(props) {
  let { visible, item, handleAccept, handleClose } = props;

  let itemToDelete = "";

  console.log(item);

  function verifyDelete() {
    if (itemToDelete === item.name) {
      // console.log("DELETE", item.id);
      handleAccept(item.id);
    } else {
      // console.log("TRY AGAIN");
    }
  }
  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "red" }}
        id="delete-dialog-title"
      >{`Are you sure you want to delete ${item.name}?`}</DialogTitle>
      <DialogContent>
        <Typography>
          {`Type "${item.name}" to
          permanently delete this farm.`}
        </Typography>
        <TextField
          id="itemName"
          label="Farm name"
          type="text"
          onChange={(event) => (itemToDelete = event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={verifyDelete} style={{ color: "red" }} autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
