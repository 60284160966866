import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from '@sentry/react';
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";

import Routes from "./Routes";
import AlertContextProvider from "./components/AlertContext";
import SessionContextProvider from "./contexts/SessionContext";
import BlocksContext from "./contexts/BlocksContext";
import LoadingBackground from "./components/LoadingBackground";
import SiteWrapper from "./components/SiteWrapper/SiteWrapper";
import getTitle from "./functions/getTitle";
import theme from "./appTheme";

import Amplify, { Auth, Cache } from "aws-amplify";
import { awsConfig } from "./config/aws_config";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UserContextProvier } from "./contexts/UserContext.js";

const queryClient = new QueryClient()

// import EntityContextProvider from "./contexts/EntityContext";

function App(props) {
  const [user, setUser] = useState(null);

  const [online, setOnline] = useState(true);
  const [loading, setLoading] = useState(true);

  Amplify.configure(awsConfig);

  const getAuthentication = useCallback(() => {
    Auth.currentSession()
      .then((result) => {
        setUser(result);
        console.log('USER:',result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

      // Get current login info for sentry
      Auth.currentUserInfo()
      .then((result) => {
        //console.log("Authentication result: ", result);
        Sentry.setUser({username: result.username});
      })
      .catch((err) => {
        //console.log("Authentication error: ", err);
        Sentry.setUser(null);
      });

  }, []);

  useEffect(() => {
    getAuthentication();
  }, [getAuthentication]);

  window.addEventListener("online", () => setOnline(true));
  window.addEventListener("offline", () => setOnline(false));

  const config = {
    itemMaxSize: 500000,
  };

  Cache.configure(config);

  let childProps = {
    isLoggedIn: user !== null,
    userHasAuthenticated: getAuthentication,
    user: user,
    online: online,
  };
  // console.log(childProps);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider autoHideDuration={2000}>
              {loading ? (
                <LoadingBackground />
              ) : (
                <AlertContextProvider>
                  <SessionContextProvider>
                    <UserContextProvier user={user}>
                      {user ? (
                        <BlocksContext>
                          <SiteWrapper
                            {...childProps}
                            title={getTitle(window.location.href)}
                            online={online}
                          >
                            <Routes childProps={childProps} />
                          </SiteWrapper>
                        </BlocksContext>
                      ) : (
                        <Routes childProps={childProps} />
                      )}
                    </UserContextProvier>
                  </SessionContextProvider>
                </AlertContextProvider>
              )}
            </SnackbarProvider>
          </MuiThemeProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
// export default withRouter(App);

/*
return (
    <div className="App">
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          {loading ? (
            <LoadingBackground />
          ) : (
            <AlertContextProvider>
              <EntityContextProvider>
                {user ? (
                  <SiteWrapper
                    {...childProps}
                    title={getTitle(window.location.href)}
                    online={online}
                  >
                    <Routes childProps={childProps} />
                  </SiteWrapper>
                ) : (
                  <Routes childProps={childProps} />
                )}
              </EntityContextProvider>
            </AlertContextProvider>
          )}
        </MuiThemeProvider>
      </BrowserRouter>
    </div>
  );*/
