import { ProfilePasswordSchema, ProfileSchema } from "../components/ValidationSchema";
import React, {useState} from "react";

import { Auth } from 'aws-amplify';
import { CardContent } from '@material-ui/core';
import FormButton from "../components/FormButton";
import FormText from "../components/FormText";
import FormikForm from "../components/FormikForm";
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
import { useAlertContext } from "../components/AlertContext";
import {useFormik} from "formik";

export default function Profile(props) {
    const { user } = props
    const alertContext = useAlertContext()
    const [ loading, setLoading ] = useState(false)
    const [ currentPasswordVisible, setCurrentPasswordVisible ] = useState(false)
    const [ newPasswordVisible, setNewPasswordVisible ] = useState(false)
    const passwordPolicy = "Password Policy: minimum 8 characters, at least 1 upper and Lower case letter and at least 1 number"
    console.log(user);
    const formikPassword = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
        },
        enableReinitialize: true,
        validationSchema: ProfilePasswordSchema,
        onSubmit: (values, actions) => {
            setLoading(true)
            Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, values.currentPassword, values.newPassword)
            })
            .then(data => {
                setLoading(false)
                alertContext.success(`Your password has been reset`)
            })
            .catch(err => {
                setLoading(false)
                alertContext.error(err, `Error resetting your password`)
            });
        }
    });

    const formikProfile = useFormik({
        initialValues: {
            firstName: user.idToken.payload['custom:first_name'],
            lastName: user.idToken.payload['custom:last_name'],
            email: user.idToken.payload['email'],
            phoneNumber: user.idToken.payload['phone_number'].substr(4)
        },
        enableReinitialize: true,
        validationSchema: ProfileSchema,
        onSubmit: (values, actions) => {
           //TODO - confirm what values a user can update if any
        }
    });

    return (
        <div style={{padding: 20}}>
        <LoadingBackground visible={loading}/>
        <LogoCard>
            <PageHeader
                title="My Account details"
                subheader={`To update please contact an administrator`}
                backPath={'back'}
            />
            <CardContent>
                <FormikForm formik={formikProfile}>
                    <FormText name="firstName" label="First Name:" disabled />
                    <FormText name="lastName" label="Last Name:" disabled/>
                    <FormText name="email" label="Email Address:" disabled/>
                    <FormText name="phoneNumber" label="Phone Number:" prefix={'+614'} disabled/>
                </FormikForm>
            </CardContent>
        </LogoCard>
        <br/>
        <LogoCard>
            <PageHeader
                title="Reset Password"
                subheader="Fill in the details below to reset your password"
            />
            <CardContent>
                <FormikForm formik={formikPassword}>
                    <FormText name="currentPassword" label="Current Password:" password
                      type={currentPasswordVisible ? "text" : "password"}
                      handlePasswordVisibility={()=>setCurrentPasswordVisible(!currentPasswordVisible)} />
                    <FormText name="newPassword" label="New Password:" password
                      type={newPasswordVisible ? "text" : "password"}
                      handlePasswordVisibility={()=>setNewPasswordVisible(!newPasswordVisible)}
                      helpText={passwordPolicy} />
                    <FormButton text={'Update Password'} />
                </FormikForm>
            </CardContent>
        </LogoCard>
        </div>
    );
}
