import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  InputBase,
} from '@material-ui/core';

import { Edit, PinDrop, Timeline, Search as SearchIcon } from '@material-ui/icons';

import UploadLabSamples from '../../components/UploadLabSamples';

import { getSorting, stableSort } from '../../functions/tableActions';
import { formatDate } from '../../functions/formatDate';

const rows = [
  { id: 'lab_sample_number', label: 'Lab Sample Number' },
  { id: 'lat', label: 'Latitude' },
  { id: 'lng', label: 'Longitude' },
  { id: 'sampling_date', label: 'Sampling Date' },
  { id: 'blocks', label: 'Block Names' },
  { id: 'sample_state_id', label: 'State Id' },
  { id: 'view', label: '' },
  { id: 'action', label: '' },
];

export default function SoilSamplesView(props) {
//   if (!props.match.params) {
//     window.location.href = '/';
//   }

  const { farmId, farmName, admin, agronomist, filteredBlocks, selectedBlocks, soilSamples } = props;
//   const farmId = props.match.params.id;
//   const farmName = props.match.params.name ? props.match.params.name : farmId;
//   const soilSamples = props.soilSamples;

//   const agronomist = props.user.idToken.payload.agronomist === 'true';

  const [query, setQuery] = useState('');
  const [orderBy, setOrderBy] = useState('sampling_date');
  const [order, setOrder] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    setOrder(orderBy === property && order === 'desc' ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, soilSamples.length - page * rowsPerPage);

  let filteredSearch;
  filteredSearch = soilSamples.filter((item) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });

  return (
    <div>
      <div>
        <div>
          <IconButton aria-label='Search'>
            <SearchIcon />
          </IconButton>
          <InputBase onChange={(e) => setQuery(e.target.value)} placeholder={'Search...'} />
        </div>

        <UploadLabSamples farmId={farmId} farmName={farmName} />

        <Table aria-labelledby='tableTitle'>
          <TableHead>
            <TableRow>
              {rows.map((row, index) => {
                return [
                  <TableCell
                    key={index}
                    align={row.id === 'location' ? 'center' : 'left'}
                    padding='normal'
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Tooltip title='Sort' placement='bottom-start' enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>,
                ];
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredSearch, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sample) => {
                // const sample = JSON.parse(item);
                return [
                  <TableRow hover tabIndex={-1} key={sample.id}>
                    <Tooltip title={`Lab Sample Id: ${sample.lab_sample_id}`} enterDelay={300}>
                      <TableCell component='th' scope='row' padding='normal'>
                        {sample.lab_sample_number}
                      </TableCell>
                    </Tooltip>
                    <TableCell component='th' scope='row' padding='normal'>
                      {sample.lat}
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      {sample.lng}
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      {formatDate(sample.sampling_date)}
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      {sample.blocks &&
                        sample.blocks
                          .map((block) => {
                            return block.name;
                          })
                          .sort()
                          .toString()}
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      {String(sample.sample_state_id) === '1'
                        ? 'Requested'
                        : String(sample.sample_state_id) === '2'
                        ? 'Sent'
                        : String(sample.sample_state_id) === '3'
                        ? 'Received'
                        : String(sample.sample_state_id) === '4'
                        ? 'Rejected'
                        : String(sample.sample_state_id) === '5'
                        ? 'Accepted'
                        : 'Unknown'}{' '}
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      <Tooltip title='View Lab Sample'>
                        <IconButton
                          disabled={!(!agronomist || sample.lab_sample_id)}
                          edge='end'
                          aria-label='View'
                          color='primary'
                          component={Link}
                          to={`/lab-sample/${sample.id}/${sample.lab_sample_id}`}
                        >
                          <Timeline />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      <Tooltip title='Update Soil Sample'>
                        <IconButton
                          disabled={!agronomist}
                          edge='end'
                          aria-label='View'
                          color='primary'
                          component={Link}
                          to={`/view-soil-sample/${farmName}/${farmId}/${sample.id}`}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component='th' scope='row' padding='normal'>
                      <Tooltip title='Open Map'>
                        <IconButton
                          aria-label='View'
                          variant='link'
                          target={'_t'}
                          href={`https://www.google.com/maps/place/${sample.lat},${sample.lng}/@${sample.lat},${sample.lng},466m/data=!3m1!1e3!4m5!3m4!7e2!8m2!3d${sample.lat}!4d${sample.lng}`}
                        >
                          <PinDrop color='primary' />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>,
                ];
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={rows.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={soilSamples.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
