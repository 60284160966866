export const reportUploader = { 
    mutation: `mutation reportUploader($data: AWSJSON) {
        reportUploader(data: $data)
    }`
};

export const listReports = { 
    query: `query listReports($data: AWSJSON) {
        listReports(data: $data)
    }`
};

export const getReport = { 
    query: `query getReport($data: AWSJSON) {
        getReport(data: $data)
    }`
};

export const getDashboard = {
    query: `query getDashboard($data: AWSJSON)  {
        getDashboard(data: $data)
    }`
};

export const deleteReports = {
    mutation: `mutation deleteReports($data: AWSJSON) {
        deleteReports(data: $data)
    }`
}
