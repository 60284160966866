import {
  Grid,
  IconButton,
  ListItem,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { NavigateShapeButton } from './NavigateShapeButton';
import { Clear, Done } from '@material-ui/icons';
import { useCallbackRef } from './useCallbackRef';

export const NewShapeCard = (props) => {
  const {
    blockId,
    blockName,
    isNewSelected = false,
    oldShape,
    newShape,
    canAcceptOld = false,
    onNavigate,
    onSelectChange,
    onAccept,
    onReject,
  } = props;

  const invokeOnNavigate = useCallbackRef(onNavigate);
  const invokeOnSelectChange = useCallbackRef(onSelectChange);
  const invokeOnAccept = useCallbackRef(onAccept);
  const invokeOnReject = useCallbackRef(onReject);

  const state = useMemo(
    () => ({
      blockId,
      blockName,
      oldShape,
      newShape,
      isNewSelected,
      selectedShape: isNewSelected ? newShape : oldShape,
    }),
    [blockId, blockName, oldShape, newShape, isNewSelected]
  );
  const isAcceptDisabled = useMemo(
    () => !canAcceptOld && !isNewSelected,
    [canAcceptOld, isNewSelected]
  );

  const handleSwitchChange = useCallback(
    (_, checked) => {
      invokeOnSelectChange({
        ...state,
        isNewSelected: checked,
        selectedShape: checked ? newShape : oldShape,
      });
    },
    [oldShape, newShape, invokeOnSelectChange, state]
  );

  const handleClickNavigate = useCallback(() => {
    invokeOnNavigate(state);
  }, [invokeOnNavigate, state]);

  const handleClickAccept = useCallback(() => {
    invokeOnAccept(state);
  }, [invokeOnAccept, state]);

  const handleClickReject = useCallback(() => {
    invokeOnReject(state);
  }, [invokeOnReject, state]);

  return (
    <Paper elevation={0}>
      <ListItem>
        <Grid container alignItems="center">
          <Grid item>
            <Typography>{blockName}</Typography>
          </Grid>
          <Grid item>
            <NavigateShapeButton size="small" onClick={handleClickNavigate} />
          </Grid>
          <Grid item>
            <Typography component="div">
              <Grid container component="label" alignItems="center" spacing={1}>
                <Grid item>
                  <Typography
                    color={isNewSelected ? 'textSecondary' : 'textPrimary'}
                  >
                    Old
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    size="small"
                    checked={isNewSelected}
                    onChange={handleSwitchChange}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    color={isNewSelected ? 'textPrimary' : 'textSecondary'}
                  >
                    New
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                isAcceptDisabled
                  ? ''
                  : `Accept ${isNewSelected ? 'New' : 'Old'}`
              }
            >
              <div>
                <IconButton
                  size="small"
                  disabled={isAcceptDisabled}
                  onClick={handleClickAccept}
                >
                  <Done color={isAcceptDisabled ? 'disabled' : 'primary'} />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Reject">
              <IconButton size="small" onClick={handleClickReject}>
                <Clear color="error" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  );
};
