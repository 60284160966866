import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

// MATERIAL
import Grid from '@material-ui/core/Grid';

export default function MicroTables(props) {
  const { sample, tableData, requirement } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
      <Typography variant='h6' style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}>
        Copper (Cu) {requirement && `${requirement.copper}kg/ha`}
      </Typography>
      <Typography>Copper DTPA (Cu/kg): {sample.copper_dtpa_mg_kg}</Typography>
      <TableContainer  style={{ marginBottom: 20}}>
        <Table stickyHeader size='small'>
          <TableHead>
          <TableCell colSpan={2}>Copper (Cu) fertiliser guidelines {tableData.name}</TableCell>
          
          </TableHead>
          <TableBody>
            <TableRow>
            <TableCell >Copper (DTPA)</TableCell>
            <TableCell>Application Rate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >{`< 0.2 mg Cu/kg`}</TableCell>
              <TableCell style={{background: (sample.copper_dtpa_mg_kg > 0 && sample.copper_dtpa_mg_kg < 0.2) ? 'rgba(65, 172, 210, 0.6)' : '#FFF'}}>{`10 kg Cu/ha once per crop cycle`}</TableCell>
            </TableRow>   
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>

      <Grid item xs={12} md={6}>
      <Typography variant='h6' style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}>
        Zinc (Zn) {requirement && `${requirement.zinc}kg/ha`}
      </Typography>
      <Typography>Zinc (HCL): {sample.zinc_hcl}</Typography>
      <Typography>Zinc (DTPA): {sample.zink_dtpa_mg_kg}</Typography>
      <TableContainer  style={{ marginBottom: 20}}>
        <Table stickyHeader size='small'>
          <TableHead>
          <TableCell colSpan={2}>Zinc (Zu) fertiliser guidelines {tableData.name}</TableCell>
          
          </TableHead>
          <TableBody>
            <TableRow>
            <TableCell >Zinc (HCL)</TableCell>
            <TableCell>Application Rate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >{`< 0.6 mg Zn/kg`}</TableCell>
              <TableCell style={{background: (sample.zinc_hcl > 0 && sample.zinc_hcl < 0.6) ? 'rgba(65, 172, 210, 0.6)' : '#FFF'}}>{`10 kg Zn/ha once per crop cycle`}</TableCell>
            </TableRow>   
            <TableRow>
            <TableCell >Zinc (DTPA)</TableCell>
            <TableCell>Application Rate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >{`< 0.3 mg Zn/kg`}</TableCell>
              <TableCell style={{background: (sample.zink_dtpa_mg_kg > 0 && sample.zink_dtpa_mg_kg < 0.3) ? 'rgba(65, 172, 210, 0.6)' : '#FFF'}}>{`10 kg Zn/ha once per crop cycle`}</TableCell>
            </TableRow>   
            <TableRow>
              <TableCell colSpan={2}>{`*If Soil Ph is < 6.5 use HCL otherwise use DTPA`}</TableCell>
            </TableRow>  
          </TableBody>
        </Table>
      </TableContainer>
      </Grid>
    </Grid>
  );
}
