/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Route, Redirect } from "react-router-dom";

export default ({ render: C, props: childProps, ...rest }) => {
  // console.log(C, childProps);
  return (
    <Route
      {...rest}
      render={(rProps) =>
        childProps.isLoggedIn ? (
          <C {...rProps} {...childProps} />
        ) : (
          <Redirect to={`/login`} />
        )
      }
    />
  );
};
