// Libraries
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

// Icons
import { Description, Save, ViewQuilt, Map } from '@material-ui/icons';

// Dialogs
import ApplicationProductDialog from '../components/Applications/ApplicationProductDialog';
import ApplicationMillDialog from '../components/Applications/ApplicationMillDialog';

// Components
import LoadingBackground from '../components/LoadingBackground';
import LogoCard from '../components/LogoCard';
import PageHeader from '../components/PageHeader';
import BlockLine from '../components/FarmOverview/BlockLine';
import Charts from '../components/FarmOverview/Charts';

// Contexts
import { useAlertContext } from '../components/AlertContext';
import { BlockContext } from '../contexts/BlocksContext';

// Queries
// import { createRequirements } from "../graphQL/Requirement";
import { getFarmBlocks } from '../graphQL/Farm';
// INSERTED TO KEEP APP HAPPY
// import { updateBlocksCycle } from '../graphQL/Blocks';
// import generateFNA from "../graphQL/GenerateFNA";
// import setPrimarySoilSample from "../graphQL/SetPrimarySoilSample";

// Functions
import { filterFertYear } from '../functions/filterDataForFertYear';

// HOOKS
import useWindowDimensions from '../hooks/useWindowDimensions';
// import { set } from 'date-fns';

const showMap = (farmName, farmId, filteredBlocks) => {
  const mapData = {
    type: 'FeatureCollection',
    farmName: farmName,
    farmId: farmId,
  };

  if (filteredBlocks && filteredBlocks.length > 0) {
    mapData.features = filteredBlocks.map((block) => {
      let geo = {
        ...block.geometry,
        bbox: [
          block.bbox.coordinates[0][0][0],
          block.bbox.coordinates[0][0][1],
          block.bbox.coordinates[0][2][0],
          block.bbox.coordinates[0][2][1],
        ],
        // coordinates: block.geometry.coordinates,
      };
      const res = {
        type: 'Feature',
        geometry: geo,
        properties: {
          name: block.name,
          id: block.id,
          hectares: block.hectares,
          soilSampleId: block.primary_sample_id,
          products: block.productsselected,
          selected: false,
        },
      };
      res.properties.requirements =
        block.requirements && block.requirements ? block.requirements[0] : null;
      res.properties.nutrient_group =
        block.productsselected && block.productsselected.length
          ? block.productsselected[0].nutrient_group
          : null;
      res.properties.soil_sample =
        block.soil_samples && block.soil_samples ? block.soil_samples[0] : null;
      return res;
    });
  }
  // console.log(mapData);
  return mapData;
};

// Searches through all the requirements and returns all the fertilizer years found in a sorted list
const getFertYears = (blockArray) => {
  const fertYears = new Set(
    blockArray
      .flatMap((blockItem) =>
        blockItem.requirements
          ? blockItem.requirements.map((item) => item.fertilizer_year)
          : []
      )
      .sort((a, b) => a - b)
  );

  // NOTE: Give a few years of buffer incase no blocks on this farm have requirements
  for (let i = 0; i < 4; i++) {
    fertYears.add(new Date().getFullYear() + i);
  }

  // console.log(fertYears);
  return [...fertYears];
};

// let productCount = 0;

// Uses query to filters through all blocks and returns relevant data for that fertilizer year
// const filterFertYear = (blockArray, yearToFilter) => {
//   console.log(blockArray, yearToFilter);
//   const yearToFilterInt = parseInt(yearToFilter);
//   // console.log(blockArray.filter((b) => b.blockName == "12-1"));

//   let filteredSearch = blockArray.flatMap((block) =>
//     (parseInt(block.decommissioned_cycle) || yearToFilterInt + 1) > yearToFilterInt &&
//     parseInt(block.commissioned_cycle) <= yearToFilterInt
//       ? [
//           {
//             ...block,
//             requirementCount: block.requirements?.length,
//             requirements: block.requirements?.filter((requirement) => {
//               if (
//                 requirement.fertilizer_year?.toString() === yearToFilter.toString() /*&&
//                 requirement.primary_sample_id?.toString() ===
//                   block.primary_sample_id?.toString()*/ //TODO SORT BLOCKS WITH ORPHANED REQUIREMENTS
//               ) {
//                 return true;
//               } else return false;
//             }),
//             productsselected: block.productsselected?.filter((product) => {
//               if (product.fertilizer_year?.toString() === yearToFilter.toString()) {
//                 return true;
//               }
//               return false;
//             }),
//           },
//         ]
//       : []
//   );
//   console.log(filteredSearch);

//   productCount = filteredSearch.reduce(
//     (result, fBcurr) =>
//       result + ((fBcurr.productsselected && fBcurr.productsselected.length) || 0),
//     0
//   );
//   // console.log(productCount);

//   return filteredSearch.sort(
//     (a, b) => parseFloat(a.name.replace('-', '.')) - parseFloat(b.name.replace('-', '.'))
//   );
// };
let productCount = 0;

export default function ViewFarmOverview(props) {
  const { width: displayWidth, divideWidth } = useWindowDimensions();
  if (!props.match.params) {
    window.location.href = '/';
  }
  const history = useHistory();
  const classes = style();
  const farmId = props.match.params.id;
  const farmName = props.match.params.name;
  const online = props.online;
  const admin = props.user.idToken.payload.admin === 'true';
  const agronomist = props.user.idToken.payload.agronomist === 'true';
  // const admin = false;
  // const agronomist = false;

  const [selectedFarmBlocks, setSelectedFarmBlocks] = useState(new Set());
  const [openApplicationDialog, setOpenApplicationDialog] = useState(false);
  const [openMillApplicationDialog, setOpenMillApplicationDialog] = useState(false);

  // Still to Implement
  const { blocks, dispatchBlock } = useContext(BlockContext);

  // console.log(selectedFarmBlocks.size);

  // const [loading, setLoading] = useState(true);

  const alertContext = useAlertContext();

  const [view, setView] = useState(0);

  // const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [filteredBlocks, setfilteredBlocks] = useState([]);

  const [fertYear, setfertYear] = useState(new Date().getFullYear());
  const handleYearChange = (year) => {
    setfertYear(year);
    localStorage.setItem(`fertYear`, year);
    const filteredSearch = filterFertYear(blocks, year);

    productCount = filteredSearch.reduce(
      (result, fBcurr) =>
        result + ((fBcurr.productsselected && fBcurr.productsselected.length) || 0),
      0
    );

    setfilteredBlocks(filteredSearch);
  };

  useEffect(() => {
    const locallyStoredFertYear = localStorage.getItem(`fertYear`);
    if (isFinite(locallyStoredFertYear) && locallyStoredFertYear !== null) {
      handleYearChange(locallyStoredFertYear);
    } else {
      let currentFertYear = Math.min(
        ...blocks.flatMap((el) =>
          el.decommissioned_cycle === null
            ? [parseInt(el.current_cycle || new Date().getFullYear())]
            : []
        )
      ).toString();
      handleYearChange(currentFertYear);
    }
  }, [blocks]);

  // const setCurrentWorkingYear = () => {
  //       // INSERTED TO KEEP APP HAPPY
  //       const data = {
  //         farm_id: parseInt(farmId),
  //         nextcycle: parseInt(fertYear),
  //       };
  //       console.log('HERE: ', data);
  //       API.graphql(
  //         graphqlOperation(updateBlocksCycle.mutation, {
  //           cycleBlocks: data,
  //         })
  //       )
  //         .then(async (res) => {
  //           alertContext.success(`Set working fert year to ${fertYear}`);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           alertContext.error(err, `Error`);
  //         });
  //         // DELETE WHEN APP UPDATED
  // };

  const { data: ngBlock, isLoading: loading } = useQuery({
    queryKey: ['blocks'],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getFarmBlocks.query, { farmId: farmId })
      );
      const ngBlock = JSON.parse(result.data.getFarmBlocks);
      dispatchBlock({
        type: 'LOAD_BLOCKS',
        blocks: ngBlock,
      });

      console.log('GOT THIS AGAIN...');

      return ngBlock;
    },
  });

  function gotoProductSelection() {
    history.push({
      pathname: `/products/${farmName}/${farmId}`,
    });
  }

  // Add's them to selected stack
  function handleSelectBlock(blockId) {
    if (selectedFarmBlocks.has(blockId)) {
      setSelectedFarmBlocks((prev) => new Set([...prev].filter((x) => x !== blockId)));
    } else {
      setSelectedFarmBlocks((prev) => new Set(prev.add(blockId)));
    }
  }

  function groupApplications() {
    let selectedBlockData = filteredBlocks.flatMap((block) =>
      selectedFarmBlocks.has(block.id)
        ? block.productsselected.map((product) => ({
            ...product,
            blockname: block.name,
            blockid: block.id,
            blockarea: block.hectares,
            disableChanges: block.requirements[0].to_fallow !== null,
          }))
        : []
    );
    return selectedBlockData;
  }

  function groupMillApplications() {
    let selectedBlockData = filteredBlocks.flatMap((block) =>
      selectedFarmBlocks.has(block.id) && block.requirements !== undefined ? { ...block } : []
    );
    console.log(selectedBlockData);
    return selectedBlockData;
  }

  const tableNavigationIcon = (icon, ttTitle, gotoView) => {
    return (
      <Tooltip title={ttTitle} className={classes.actionIcon}>
        <IconButton
          style={{ flexDirection: 'column' }}
          disabled={!online}
          edge='end'
          aria-label='View'
          onClick={() => setView(gotoView)}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  };

  const majorActionButton = [
    <>
      {(admin || agronomist) && selectedFarmBlocks.size > 0 && (
        <>
          <Button
            style={{ marginLeft: 'auto' }}
            color='secondary'
            variant='contained'
            onClick={() => setOpenApplicationDialog(true)}
            disabled={!online || productCount === 0}
          >
            Applications
          </Button>
          <Button
            style={{ marginLeft: 'auto' }}
            color='secondary'
            variant='contained'
            onClick={() => setOpenMillApplicationDialog(true)}
          >
            Mill Applications
          </Button>
        </>
      )}
    </>,

      <Button
        style={{ marginLeft: '10px' }}
        color='secondary'
        variant='contained'
        onClick={gotoProductSelection}
      >
        Product Selection
      </Button>
,
  ];

  const columnNames = [
    '',
    '',
    '',
    '',
    '',
    'Nitrogen',
    'Phosphorus',
    'Potassium',
    'Sulphur',
    'Copper',
    'Zinc',
    'Lime Ca',
    'Lime Ph',
    'Gypsum',
    'Magnesium',
    'Silicon',
  ];

  return (
    <div>
      <LoadingBackground visible={loading} />
      {openApplicationDialog && (
        <ApplicationProductDialog
          visible={openApplicationDialog}
          blockName={'Group'}
          products={groupApplications() || null}
          handleClose={() => setOpenApplicationDialog(false)}
        />
      )}
      {openMillApplicationDialog && (
        <ApplicationMillDialog
          visible={openMillApplicationDialog}
          blocks={groupMillApplications() || null}
          handleClose={() => setOpenMillApplicationDialog(false)}
        />
      )}
      {/* <AlertDialog
        visible={openDialog}
        title={"Update Primary Soil Sample?"}
        subtitle={
          "Updating the blocks primary soil sample will create a new requirement for the block are you " +
          "sure you want to continue?"
        }
        handleAccept={handlePrimarySoilSample}
        handleClose={() => setOpenDialog(false)}
      /> */}
      {!loading && (
        <LogoCard>
          <PageHeader
            title={'Farm Overview'}
            subheader={`Viewing Blocks for ${farmName}`}
            backPath={'/'}
          />

          <Divider />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: 20,
            }}
          >
            <span>
              <FormControl>
                <Select
                  labelId='select-fert-year'
                  id='fert-year-id'
                  value={fertYear}
                  onChange={(e) => handleYearChange(e.target.value)}
                >
                  {getFertYears(blocks).map((year, index) => (
                    <MenuItem value={year.toString()} key={index}>
                      {year.toString()}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Fert year</FormHelperText>
                {/* {(agronomist || admin) && (
                  <Button
                    style={{ marginLeft: 'auto' }}
                    color='secondary'
                    variant='contained'
                    onClick={() => setCurrentWorkingYear()}
                  >
                    Set Year
                  </Button>
                )} */}
              </FormControl>
              <Tooltip title='View NMP Report' className={classes.actionIcon}>
                <IconButton
                  component={Link}
                  to={{
                    pathname: `/reportslist/${farmName}/${farmId}`,
                  }}
                  edge='end'
                  color='primary'
                  disabled={!online}
                >
                  <Description id={`icon_${farmName}`} color='primary' />
                </IconButton>
              </Tooltip>
              {(admin || agronomist) && (
                <Tooltip title='Generate NMP Report' className={classes.actionIcon}>
                  <IconButton
                    component={Link}
                    to={{
                      pathname: `/report/${farmName}/${farmId}`,
                      state: {
                        fertYear: fertYear,
                        blocks: filteredBlocks,
                        mapData: showMap(farmName, farmId, filteredBlocks),
                      },
                    }}
                    edge='end'
                    color={productCount > 0 ? 'primary' : 'secondary'}
                    disabled={!online || productCount === 0}
                  >
                    <Save id={`icon_${farmName}2`} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title='Map View' className={classes.actionIcon}>
                <IconButton
                  component={Link}
                  to={{
                    pathname: `/farmmap`,
                    state: {
                      fertYear,
                      mapData: showMap(farmName, farmId, filteredBlocks),
                    },
                  }}
                  edge='end'
                  color='primary'
                  disabled={!online}
                  // data={showMap(farmName, farmId, filteredBlocks)}
                >
                  <Map id={`icon_${farmName}3`} color='primary' />
                </IconButton>
              </Tooltip>
              <Tooltip title='View Soil Samples' className={classes.actionIcon}>
                <IconButton
                  style={{ flexDirection: 'column' }}
                  disabled={!online}
                  edge='end'
                  aria-label='View'
                  component={Link}
                  to={`/farm-soil-samples/${farmName}/${farmId}`}
                >
                  <SvgIcon id={`icon_${farmName}`} color='primary'>
                    <path d='M3,3V5A2,2 0 0,1 5,7V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V7A2,2 0 0,1 21,5V3H3M7,9H10V10H7V9M7,11H10V12H7V11M10,16H7V15H10V16M12,14H7V13H12V14M12,8H7V7H12V8Z' />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </span>
            {filteredBlocks && filteredBlocks.length > 0 && displayWidth > divideWidth && (
              <Charts filteredBlocks={filteredBlocks} />
            )}
            <span style={{ marginLeft: 'auto', paddingRight: 50 }}>
              {tableNavigationIcon(
                <ViewQuilt id={'Actions'} color={view === 0 ? 'secondary' : 'primary'} />,
                'Actions',
                0
              )}
              {tableNavigationIcon(
                <SvgIcon id={'Requirements'} color={view === 1 ? 'secondary' : 'primary'}>
                  <path d='M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z' />
                </SvgIcon>,
                'Requirements',
                1
              )}
            </span>
          </div>

          {majorActionButton[view]}
          <TableContainer style={{ width: '100vw' }}>
            <Table aria-labelledby='tableTitle'>
              <TableHead>
                {/* <TableCell colSpan={5}></TableCell> */}
                {view === 0 ? (
                  <TableRow>
                    <TableCell>Block</TableCell>
                    <TableCell></TableCell>
                    {/* <TableCell>Group</TableCell> */}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Class</TableCell>
                    <TableCell>Variety</TableCell>
                    <TableCell>Soil Sample</TableCell>
                    <TableCell colSpan={columnNames.length - 7}></TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={columnNames.length - 7} />
                      <TableCell colSpan={7} className={classes.tableNote}>
                        * These are crop cycle requirements (~5 years)
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columnNames.map((element, index) => (
                        <TableCell key={index}>{element}</TableCell>
                      ))}
                    </TableRow>
                  </>
                )}
              </TableHead>

              <TableBody>
                {!loading &&
                  filteredBlocks.map((block, index) => (
                    <BlockLine
                      key={index}
                      block={block}
                      view={view}
                      fertYear={fertYear}
                      agronomist={agronomist}
                      admin={admin}
                      // productCount={productCount}
                      // online={online}

                      // fetchBlocks={fetchBlocks}
                      // setLoading={setLoading}
                      handleSelectBlock={(id) => handleSelectBlock(id)}
                      selected={selectedFarmBlocks.has(block.id)}
                      farmName={farmName}
                      // handleChangePrimarySoilSample={
                      //   (e, id, reqid, reqState, reqFallowHarvested) =>
                      //     console.log('REMOVED SOIL SAMPLE CHANGE')
                      // handleChangePrimarySoilSample(
                      //   e,
                      //   id,
                      //   reqid,
                      //   reqState,
                      //   reqFallowHarvested,
                      //   index
                      // )
                      // }
                    />
                  ))}
                {/* {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={rows.length} />
              </TableRow>
            )} */}
              </TableBody>
            </Table>
          </TableContainer>
        </LogoCard>
      )}
    </div>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginLeft: 20,
  },
  listItem: {
    height: 400,
  },
  actionDiv: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  root: {
    height: '100%',
  },
  select: {
    backgroundColor: 'rgba( 255,255,255, 0.6)',
  },
  selectIcon: {
    color: '#09bd2f',
  },
  tableNote: {
    backgroundColor: 'rgba(0, 157, 72, 0.2)',
    padding: '4px 8px',
  },
}));
