import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// APIS
import useFarmBlocks from './queries/useFarmBlocks';
import useFarmSoilSamples from './queries/useFarmSoilSamples';

// COMPONENTS
import LoadingBackground from '../../components/LoadingBackground';
import ListView from './ListView';
import MapView from './features/MapView';
import ZonesView from './features/ZoneView';
import SoilSamplesView from './SoilSamplesView';
import ProductSelectionView from './features/ProductSelectionView';

// FUNCTIONS
import { filterFertYear } from '../../functions/filterDataForFertYear';
import useWindowDimensions from '../../hooks/useWindowDimensions';
// MATERIAL
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import SvgIcon from '@material-ui/core/SvgIcon';
// ICONS
import ArrowBack from '@material-ui/icons/ArrowBack';
import Description from '@material-ui/icons/Description';
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import Map from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import IsoIcon from '@material-ui/icons/Iso';

let productCount = 0;

export default function FarmWindow(props) {
  const history = useHistory();
  const { smallDisplay } = useWindowDimensions();
  if (!props.match.params) {
    window.location.href = '/';
  }
  const farmId = props.match.params.id;
  const farmName = props.match.params.name;
  const view = props.match.params.view;
  // const online = props.online;
  const admin = props.user.idToken.payload.admin === 'true';
  const agronomist = props.user.idToken.payload.agronomist === 'true';
  console.log(props)

  const [page, setPage] = useState(agronomist ? 1 : 0);

  // QUERY
  const { data: farmData, isLoading: loading, isFetching } = useFarmBlocks(farmId);
  const { data: soilSamples } = useFarmSoilSamples(farmId);

  
  const [filteredBlocks, setfilteredBlocks] = useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState(new Set());

  const modifySelectedBlocks = (blockId) => {
    if (selectedBlocks.has(blockId)) {
      setSelectedBlocks((prev) => new Set([...prev].filter((x) => x !== blockId)));
    } else {
      setSelectedBlocks((prev) => new Set(prev.add(blockId)));
    }
  }

  const [fertYear, setfertYear] = useState(new Date().getFullYear());
  const handleYearChange = (year) => {
    setfertYear(year);
    localStorage.setItem(`fertYear`, year);
    const filteredSearch = filterFertYear(farmData.blocks, year);

    productCount = filteredSearch.reduce(
      (result, fBcurr) =>
        result + ((fBcurr.productsselected && fBcurr.productsselected.length) || 0),
      0
    );

    setfilteredBlocks(filteredSearch);
  };
  console.log(filteredBlocks);

  useEffect(() => {
    if (farmData?.blocks && farmData?.blocks.length > 0) {
      const locallyStoredFertYear = localStorage.getItem(`fertYear`);
      if (isFinite(locallyStoredFertYear) && locallyStoredFertYear !== null) {
        handleYearChange(locallyStoredFertYear);
      } else {
        let currentFertYear = Math.min(
          ...farmData.blocks.flatMap((el) =>
            el.decommissioned_cycle === null
              ? [parseInt(el.current_cycle || new Date().getFullYear())]
              : []
          )
        ).toString();
        handleYearChange(currentFertYear);
      }
    }
  }, [farmData]);

  const handleChange = (event, newValue) => {
    setPage(newValue);
  };

  const styledTab = (icon, label) => {
    return smallDisplay ? (
      <Tab key={label} style={{ padding: '4px', minWidth: 100 }} label={label} />
    ) : (
      <Tab key={label} style={{ padding: '4px', minWidth: 120 }} icon={icon} label={label} />
    );
  };

  const tabData = [
    {label: 'MAP VIEW', icon: <Map />, page: <MapView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear} filteredBlocks={filteredBlocks} selectedBlocks={selectedBlocks} modifySelectedBlocks={modifySelectedBlocks} soilSamples={soilSamples}/>},
    {label: 'LIST VIEW', icon: <ListIcon />, page: <ListView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear} productCount={productCount} view={0} filteredBlocks={filteredBlocks} selectedBlocks={selectedBlocks} modifySelectedBlocks={modifySelectedBlocks}/>},
    {label: 'REQUIREMENTS', icon: <ViewQuilt />, page: <ListView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear} productCount={productCount} view={1} filteredBlocks={filteredBlocks} selectedBlocks={selectedBlocks} modifySelectedBlocks={modifySelectedBlocks}/>},
    {label: 'ZONES', icon: <IsoIcon />, page: <ZonesView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear}  filteredBlocks={filteredBlocks} />},
    {label: 'PRODUCTS', icon: <IsoIcon />, page: <ProductSelectionView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear}  filteredBlocks={filteredBlocks} />},
    {label: 'SAMPLES', icon: <SvgIcon id={`icon_${farmId}`}>
      <path d='M3,3V5A2,2 0 0,1 5,7V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V7A2,2 0 0,1 21,5V3H3M7,9H10V10H7V9M7,11H10V12H7V11M10,16H7V15H10V16M12,14H7V13H12V14M12,8H7V7H12V8Z' />
    </SvgIcon>, page: <SoilSamplesView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} filteredBlocks={filteredBlocks} selectedBlocks={selectedBlocks} soilSamples={soilSamples}/>},
    {label: 'REPORTS', icon: <Description />, page: <ListView farmId={farmId} farmName={farmName} admin={admin} agronomist={agronomist} fertYear={fertYear} productCount={productCount} view={0} filteredBlocks={filteredBlocks} selectedBlocks={selectedBlocks} modifySelectedBlocks={modifySelectedBlocks}/>},
  ];


  return (
    <>
      <LoadingBackground visible={loading} />
      {!loading && (
        <>
          <Box display='flex' alignItems='center' flexWrap='wrap'>
            <Box
              display='flex'
              alignItems='center'
              width={smallDisplay ? '100%' : '25%'}
              paddingLeft={'15px'}
              paddingY={'5px'}
            >
              <Typography variant='h5' component='h2'>
                {farmName}
              </Typography>

              <Box paddingLeft={'25px'} flexGrow={smallDisplay ? 1 : 0}>
                <FormControl>
                  <Select
                    labelId='select-fert-year'
                    id='fert-year-id'
                    value={fertYear}
                    onChange={(e) => handleYearChange(e.target.value)}
                  >
                    {farmData.fertYears.map((year, index) => (
                      <MenuItem value={year.toString()} key={index}>
                        {year.toString()}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Select Fert year</FormHelperText>
                </FormControl>
              </Box>
              {smallDisplay && (
                <Box>
                  <Tooltip title='Back' aria-label='list'>
                    <IconButton
                      aria-label='arrow_back'
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              width={smallDisplay ? '100%' : '70%'}

            >
              <Box flexGrow={1}>
                <Tabs
                  value={page}
                  onChange={handleChange}
                  indicatorColor='secondary'
                  textColor='secondary'
                  variant='scrollable'
                  scrollButtons='auto'
                >
                  {tabData.map((tab) => (
                    styledTab(tab.icon, tab.label)
                  ))}
                </Tabs>
              </Box>
              {!smallDisplay && (
                <Box>
                  <Tooltip title='Back' aria-label='list'>
                    <IconButton
                      aria-label='arrow_back'
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          {filteredBlocks && filteredBlocks.length > 0 && tabData[page].page}
          </>
      )}
    </>
  );
}
