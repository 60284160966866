import { API, graphqlOperation } from "aws-amplify";
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';

import AlertDialog from "../components/AlertDialog";
import { Link } from 'react-router-dom';
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
import { listAccounts, deleteAccount } from '../graphQL/Account';
import { useAlertContext } from "../components/AlertContext";

export default function Accounts(props) {
    const [ users, setUsers ] = useState([])
    const [ selectedUser, setSelectedUser ] = useState([])
    const [ dialogOpen, setDialogOpen ] = useState(false)
    const [ query, setQuery ] = useState('' )
    const [ loading, setLoading ] = useState(true )
    const alertContext = useAlertContext()

    const fetchUsers = useCallback(() => {
        setLoading(true)
        API.graphql(graphqlOperation(listAccounts.query)).then(result => {
            const users = JSON.parse(result.data.listAccounts);
            console.log(users)
            setUsers(users);
            setLoading(false)
        }).catch(err => {
            console.log(err)
            alertContext.error(err, 'Error fetching user accounts')
            setLoading(false)
        })
    }, [alertContext])

    useEffect( () => { fetchUsers()}, [fetchUsers]);

    const handleDeleteUser = async () => {
        setLoading(true);
        console.log(selectedUser)
        API.graphql(graphqlOperation(deleteAccount.mutation, {username: selectedUser.username, sub: selectedUser.sub}))
        .then(async (result) => {
            setDialogOpen(false)
            fetchUsers()
            setSelectedUser(null)
        })
        .catch(err => {
            alertContext.error(err, 'Error deleting the user account')
        })
    }

    const handleDeleteDialog = (user) => {
        setSelectedUser(user)
        setDialogOpen(true)
    }

    let filteredSearch;
    filteredSearch = users?.filter(
        (item) => {return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;}
    );

    return (
        <div>
            { loading &&
                <LoadingBackground visible={loading} />
            }
            <AlertDialog visible={dialogOpen} title={'Delete User Account?'}
                 subtitle={`Are you sure you want to delete the user ${selectedUser?.username}? `}
                 handleAccept={handleDeleteUser} handleClose={()=> setDialogOpen(false)}/>
            <LogoCard>
                <PageHeader title={`User Accounts`} subheader={`Use the green pencil icon to view a users details 
                    and the red rubbish bin icon to delete the user`}
                    showSearch={true} setQuery={ (q)=>setQuery(q) } backPath={'/'} addPath={`/new-user-account`} />
                <List>
                    {
                        filteredSearch.map((user, key) =>
                            <React.Fragment key={key}>
                            <ListItem alignItems="flex-start" key={key}>
                                <ListItemText
                                    primary={user.username}
                                    secondary={`${user['custom:first_name']} ${user['custom:last_name']}`}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip title='Edit Account'>
                                        <IconButton aria-label="Edit" component={Link} to={{
                                            pathname: `/update-user-account/${user.sub}`,
                                            state: user
                                        }}>
                                            <Edit color='primary' id={`edit-${user.username}`}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Delete Account'>
                                        <IconButton edge="end" aria-label="Delete" onClick={() => handleDeleteDialog(user)}>
                                            <DeleteIcon color='error'/>
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                            </React.Fragment>
                        )
                    }
                </List>
            </LogoCard>
        </div>
    );
}
