import React, { useState } from 'react';
// Material
import {
  Adjust,
  Eco,
  Edit,
  Loop,
  Loupe,
  NoteAdd,
  PinDrop,
  Texture,
  CheckBox,
  Info,
} from '@material-ui/icons';
import {
  Button,
  Box,
  Grid,
  IconButton,
  SvgIcon,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
// import { Autocomplete } from "@material-ui/lab";

// Components
import CycleDialog from './CycleDialog';
import FallowDialog from './FallowDialog';
import SoilSampleDialog from './SoilSampleDialog';
import CalculateDialog from './CalculateDialog';
import PlantDialog from './PlantDialog';
import HarvestDialog from './HarvestDialog';
import ToFallowDialog from './ToFallowDialog';
import ApplicationMillDialog from '../Applications/ApplicationMillDialog';
import ApplicationProductDialog from '../Applications/ApplicationProductDialog';
import NoteDialog from './NoteDialog';
import HistoricDialog from './HistoricDialog';
import ModifyDialog from './ModifyDialog';
import PloughOutDialog from './PloughOutDialog';
import ViewSoilSampleDialog from '../ViewSoilSample/ViewSoilSampleDialog';

// Queries
// import {createRequirements} from "../../graphQL/Requirement";
// import requirementApplicationMillmud from "../../graphQL/RequirementApplicationMillmud";

export default function BlockLine(props) {
  const classes = style();

  const {
    block,
    view,
    fertYear,
    agronomist,
    admin,
    // fetchBlocks,
    handleSelectBlock,
    selected,
    farmName,
  } = props;

  const [openDialog, setOpenDialog] = useState(null);

  const colData = [
    block.requirements ? block.requirements[0]?.nitrogen : '-',
    block.requirements ? block.requirements[0]?.phosphorus : '-',
    block.requirements ? block.requirements[0]?.potassium : '-',
    block.requirements ? block.requirements[0]?.sulphur : '-',
    block.requirements ? block.requirements[0]?.copper : '-',
    block.requirements ? block.requirements[0]?.zinc : '-',
    block.requirements ? block.requirements[0]?.lime_calcium : '-',
    block.requirements ? block.requirements[0]?.lime_acidic : '-',
    block.requirements ? block.requirements[0]?.gypsum : '-',
    block.requirements ? block.requirements[0]?.magnesium : '-',
    block.requirements ? block.requirements[0]?.silicate : '-',
  ];

  // let cycleUseCase = "C";
  // function openCyclePlantDialog(useCase) {
  //   cycleUseCase = useCase;
  //   setOpenDialog("Cycle");
  // }

  const actionButton = (name, icon, clickFunction, disabled = false, color = 'secondary') => {
    return (
      <Button
        disabled={disabled}
        onClick={clickFunction}
        variant='contained'
        color={color}
        className={classes.button}
        startIcon={icon}
      >
        {name}
      </Button>
    );
  };

  const requirementStage = () => {
    // CYCLE - No requirements for current year
    if (block.requirements == null || block.requirements[0]?.crop_class_id == null) {
      return actionButton('Cycle', <Loop />, () => setOpenDialog('Cycle'));
    }
    // Block in Fallow
    if (block.requirements[0].crop_class_id === 1) {
      // CONFIRM FALLOW YIELD - No fallow yield for current year
      if (block.requirements[0].fallow_yield == null)
        return actionButton('Fallow', <Texture />, () => setOpenDialog('Fallow'));
      // SOIL SAMPLE - No primary soil sample
      if (block.requirements[0].primary_sample_id == null)
        return actionButton(
          'Soil Sample',
          <SvgIcon>
            <path d='M3,3V5A2,2 0 0,1 5,7V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V7A2,2 0 0,1 21,5V3H3M7,9H10V10H7V9M7,11H10V12H7V11M10,16H7V15H10V16M12,14H7V13H12V14M12,8H7V7H12V8Z' />
          </SvgIcon>,
          () => setOpenDialog('SoilSample')
        );
      // CONFIRM MILL APPLICATIONS - No Mill apps for current year
      if (block.requirements[0].mill_mud_rate == null)
        return actionButton('Mill Application', <Loupe />, () =>
          setOpenDialog('MillApplications')
        );
    }
    // RUN CALCULATIONS
    if (
      block.requirements[0].requirement_state_id <= 2 ||
      block.requirements[0].requirement_state_id == null
    )
      return actionButton('Calculate', <CheckBox />, () => setOpenDialog('Calculate'));
    // PLANT
    if (
      block.requirements[0].crop_class_id === 2 &&
      block.requirements[0].plant_date == null
    ) {
      let daysSincePloughout = block.requirements[0].ploughout_date
        ? (new Date().getTime() - new Date(block.requirements[0].ploughout_date).getTime()) /
          (1000 * 3600 * 24)
        : 365;

      return actionButton(daysSincePloughout < 182 ? 'Replant' : 'Plant', <Eco />, () =>
        setOpenDialog('Plant')
      );
    }

    if (block.productsselected == null || block.productsselected.length === 0) {
      return actionButton(
        'Plough Out',
        <SvgIcon>
          <path d='M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z' />
        </SvgIcon>,
        () => setOpenDialog('PloughOut')
      );
    }

    if (block.requirements[0].to_fallow === null) {
      console.log(block.name, block.productsselected.find((ps) => ps.applied === null))
      return actionButton(
        'Finalise',
        <SvgIcon>
          <path d='M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z' />
        </SvgIcon>,
        () => setOpenDialog('ToFallow'),
        (block.productsselected.length === 0 || block.productsselected.find((ps) => ps.applied === null) !== undefined)
    )}

    if (block.requirements[0].yield === null) {
      return actionButton(
        'Harvest',
        <SvgIcon>
          <path d='M5,4V11.26C3.2,11.9 2,13.6 2,15.5C2,18 4,20 6.5,20C8.79,20 10.71,18.28 10.97,16H15.17C15.06,16.32 15,16.66 15,17A3,3 0 0,0 18,20A3,3 0 0,0 21,17C21,16.66 20.94,16.32 20.82,16H22V13C22,11.89 21.11,11 20,11H15.04L13.65,4H5M7,6H12L13,11V14H10.74C10.16,12.38 8.71,11.23 7,11.03V6M6.5,13.25A2.25,2.25 0 0,1 8.75,15.5A2.25,2.25 0 0,1 6.5,17.75A2.25,2.25 0 0,1 4.25,15.5A2.25,2.25 0 0,1 6.5,13.25M18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5Z' />
        </SvgIcon>,
        () => setOpenDialog('Harvest')
      );
    }
    return (
      <Box alignItems='center' display='flex'>
        <Box>
          <Typography>Yield {block.requirements[0].yield} t/ha</Typography>
          <Tooltip title='Agronomic Efficency of Fertiliser N'>
          <Typography>AgronEff-N:
            {Math.round(
              (block.requirements[0].yield /
                block.productsselected?.reduce(
                  (prevProd, curProd) =>
                    prevProd +
                    ((curProd.applied > 0 ? curProd.n : 0) * (curProd.apprate || 0)) / 100,
                  0
                )) *
                100
            ) / 100}
          </Typography>
          </Tooltip>
        </Box>
        {(agronomist || admin) && <Edit onClick={() => setOpenDialog('Harvest')} />}
      </Box>
    );
  };

  // console.log(block);

  return (
    <>
      {openDialog === 'Cycle' && (
        <CycleDialog
          visible={openDialog === 'Cycle'}
          fertYear={fertYear}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Fallow' && (
        <FallowDialog
          visible={openDialog === 'Fallow'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Calculate' && (
        <CalculateDialog
          visible={openDialog === 'Calculate'}
          fertYear={fertYear}
          block={block}
          // fetchBlocks={fetchBlocks}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'SoilSample' && (
        <SoilSampleDialog
          visible={openDialog === 'SoilSample'}
          block={block}
          farmName={farmName}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'ViewSoilSample' && (
        <ViewSoilSampleDialog
          visible={openDialog === 'ViewSoilSample'}
          blockName={block.name}
          sampleId={block.primary_sample_id}
          farmId={block.farm_id}
          requirement={block.requirements[0]}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Plant' && (
        <PlantDialog
          visible={openDialog === 'Plant'}
          fertYear={fertYear}
          block={block}
          handleClose={(e) => setOpenDialog(e)}
        />
      )}
      {openDialog === 'Harvest' && (
        <HarvestDialog
          visible={openDialog === 'Harvest'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'ToFallow' && (
        <ToFallowDialog
          visible={openDialog === 'ToFallow'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'PloughOut' && (
        <PloughOutDialog
          visible={openDialog === 'PloughOut'}
          block={block}
          fertYear={fertYear}
          handleClose={() => setOpenDialog(null)}
        />
      )}

      {openDialog === 'Fallow' && (
        <FallowDialog
          visible={openDialog === 'Fallow'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Applications' && (
        <ApplicationProductDialog
          visible={openDialog === 'Applications'}
          blockName={block.name}
          products={block.productsselected.map((product) => ({
            ...product,
            blockid: block.id,
            blockarea: block.hectares,
            disableChanges: block.requirements[0].to_fallow !== null,
          }))}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'MillApplications' && (
        <ApplicationMillDialog
          visible={openDialog === 'MillApplications'}
          blocks={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Modify' && (
        <ModifyDialog
          visible={openDialog === 'Modify'}
          block={block}
          fertYear={fertYear}
          // fetchBlocks={fetchBlocks}
          handleClose={(nextPage) => setOpenDialog(nextPage)}
        />
      )}
      {openDialog === 'Note' && (
        <NoteDialog
          visible={openDialog === 'Note'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      {openDialog === 'Historic' && (
        <HistoricDialog
          visible={openDialog === 'Historic'}
          block={block}
          handleClose={() => setOpenDialog(null)}
        />
      )}
      <TableRow
        hover
        tabIndex={-1}
        key={block.id}
        style={{
          backgroundColor: selected
            ? 'rgba(76, 175, 80, 0.2)'
            : block.decommission_date && (agronomist || admin)
            ? 'rgba(255, 0, 0, 0.2)'
            : null,
        }}
      >
        <TableCell component='th' scope='row'>
          <Grid container spacing={2}>
            <Grid item xs container direction='column' spacing={2}>
              <Grid item xs>
                {`${block.name}`}
              </Grid>
              <Grid item xs>
                {`${Math.round(block.hectares * 100) / 100}ha`}
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={{padding: '0px'}}>
          {view === 0 && block.requirements && block.requirements.length > 0 && (
            <Checkbox
              checked={selected}
              color='primary'
              onChange={() => handleSelectBlock(block.id)}
            />
          )}
        </TableCell>
        <TableCell style={{padding: '0px'}}>
          {(agronomist || admin) && block.decommission_date && (
            <Tooltip
              title={`Block was modified in ${block.decommission_date.substring(0, 4)}`}
            >
              <Info />
            </Tooltip>
          )}
        </TableCell>
        {view === 0 ? (
          <TableCell style={{padding: '0px'}}>
            <Grid>
              <Grid item>
                <Tooltip title='Open Map' className={classes.actionIcon}>
                  <IconButton
                    aria-label='View'
                    variant='link'
                    target={'_t'}
                    href={`https://www.google.com/maps/place/${block.lat},${block.lng}/@${block.lat},${block.lng},466m/data=!3m1!1e3!4m5!3m4!7e2!8m2!3d${block.lat}!4d${block.lng}`}
                  >
                    <PinDrop color='primary' id={`map_icon_${block.id}`} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={block.dronedeploy_link ? 'Drone deploy' : 'Drone deploy DEMO'}
                  className={classes.actionIcon}
                >
                  <IconButton
                    aria-label='View'
                    variant='link'
                    target={'_t'}
                    href={
                      block.dronedeploy_link
                        ? block.dronedeploy_link
                        : 'https://www.dronedeploy.com/app2/data/5cc64de2ef14a648f98388c3?jwt_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1MzQwMjMwMDc5OSwiaWQiOiI1Y2M2NGRlMmVmMTRhNjQ4Zjk4Mzg4YzMiLCJzY29wZSI6WyI4NmFhOGM5ZTdjX0Q1NkRFQjM1QTlPUEVOUElQRUxJTkUiXSwidHlwZSI6IlJlYWRPbmx5UGxhbiIsIm92ZXJsYXlfZm9sZGVyX2lkIjoiNWNiZDRhNzNjMWUwMWUwMDAxZjEwMjUzIn0.jM3puegJhAvkfBt5rTDbSP2VINOMqu6JZx6LU1tBzlQu1JAttEQJkr5VUqbte_skdzxwTXHWw0gOrxwfieqMAw'
                    }
                  >
                    <Adjust color='primary' id={`drone_icon_${block.id}`} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
        ) : null}
        <TableCell component='th' scope='row' padding='normal'>
          {block.requirements && block.requirements.length > 0
            ? block.requirements[0].crop_class_name
            : 'Not In Cycle'}
        </TableCell>
        <TableCell component='th' scope='row' padding='normal'>
          {block.requirements && block.requirements.length > 0
            ? block.requirements[0].variety_name
            : ''}
        </TableCell>
        {view === 0 ? (
          <>
            <TableCell component='th' scope='row' padding='normal'>
              {block.requirements?.length > 0 &&
              block.requirements[0].primary_sample_id &&
              block.requirements[0].primary_sample_id !== null &&
              block.soil_samples?.length > 0
                ? (<>
                <IconButton onClick={() => setOpenDialog('ViewSoilSample')}>
                <SvgIcon id={`sample_icon_${block.id}`} color="primary">
                            <path d="M3,3V5A2,2 0 0,1 5,7V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V7A2,2 0 0,1 21,5V3H3M7,9H10V10H7V9M7,11H10V12H7V11M10,16H7V15H10V16M12,14H7V13H12V14M12,8H7V7H12V8Z" />
                          </SvgIcon>
                </IconButton>
                {`${
                  block.soil_samples.find(
                    (ss) => ss.id === parseInt(block.requirements[0].primary_sample_id)
                  )?.lab_sample_number
                }`}
                </>)
                : 'N/A'}
            </TableCell>
            <TableCell component='th' scope='row' padding='normal'>
              {(admin || agronomist) && requirementStage()}
            </TableCell>
            <TableCell component='th' scope='row' padding='normal'>
              {actionButton(
                'Applications',
                <Loupe />,
                () => setOpenDialog('Applications'),
                !(block.productsselected && block.productsselected.length > 0) ||
                  block.requirements[0]?.crop_class_id < 2,
                block.productsselected &&
                  (block.productsselected.filter((ps) => ps.applied === block.hectares)
                    .length === block.productsselected.length ||
                    (block.requirements && block.requirements[0]?.to_fallow !== null))
                  ? 'primary'
                  : 'secondary'
              )}
            </TableCell>
            <TableCell component='th' scope='row' padding='normal'>
              {actionButton('Note', <NoteAdd />, () => setOpenDialog('Note'))}
            </TableCell>
            <TableCell component='th' scope='row' padding='normal'>
              {admin || agronomist
                ? actionButton('Modify', <Edit />, () =>
                    block.requirementCount > 0
                      ? setOpenDialog('Modify')
                      : setOpenDialog('Historic')
                  )
                : null}
            </TableCell>
          </>
        ) : (
          colData.map((element, index) => (
            <TableCell component='th' scope='row' padding='normal' key={index}>
              {element}
            </TableCell>
          ))
        )}
      </TableRow>
    </>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginLeft: 20,
  },
  listItem: {
    height: 400,
  },
  actionDiv: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  root: {
    height: '100%',
  },
  select: {
    backgroundColor: 'rgba( 255,255,255, 0.6)',
  },
  selectIcon: {
    color: '#09bd2f',
  },
}));
