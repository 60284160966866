import React, { memo } from 'react';
// Charts
import { Doughnut, Pie } from 'react-chartjs-2';

import Box from '@material-ui/core/Box';

const getKeypair = (filteredBlocks, element, unknownItemLabel) => {
  return filteredBlocks
    .map((block) => ({
      itemName:
        block.requirements && block.requirements[0] && block.requirements[0][element]
          ? block.requirements[0][element]
          : unknownItemLabel,
    }))
    .reduce((acc, o) => {
      acc[o.itemName] = (acc[o.itemName] || 0) + 1;
      return acc;
    }, {});
};

const chartData = (chartLabel, filteredBlocks, element, unknownItemLabel) => {
  const data = {
    labels: Object.keys(getKeypair(filteredBlocks, element, unknownItemLabel)),
    datasets: [
      {
        label: chartLabel,
        backgroundColor: [
          '#9575CD',
          '#7986CB',
          '#64B5F6',
          '#4FC3F7',
          '#4DD0E1',
          '#4DB6AC',
          '#81C784',
          '#AED581',
          '#DCE775',
        ],
        hoverBackgroundColor: [
          '#673AB7',
          '#3F51B5',
          '#2196F3',
          '#03A9F4',
          '#00BCD4',
          '#009688',
          '#4CAF50',
          '#8BC34A',
          '#CDDC39',
        ],
        data: Object.values(getKeypair(filteredBlocks, element, unknownItemLabel)),
      },
    ],
  };
  return data;
};

const Charts = ({ filteredBlocks }) => {
  return (
    <Box display='flex' alignItems='center' flexWrap='wrap'>
      <Box
        display='flex'
        alignItems='center'
        width={'50%'}
        paddingLeft={'5px'}
        paddingY={'5px'}
      >
        <Pie
          data={chartData('Crop Stage', filteredBlocks, 'crop_class_name', 'Fallow')}
          width={350}
          height={200}
          options={{
            rotation: 270,
            circumference: 180,
            maintainAspectRatio: false,
          }}
        />
      </Box>{' '}
      <Box
        display='flex'
        alignItems='center'
        width={'50%'}
        paddingLeft={'5px'}
        paddingY={'5px'}
      >
        <Doughnut
          data={chartData('Variety', filteredBlocks, 'variety_name', 'Unknown / Fallow')}
          width={350}
          height={200}
          options={{
            rotation: 270,
            circumference: 180,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'right',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default memo(Charts);
