// Select report page

import "./main.css";

import { API, graphqlOperation } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";

import FormButton from "../../components/FormButton";
import FormSelect from "../../components/FormSelect";
import FormikForm from "../../components/FormikForm";
import LoadingBackground from "../../components/LoadingBackground";
import PageHeader from "../../components/PageHeader";
import { listReports } from "../../graphQL/Report";
import { useAlertContext } from "../../components/AlertContext";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

export default function ReportsList(props) {
  const [loading, setLoading] = useState(true);
  let date = new Date();
  const year = date.getFullYear();
  const farmId = props.match.params.name;
  const farmName = props.match.params.name;
  const history = useHistory();

  const alertContext = useAlertContext();

  const [ options ] = useState({ farmId: farmId, year: year });
  const [reports, setReports] = useState([]);

  const fetchReportsList = useCallback(() => {
    setLoading(true);
    API.graphql(
      graphqlOperation(listReports.query, { data: JSON.stringify(options) })
    )
      .then((result) => {
        const sortedReports = JSON.parse(result.data.listReports).sort(function(a, b) {
          return (a.timestamp > b.timestamp) ? -1 : ((a.timestamp < b.timestamp) ? 1 : 0);
      });
        setReports(sortedReports);
        console.log(sortedReports);
        setLoading(false);
      })
      .catch(async (err) => {
        console.log(err);
        setLoading(false);
        alertContext.error(err, "Error fetching reports list");
      });
  }, [options, alertContext]);

  useEffect(() => {
    fetchReportsList();
  }, [fetchReportsList]);

  const formik = useFormik({
    initialValues: {
      reportsAvailable: reports.length ? reports[0].name.slice(0, -5) : "",
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      setLoading(true);
      console.log(values);
      const data = {
        report: values.reportsAvailable,
      };
      if (data.report !== "") {
        setLoading(false);
        history.push(`/viewreport/${data.report}`); //${farmId}/${year}/
        actions.setSubmitting(false);
      } else {
        setLoading(false);
        const err = "Failed to Submit";
        actions.setSubmitting(false);
        alertContext.error(err, `Please Select a Report`);
      }
    },
  });

  const reportsOptions = reports.map((r) => ({
    name: `${r.name.split("/")[1]} - ${
      r.name.split("/")[2].charAt(0) === "F" ? "Final" : "Preliminary"
    } - by ${r.name.split("-")[3].slice(0, -5)} (${r.timestamp
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/")})`,
    value: r.name.slice(0, -5),
  }));

  return (
    <>
      <LoadingBackground visible={loading} />
      <PageHeader
        title={"Select Farm NMP Report"}
        subheader={`Viewing Reports for ${farmName}`}
        backPath={"back"}
      />
      <div className="reportList">
        <FormikForm formik={formik}>
          <FormSelect
            name="reportsAvailable"
            label="Reports:"
            options={reportsOptions}
          />
          <FormButton text="View Report" />
        </FormikForm>
      </div>
    </>
  );
}
