import React, { useCallback, useState } from 'react';
import { useAlertContext } from './AlertContext';
import { useSnackbar } from 'notistack';
import { UploadCSVDialog } from './FarmOverview/UploadCSVDialog/UploadCSVDialog';
import { FileDropZone } from './FileDropZone';
import * as excel from '../functions/excel';

export default function UploadFarm(props) {
  const { onUploaded } = props;

  const alertContext = useAlertContext();
  const { enqueueSnackbar } = useSnackbar();

  const [uploadedFarms, setUploadedFarms] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleFilesDropped = useCallback(
    async (files) => {
      if (!files) {
        alertContext.error(`Invalid files`);
        return;
      }

      try {
        const filesArray = Array.from(files);
        const farmDataArray = await Promise.all(
          filesArray.flatMap((file, index) => {
            if (file.type !== 'application/vnd.ms-excel') {
              console.error('Incorrect file type', { file });
              enqueueSnackbar(`${file.name} is the Incorrect file type`, {
                variant: 'error',
              });
              return [];
            }

            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = async function (e) {
                const farmSheet = excel.parse(e.target.result);
                const sheetData = {
                  farms: farmSheet.nthSheet(0),
                  blocks: farmSheet.nthSheet(1),
                  users: farmSheet.nthSheet(2),
                  samples: farmSheet.nthSheet(3),
                };

                const sanitiseDateStr = (dateStr) => {
                  try {
                    const regex =
                      /^\s*(?<day>[0-9]+)\s*\/\s*(?<month>[0-9]+)\s*\/\s*(?<year>[0-9]+)\s*$/;
                    const match = regex.exec(dateStr);
                    if (match === null || match.groups === undefined)
                      return { date: undefined, str: undefined };

                    const { day, month, year } = match.groups;
                    const date = new Date(`${month}/${day}/${year}`);
                    // NOTE: `isNaN()` will return true if Date is invalid.
                    if (isNaN(date)) return { date: undefined, str: undefined };

                    return {
                      date,
                      str: date.toLocaleDateString('EN-AU'),
                    };
                  } catch (err) {
                    // TODO: Sentry?
                    console.error(
                      `An error occured during Farm upload, while parsing the Date ('${dateStr}').`,
                      err
                    );
                    return { date: undefined, str: undefined };
                  }
                };

                sheetData.farms.forEach((farm) => {
                  farm.Name = farm.Name.trim();
                  farm.Description = farm.Description.trim();
                  farm.Regions = farm.Regions.trim();
                });

                sheetData.blocks.forEach((block) => {
                  block.Farm = block.Farm.trim();
                  block.Name = block.Name.trim();
                });

                console.log(sheetData.blocks);

                // Sanitise Sample / Plant Dates / Trim white space
                sheetData.samples.forEach((sample) => {
                  const { date: cleanSampleDateObject, str: cleanSampleDate } =
                    sanitiseDateStr(sample['Sample date']);
                  sample.cleanSampleDate = cleanSampleDate;
                  sample.cleanSampleDateObject = cleanSampleDateObject;

                  const { date: cleanPlantDateObject, str: cleanPlantDate } =
                    sanitiseDateStr(sample['Plant Date']);
                  sample.cleanPlantDate = cleanPlantDate;
                  sample.cleanPlantDateObject = cleanPlantDateObject;

                  sample['Soil test number'] = sample['Soil test number'].trim();
                  sample['Farm no.'] = sample['Farm no.'].trim();
                  sample['Block no.'] = sample['Block no.'].trim();
                  sample['Soil texture'] = sample['Soil texture'] && sample['Soil texture'].trim();
                  sample['Crop class at sampling'] = sample['Crop class at sampling'] && sample['Crop class at sampling'].trim();
                  sample['Variety'] = sample['Variety'] && sample['Variety'].trim();
                  sample['Sample Description'] = sample['Sample Description'] && sample['Sample Description'].trim();
                });

                const farmData = sheetData.farms.map((farm) => ({
                  name: farm.Name,
                  region: farm.Regions,
                  description: farm.Description,
                  blocks: sheetData.blocks.filter((b) => b.Farm === farm.Name),
                  samples: sheetData.samples.filter(
                    (s) => s['Farm no.'] === farm.Name
                  ),
                  users: sheetData.users.filter((u) => u.Farms === farm.Name),
                }));

                resolve([...farmData]);
              };
              reader.readAsBinaryString(files[index]);
            });
          })
        );
        setUploadedFarms(farmDataArray.flat());
        setOpenDialog(true);
      } catch (err) {
        console.log('Error', err);
        enqueueSnackbar(`Error Reading Spreadsheets`, {
          variant: 'error',
        });
      }
    },
    [alertContext, enqueueSnackbar]
  );

  const handleOnClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  return (
    <>
      <FileDropZone
        label={'Drag files here to upload new Farms'}
        onDrop={handleFilesDropped}
      />
      {openDialog && uploadedFarms?.length > 0 && (
        <UploadCSVDialog
          data={uploadedFarms}
          onUploaded={onUploaded}
          onClose={handleOnClose}
        />
      )}
    </>
  );
}
