import { APIFetch } from '../../fetch.js';

export const requirementHarvest = async ({
  token,
  requirementId,
  yield: requirementYield,
  harvestDate,
  plantDate,
  nue,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementHarvest',
    method: 'POST',
    authToken: token,
    body: {
      requirementId,
      yield: requirementYield,
      harvestDate,
      plantDate,
      nue,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementHarvest request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
