import JSZip from 'jszip';
import { getFilePathInfo } from './filePath';

export const extractFilesFromZip = async (zipFile, options = {}) => {
  const { recursive = false } = options;

  const zipData = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (ev) => {
      try {
        const zipData = await JSZip.loadAsync(ev.target.result);
        resolve(zipData);
      } catch (err) {
        reject(err);
      }
    };
    reader.readAsArrayBuffer(zipFile);
  });

  const { files: fileMap } = zipData;
  const files = Object.entries(fileMap).map(([_, file]) => file);

  const filteredFiles = files.filter((file) => {
    if (file.dir === true) return false;

    const { directory } = getFilePathInfo(file.name);
    if (recursive === false && directory !== undefined) return false;

    return true;
  });

  const result = filteredFiles.map((file) => ({
    filePath: file.name,
    read: async () => {
      const blob = await file.async('blob');
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
          try {
            resolve(ev.target.result);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsArrayBuffer(blob);
      });
    },
  }));

  return result;
};
