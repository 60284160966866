import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const MapViewLegend = () => {
  return (
    <Paper
      style={{
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
        paddingLeft: '10px',
      }}
    >
      <Typography variant='h6' style={{ marginTop: '10px' }}>
        Legend
      </Typography>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            color: '#f00',
            backgroundColor: '#f00',
            height: 5,
            width: 25,
            marginRight: 10,
          }}
        />
        <Typography>Actions Available</Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            color: '#0f0',
            backgroundColor: '#0f0',
            height: 5,
            width: 25,
            marginRight: 10,
          }}
        />
        <Typography>Actions Completed</Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            color: '#00f',
            backgroundColor: '#00f',
            height: 5,
            width: 25,
            marginRight: 10,
          }}
        />
        <Typography>Blocks Selected</Typography>
      </Box>
    </Paper>
  );
};
