import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useCallback, useState } from 'react';

export const CollapsableListItem = ({ children, ...props }) => {
  const { startOpen, ...restProps } = props;

  const [isOpen, setIsOpen] = useState(startOpen ?? false);

  const handleOnClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <ListItem button onClick={handleOnClick}>
        <ListItemText {...restProps} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <ListItem>
        <Collapse in={isOpen}>{children}</Collapse>
      </ListItem>
    </>
  );
};
