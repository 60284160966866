const sortProductsByAppTiming = (productArray) => {
    if (productArray.length === 0) return [];
    const indexedProducts = productArray.map((product, index) => ({
        ...product,
        originalIndex: index,
    }));
    if (productArray.length === 1) return indexedProducts;
    const sortedProducts = [
        ...indexedProducts.filter((product) => product.apptiming === 'Pre Planting'),
        ...indexedProducts.filter((product) => product.apptiming === 'At Planting'),
        ...indexedProducts.filter((product) => product.apptiming === 'Before out of hand'),
        ...indexedProducts.filter((product) => product.apptiming === 'Standing Crop'),
    ];
    return sortedProducts;
};

export { sortProductsByAppTiming };
