import React, { useCallback, useEffect, useState, useMemo } from 'react';
// import { API, graphqlOperation } from "aws-amplify";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
// import Paper from "@material-ui/core/Paper";
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  LayersControl,
  LayerGroup,
  SVGOverlay,
} from 'react-leaflet';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
// import domtoimage from "dom-to-image";
import { Link } from 'react-router-dom';

// import { getMapLayers } from "../graphQL/Farm"; //, getLayerProperties, getLayerCadastre, getLayerSoil
// import LoadingBackground from "../components/LoadingBackground";
// import { useAlertContext } from "../components/AlertContext";
import PageHeader from '../components/PageHeader';
import ApplicationProductDialog from '../components/Applications/ApplicationProductDialog';
import { HarvestMapDataLayer } from '../components/FarmMap/HarvestMapDataLayer';
import { EMMapDataLayer } from '../components/FarmMap/EMMapDataLayer';
// import MapLegend from '../components/FarmMap/MapLegend';
import {
  Paper,
  Checkbox,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

import { qualitativeColorArray } from '../functions/colours';
import { filterFertYear } from '../functions/filterDataForFertYear';
// import RolloverDialog from '../components/FarmMap/RolloverDialog';

import { getFarmBlocks } from '../graphQL/Farm';

const { BaseLayer, Overlay } = LayersControl;

// const filters = [
//   { title: "Crop class", filter: "properties.requirements.crop_class_id" },
//   {
//     title: "Requirement: nitrogen",
//     filter: "properties.requirements.nitrogen",
//   },
//   { title: "Sample: Ph", filter: "properties.soil_sample.ph_1_5_water" },
//   { title: "Nutrient Groups", filter: "properties.nutrient_group" },
// ];

const useStyles = makeStyles((theme) => ({
  blockLabel: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    border: 0,
    color: 'white',
  },
  oldBlockShape: {
    fill: 'red',
    stroke: 'red',
    '&:hover': {
      fill: 'orange',
      stroke: 'orange',
    },
  },
  newBlockShape: {
    fill: 'blue',
    stroke: 'blue',
    '&:hover': {
      fill: 'green',
      stroke: 'green',
    },
  },
  modifiedBlockShape: {
    fill: 'orange',
    stroke: 'orange',
    '&:hover': {
      fill: 'yellow',
      stroke: 'yellow',
    },
  },
}));

export default function FarmMap(props) {
  const classes = useStyles();
  const { fertYear } = props.location.state;
  const farmId = props.location.state.mapData.farmId;
  const farmName = props.location.state.mapData.farmName;
  const [selectedBlocks, setSelectedBlocks] = useState([]);

  // const [farmBlocks] = useState(props.location.state.mapData);
  const [farmBlocks, setFarmBlocks] = useState();

  const { data: ngBlock, isLoading: loading } = useQuery({
    queryKey: ['maps', farmId],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getFarmBlocks.query, { farmId: farmId })
      );
      const ngBlock = JSON.parse(result.data.getFarmBlocks);

      // console.log('BOOM!!! GOT THIS AGAIN...');

      return ngBlock;
    },
  });

  useEffect(() => {
    const showMap = (farmName, farmId, filteredBlocks) => {
      const mapData = {
        type: 'FeatureCollection',
        farmName: farmName,
        farmId: farmId,
      };

      if (filteredBlocks && filteredBlocks.length > 0) {
        mapData.features = filteredBlocks.map((block) => {
          let geo = {
            ...block.geometry,
            bbox: [
              block.bbox.coordinates[0][0][0],
              block.bbox.coordinates[0][0][1],
              block.bbox.coordinates[0][2][0],
              block.bbox.coordinates[0][2][1],
            ],
            // coordinates: block.geometry.coordinates,
          };
          const res = {
            type: 'Feature',
            geometry: geo,
            properties: {
              name: block.name,
              id: block.id,
              hectares: block.hectares,
              soilSampleId: block.primary_sample_id,
              products: block.productsselected,
              selected: false,
            },
          };
          res.properties.requirements =
            block.requirements && block.requirements ? block.requirements[0] : null;
          res.properties.nutrient_group =
            block.productsselected && block.productsselected.length
              ? block.productsselected[0].nutrient_group
              : null;
          res.properties.soil_sample =
            block.soil_samples && block.soil_samples ? block.soil_samples[0] : null;
          return res;
        });
      }
      // console.log(mapData);
      return mapData;
    };

    if (ngBlock?.length > 0) {
      const filteredBlocks = filterFertYear(ngBlock, fertYear);
      const mapData = showMap(farmName, farmId, filteredBlocks);
      console.log(mapData);
      setFarmBlocks(mapData);
    }
  }, [farmId, farmName, fertYear, ngBlock]);

  const [dataLayerSelect, setDataLayerSelect] = useState(null);

  const [layerOptions, setLayerOptions] = useState([]);
  const [layerSelection, setLayerSelection] = useState(new Set());

  const [harvestLegend, setHarvestLegend] = useState(null);
  const [emMapLegend, setEMLegend] = useState(null);

  const [productToShow, setProductToShow] = useState();
  const [productGroups, setProductGroups] = useState([]);

  const style = useCallback(
    (feature) => {
      // console.log(feature.properties);
      // Calculate the area products have not been applied to
      const areaNotApplied = feature.properties.products
        ? feature.properties.products.reduce((acc, product) => {
            return acc + (feature.properties.hectares - product.applied || 0);
          }, 0) || 0
        : feature.properties.hectares;
      return selectedBlocks.some((id) => id === feature.properties.id)
        ? {
            weight: 4,
            opacity: 1,
            color: feature.properties.products ? 'blue' : 'green',
            fillColor: qualitativeColorArray[productGroups.indexOf(productToShow)],
            fillOpacity: (feature.properties.products || []).some(
              (product) => `${product.name} @ ${product.apprate}kg/ha` === productToShow
            )
              ? 1
              : 0,
          }
        : {
            weight: 2,
            opacity: 1,
            color: feature.properties.products && areaNotApplied ? 'red' : 'green',
            fillColor: qualitativeColorArray[productGroups.indexOf(productToShow)],
            fillOpacity: (feature.properties.products || []).some(
              (product) => `${product.name} @ ${product.apprate}kg/ha` === productToShow
            )
              ? 1
              : 0,
          };
    },
    [selectedBlocks, productToShow, productGroups]
  );

  const displayBlockInfo = useCallback(() => {
    if (selectedBlocks.length === 0)
      return (
        <>
          <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
            Block Information
          </Typography>
          <Typography style={{ marginLeft: '10px' }}>
            Click a block to view information
          </Typography>
        </>
      );
    if (selectedBlocks.length === 1) {
      const blockInfo = farmBlocks.features.find(
        (block) => block.properties.id === selectedBlocks[0]
      ).properties;
      console.log(blockInfo);
      return (
        <>
          <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
            {`Block ${blockInfo.name} Information`}
          </Typography>
          <Typography
            style={{
              flexDirection: 'row',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            {`Area: ${blockInfo.hectares}ha `}
            {blockInfo.requirements
              ? `(${blockInfo.requirements.crop_class_name})`
              : '(Not in cycle)'}
          </Typography>

          {blockInfo.products?.length > 0
            ? blockInfo.products.map((product) => {
                // console.log(product, productToShow);
                return `${product.name} @ ${product.apprate}kg/ha` === productToShow ? (
                  <Paper
                    key={product.id}
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      backgroundColor:
                        qualitativeColorArray[productGroups.indexOf(productToShow)],
                    }}
                  >
                    <Typography style={{ marginLeft: '5px' }}>
                      {product.name} @ {product.apprate}kg/ha
                      {blockInfo.hectares - product.applied > 0 ? (
                        ` = ${Math.round(
                          product.apprate * (blockInfo.hectares - product.applied || 0)
                        )}kg`
                      ) : (
                        <Tooltip title='Applied'>
                          <CheckCircleIcon fontSize='small' color='primary' />
                        </Tooltip>
                      )}
                    </Typography>
                  </Paper>
                ) : (
                  <Typography key={product.id} style={{ marginLeft: '15px' }}>
                    {product.name} @ {product.apprate}kg/ha
                    {blockInfo.hectares - product.applied > 0 ? (
                      ` = ${Math.round(
                        product.apprate * (blockInfo.hectares - product.applied || 0)
                      )}kg`
                    ) : (
                      <Tooltip title='Applied'>
                        <CheckCircleIcon fontSize='small' color='primary' />
                      </Tooltip>
                    )}
                  </Typography>
                );
              })
            : null}
          {/* <Button
            style={{
              marginTop: '10px',
              marginLeft: '10px',
              marginBottom: '10px',
            }}
            color='secondary'
            variant='contained'
            // disabled={selectedBlocks.length <= 0}
            onClick={() => setOpenDialog('ROLLOVER')}
          >
            MODIFY
          </Button> */}
        </>
      );
    }
    // Add up the total area of the selected blocks and calculate the kg amount of fertiliser per product required
    const totalArea =
      Math.round(
        selectedBlocks.reduce((acc, blockId) => {
          const block = farmBlocks.features.find((block) => block.properties.id === blockId);
          return acc + block.properties.hectares;
        }, 0) * 100
      ) / 100;
    const totalProducts = selectedBlocks.reduce((acc, blockId) => {
      const block = farmBlocks.features.find((block) => block.properties.id === blockId);
      return block.properties.products
        ? acc.concat(
            block.properties.products.map((product) => ({
              ...product,
              hectares: block.properties.hectares,
            }))
          )
        : acc;
    }, []);
    // console.log(totalProducts)
    const totalProductsMap = totalProducts.reduce((acc, product) => {
      const key = `${product.name} @ ${product.apprate}kg/ha`;
      if (acc.has(key)) {
        acc.set(
          key,
          acc.get(key) +
            Math.round(product.apprate * (product.hectares - product.applied || 0))
        );
      } else {
        acc.set(key, Math.round(product.apprate * (product.hectares - product.applied || 0)));
      }
      return acc;
    }, new Map());
    // console.log(totalProductsMap)
    const totalProductsArray = [...totalProductsMap.entries()];

    // console.log(totalProductsArray)

    return (
      <>
        <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
          Selection Information
        </Typography>
        <Typography style={{ marginLeft: '10px' }}>
          {`${selectedBlocks.length} Blocks - Total Area: ${totalArea}ha`}
        </Typography>
        <Typography></Typography>
        {totalProductsArray.map((product) => {
          return product[0] === productToShow ? (
            <Paper
              key={`product_${product[0]}`}
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: qualitativeColorArray[productGroups.indexOf(productToShow)],
              }}
            >
              <Typography style={{ marginLeft: '5px' }}>
                {`${product[0]} = ${product[1]}kg`}
              </Typography>
            </Paper>
          ) : (
            <Typography key={`product_${product[0]}`} style={{ marginLeft: '15px' }}>
              {`${product[0]} = ${product[1]}kg`}
            </Typography>
          );
        })}
      </>
    );
  }, [farmBlocks?.features, productGroups, productToShow, selectedBlocks]);

  // const highlightFeature = useCallback(
  //   (e) => {
  //     setHighlighted(e.target.feature.properties);
  //   },
  //   [setHighlighted]
  // );

  // const resetHighlight = useCallback(
  //   (e) => {
  //     setHighlighted(null);
  //   },
  //   [setHighlighted]
  // );

  // Add's them to selected stack
  const handleClick = useCallback(
    (e) => {
      // setHighlighted(e.target.feature.properties);
      const index = selectedBlocks.indexOf(e.target.feature.properties.id);
      if (index >= 0) {
        setSelectedBlocks((current) => {
          const newSelectedBlocks = [...current];
          newSelectedBlocks.splice(index, 1);
          return newSelectedBlocks;
        });
      } else {
        setSelectedBlocks((current) => [...current, e.target.feature.properties.id]);
      }
      e.target.setStyle(style(e.target.feature));
    },
    [selectedBlocks, style]
  );

  const onEachFeature = useCallback(
    (feature, layer) => {
      // console.log(feature.properties);
      layer.on({
        // mouseover: highlightFeature,
        // mouseout: resetHighlight,
        click: handleClick,
      });
      layer
        .bindTooltip(feature.properties.name, {
          permanent: true,
          direction: 'center',
          className: classes.blockLabel,
        })
        .openTooltip();
    },
    [classes, handleClick] //highlightFeature, resetHighlight
  );

  const mapPosition = useMemo(() => {
    if (!farmBlocks || farmBlocks?.features.length === 0) return { x: 0, y: 0 };
    console.log(farmBlocks);
    let bbox = { ...farmBlocks.features[0].geometry.bbox };
    farmBlocks.features.forEach((feature) => {
      // Calc bounding box max/min
      if (feature.geometry.bbox[0] < bbox[0]) bbox[0] = feature.geometry.bbox[0];
      if (feature.geometry.bbox[1] < bbox[1]) bbox[1] = feature.geometry.bbox[1];
      if (feature.geometry.bbox[2] > bbox[2]) bbox[2] = feature.geometry.bbox[2];
      if (feature.geometry.bbox[3] > bbox[3]) bbox[3] = feature.geometry.bbox[3];
    });

    return {
      x: bbox[3] + (bbox[1] - bbox[3]) / 2,
      y: bbox[0] + (bbox[2] - bbox[0]) / 2,
    };
  }, [farmBlocks]);

  useEffect(() => {
    if (!farmBlocks || farmBlocks?.features.length === 0) return;
    const productGroups = new Set([]);
    farmBlocks.features.forEach((block) => {
      if (block.properties.products === undefined) return;
      block.properties.products.forEach((product) =>
        productGroups.add(`${product.name} @ ${product.apprate}kg/ha`)
      );
    });

    const sortedProductGroups = [...productGroups].sort();

    // console.log(sortedProductGroups);

    if (sortedProductGroups.length > 0) {
      setLayerOptions((prev) => [...prev, 'Products']);
    }
    setProductGroups([...sortedProductGroups]);
  }, [farmBlocks?.features]);

  const [openDialog, setOpenDialog] = useState(null);
  const groupApplications = useCallback(() => {
    let selectedBlockData = farmBlocks?.features.flatMap((feature) => {
      if (feature.properties.products === undefined) return [];
      if (!selectedBlocks.some((id) => id === feature.properties.id)) return [];

      return feature.properties.products.map((product) => ({
        ...product,
        blockname: feature.properties.name,
        blockid: feature.properties.id,
        blockarea: feature.properties.hectares,
        disableChanges: feature.properties.requirements.to_fallow !== null,
      }));
    });
    return selectedBlockData;
  }, [farmBlocks?.features, selectedBlocks]);

  const [map, setMap] = useState(null);

  const onCheckBoxChange = (status, layer) => {
    if (layer === 'Products') setProductToShow(status ? productGroups[0] : null);
    status
      ? setLayerSelection((prev) => new Set(prev.add(layer)))
      : setLayerSelection((prev) => new Set([...prev].filter((x) => x !== layer)));
  };



  return (
    <>
      {/* {openDialog === 'ROLLOVER' && (
        <RolloverDialog
          visible={openDialog === 'ROLLOVER'}
          blockId={selectedBlocks[0]}
          fertYear={fertYear}
          // fetchBlocks={fetchBlocks}
          handleClose={(nextPage) => setOpenDialog(nextPage)}
        />
      )} */}
      {openDialog === 'APPLICATIONS' && (
        <ApplicationProductDialog
          visible={openDialog === 'APPLICATIONS'}
          blockName={'Group'}
          products={groupApplications() || null}
          handleClose={() => setOpenDialog(null)}
          productToShow={{
            product: productToShow,
            color: qualitativeColorArray[productGroups.indexOf(productToShow)],
          }}
        />
      )}
      <PageHeader
        title={'Farm Overview'}
        subheader={`Viewing Blocks for ${farmName} - ${fertYear}`}
        backPath={`/view-farm-overview/${farmName}/${farmId}`}
      />

      <Grid container style={{ height: '79%', overflowY: 'hidden' }}>
        <Grid item xs={8}>
          {farmBlocks?.features.length > 0 && (
            <MapContainer
              center={[mapPosition.x, mapPosition.y]}
              zoom={16}
              zoomControl={true}
              style={{ height: '100%' }}
              whenCreated={setMap}
            >
              <LayersControl position='topright'>
                <BaseLayer checked name='Satellite View'>
                  <TileLayer url='http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}' />
                </BaseLayer>
                <BaseLayer name='Street Map'>
                  <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                </BaseLayer>

                {/* <GeoJSON
                key={Math.random()}
                data={farmBlocks}
                style={style}
                onEachFeature={onEachFeature}
              /> */}
                {farmBlocks.features.length > 0 &&
                  farmBlocks.features.map((block) => {
                    // console.log(block);
                    return selectedBlocks.some((id) => id === block.properties.id) ? (
                      <GeoJSON
                        key={Math.random()}
                        data={block}
                        style={style}
                        onEachFeature={onEachFeature}
                      />
                    ) : (
                      <GeoJSON
                        key={Math.random()}
                        data={block}
                        style={style}
                        onEachFeature={onEachFeature}
                      />
                    );
                  })}

                <HarvestMapDataLayer
                  setDataLayerSelect={setDataLayerSelect}
                  farmId={farmId}
                  fertYear={fertYear}
                  displayLayer={layerSelection.has('Harvest Data')}
                  setHarvestLegend={setHarvestLegend}
                />

                <EMMapDataLayer
                  farmId={farmId}
                  setLayerOptions={setLayerOptions}
                  displayLayer={layerSelection.has('EM Data')}
                  setEMLegend={setEMLegend}
                />
              </LayersControl>
              {/* {legendData && <MapLegend  map={map} legendData={legendData}/>} */}
            </MapContainer>
          )}
        </Grid>
        <Grid item xs={4}>
          <Button
            component={Link}
            to={{
              pathname: `/manage-blocks/${farmId}`,
              state: { farmName: farmName },
            }}
            style={{
              marginLeft: '10px',
              marginRight: '10px',
            }}
            color='primary'
            variant='contained'
          >
            Modify Farm
          </Button>
          <Paper style={{ marginLeft: '10px', marginRight: '10px', paddingLeft: '10px', }}>
              <Typography variant='h6' style={{ marginTop: '10px' }}>
                Legend
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
              <div
                  style={{
                      color: "#f00",
                      backgroundColor: "#f00",
                      height: 5,
                      width: 25,
                      marginRight: 10,
                  }}
              />
                <Typography >Actions Available</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
              <div
                  style={{
                      color: "#0f0",
                      backgroundColor: "#0f0",
                      height: 5,
                      width: 25,
                      marginRight: 10,
                  }}
              />
              <Typography>Actions Completed</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
              <div
                  style={{
                      color: "#00f",
                      backgroundColor: "#00f",
                      height: 5,
                      width: 25,
                      marginRight: 10,
                  }}
              />
              <Typography>Blocks Selected</Typography>
              </Box>
          </Paper>
          {layerOptions && layerOptions.length > 0 && (
            <Paper
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '10px',
              }}
            >
              <Typography variant='h6' style={{ marginLeft: '10px', marginTop: '10px' }}>
                Map Options
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={productGroups.length === 0}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'Products')}
                />
                <Typography>Products</Typography>
                {layerSelection.has('Products') && productGroups.length > 0 && (
                  <Select
                    labelId='productOptions-label'
                    id='productOptions'
                    label='Products'
                    defaultValue={productGroups[0]}
                    onChange={(e) => setProductToShow(e.target.value)}
                    fullWidth
                    style={{
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  >
                    {productGroups.map((productGroup) => (
                      <MenuItem key={`menu_${productGroup}`} value={productGroup}>
                        {productGroup}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={layerOptions.find((layer) => layer === 'EM Data') === undefined}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'EM Data')}
                />
                <Typography>EM Data</Typography>
              </Box>
              {layerSelection.has('EM Data') && emMapLegend}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  // disabled={layerOptions.find((layer) => layer === 'EM Data') === undefined}
                  onChange={(e) => onCheckBoxChange(e.target.checked, 'Harvest Data')}
                />
                <Typography>Harvest Data</Typography>
                {layerSelection.has('Harvest Data') && dataLayerSelect}
              </Box>
              {layerSelection.has('Harvest Data') && harvestLegend}
            </Paper>
          )}
          <Paper style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '20px' }}>
            {displayBlockInfo()}
            <Button
              style={{
                marginLeft: '10px',
                marginRight: '10px',
                marginBottom: '10px',
                marginTop: '10px',
              }}
              color='secondary'
              variant='contained'
              disabled={(groupApplications() || []).length <= 0}
              onClick={() => setOpenDialog('APPLICATIONS')}
            >
              APPLICATIONS
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* <div className={classes.legend}>{legend([...filterSet])}</div> */}
    </>
  );
}
