import React from 'react';
import {Snackbar, IconButton, Typography, } from '@material-ui/core';
import {Close} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    notificationSuccess: {
        backgroundColor: 'rgba(9, 189, 47, 0.7)',
    },
    notificationError: {
        backgroundColor: 'rgba(228, 0, 43, 0.7))',
    },
    message: {
        color: '#FFFFFF'
    }
}))

export default function Alert(props) {
    const classes = styles();
    const {open, success, message } = props.alert
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={success ? 2000 : null}
            ContentProps={{
                'aria-describedby': 'message-id',
                classes: {
                    root: success ? classes.notificationSuccess : classes.notificationError
                }
            }}
            onClose={props.handleClose}
            message={<span id="message-id" className={classes.message}>
                <Typography><b id="alert-msg">{message}</b></Typography></span>}
            action={[
                <IconButton
                    id="alert-close"
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={props.handleClose}>
                    <Close />
                </IconButton>,
            ]}
        />
    )
}
