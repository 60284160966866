import { Grid, ListItem, Paper, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { NavigateShapeButton } from './NavigateShapeButton';
import { EditShapeButton } from './EditShapeButton';
import { useCallbackRef } from './useCallbackRef';

export const OldShapeCard = (props) => {
  const {
    blockId,
    blockName,
    shape,
    disableEditing,
    disableNavigating,
    onNavigate,
    onStartEditing,
  } = props;

  const invokeOnNavigate = useCallbackRef(onNavigate);
  const invokeOnStartEditing = useCallbackRef(onStartEditing);

  const state = useMemo(
    () => ({ blockId, blockName, shape }),
    [blockId, blockName, shape]
  );

  const handleClickNavigate = useCallback(() => {
    invokeOnNavigate(state);
  }, [invokeOnNavigate, state]);

  const handleClickEdit = useCallback(() => {
    invokeOnStartEditing(state);
  }, [invokeOnStartEditing, state]);

  return (
    <Paper elevation={0}>
      <ListItem>
        <Grid container alignItems="center">
          <Grid item>
            <Typography>{blockName}</Typography>
          </Grid>
          <Grid item>
            <NavigateShapeButton
              size="small"
              disabled={disableNavigating}
              onClick={handleClickNavigate}
            />
          </Grid>
          <Grid item>
            <EditShapeButton
              size="small"
              disabled={disableEditing}
              onClick={handleClickEdit}
            />
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  );
};
