import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
  getCropClass,
  getFallowCrop,
  getRequirementState,
  getVarieties,
} from "../functions/offlineQueries";

import { Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
import { format } from "date-fns";
import { getRequirements as getRequirementsAPI } from '../api/queries/requirements/getRequirements.js';
import { useAlertContext } from "../components/AlertContext";
import { useHistory } from "react-router-dom";

// getRequirements.js Lambda
// module.exports = {
//   query: async (client, event) => {
//       const query = {
//           text: `SELECT R.*, B.name as "block_name", B.hectares as "block_hectares", CC.name as "crop_class_name" FROM requirements R
//               LEFT JOIN blocks B ON R.block_id = B.id
//               LEFT JOIN farms_users F ON B.farm_id = F.farm_id
//               LEFT JOIN users U ON F.user_id = U.id
//               LEFT JOIN crop_classes CC ON R.crop_class_id = CC.id
//               WHERE B.farm_id = $1
//               AND U.cognito_id = $2
//               GROUP BY R.id, B.id, CC.id
//               ORDER BY B.name`,
//           values: [
//               event.farmId,
//               event.sub
//           ]
//       };
//       return await client.query(query);
//   }
// };

export default function ViewRequirements(props) {
  if (!props.match.params) {
    window.location.href = "/";
  }
  const history = useHistory();

  const farmId = props.match.params.id;
  const farmName = props.match.params.name;

  const userAuthToken = props?.user?.idToken?.jwtToken;

  const alertContext = useAlertContext();

  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState(
    sessionStorage.getItem("requirementFilter")
      ? sessionStorage.getItem("requirementFilter")
      : new Date().getFullYear().toString()
  );
  // TODO Handle if page > pages available
  const [rowsPerPage, setRowsPerPage] = useState(
    sessionStorage.getItem("requirementRowsPerPage")
      ? sessionStorage.getItem("requirementRowsPerPage")
      : 10
  );
  const [page, setPage] = useState(
    sessionStorage.getItem("requirementPage")
      ? sessionStorage.getItem("requirementPage")
      : 0
  );

  let years = [];
  var year = new Date().getFullYear();
  for (let i = 0; i < 8; i++) {
    years.push((year + i).toString());
  }

  const fetchRequirements = useCallback(async () => {
    setLoading(true);

    // Note: New APIs Code.
    try {
      const requirements = await getRequirementsAPI({farmId, token: userAuthToken});
      
      requirements.map((r) => {
        r.formated_from = r.from_date
          ? format(new Date(r.from_date), "dd/MM/yyyy")
          : "";
        r.formated_to = r.to_date
          ? format(new Date(r.to_date), "dd/MM/yyyy")
          : "";
        return r;
      });
      setLoading(false);
      setRequirements(requirements);

      console.log(requirements);
    } catch (err) {
      console.log(err);
      setLoading(false);
      alertContext.error(err, "Error fetching requirements");
    }

    // Note: Old GraphQL Code.
    // API.graphql(graphqlOperation(getRequirements.query, { farmId: farmId }))
    //   .then((result) => {
    //     if (result.data.getRequirements) {
    //       let res = JSON.parse(result.data.getRequirements);
    //       res.map((r) => {
    //         r.formated_from = r.from_date
    //           ? format(new Date(r.from_date), "dd/MM/yyyy")
    //           : "";
    //         r.formated_to = r.to_date
    //           ? format(new Date(r.to_date), "dd/MM/yyyy")
    //           : "";
    //         return r;
    //       });
    //       setLoading(false);
    //       setRequirements(res);

    //       console.log(res);
    //     }
    //   })
    //   .catch(async (err) => {
    //     console.log(err);
    //     setLoading(false);
    //     alertContext.error(err, "Error fetching requirements");
    //   });
  }, [farmId, userAuthToken, alertContext]);

  useEffect(() => {
    fetchRequirements();
  }, [fetchRequirements]);

  const handleChangePage = (event, page) => {
    sessionStorage.setItem("requirementPage", page);
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem("requirementRowsPerPage", event.target.value);
    setRowsPerPage(event.target.value);
  };

  let filteredSearch;
  // filteredSearch = requirements.filter((obj) => {
  //   obj.fertilizer_year == '2020' || obj.fertilizer_year == filter.address
  // })
  filteredSearch = requirements.filter((item) => {
    // console.log(item);
    return (
      JSON.stringify(item.fertilizer_year)
        .toLowerCase()
        .indexOf(query.toLowerCase()) !== -1
    );
  });

  function gotoProductionSelection() {
    //Reduce key pairs to only whats needed
    let reducedRes = filteredSearch.map(
      ({
        block_id,
        block_name,
        block_hectares,
        copper,
        crop_class_id,
        crop_class_name,
        gypsum,
        lime,
        magnesium,
        nitrogen,
        phosphorus,
        potassium,
        silicate,
        sulphur,
        zinc,
      }) => ({
        block_id,
        block_name,
        block_hectares,
        copper,
        crop_class_id,
        crop_class_name,
        gypsum,
        lime,
        magnesium,
        nitrogen,
        phosphorus,
        potassium,
        silicate,
        sulphur,
        zinc,
      })
    );

    history.push({
      pathname: `/products/${farmName}/${farmId}`,
      state: { reducedRes },
    });
  }

  const [fertYear, setfertYear] = React.useState(query);
  const handleChange = (event) => {
    sessionStorage.setItem("requirementFilter", event.target.value);
    setfertYear(event.target.value);
    setQuery(event.target.value);
  };

  return (
    <LogoCard>
      {loading && <LoadingBackground visible={loading} />}

      <PageHeader
        title={`Farm: ${farmName}`}
        subheader={`Requirements for farm ${farmName} - use the drop down to select year`}
        showSearch={false}
        setQuery={(q) => setQuery(q)}
        backPath={"/"}
        placeholder={"Search Text - Example: 2020"}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          padding: 20,
        }}
      >
        <FormControl>
          <Select
            labelId="select-fert-year-label"
            id="select-fert-year-id"
            value={fertYear}
            onChange={handleChange}
          >
            {years.map((yearStr, index) => (
              <MenuItem value={yearStr} key={index}>
                {yearStr}
              </MenuItem>
            ))}
            ;
          </Select>
          <FormHelperText>Select Fert year</FormHelperText>
        </FormControl>

        <span style={{ marginLeft: "auto" }}>
          Nitrogen{" "}
          {!loading &&
            filteredSearch.reduce((a, b) => a + (b.nitrogen || 0), 0)}
        </span>
        <span style={{ marginLeft: "auto" }}>
          Phosphorus{" "}
          {!loading &&
            filteredSearch.reduce((a, b) => a + (b.phosphorus || 0), 0)}
        </span>
        <span style={{ marginLeft: "auto" }}>
          Potassium{" "}
          {!loading &&
            filteredSearch.reduce((a, b) => a + (b.potassium || 0), 0)}
        </span>
        <span style={{ marginLeft: "auto" }}>
          Sulphur{" "}
          {!loading && filteredSearch.reduce((a, b) => a + (b.sulphur || 0), 0)}
        </span>

        <Button
          style={{ marginLeft: "auto" }}
          color="secondary"
          variant="contained"
          disabled={
            filteredSearch.every((x) => x.requirement_state_id === "3")
              ? false
              : true
          }
          onClick={gotoProductionSelection}
        >
          Product Selection
        </Button>
      </div>
      <TableContainer style={{ width: "100vw", height: "63vh" }}>
        <Table aria-labelledby="tableTitle">
          <TableHead key={99.9}>
            {/* style={{ position: "fixed" }} */}
            <TableRow key={1.99}>
              {rows.map(
                (
                  row,
                  index //{
                ) => (
                  // return [
                  <Tooltip title={row.tt} placement="top" key={index}>
                    <TableCell
                      align={row.id === "location" ? "center" : "left"}
                      padding="normal"
                    >
                      {row.label}
                    </TableCell>
                  </Tooltip>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              filteredSearch
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((requirement) => {
                  let trace =
                    requirement.zinc > 0
                      ? requirement.zinc.toString() + " Zn"
                      : " ";
                  trace +=
                    requirement.copper > 0
                      ? requirement.copper.toString() + " Cu"
                      : " ";
                  trace +=
                    requirement.lime > 0
                      ? requirement.lime.toString() + " Lm"
                      : " ";
                  trace +=
                    requirement.lime_ph > 0
                      ? requirement.lime_ph.toString() + " Lph"
                      : " ";
                  trace +=
                    requirement.magnesium > 0
                      ? requirement.magnesium.toString() + " Mg"
                      : " ";
                  trace +=
                    requirement.gypsum > 0
                      ? requirement.gypsum.toString() + " Ca"
                      : " ";
                  trace +=
                    requirement.silicate > 0
                      ? requirement.silicate.toString() + " Si"
                      : "";
                  return [
                    <TableRow hover tabIndex={-1} key={requirement.id}>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.block_name}
                      </TableCell>
                      {/* <TableCell component="th" scope="row" padding="normal">
                        {requirement.fertilizer_year}
                      </TableCell> */}
                      <TableCell component="th" scope="row" padding="normal">
                        {getCropClass(requirement.crop_class_id)}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.nitrogen}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.phosphorus}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.potassium}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.sulphur}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {trace}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.mill_mud_rate}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.mill_mud_ash_rate}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.mill_ash_rate}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {getFallowCrop(requirement.fallow_crop_id)}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.formated_from}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {getRequirementState(requirement.requirement_state_id)}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {getVarieties(requirement.variety_id)}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.yield}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {requirement.fallow_harvested
                          ? requirement.fallow_harvested === 0
                            ? "False"
                            : "True"
                          : "False"}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        <Tooltip title={`Update requirement ${requirement.id}`}>
                          <IconButton
                            size="small"
                            edge="end"
                            aria-label="View"
                            color="primary"
                            component={Link}
                            to={`/view-requirement/${farmName}/${farmId}/${requirement.id}`}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>,
                  ];
                })}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={rows.length} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredSearch.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </LogoCard>
  );
}

const rows = [
  { id: "block_name", label: "Block", tt: "" },
  // { id: "fertilizer_year", label: "Fertilizer\u00a0Year" },
  { id: "crop_class_id", label: "Crop\u00a0Class", tt: "" },
  { id: "nitrogen", label: "N", tt: "Nitrogen" },
  { id: "phosphorus", label: "P", tt: "Phosphorus" },
  { id: "potassium", label: "K", tt: "Potassium" },
  { id: "sulphur", label: "S", tt: "Sulphur" },
  { id: "Ameliorant", label: "Ameliorant", tt: "" },
  { id: "mill_mud_rate", label: "Mill\u00a0Mud", tt: "Applied" },
  { id: "mill_mud_ash_rate", label: "Mill\u00a0Mud\u00a0Ash", tt: "Applied" },
  { id: "mill_ash_rate", label: "Mill\u00a0Ash", tt: "Applied" },
  { id: "fallow_crop_name", label: "Fallow\u00a0Crop", tt: "" },
  { id: "from_date", label: "Date\u00a0From", tt: "" },
  { id: "requirement_state_id", label: "State", tt: "" },
  { id: "variety_id", label: "Variety", tt: "" },
  { id: "yield", label: "Yield", tt: "" },
  { id: "fallow_harvested", label: "Fallow\u00a0Harvested", tt: "" },
  { id: "action", label: "", tt: "" },
];
