import React, { useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";

// Queries
import { createRequirements } from "../../graphQL/Requirement";
import { requirementHistory } from '../../api/mutations/requirements/requirementHistory.js';

// Context
import { useAlertContext } from "../AlertContext";
// import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from "../../contexts/UserContext.js";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  cropClasses,
  fallowCrops,
  varieties,
} from "../../functions/offlineQueries";

// import { setDate } from "date-fns";

const millProducts = [
  { value: 0, dbname: "mill_mud_rate", name: "Mill Mud" },
  { value: 1, dbname: "mill_ash_rate", name: "Mill Ash" },
  { value: 2, dbname: "mill_mud_ash_rate", name: "Mill Mud Ash" },
];

export default function HistoricDialog(props) {
  const { visible, block, handleClose } = props;

  const alertContext = useAlertContext();
  // const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();

  const soilSampleDate =
    block.primary_sample_id == null
      ? undefined
      : block.soil_samples && block.soil_samples[0].sampling_date;

  // console.log(block);

  console.log("HarvestDialog", block)

  // Toggle to enable mill byproduct inputs
  const [millProductApplied, setMillProductApplied] = React.useState(false);

  let currentYear = new Date().getFullYear();

  const soilDate = soilSampleDate ? new Date(soilSampleDate) : new Date();

  const [millProduct, setMillProduct] = React.useState(0);
  const handleProductChange = (event) => {
    // console.log(event);
    setMillProduct(event.target.value);
  };
  const [millAppRate, setMillAppRate] = React.useState(0);
  const handleRateChange = (event) => {
    setMillAppRate(event.target.value);
  };
  const [yearApplied, setYearApplied] = React.useState(2021);
  const handleYearChange = (event) => {
    setYearApplied(event.target.value);
  };
  const [cropClass, setCropClass] = React.useState(
    currentYear - soilDate.getFullYear() + 2
  );
  const [plantDate, setPlantDate] = React.useState(
    soilDate.toISOString().split("T")[0]
  );
  //   new Date().toISOString().split("T")[0]
  // );
  const handlePlantDateChange = (event) => {
    const year = new Date(event.target.value).getFullYear();
    setPlantDate(event.target.value);
    if (year > 2000 && year < 9999) {
      setCropClass(currentYear - year + 2);
    }
  };

  //************************FALLOW *********************************/
  const [fallowPlanted, setFallowPlanted] = React.useState(false);
  const [fallowCrop, setFallowCrop] = React.useState(1);

  const [fallowYield, setFallowYield] = React.useState(0);
  const handleYieldChange = (event) => {
    setFallowYield(event.target.value);
  };

  const handleCropClassChange = (event) => {
    setFallowCrop(event.target.value);
  };

  function toggleFallowCrop() {
    setFallowPlanted(!fallowPlanted);
    setFallowYield(0);
    setFallowCrop(1);
  }

  function toggleMillProductApplied() {
    setMillProductApplied(!millProductApplied);
    setMillAppRate(0);
    setMillProduct(0);
  }

  const [variety, setVariety] = React.useState(1);
  const handleVarietyChange = (event) => {
    setVariety(event.target.value);
  };

  let soilSampleTime = soilDate.getTime();
  const soilDateStr =
    soilDate.getDate() +
    "/" +
    (soilDate.getMonth() + 1) +
    "/" +
    soilDate.getFullYear();
  let differenceInDays =
    (new Date(plantDate).getTime() - soilSampleTime) / (1000 * 3600 * 24);

  const handleAccept = useCallback(async () => {
    try {
      const requirementId = block.requirements?.[0].id;
      
      await requirementHistory({
        token: user?.idToken?.jwtToken,
        requirementId,
        fallowYield,
        fallowCropId: fallowCrop,
        varietyId: variety,
        plantDate,
        byProductAppliedYear: yearApplied,
        millProductDbName: millProducts[millProduct].dbname,
        millAppRate,
      });

      alertContext.success(`Requirement backdated`);

      if (!block.primary_sample_id) return;
      
      //Create NFAs
      try {
        const result = await API.graphql(
          graphqlOperation(createRequirements.mutation, {
            requirementId,
          })
        )
        console.log(result);
        alertContext.success(
          `Farm Nutrient Allowance is being calculated`
        );
        // TODO: Add requirement to block using context?
        handleClose(true);
      } catch (err) {
        alertContext.error(err,
          `Error generating the Farm Nutrient Allowance`
        );
      }
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error backdating the requirement`);
    }
  }, [alertContext, block.primary_sample_id, block.requirements, fallowCrop, fallowYield, handleClose, millAppRate, millProduct, plantDate, user?.idToken?.jwtToken, variety, yearApplied]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="modify-dialog-title"
      aria-describedby="modify-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="modify-dialog-title"
      >{`Modify actions for Block ${block.name}`}</DialogTitle>
      <DialogContent>
        {block.requirementCount > 1 ? (
          <Typography color="error">
            More requirements then expected - may override old data!
          </Typography>
        ) : null}
        {soilSampleDate == null ? null : (
          <Typography>Soil Sample Date: {soilDateStr}</Typography>
        )}
        <TextField
          id="plantDate"
          label="Plant date"
          type="date"
          defaultValue={plantDate}
          fullWidth
          onChange={handlePlantDateChange}
        />
        {soilSampleDate == null ? (
          <Typography color="error">
            A valid soil sample will have to be selected
          </Typography>
        ) : differenceInDays > 365 ? (
          <Typography color="error">
            Planting must occur within 365 days of sample date
          </Typography>
        ) : differenceInDays < 0 ? (
          <Typography color="error">
            Planting must occur after the sample date
          </Typography>
        ) : null}
        <Typography>
          Block's calculated crop class is: {cropClasses[cropClass].name}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="variety_label">Crop Type</InputLabel>
          <Select
            name="fallow_crop_id"
            label="Crop type"
            value={variety}
            fullWidth
            onChange={handleVarietyChange}
          >
            {varieties.map((variety, index) => (
              <MenuItem value={variety.value} key={index}>
                {variety.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup row>
          <FormControlLabel
            label="Mill Product Applied?"
            control={
              <Switch
                checked={millProductApplied}
                onChange={() => toggleMillProductApplied()}
                color="secondary"
                name="fallowPlanted"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
          />
        </FormGroup>
        <Select
          disabled={!millProductApplied}
          name="millProduct"
          label="Product"
          value={millProduct}
          fullWidth
          onChange={handleProductChange}
        >
          {millProducts.map((product, index) => (
            <MenuItem value={product.value} key={index}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          disabled={!millProductApplied}
          id="millAppRate_tha"
          label="App Rate (t/ha)?"
          value={millAppRate}
          type="number"
          // fullWidth
          onChange={handleRateChange}
        />

        <TextField
          disabled={!millProductApplied}
          id="millYearApplied"
          label="Year Applied (YYYY)?"
          value={yearApplied}
          type="number"
          // fullWidth
          onChange={handleYearChange}
        />
        <FormGroup row>
          <FormControlLabel
            label="Fallow crop harvested?"
            control={
              <Switch
                checked={fallowPlanted}
                onChange={() => toggleFallowCrop()}
                color="secondary"
                name="fallowPlanted"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
          />
        </FormGroup>
        <Select
          disabled={!fallowPlanted}
          name="fallow_crop_id"
          label="Crop type"
          value={fallowCrop}
          fullWidth
          onChange={handleCropClassChange}
        >
          {fallowCrops.map((crop, index) => (
            <MenuItem value={crop.value} key={index}>
              {crop.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          disabled={!fallowPlanted}
          id="fallow_yeild"
          label="Enter yield (t/ha)?"
          value={fallowYield}
          type="number"
          fullWidth
          onChange={handleYieldChange}
        />
        <Typography>Accept will create a backdated requirement</Typography>
        <Typography>and run all the calculations for it.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleAccept()} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
