import { TableCell, TableRow, TextField } from "@material-ui/core"
import { SubdirectoryArrowRight } from "@material-ui/icons";
import { useCallback, useRef, useState } from "react"

const DEFAULT_MAX_LENGTH = 160;

export const CommentRow = (props) => {
  const {comment, maxLength, onTypingStopped} = props;

  const [value, setValue] = useState(props.comment);

  const timeoutRef = useRef(null);
  const handleChange = useCallback((e) => {
    setValue(e.target.value)

    if (timeoutRef.current !== null)
      clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      onTypingStopped?.(e.target.value);
      setValue(null);
    }, 1000);
  }, [onTypingStopped]);


  return (
    <TableRow>
    <TableCell style={{padding: 0}} align="right">
      {(value ?? comment) !== null && (
        <SubdirectoryArrowRight color="action" style={{width: 18}}/>
      )}
    </TableCell>
    <TableCell colSpan={12}>
      {(value ?? comment) !== null && (
        <div style={{display: 'flex', gap: 6}}>
          <TextField
            placeholder="Product Comment"
            inputProps={{maxLength: maxLength ?? DEFAULT_MAX_LENGTH}}
            value={value ?? props.comment}
            onChange={handleChange}
            style={{flexGrow: 1}}
          />
        </div>
      )}
    </TableCell>
  </TableRow>
  )
}
