import { APIFetch } from '../../fetch.js';

export const requirementPlant = async ({
  token,
  blockId,
  primarySampleId,
  fertYear,
  plantDate,
  varietyId,
  newCrop,
  newCropType,
  replant,
  requirementId,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementPlant',
    method: 'POST',
    authToken: token,
    body: {
      blockId,
      primarySampleId,
      fertYear,
      plantDate,
      varietyId,
      newCrop,
      newCropType,
      replant,
      requirementId,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementPlant request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
