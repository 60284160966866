import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

// Queries
import { getNotes, createNote } from "../../graphQL/Blocks";

// Context
import { useAlertContext } from "../AlertContext";

// Material
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";

import { AddCircle } from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function NoteDialog(props) {
  const alertContext = useAlertContext();
  const { visible, block, handleClose } = props;

  const [blockNotes, setBlockNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.graphql(graphqlOperation(getNotes.query, { block_id: block.id }))
      .then(async (result) => {
        setBlockNotes(JSON.parse(result.data.getNotes));
      })
      .catch((err) => {
        console.log(err);
        alertContext.error(err, `Error getting Notes`);
      });
    setLoading(false);
  }, [block.id, alertContext]);

  const [notedDate, setNotedDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  const [addNewNote, setAddNewNote] = React.useState(false);

  const handleNotedDateChange = (event) => {
    setNotedDate(event.target.value);
  };

  let newNoteStr;

  function handleAccept() {
    const noteData = {
      noted_date: notedDate,
      note: newNoteStr,
      block_id: block.id,
    };
    API.graphql(
      graphqlOperation(createNote.mutation, {
        noteData: noteData,
      })
    )
      .then(async (result) => {
        alertContext.success(`Note Created`);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        alertContext.error(err, `Error creating note`);
      });
  }

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="note-dialog-title"
      aria-describedby="note-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="note-dialog-title"
      >
        Notes for Block {block.name}
      </DialogTitle>

      <DialogContent>
        {addNewNote ? (
          <FormGroup row>
            <TextField
              id="notedDate"
              label="Date"
              type="date"
              defaultValue={notedDate}
              fullWidth
              onChange={handleNotedDateChange}
            />
            <TextField
              id="newNote"
              label="Note"
              type="text"
              fullWidth
              multiline
              onChange={(event) => (newNoteStr = event.target.value)}
            />
          </FormGroup>
        ) : (
          <Typography>
            Add new note
            <IconButton
              style={{ float: "right" }}
              color="secondary"
              aria-label="arrow_back"
              onClick={() => setAddNewNote(true)}
              size="small"
            >
              <AddCircle />
            </IconButton>
          </Typography>
        )}
        <br />
        <Typography
          style={{
            backgroundColor: "rgba(0, 157, 72, 0.2)",
            textAlign: "center",
          }}
        >
          Note History
        </Typography>
        <LinearProgress
          color="secondary"
          variant={loading ? "indeterminate" : "determinate"}
          value={100}
        />
        {blockNotes.length === 0 ? (
          <Typography>No Notes Found</Typography>
        ) : (
          blockNotes.map((note, index) => (
            <Typography component="div" key={index}>
              <Box fontStyle="italic">
                {note.name}: {note.noted_date.split("T")[0]}
              </Box>
              <Box>{note.note}</Box>
            </Typography>
          ))
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {addNewNote ? "Cancel" : "Close"}
        </Button>
        <Button
          disabled={!addNewNote}
          onClick={() => handleAccept()}
          color="primary"
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
