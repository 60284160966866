const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: 12,
    [theme.breakpoints.up('md')]: {
      marginRight: 36
    }
  },
  drawer: {
    width: drawerWidth,
  },
  drawerList: {
    backgroundColor: '#FFFFFF',
    width: drawerWidth, 
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flex: 1,
    //padding: theme.spacing(3),
    // backgroundColor: '#E7F6ED',
    backgroundColor: '#FFFFFF',
    'min-height': '100vh',
    width: '100vw'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  active: {
    backgroundColor: 'rgba(0, 130, 200, 0.3)',
  },
  header: {
    color: '#21761B'
  },
  drawerText: {
    color: '#747474'
  },
  white: {
    color: '#FFFFFF'
  }
});

  export default styles;
