import React from 'react'
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik'

const style = makeStyles(theme => ({
    button: {
        marginTop: 15
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapper: {
        position: 'relative'
    }
}));

export default function FormButton(props) {
    const classes = style()
    const { callback, text, dialog, disabled } = props;
    const formik = useFormikContext()
    const buttonProps = callback ? { onClick:callback } : {
        type:'submit'
    };
    const buttonUIProps =
        dialog === true ?
            {
                color:"primary"
            }
            :
            {
                variant:"contained" ,
                fullWidth:true,
                color:"secondary",
                className:classes.button
            }

    return (
        <div className={classes.wrapper}>
            <Button {...buttonProps} {...buttonUIProps} data-cy={props['data-cy']} disabled={formik.isSubmitting || disabled}>
                {text}
            </Button>
            { formik.isSubmitting &&
                <CircularProgress size={24} className={classes.progress} />
            }
        </div>
    )
}
