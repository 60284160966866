import {
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { RemoveCircle, AddCircle } from "@material-ui/icons";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

const swapBetweenArrays = (item, from, to) => {
  const index = from.findIndex((other) => other === item);
  from.splice(index, 1);
  to.push(item);
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    overflowY: 'auto',
  }
}));

export const FarmUsersSelector = ({
  farmId,
  cognitoId,
  nonFarmUsers: storedNonFarmUsers,
  farmUsers: storedFarmUsers,
  filter,
  onChange,
}) => {
  const classes = useStyles();

  const [farmUsers, setFarmUsers] = useState([]);
  const [nonFarmUsers, setNonFarmUsers] = useState([]);

  useEffect(() => {
    setNonFarmUsers([...storedNonFarmUsers]);
    setFarmUsers([...storedFarmUsers]);
  }, [storedNonFarmUsers, storedFarmUsers]);

  useEffect(() => {
    const addedUsers = farmUsers.filter(
      (user) => !storedFarmUsers.some((other) => other === user)
    );
    const removedUsers = nonFarmUsers.filter(
      (user) => !storedNonFarmUsers.some((other) => other === user)
    );
    onChange({ addedUsers, removedUsers });
  }, [onChange, storedNonFarmUsers, storedFarmUsers, nonFarmUsers, farmUsers]);

  const filteredFarmUsers = useMemo(
    () =>
      farmUsers.filter((user) =>
        new RegExp(`.*${filter}.*`, "i").test(JSON.stringify(user))
      ),
    [farmUsers, filter]
  );

  const filteredNonFarmUsers = useMemo(
    () =>
      nonFarmUsers.filter((user) =>
        new RegExp(`.*${filter}.*`, "i").test(JSON.stringify(user))
      ),
    [nonFarmUsers, filter]
  );

  const addUserToFarm = useCallback(
    (user) => {
      const newNonFarmUsers = [...nonFarmUsers];
      const newFarmUsers = [...farmUsers];

      swapBetweenArrays(user, newNonFarmUsers, newFarmUsers);
      newFarmUsers.sort((u1, u2) => u1.name.localeCompare(u2.name));

      setNonFarmUsers(newNonFarmUsers);
      setFarmUsers(newFarmUsers);
    },
    [nonFarmUsers, farmUsers, setNonFarmUsers, setFarmUsers]
  );

  const removeUserFromFarm = useCallback(
    (user) => {
      const newNonFarmUsers = [...nonFarmUsers];
      const newFarmUsers = [...farmUsers];

      swapBetweenArrays(user, newFarmUsers, newNonFarmUsers);
      newNonFarmUsers.sort((u1, u2) => u1.name.localeCompare(u2.name));

      setNonFarmUsers(newNonFarmUsers);
      setFarmUsers(newFarmUsers);
    },
    [nonFarmUsers, farmUsers, setNonFarmUsers, setFarmUsers]
  );

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        spacing={2}
      >
        {/* Non Farm Users */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="div">
            Non Farm Users
          </Typography>
          <Card variant="outlined">
            <List className={classes.root}>
              {filteredNonFarmUsers.map((user, key) => (
                <React.Fragment key={user.id}>
                  <ListItem alignItems="flex-start" disabled={user.cognito_id === cognitoId}>
                    <ListItemText
                      primary={`${user.name}${
                        !storedNonFarmUsers.some((other) => other === user)
                          ? " *"
                          : ""
                      }`}
                      secondary={`${user.email}`}
                    />
                    {user.cognito_id !== cognitoId && (<ListItemSecondaryAction>
                      <Tooltip title="Add To Farm">
                        <IconButton
                          disabled={user.cognito_id === cognitoId}
                          aria-label="Add"
                          onClick={() => addUserToFarm(user)}
                        >
                          <AddCircle
                            color="primary"
                            id={`add-${user.id}`}
                          />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>)}
                  </ListItem>
                  {key + 1 < filteredNonFarmUsers.length && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Card>
        </Grid>

        {/* Farm Users */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="div">
            Farm Users
          </Typography>
          <Card variant="outlined">
            <List className={classes.root}>
              {filteredFarmUsers.map((user, key) => (
                <React.Fragment key={user.id}>
                  <ListItem alignItems="flex-start" disabled={user.cognito_id === cognitoId}>
                    <ListItemText
                      primary={`${user.name}${
                        !storedFarmUsers.some((other) => other === user)
                          ? " *"
                          : ""
                      }`}
                      secondary={`${user.email}`}
                    />
                    {user.cognito_id !== cognitoId && (<ListItemSecondaryAction>
                      <Tooltip title="Remove From Farm">
                        <IconButton
                          disabled={user.cognito_id === cognitoId}
                          aria-label="Remove"
                          onClick={() => removeUserFromFarm(user)}
                        >
                          <RemoveCircle
                            color="error"
                            id={`remove-${user.Username}`}
                          />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>)}
                  </ListItem>
                  {key + 1 < filteredFarmUsers.length && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
