import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MillMods from './MillMods';

export default function KTable(props) {
  const { sample, tableData, requirement } = props;

  const plant = requirement && requirement.crop_class_id <= 2;

  // Extract all the unique exchangeable_potassium values
  const exK = [
    ...new Set(
      [
        ...tableData.potassium.map((el) => {
          return el.exchangeable_potassium_max;
        }),
      ].sort()
    ),
  ];

  const getCellColor = (correctNitricKRange, correctTexture, correctCropClass, correctExchangableKRange) => {
    if (correctNitricKRange && correctTexture && correctCropClass && correctExchangableKRange) return 'rgba(65, 172, 210, 0.6)';
    return '#FFF';
  };


  console.log(tableData);

  const filterTable = (nitricK, exchangeK, texture, plantRow, index) => {
    const filtered = tableData.potassium.filter((el) => {
      return (
        el.nitric_potassium_max === nitricK &&
        el.exchangeable_potassium_min < exchangeK &&
        el.exchangeable_potassium_max >= exchangeK &&
        el.soil_texture === texture
      );
    });
    if (filtered.length === 0) return <TableCell>0</TableCell>;

    const correctNitricKRange = filtered[0].nitric_potassium_min <= sample.nitric_potassium && filtered[0].nitric_potassium_max > sample.nitric_potassium;
    const correctTexture = sample.soil_texture === texture;
    const correctCropClass = plant === plantRow;
    const correctExchangableKRange = ((index - 1 < 0 ? 0 : exK[index - 1]) <= sample.potassium_exchange && sample.potassium_exchange < exK[index] );

    return (
      <TableCell
        style={{
          background: getCellColor(
            correctNitricKRange,
            correctTexture,
            correctCropClass,
            correctExchangableKRange,
            index
          ),
        }}
      >
        {plantRow
          ? filtered[0].potassium_plant_application_rate
          : filtered[0].potassium_replant_and_ratoon_application_rate}
      </TableCell>
    );
  };

  return (
    <div>
      <Typography>Nitric K (meq/100g): {sample.nitric_potassium}</Typography>
      <Typography>Exchangable K (meq/100g): {sample.potassium_exchange}</Typography>
      <Typography style={{ marginBottom: 10 }}>Soil Type: {sample.soil_texture}</Typography>
      <TableContainer  style={{ marginBottom: 20}}>
        <Table stickyHeader size='small'>
          <TableHead>
          <TableRow>
              <TableCell>Nitric K</TableCell>
              <TableCell>Texture</TableCell>
              <TableCell>Crop</TableCell>
              <TableCell colSpan={exK.length}>Exchangable K (meq/100g)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              {exK.map((el, index) => (
                <TableCell>
                  {index === exK.length - 1 ? `>= ${exK[exK.length - 2]}` : `< ${el}`}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            
            <TableRow>
              <TableCell rowSpan={6}>{`< 0.70`}</TableCell>
              <TableCell rowSpan={2}>{`Sand`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Sand', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Sand', false, index))}
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>{`Loam`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Loam', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Loam', false, index))}
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>{`Clay`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Clay', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(0.7, el, 'Clay', false, index))}
            </TableRow>

            <TableRow>
              <TableCell rowSpan={6}>{`> 0.70`}</TableCell>
              <TableCell rowSpan={2}>{`Sand`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Sand', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Sand', false, index))}
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>{`Loam`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Loam', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Loam', false, index))}
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>{`Clay`}</TableCell>
              <TableCell>Plant</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Clay', true, index))}
            </TableRow>
            <TableRow>
              <TableCell>Replant and Ratoon</TableCell>
              {exK.map((el, index) => filterTable(100, el, 'Clay', false, index))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <MillMods nutrient='Potassium' element='K' tableData={tableData.potassium_mod} requirement={requirement} />
    </div>
  );
}
