import React, { useState } from "react";

// COMPONENTS
import NutrientBlock from "./NutrientBlock";
import ProductSelectLine from "./ProductSelectLine";

// FUNCTIONS
import { sortProductsByAppTiming } from "../../functions/sortProducts";

// MATERIAL
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

// ICONS
import { AddCircle } from "@material-ui/icons";



const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: "auto",
    borderRadius: spacing(2), // 16px
    transition: "0.3s",
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
    position: "relative",
    maxWidth: "lg",
    marginLeft: "auto",
    overflow: "initial",
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: spacing(2),
    [breakpoints.up("md")]: {
      flexDirection: "row",
      paddingTop: spacing(2),
    },
  },
  card: {
    marginTop: 40,
    borderRadius: spacing(0.5),
    transition: "0.3s",
    width: "98%",
    overflow: "initial",
    background: "#ffffff",
    margin: "1%",
  },
  header: {
    backgroundColor: "rgba(0, 161, 201, 0.1)",
  },
  content: {
    paddingTop: 0,
    textAlign: "center",
    overflowX: "auto",
    "& table": {
      marginBottom: 0,
    },
  },
  goodValueColor: {
    backgroundColor: "rgba(0, 157, 72, 0.2)",
  },
  badValueColor: {
    backgroundColor: "rgba(100, 0, 72, 0.2)",
  },
  //   cta: {
  //     marginTop: 24,
  //     textTransform: "initial",
  //   },
}));

export default function NutrientGroup(props) {
  const styles = useStyles();
  // const alertContext = useAlertContext();

  const {
    products,
    nutrientGroup,
    addProductRow,
    removeProductRow,
    newComment,
    newAppTiming,
    newAppMethod,
    newProductSelected,
    newRateSelected,
  } = props;

  const [grouped, setGrouped] = useState(groupedDataIsEqual());


  function calculateNutrientTotal(nutrientToCalc) {
    return Math.round(
      nutrientGroup.blocks.reduce(
        (
          prev,
          cur //4 arrays of objects
        ) =>
          prev +
          (cur.productsSelected.reduce(
            (
              prevProd,
              curProd // productsSelected array of objects
            ) =>
              prevProd +
              (curProd[nutrientToCalc] || 0) * (curProd.apprate || 0), //  was / 100
            0
          ) *
            parseFloat(cur.block_hectares)) /
            nutrientGroup.groupedArea,
        0
      ) / 100, // moved here
      0
    );
  }

  const totalN = calculateNutrientTotal("n");
  const totalP = calculateNutrientTotal("p");
  const totalK = calculateNutrientTotal("k");
  const totalS = calculateNutrientTotal("s");

  function groupedDataIsEqual() {
    if (nutrientGroup.blocks[0].productsSelected.length > 0) {
      for (
        let blockNumber = 0;
        blockNumber < nutrientGroup.blocks.length;
        blockNumber++
      ) {
        // check blocks have same amount of products
        // console.log("==", blockNumber, nutrientGroup.blocks.length);
        // console.log("**", nutrientGroup.blocks[0].productsSelected.length, nutrientGroup.blocks[blockNumber].productsSelected.length);
        if (
          nutrientGroup.blocks[0].productsSelected.length !==
          nutrientGroup.blocks[blockNumber].productsSelected.length
        )
          return false;

          if (
            nutrientGroup.blocks[blockNumber].to_fallow !== null
          )
            return false;

        for (
          let productNumber = 0;
          productNumber < nutrientGroup.blocks[0].productsSelected.length;
          productNumber++
        ) {
          if (
            nutrientGroup.blocks[0].productsSelected[productNumber]
              .productId !==
            nutrientGroup.blocks[blockNumber].productsSelected[productNumber]
              .productId
          )
            return false;
          if (
            nutrientGroup.blocks[0].productsSelected[productNumber]
              .apptiming !==
            nutrientGroup.blocks[blockNumber].productsSelected[productNumber]
              .apptiming
          )
            return false;
          if (
            nutrientGroup.blocks[0].productsSelected[productNumber].applied !==
            nutrientGroup.blocks[blockNumber].productsSelected[productNumber]
              .applied
          )
            return false;
          if (
            nutrientGroup.blocks[0].productsSelected[productNumber]
              .appmethod !==
            nutrientGroup.blocks[blockNumber].productsSelected[productNumber]
              .appmethod
          )
            return false;
          if (
            nutrientGroup.blocks[0].productsSelected[productNumber].apprate !==
            nutrientGroup.blocks[blockNumber].productsSelected[productNumber]
              .apprate
          )
            return false;
        }
      }
    } else if (totalK + totalN + totalP + totalS > 0) {
      return false;
    }
    console.log(totalK + totalN + totalP + totalS);
    return true;
  }

  function okTogroup() {
    if (groupedDataIsEqual() === true && nutrientGroup.blocks.length > 1) {
      if (grouped === true) {
        // console.log("Grouped, Groups match && > 1 block");
        return false;
      } else {
        // console.log("Not grouped, Groups match && > 1 block");
        return true;
      }
    } else {
      // console.log("Grouped products don't match or only 1 block");
      return false;
    }
    // }
  }

  const handleGroupedChange = () => {
    setGrouped(okTogroup());

    //alertContext.error(null, "Error Product selections don't match");
  };

  console.log("nutrientGroup", nutrientGroup);

  // let area = Math.round(nutrientGroup.groupedArea * 100) / 100;
    // Math.round(
    //   (nutrientGroup.blocks.reduce(
    //     (a, b) => a + parseFloat(b.block_hectares),
    //     0
    //   ) +
    //     Number.EPSILON) *
    //     100
    // ) / 100;

  // let proportion = Math.round(area / nutrientGroup.totalArea * 1000) / 10;
    // Math.round(
    //   ((area / nutrientGroup.totalArea) * 100 + Number.EPSILON) * 100
    // ) / 100;

    // nutrientGroup.groupedArea
    // nutrientGroup.totalArea

  const ameliorantRequired = nutrientGroup.blocks.some((block) => {
    return (
      parseInt(block.zinc) > 0 ||
      parseInt(block.copper) > 0 ||
      parseInt(block.lime_calcium) > 0 ||
      parseInt(block.lime_acidic) > 0 ||
      parseInt(block.magnesium) > 0 ||
      parseInt(block.gypsum) > 0 ||
      parseInt(block.silicate) > 0
    )
  });


  return (
    <>
      <Card className={styles.card}>
        <CardContent className={styles.header}>
          <div style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={9}>
                <Typography variant="h5" component="h2">
                  Total Area: {nutrientGroup.groupedArea}ha Farm Proportion: {nutrientGroup.proportion}%
                </Typography>
                <Typography variant="body2" component="p">
                  {nutrientGroup.blocks.length} Blocks (
                  {nutrientGroup.blocks.map((block, index) => {
                    return nutrientGroup.blocks.length - 1 === index
                      ? block.block_name
                      : block.block_name + ", ";
                  })}
                  ) {ameliorantRequired && "*Ameliorant in grouping"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" component="h2">
                  Block group:{" "}
                  {String.fromCharCode(
                    "a".charCodeAt(0) + nutrientGroup.ngbIndex
                  )}
                  {/* TODO Add a proper blockgroup and save to DB */}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1}>
                <FormControlLabel
                  control={
                    <Switch checked={grouped} onChange={handleGroupedChange} />
                  }
                  label={grouped ? "Grouped" : "Ungrouped"}
                />
              </Grid>
            </Grid>
          </div>
        </CardContent>

        <CardContent className={styles.content}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{width: 0, padding: 0}} />
                  <TableCell style={{ width: 150 }} align="center">
                    App Timing
                  </TableCell>
                  <TableCell style={{ width: 150 }} align="center">
                    App Method
                  </TableCell>
                  <TableCell
                    style={{ width: 250 }}
                    size="medium"
                    align="center"
                  >
                    Product
                  </TableCell>
                  <TableCell size="small" align="right">
                    %N
                  </TableCell>
                  <TableCell size="small" align="right">
                    %P
                  </TableCell>
                  <TableCell size="small" align="right">
                    %K
                  </TableCell>
                  <TableCell size="small" align="right">
                    %S
                  </TableCell>
                  <TableCell align="center">Application Rate (kg/ha)</TableCell>
                  <TableCell align="center">N Supplied (kg/ha)</TableCell>
                  <TableCell align="center">P Supplied (kg/ha)</TableCell>
                  <TableCell align="center">K Supplied (kg/ha)</TableCell>
                  <TableCell align="center">S Supplied (kg/ha)</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell
                    className={styles.goodValueColor}
                    align="center"
                    colSpan={9}
                  >
                    Nutrient required
                  </TableCell>
                  <TableCell className={styles.goodValueColor} align="left">
                    {nutrientGroup.N}
                  </TableCell>
                  <TableCell className={styles.goodValueColor} align="left">
                    {nutrientGroup.P}
                  </TableCell>
                  <TableCell className={styles.goodValueColor} align="left">
                    {nutrientGroup.K}
                  </TableCell>
                  <TableCell className={styles.goodValueColor} align="left">
                    {nutrientGroup.S}
                  </TableCell>
                  <TableCell />
                </TableRow>

                {/* THIS IS THE START OF THE ROW */}
                {grouped
                  ? sortProductsByAppTiming(nutrientGroup.blocks[0].productsSelected).map(
                      (
                        productSelected,
                        index // This is for the GROUPED farms, -1 means ALL BLOCKS in group
                      ) => (
                        <ProductSelectLine
                          key={index}
                          products={products}
                          area={parseFloat(
                            nutrientGroup.blocks[0].block_hectares
                          )}
                          productSelected={productSelected}
                          removeProductRow={() => removeProductRow("-1", productSelected.originalIndex)}
                          newComment={(comment) =>
                            newComment(comment, "-1", productSelected.originalIndex)
                          }
                          newAppTiming={(appTiming) =>
                            newAppTiming(appTiming, "-1", productSelected.originalIndex)
                          }
                          newAppMethod={(appMethod) =>
                            newAppMethod(appMethod, "-1", productSelected.originalIndex)
                          }
                          newProductSelected={(newProduct) =>
                            newProductSelected(newProduct, "-1", productSelected.originalIndex)
                          }
                          newRateSelected={(newRate) =>
                            newRateSelected(newRate, "-1", productSelected.originalIndex)
                          }
                          hideComment={true}
                        />
                      )
                    )
                  : nutrientGroup.blocks.map((block, index) => (
                      <NutrientBlock
                        key={index}
                        block={block}
                        products={products}
                        addProductRow={() => addProductRow(block.block_id)}
                        removeProductRow={(productIndex) =>
                          removeProductRow(block.block_id, productIndex)
                        }
                        newComment={(comment, productIndex) =>
                          newComment(comment, block.block_id, productIndex)
                        }
                        newAppTiming={(appTiming, productIndex) =>
                          newAppTiming(appTiming, block.block_id, productIndex)
                        }
                        newAppMethod={(appMethod, productIndex) =>
                          newAppMethod(appMethod, block.block_id, productIndex)
                        }
                        newProductSelected={(newProduct, productIndex) =>
                          newProductSelected(
                            newProduct,
                            block.block_id,
                            productIndex
                          )
                        }
                        newRateSelected={(newRate, productIndex) =>
                          newRateSelected(newRate, block.block_id, productIndex)
                        }
                      />
                    ))}

                {/* <TableRow key={1.423}>
              <TableCell colSpan={13} padding={"none"}>
                <ExpandTableAccord />
              </TableCell>
            </TableRow>
            <TableRow key={1.41}>
              <TableCell colSpan={12} />
            </TableRow> */}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell
                    className={styles.goodValueColor}
                    align="center"
                    colSpan={9}
                  >
                    Calculated Nutrient Total
                  </TableCell>
                  <TableCell
                    className={
                      totalN <= nutrientGroup.N
                        ? styles.goodValueColor
                        : styles.badValueColor
                    }
                    align="left"
                  >
                    {totalN}
                  </TableCell>
                  <TableCell
                    className={
                      totalP <= nutrientGroup.P
                        ? styles.goodValueColor
                        : styles.badValueColor
                    }
                    align="left"
                  >
                    {totalP}
                  </TableCell>
                  <TableCell
                    className={
                      totalK <= nutrientGroup.K
                        ? styles.goodValueColor
                        : styles.badValueColor
                    }
                    align="left"
                  >
                    {totalK}
                  </TableCell>
                  <TableCell
                    className={
                      totalS <= nutrientGroup.S
                        ? styles.goodValueColor
                        : styles.badValueColor
                    }
                    align="left"
                  >
                    {totalS}
                  </TableCell>

                  <TableCell>
                    {grouped && (
                      <IconButton
                        color="secondary"
                        aria-label="arrow_back"
                        onClick={() => addProductRow("-1")}
                        size="small"
                      >
                        <AddCircle />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
