import { APIFetch } from '../../fetch.js';

export const requirementHistory = async ({
  token,
  requirementId,
  fallowYield,
  fallowCropId,
  varietyId,
  plantDate,
  byProductAppliedYear,
  millProductDbName,
  millAppRate,
}) => {
  const response = await APIFetch({
    route: 'requirements/requirementHistory',
    method: 'POST',
    authToken: token,
    body: {
      requirementId,
      fallowYield,
      fallowCropId,
      varietyId,
      plantDate,
      byProductAppliedYear,
      millProductDbName,
      millAppRate,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during RequirementHistory request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
