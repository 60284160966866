import React from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik'

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    },
    label: {
        textAlign: 'left',
        color: '#282828'
    },
    disabledLabel: {
        textAlign: 'left',
        color: '#828282'
    }
}));

export default function FormSelect(props) {
    const classes =  style()
    const { name, label, disabled, options, helpText } = props

    const [field, meta] = useField(props);
    const hasError = Boolean(meta.touched && meta.error)

    return (
        <FormControl className={classes.formControl} error={hasError}>
            <InputLabel className={disabled ? classes.disabledLabel : classes.label } shrink={true}
                htmlFor={`input-${name}`}>{label}
            </InputLabel>
            <Select
                native
                disabled={disabled}
                inputProps={{
                    name: name,
                    id: `input-${name}`
                }}
                {...field}>
                {
                    options.map((option, index) => <option key={index} value={option.value}>{option.name}</option> )
                }
            </Select>
            <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
    );
}
