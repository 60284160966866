import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Timeline } from '@material-ui/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TabContent } from './TabContent';

export const SamplesTabValue = 'samples';

export const validateSample = (sample, data) => {
  const errors = [];

  const farmName = sample.farm;
  const farm = data.find((farm) => farm.name === farmName);
  if (typeof farmName !== 'string') {
    errors.push("Not assigned a Farm.\nPlease ensure Sample has a 'Farm no.'.");
  } else if (farm === undefined) {
    errors.push(
      `Cannot find assigned Farm ('${farmName}') in Spreadsheet.\nPlease ensure Sample has correct 'Farm no.'.`
    );
  } else {
    const blockName = sample.block;
    const block = farm.blocks.find((block) => block.name === blockName);
    if (typeof blockName !== 'string') {
      errors.push(
        "Missing a Block number.\nPlease ensure all rows for this Sample have a value in the 'Block no.' column."
      );
    } else if (block === undefined) {
      errors.push(
        `The Block (${blockName}) does not exist.
Please ensure all rows for this Sample have the correct 'Block no.'
and the Block exists in the same Farm as the Sample.`
      );
    }
  }

  if (sample['Sample date'] === undefined) {
    errors.push('Missing Sample Date.');
  } else if (sample.cleanSampleDate === undefined) {
    errors.push(
      `Unable to parse Sample Date (${sample['Sample date']}).\nExpected format (DD/MM/YYYY).`
    );
  } else if (sample.cleanSampleDateObject > new Date()) {
    errors.push(
      `Sample Date (${sample.cleanSampleDate}) must not be in the future.`
    );
  }

  if (sample['Plant Date'] === undefined) {
    errors.push('Missing Plant Date.');
  } else if (sample.cleanPlantDate === undefined) {
    errors.push(
      `Unable to parse Plant Date (${sample['Plant Date']}).\nExpected format (DD/MM/YYYY).`
    );
  } else if (sample.cleanPlantDateObject > new Date()) {
    errors.push(
      `Plant Date (${sample.cleanPlantDate}) must not be in the future.`
    );
  }

  const soilTextures = ['Clay', 'Loam', 'Sand'];
  if (soilTextures.includes(sample['Soil texture']) === false) {
    errors.push('Soil Texture must be Clay, Loam or Sand');
  }

  if (sample['Crop class at sampling'] !== 'Fallow' && sample['Crop class at sampling'].substring(0, 6) !== 'Ratoon') {
    errors.push('Crop Class at sampling must be Fallow or Ratoon (1-15)');
  }

  return errors.length === 0
    ? { isValid: true }
    : { isValid: false, errors: errors };
};

export const SamplesValidator = (data) => {
  const samples = data.reduce((samples, farm) => {
    samples.push(...farm.samples);
    return samples;
  }, []);
  const errors = samples.flatMap(
    (sample) => validateSample(sample, data).errors ?? []
  );
  return errors.length > 0
    ? { success: false, errors: errors }
    : { success: true };
};

export const SamplesTabContent = ({ value, data }) => {
  useEffect(() => {
    console.log('Samples', data);
  }, [data]);

  return (
    <TabContent value={value} tabValue={SamplesTabValue}>
      <List>
        {data.map((farm) => (
          <FarmSamplesSection key={farm.name} farm={farm} />
        ))}
      </List>
    </TabContent>
  );
};

const FarmSamplesSection = ({ farm }) => {
  const [isOpen, setIsOpen] = useState(true);

  const groupedSamples = useMemo(() => {
    return farm.samples.reduce((samples, sample) => {
      const existingSample = samples.find(
        (other) => other['Soil test number'] === sample['Soil test number']
      );
      if (existingSample !== undefined) {
        existingSample.blocks.push(sample.block);
        return samples;
      }
      const { block, ...strippedSample } = sample;
      samples.push({ ...strippedSample, blocks: [block] });
      return samples;
    }, []);
  }, [farm]);

  const handleClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <ListSubheader style={{ backgroundColor: '#F5FBFD', padding: 0 }}>
        <ListItem
          button
          style={{ paddingTop: 0, paddingBottom: 0 }}
          onClick={handleClick}
        >
          <ListItemText
            primaryTypographyProps={{ color: 'textPrimary' }}
            primary={farm.name}
            secondary={`${groupedSamples.length ?? 0} Samples`}
          />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </ListSubheader>
      <ListItem>
        <Collapse in={isOpen} style={{ width: '100%' }}>
          <List>
            {groupedSamples.map((sample) => (
              <SampleListItem
                key={sample['Soil test number']}
                sample={sample}
              />
            ))}
          </List>
        </Collapse>
      </ListItem>
    </>
  );
};

const tableColumns = [
  { name: 'Soil texture', label: 'Soil Texture' },
  {
    name: 'Sample date',
    clean: 'cleanSampleDate',
    label: 'Sample Date',
    type: 'date',
  },
  { name: 'pH 1:5 water', label: 'pH (1:5 Water)' },
  { name: 'pH Ca Cl2', label: 'pH (1:5 Cacl2)' },
  { name: 'Organic carbon %', label: 'Organic Carbon (W&B)' },
  {
    name: 'Electrical conductivity 1:5 water',
    label: 'Electrical Conductivity (1:5 water)',
  },
  { name: 'Electrical conductivity se', label: 'Elec. Cond. (Sat. Ext.)' },
  { name: 'Chloride ppm', label: 'Chloride' },
  { name: 'Nitrate N', label: 'Nitrate Nitrogen (NO3)' },
  { name: 'Ammonium N', label: 'Ammonium Nitrogen' },
  { name: 'Phosphorus BSES', label: 'Phosphorus (BSES)mg/kg' },
  { name: 'Phosphorus Colwell', label: 'Phosphorus (Colwell)mg/kg' },
  {
    name: 'Phosphorus Buffer Index',
    label: 'Phosphorus Buffer Index (PBI-Col)',
  },
  { name: 'Potassium HNO3', label: 'Potassium (HNO3)Meq/100g' },
  { name: 'Sulfate S', label: 'Sulfate Sulfur (MCP)mg/kg' },
  { name: 'Calcium exch cmol/kg', label: 'Calcium (Amm-acet.)Meq/100g' },
  {
    name: 'Magnesium exch cmol/kg',
    label: 'Magnesium (Amm-acet.)Meq/100g',
  },
  { name: 'Potassium exch cmol/kg', label: 'Potassium (Amm-acet.)Meq/100g' },
  { name: 'Sodium exch cmol/kg', label: 'Sodium (Amm-acet.)Meq/100g' },
  {
    name: 'Aluminium exch cmol/kg',
    label: 'Aluminium (KCl)Meq/100g',
  },
  { name: 'Cation Exchange Capacity', label: 'Cation Exch. Cap.Meq/100g' },
  { name: 'Calcium % of CEC', label: 'Calcium (Amm-acet.)%' },
  { name: 'Magnesium % of CEC', label: 'Magnesium (Amm-acet.)%' },
  { name: 'Potassium % of CEC', label: 'Potassium (Amm-acet.)%' },
  { name: 'Sodium % of CEC', label: 'Sodium % of Cations (ESP)%' },
  { name: 'Aluminium % of CEC', label: 'Aluminium Saturation%' },
  { name: 'Calcium/Magnesium ratio', label: 'Ca:Mg  ratio' },
  { name: 'Copper mg/kg DTPA', label: 'Copper (DTPA)mg/kg' },
  { name: 'Zinc mg/kg DTPA', label: 'Zinc (DTPA)mg/kg' },
  { name: 'Zinc mg/kg BSES', label: 'HCl Zinc mg/kg' },
  { name: 'Iron mg/kg DTPA', label: 'Iron (DTPA)mg/kg' },
  { name: 'Manganese mg/kg DTPA', label: 'Manganese (DTPA)mg/kg' },
  { name: 'Boron', label: 'Boron mg/kg' },
  { name: 'Silicon BSES', label: 'Silicon (BSES)mg/kg' },
  { name: 'Silicon CaCl2', label: 'Silicon (CaCl2)mg/kg' },
];

const SampleListItem = ({ sample }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <ListItemText
          primary={`${sample['Soil test number']}`}
          secondary={`Block${
            sample.blocks.length > 1 ? 's' : ''
          } (${sample.blocks.join(', ')})`}
        />
        {isOpen ? (
          <ExpandLess style={{ opacity: 0.54 }} />
        ) : (
          <ExpandMore style={{ opacity: 0.54 }} />
        )}
        <Timeline color="primary" />
      </ListItem>
      <Collapse in={isOpen}>
        <Table size="small">
          <TableHead style={{ backgroundColor: '#FAFAFA' }}>
            <TableRow>
              <TableCell>Sample Type</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableColumns.map((column) => {
              const rawValue =
                (column.clean !== undefined && sample[column.clean]) ||
                sample[column.name];
              const value =
                typeof column.parser === 'function'
                  ? column.parser(rawValue)
                  : rawValue;
              return (
                <TableRow key={column.label}>
                  <TableCell>{column.label}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Collapse>
    </>
  );
};
