import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MillMods from './MillMods';

export default function STable(props) {
  const { sample, tableData, requirement } = props;


  // console.log(sample);

  // Extract all the unique exchangeable_potassium values
  const columnValues = [
    ...new Set(
      tableData.sulphur.map((el) => {
          return el.mineralisation_index_max;
        }),
    ),
  ].sort((a, b) => a -b);

  const getCellColor = (correctSulfateSRange, correctIndex) => {
    if (correctSulfateSRange && correctIndex) return 'rgba(65, 172, 210, 0.6)';

    // return 'rgba(0, 161, 201, 0.1)';
    // return 'rgba(0, 157, 72, 0.1)';

    return '#FFF';
  };

  const filterTable = (sulfateSRange, indexRange, index) => {
    //el.sulphate_s_min <= sulfateSRange && 
    const filtered = tableData.sulphur.filter((el) => {
      return (
        el.sulphate_s_max === sulfateSRange &&
        el.mineralisation_index_min < indexRange &&
        el.mineralisation_index_max >= indexRange
      );
    });

    if (filtered.length === 0) return <TableCell>0</TableCell>;
    const correctSulfateSRange = filtered[0].sulphate_s_min <= sample.sulfate_suplfur_mcp_mg_kg && filtered[0].sulphate_s_max > sample.sulfate_suplfur_mcp_mg_kg;
    const correctIndex = filtered[0].mineralisation_index_min < sample.organic_carbon && filtered[0].mineralisation_index_max >= sample.organic_carbon;

    return (
      <TableCell
        style={{
          background: getCellColor(
            correctSulfateSRange,
            correctIndex,
          ),
        }}
      >
        {filtered[0].sulphur_application_rate}
      </TableCell>
    );
  };

  return (
    <div>
      <Typography>Sulfate S (mg/kg): {sample.sulfate_suplfur_mcp_mg_kg}</Typography>
      <Typography>Organic C: {sample.organic_carbon}%</Typography>
      <TableContainer  style={{ marginBottom: 20}}>
        <Table stickyHeader size='small'>
          <TableHead>
          <TableRow>
            <TableCell >Sulphate S</TableCell>
            <TableCell colSpan={3}>N mineralisation index</TableCell>
            </TableRow>
            <TableRow>
              <TableCell >(mg/kg)</TableCell>
              <TableCell >{`VL - L (< 0.8 OC%)`}</TableCell>
              <TableCell >{`ML - M (0.8 - 1.6 OC%)`}</TableCell>
              <TableCell >{`MH - VH (> 1.6 OC%)`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            <TableRow>
              <TableCell >{`< 5`}</TableCell>
              {columnValues.map((el, index) => filterTable(5, el, index))}
            </TableRow>
            <TableRow>
              <TableCell >{`5 - 10`}</TableCell>
              {columnValues.map((el, index) => filterTable(10, el, index))}
            </TableRow>
            <TableRow>
              <TableCell >{`10 - 15`}</TableCell>
              {columnValues.map((el, index) => filterTable(15, el, index))}
            </TableRow>
            <TableRow>
              <TableCell >{`> 15`}</TableCell>
              {columnValues.map((el, index) => filterTable(100, el, index))}
            </TableRow>   
          </TableBody>
        </Table>
      </TableContainer>

      <MillMods nutrient='Sulphur' element='S' tableData={tableData.sulphur_mod} requirement={requirement} />

    </div>
  );
}
