import React, {useCallback, useEffect, useState} from "react";
// import {getSugarPrices} from "../graphQL/Product";
// import getFertPrices from '../graphQL/GetFertPrices';
import {API, graphqlOperation} from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Cloudy from '../assets/Cloudy.png';
import Rain from '../assets/Rain.png';
import Sunny from '../assets/Sunny.png';
//Tab Components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { getWeather } from '../graphQL/Farm'
//Card Components
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

function TabPanel(props){
    const {children, value, index, ...other} = props;

    return (
        <div
            role = "tabpanel"
            hidden = {value !== index}
            id = {`simple-tabpanel-${index}`}
            {...other}>
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index){
    return{
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'auto'
	},
	cards: {
		display: 'inline-block',
		alignContent: 'center',
		margin: 10,
		background: '#e0f7fa'
	},
	media: {
		height: 150,
		width: 150,
		objectFit: 'none',
		backgroundColor: '#42a5f5',
	}
  }));


  export default function Forecast(props) {
	const classes = useStyles();

	// const[sugar, setSugar] = useState([])
    // const[fert, setFert] = useState([])
    const[value, setValue] = useState(0)
	// const[weatherValue, setWeatherValue] = useState(0)
	let dateArray = [];
	let uniqueDateArray = [];

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

	// const weatherChange = (event, newValue) => {
    //     setWeatherValue(newValue)
    // }

	const farmName = props.match.params.farm
	//console.log(farmName)
	const[items, setItems] = useState([])
	const[DataisLoaded, setDataisLoaded] = useState(false)

	const getWeatherDetails = useCallback(() => {
		API.graphql(graphqlOperation(getWeather.query, { farmId: farmName }))
		.then(async (result) => {
			//console.log('RESULT', result)
			const blockGeoms = JSON.parse(result.data.getWeather)
			console.log('BLOCKGEOMS', blockGeoms)
			setItems(blockGeoms)
			setDataisLoaded(true)

		})
		.catch(err => {
			console.log('Error', err);
		})
	}, [farmName])

	useEffect(()=> {
		getWeatherDetails();

}, [getWeatherDetails]);


// const getSugarDetails = useCallback(() => {
// 	API.graphql(graphqlOperation(getSugarPrices.query, {}))
// 	.then(async (result) => {
// 		//console.log('RESULT', result)
// 		const sugarPrices = JSON.parse(result.data.getSugarPrices)
// 		console.log('SUGAR', sugarPrices)
// 		setSugar(sugarPrices)
// 	})
// 	.catch(err => {
// 		console.log('Error', err);
// 	})
// }, [])

// const getFertDetails = useCallback(() => {
// 	API.graphql(graphqlOperation(getFertPrices.query, {}))
// 	.then(async (result) => {
// 		//console.log('RESULT', result)
// 		const fertPrices = JSON.parse(result.data.getFertPrices)
// 		console.log('FERT', fertPrices)
// 		setFert(fertPrices)
// 	})
// 	.catch(err => {
// 		console.log('Error', err);
// 	})
// }, [])

// useEffect(()=> {
// 	getSugarDetails();
// }, [getSugarDetails]);

// useEffect(() => {
// 	getFertDetails();
// }, [getFertDetails]);

function weatherFilter(file, date) {
	return (file.list.filter(v => v.dt_txt.split(" ")[0] === date))
}

let images
	return(
		<div>
		{
			DataisLoaded === false ?
			<div>
				<h1> Pleses wait some time.... </h1>
			</div>
			:
				<div className="App">
					<div className={classes.root}>
						<div style={{textAlign:'left', paddingLeft: 25}}>
							<h1> Weather Data </h1>
						</div>
						<div className={classes.root}>
							<AppBar position="static">
								<Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
									{items && items?.list.map((item) => {
											return dateArray.push(item.dt_txt.split(" ")[0]);
										})}
									{uniqueDateArray = [...new Set(dateArray)]}
									{uniqueDateArray.map((date, idx) =>{
										return(
											<Tab label={date} {...a11yProps(idx)}/>
										);
									})}
									{console.log("EQUALS",weatherFilter(items, "2022-05-24"))}


								</Tabs>
							</AppBar>
						</div>
					<div>
					<Grid item xs={12} style={{textAlign: "center"}}>
						{uniqueDateArray.map((date, idx)=>{
							let filter = weatherFilter(items, date)
							return(
								<TabPanel value={value} index={idx}>
								{filter.map((item) =>
									<Card className={classes.cards} key={item.dt} styles={{maxWidth: 20, margin: 15}}>
									<CardActionArea>
										{item.weather.map((weather) => {
										if(weather.main.includes("Clouds")){
											return images = Cloudy
										}
										else if(weather.main.includes("Rain")){
											return images = Rain

										}
										else{
											return images = Sunny
										}
										})}
										<CardMedia
										component="img"
										alt="Weather Icon"
										image={images}
										title="Weather"
										className={classes.media}
										/>
										<CardContent>
										<Typography gutterBottom variant="h5" component="h2">
											{item.dt_txt.split(" ")[1]}
										</Typography>
										<Typography variant="body2" color="textSecondary" component="p">
											{item.weather.map((weather) =>
											<div><b>Description:</b>
												<br></br>
												{weather.description}</div>
											)}
											<br></br>
											<b>Temp:</b> {item.main.temp}
											<br></br>
											<b>-Min:</b> {item.main.temp_min}
											<br></br>
											<b>-Max</b> {item.main.temp_max}
										</Typography>
										</CardContent>
									</CardActionArea>
									</Card>
									)}
								</TabPanel>
							);
						})}

					</Grid>
					</div>
				</div>
			</div>
		}
	</div>
)}
