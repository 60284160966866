import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function LabSamplesTable(props) {
  const classes = style();
  const labSamples = props.labSamples;

  // console.log(labSamples);
  const columns = [
    { id: "labSampleNumber", label: "Sample Number", minWidth: 50 },
    { id: "lat", label: "Lat", minWidth: 50 },
    { id: "lng", label: "Lng", minWidth: 50 },
    { id: "sampling_date", label: "Sample Date", minWidth: 50 },
    { id: "blockName", label: "Block Name", minWidth: 50 },
    { id: "blockArea", label: "Area", minWidth: 50 },
    { id: "active", label: "Active", minWidth: 30 },
    // { id: 'samplingDate', label: 'Sampling Date', minWidth: 50 },
    // { id: 'description', align: 'left', label: 'Description', minWidth: 170 },
  ];

  return (
    <>
      <TableContainer className={classes.root}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "rgba(0, 161, 201, 0.1)",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {labSamples.map((sample, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {sample.lab_sample_number}
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.lat}
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.lng}
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.sampling_date?.split("-").reverse().join("/")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.block_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.block_hectares} ha
                </TableCell>
                <TableCell component="th" scope="row">
                  {sample.active ? "Active" : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const style = makeStyles((theme) => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: "100%",
  },
}));
