
  const formatDate = (date) => {
  
    // TODO: maybe in a context with language etc
    const timeOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
      hour12: false,
      timeZone: "Australia/Sydney",
    };

    return new Intl.DateTimeFormat("en-AU", timeOptions).format(
      new Date(date)
    )
  }
export { formatDate };
