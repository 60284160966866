import React, { useMemo } from 'react';
import { Overview } from './Overview';
import { validateSample } from '../SamplesTabContent';

export const SamplesOverview = (props) => {
  const { data } = props;

  const farmSampleAOA = useMemo(() => {
    const entries = [];
    data.forEach(({ name: farmName, samples }) => {
      let entry = entries.find((entry) => entry.farmName === farmName);
      if (entry === undefined) {
        entry = { farmName: farmName, samples: [] };
        entries.push(entry);
      }

      samples.forEach((sample) => {
        const soilTestNumber = sample['Soil test number'];
        let sampleEntry = entry.samples.find(
          (sampleEntry) => sampleEntry.soilTestNumber === soilTestNumber
        );
        if (sampleEntry === undefined) {
          sampleEntry = {
            soilTestNumber: soilTestNumber,
            blockNames: [],
            errors: [],
          };
          entry.samples.push(sampleEntry);
        }

        const { errors } = validateSample(sample, data);
        if (errors !== undefined) sampleEntry.errors.push(...errors);
        sampleEntry.blockNames.push(sample.block ?? '?-?');
        sampleEntry.blockNames.sort();
      });
    });

    return entries;
  }, [data]);

  const items = useMemo(() => {
    return farmSampleAOA.reduce((sampleItems, farm) => {
      const farmItems = farm.samples.map((sample) => {
        const { blockNames, errors } = sample;
        const uniqueErrors = [...new Set(errors)];

        const blocksPart = `${
          blockNames.length > 1 ? 'Blocks' : 'Block'
        } (${blockNames.join(', ')})`;

        const value = `${farm.farmName} - ${sample.soilTestNumber} - ${blocksPart}`;
        return uniqueErrors.length > 0
          ? { value, errors: uniqueErrors }
          : { value };
      });
      sampleItems.push(...farmItems);
      return sampleItems;
    }, []);
  }, [farmSampleAOA]);

  return <Overview itemLabel={'Sample'} items={items} />;
};
