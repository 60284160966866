import React from 'react'
import { Form, FormikProvider } from 'formik'

const FormikForm = props => {
    const {formik, children} = props

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off">
                {children}
            </Form>
        </FormikProvider>
    )
}

export default FormikForm
