import React, { useMemo } from 'react';
import { validateBlock } from '../BlocksTabContent';
import { Overview } from './Overview';

export const BlocksOverview = (props) => {
  const { blocks, data } = props;

  const blockItems = useMemo(
    () =>
      blocks.map((block) => {
        const { isValid, errors } = validateBlock(block, data);
        const item = { value: `${block.farm} - ${block.name}` };
        return isValid ? item : { ...item, errors };
      }),
    [blocks, data]
  );

  return <Overview itemLabel={'Block'} items={blockItems} />;
};
