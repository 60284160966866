import React, { useCallback, useEffect, useState } from 'react';
// LIBRARIES
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// COMPONENTS

import NutrientGroup from '../../../../components/ProductSelection/NutrientGroup';
import { useAlertContext } from '../../../../components/AlertContext';

// FUNCTIONS
// import { farmFertTotals } from "../functions/reports/totalsTableCalcs";
// import { filterFertYear } from '../functions/filterDataForFertYear';

// MATERIAL UI
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import { makeStyles } from '@material-ui/core/styles';

// QUERIES
import { getProducts, updateProductsSelected } from '../../../../graphQL/Product';
// import { getFarmBlocks, getFarmBlockRequirementsYear } from '../graphQL/Farm';


const useStyle = makeStyles((theme) => ({
  header: {
    margin: 10,
  },
  footer: {
    marginTop: 2,
    padding: 0,
    backgroundColor: 'white',
    position: 'fixed',
    top: 60,
    left: 0,
    width: '100%',
  },
  headSearch: {
    width: '100%',
    flexDirection: 'row',
  },
  input: {
    width: '50%',
  },
  goodValueColor: {
    backgroundColor: 'rgba(0, 157, 72, 0.2)',
  },
  badValueColor: {
    backgroundColor: 'rgba(100, 0, 72, 0.2)',
  },
}));

let removedProductIds = [];

export default function ProductSelectionView({ farmId, farmName, admin, agronomist, fertYear, productCount, view, filteredBlocks, selectedBlocks, modifySelectedBlocks }) {
  const alertContext = useAlertContext();
  // const queryClient = useQueryClient();

  const classes = useStyle();


  const trigger = useScrollTrigger({ threshold: 220, disableHysteresis: true });

  const [filteredNutrientGroupedBlocks, setfilteredNutrientGroupedBlocks] = useState([]);

  const [nutrientGroupedBlocks, setNutrientGroupedBlocks] = useState([]);
  const [notInCycleArea, setNotInCycleArea] = useState(0);
  const [totalFarmArea, setTotalFarmArea] = useState(0);

  const [saveRequired, setSaveRequired] = useState(false);


  const { data: products, isLoading: productsLoading } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const result = await API.graphql(graphqlOperation(getProducts.query));
      return JSON.parse(result.data.getProducts);
    },
  });

  const cropClassFilters = ['All', 'Fallow', 'Ratoon', 'PORP', 'PO'];
  const [cropClass, setCropClass] = useState('All');
  const handleCropClassChange = (event) => {
    setCropClass(event.target.value);
  };

  //Filter Plant (includes Fallow), PORP, Ratoon (includes Plant?), Plough Out & Old fallow/Out of production <= We don't have these as of yet
  const filterGroups = useCallback(() => {
    console.log('ALL', nutrientGroupedBlocks);
    if (cropClass === 'All') {
      setfilteredNutrientGroupedBlocks(nutrientGroupedBlocks);
    } else {
      let newFilter = nutrientGroupedBlocks
        .map((nutrientGroupedBlock) => ({
          ...nutrientGroupedBlock,
          blocks: nutrientGroupedBlock.blocks.filter((block) => {
            if (cropClass === 'Fallow') {
              return (
                JSON.stringify(block.crop_class_name)
                  .toLowerCase()
                  .indexOf(cropClass.toLowerCase()) !== -1 ||
                JSON.stringify(block.crop_class_name).toLowerCase().indexOf('plant') !== -1
              );
            } else {
              return (
                JSON.stringify(block.crop_class_name)
                  .toLowerCase()
                  .indexOf(cropClass.toLowerCase()) !== -1
              );
            }
          }),
        }))
        .filter((value) => Object.keys(value.blocks).length !== 0);
      setfilteredNutrientGroupedBlocks(newFilter);
      console.log('FILTER', newFilter);
    }
    // console.log("*********************", filteredNutrientGroupedBlocks);
  }, [nutrientGroupedBlocks, cropClass]);

  useEffect(() => {
    if (nutrientGroupedBlocks?.length > 0) {
      filterGroups();
    }
  }, [cropClass, nutrientGroupedBlocks, filterGroups]);

  // ********************************************************************** Manipulation functions **********************************************************************
  const addProductRow = useCallback(
    (groupIndex, blockId) => {
      console.log('addProductRow', groupIndex, blockId);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              return {
                ...block,
                productsSelected: [
                  ...block.productsSelected,
                  {
                    id: null,
                    fertilizer_year: parseInt(fertYear),
                    apptiming: 'Standing Crop',
                    appmethod: 'Stool Split',
                    apprate: 0,
                    productid: 260,
                    name: '',
                    n: 0,
                    p: 0,
                    k: 0,
                    s: 0,
                    nutrient_group: GroupedBlocks.ngbIndex,
                  },
                ],
              };
            } else return { ...block };
          });
          console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks, fertYear]
  );

  const removeProductRow = useCallback(
    (groupIndex, blockId, productLineIndex) => {
      console.log('removeProductRow', groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              // Add the primary key to the removed list if it exists
              if (block.productsSelected[productLineIndex].id !== null) {
                removedProductIds.push(block.productsSelected[productLineIndex].id);
              }
              block.productsSelected.splice(productLineIndex, 1);
              return block;
            } else return { ...block };
          });
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  const newComment = useCallback(
    (comment, groupIndex, blockId, productLineIndex) => {
      console.log('newComment', comment, groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              block.productsSelected[productLineIndex].comment = comment;
              if (block.productsSelected[productLineIndex].id != null) {
                block.productsSelected[productLineIndex].updated = 'T';
              }
              // console.log(block);
              return block;
            } else return { ...block };
          });
          // console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  const newAppTiming = useCallback(
    (appTiming, groupIndex, blockId, productLineIndex) => {
      console.log('newAppTiming', appTiming, groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              block.productsSelected[productLineIndex].apptiming = appTiming;
              if (block.productsSelected[productLineIndex].id != null) {
                block.productsSelected[productLineIndex].updated = 'T';
              }
              // console.log(block);
              return block;
            } else return { ...block };
          });
          // console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  const newAppMethod = useCallback(
    (appMethod, groupIndex, blockId, productLineIndex) => {
      console.log('newAppMethod', appMethod, groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              block.productsSelected[productLineIndex].appmethod = appMethod;
              if (block.productsSelected[productLineIndex].id != null) {
                block.productsSelected[productLineIndex].updated = 'T';
              }
              // console.log(block);
              return block;
            } else return { ...block };
          });
          // console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  const newProductSelected = useCallback(
    (newProduct, groupIndex, blockId, productLineIndex) => {
      console.log('newProductSelected', newProduct, groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              block.productsSelected[productLineIndex].name = newProduct.name;
              block.productsSelected[productLineIndex].n = newProduct.n;
              block.productsSelected[productLineIndex].p = newProduct.p;
              block.productsSelected[productLineIndex].k = newProduct.k;
              block.productsSelected[productLineIndex].s = newProduct.s;
              block.productsSelected[productLineIndex].productid = newProduct.id;
              if (block.productsSelected[productLineIndex].id != null) {
                block.productsSelected[productLineIndex].updated = 'T';
              }
              // console.log(block);
              return block;
            } else return { ...block };
          });
          // console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  const newRateSelected = useCallback(
    (newRate, groupIndex, blockId, productLineIndex) => {
      console.log('newRateSelected', newRate, groupIndex, blockId, productLineIndex);
      setSaveRequired(true);
      const newGroupedBlocks = nutrientGroupedBlocks.map((GroupedBlocks) => {
        if (GroupedBlocks.ngbIndex === groupIndex) {
          const blocks = GroupedBlocks.blocks.map((block) => {
            if (block.block_id === blockId || blockId === '-1') {
              block.productsSelected[productLineIndex].apprate = newRate;
              if (block.productsSelected[productLineIndex].id != null) {
                block.productsSelected[productLineIndex].updated = 'T';
              }
              // console.log(block);
              return block;
            } else return { ...block };
          });
          // console.log(blocks);
          return { ...GroupedBlocks, blocks };
        } else return GroupedBlocks;
      });
      // console.log(newGroupedBlocks);
      setNutrientGroupedBlocks(newGroupedBlocks);
    },
    [nutrientGroupedBlocks]
  );

  // ****************************************** THIS IS THE CALC'S FOR THE FILTERED FARM AREA **************************************************************

  const filteredArea =
    filteredNutrientGroupedBlocks &&
    Math.round(
      filteredNutrientGroupedBlocks.reduce(
        (prev, cur) => prev + cur.blocks.reduce((a, b) => a + parseFloat(b.block_hectares), 0),
        0
      ) * 100
    ) / 100;

  const filteredProportion = Math.round((filteredArea / totalFarmArea) * 1000) / 10;

  // ****************************************** THIS IS THE CALC'S FOR THE TOP TOTAL FARM BUDGET **************************************************************

  function calcFarmTotalNutrient(nutrientToTotal) {
    return nutrientGroupedBlocks?.length > 0
      ? Math.round(
          nutrientGroupedBlocks.reduce(
            (prev, cur) =>
              prev +
              cur.blocks.reduce(
                (a, b) => a + b[nutrientToTotal] * parseFloat(b.block_hectares),
                0
              ),
            0
          )
        )
      : 0;
  }

  // Loops through all groups.blocks.productsSelected and calculates total kg's of Nutrient selected
  function calcSelectedTotalNutrient(nutrientToTotal) {
    return nutrientGroupedBlocks?.length > 0
      ? Math.round(
          nutrientGroupedBlocks.reduce(
            (prevGroup, curGroup) =>
              prevGroup +
              curGroup.blocks.reduce(
                (prev, cur) =>
                  prev +
                  (cur.productsSelected
                    ? cur.productsSelected.reduce(
                        (prevProd, curProd) =>
                          prevProd +
                          (((curProd[nutrientToTotal] || 0) * (curProd.apprate || 0)) / 100) *
                            parseFloat(cur.block_hectares),
                        0
                      )
                    : 0),
                0
              ),
            0
          )
        )
      : 0;
  }

  // Loops through all groups.blocks.productsSelected and calculates total kg's of Nutrient selected
  function calcPartcialNutrientDeductions(nutrientToTotal) {
    return nutrientGroupedBlocks?.length > 0
      ? Math.round(
          nutrientGroupedBlocks.reduce(
            (prevGroup, curGroup) =>
              prevGroup +
              curGroup.blocks.reduce(
                (prev, cur) =>
                  prev +
                  (cur.productsSelected
                    ? cur.productsSelected.reduce(
                        (prevProd, curProd) =>
                          prevProd +
                          (((curProd[nutrientToTotal] || 0) * (curProd.apprate || 0)) / 100) *
                            (parseFloat(cur.block_hectares) -
                              (curProd.applied || cur.block_hectares)),
                        0
                      )
                    : 0),
                0
              ),
            0
          )
        )
      : 0;
  }


  const calculatedFarmFertTotals = {
    totalN: calcFarmTotalNutrient('nitrogen'),
    totalP: calcFarmTotalNutrient('phosphorus'),
    totalK: calcFarmTotalNutrient('potassium'),
    totalS: calcFarmTotalNutrient('sulphur'),
    calculatedN: calcSelectedTotalNutrient('n'),
    calculatedP: calcSelectedTotalNutrient('p'),
    calculatedK: calcSelectedTotalNutrient('k'),
    calculatedS: calcSelectedTotalNutrient('s'),
    PartialN: calcPartcialNutrientDeductions('n'),
    PartialP: calcPartcialNutrientDeductions('p'),
    PartialK: calcPartcialNutrientDeductions('k'),
    PartialS: calcPartcialNutrientDeductions('s'),
  };
  // console.log(calculatedFarmFertTotals);
  // console.log(farmFertTotals(location.state.reducedRes));
  const diffN =
    calculatedFarmFertTotals.calculatedN -
    calculatedFarmFertTotals.totalN -
    calculatedFarmFertTotals.PartialN;
  const diffP =
    calculatedFarmFertTotals.calculatedP -
    calculatedFarmFertTotals.totalP -
    calculatedFarmFertTotals.PartialP;
  const diffK =
    calculatedFarmFertTotals.calculatedK -
    calculatedFarmFertTotals.totalK -
    calculatedFarmFertTotals.PartialK;
  const diffS =
    calculatedFarmFertTotals.calculatedS -
    calculatedFarmFertTotals.totalS -
    calculatedFarmFertTotals.PartialS;
  /*
input SelectedProduct {
	id: String
	block_id: String
	fertilizer_year: String
	recommended_product_id: String
	recommended_app_method: String
	recommended_app_rate: String
	recommended_app_timing: String
}*/

  function saveChanges() {
    //updatedProductRecords
    let data = {
      updates: [],
      inserts: [],
      removes: removedProductIds,
    };
    let noChange = [];
    console.log(nutrientGroupedBlocks);
    nutrientGroupedBlocks.forEach((GroupedBlocks) => {
      GroupedBlocks.blocks.forEach((block) => {
        block.productsSelected.forEach((product) => {
          let reducedProduct = {
            id: product.id,
            block_id: block.block_id,
            requirement_id: block.requirement_id,
            fertilizer_year: product.fertilizer_year,
            recommended_product_id: product.productid,
            recommended_app_method: product.appmethod,
            recommended_app_rate: product.apprate,
            recommended_app_timing: product.apptiming,
            nutrient_group: product.nutrient_group || GroupedBlocks.ngbIndex,
            comment: (product.comment?.trim().length ?? 0) > 0 ? product.comment.trim() : null,
          };
          product.id === null
            ? data.inserts.push(reducedProduct)
            : product.updated === 'T'
            ? data.updates.push(reducedProduct)
            : noChange.push(reducedProduct);
        });
      });
    });

    console.log(data);

    // setLoading(true);
    API.graphql(
      graphqlOperation(updateProductsSelected.mutation, {
        data,
      })
    )
      .then(async (result) => {
        alertContext.success(`Products Saved`);
        removedProductIds = [];
        // queryClient.invalidateQueries(['productRequirements', farmId, fertYear]);
        // queryClient.invalidateQueries('blocks');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        alertContext.error(err, `Error saving products`);
      });

    setSaveRequired(false);
  }

  const Footer = () => (
    <>
      <Slide appear={false} direction='down' in={trigger}>
        <div className={classes.footer}>
          <TableContainer align='center'>
            <Table size='small' align='center'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align='right'>Nutrient Difference (kg)</TableCell>
                  <TableCell
                    align='center'
                    className={diffN <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    N: {diffN}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffP <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    P: {diffP}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffK <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    K: {diffK}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffS <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    S: {diffS}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      </Slide>
    </>
  );

  return (
    <div>
      
        <div align='center' style={{}}>
          <TableContainer align='center'>
            <Table size='small' align='center'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align='center'>N</TableCell>
                  <TableCell align='center'>P</TableCell>
                  <TableCell align='center'>K</TableCell>
                  <TableCell align='center'>S</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align='right'>Nutrient required (kg)</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.totalN}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.totalP}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.totalK}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.totalS}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right'>Fert plan totals (kg)</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.calculatedN}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.calculatedP}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.calculatedK}</TableCell>
                  <TableCell align='center'>{calculatedFarmFertTotals.calculatedS}</TableCell>
                </TableRow>
                {(calculatedFarmFertTotals.PartialN > 0 ||
                  calculatedFarmFertTotals.PartialP > 0 ||
                  calculatedFarmFertTotals.PartialK > 0 ||
                  calculatedFarmFertTotals.PartialS > 0) && (
                  <TableRow>
                    <TableCell align='right'>Partial deductions (kg)</TableCell>
                    <TableCell align='center'>{calculatedFarmFertTotals.PartialN}</TableCell>
                    <TableCell align='center'>{calculatedFarmFertTotals.PartialP}</TableCell>
                    <TableCell align='center'>{calculatedFarmFertTotals.PartialK}</TableCell>
                    <TableCell align='center'>{calculatedFarmFertTotals.PartialS}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align='right'>Difference (kg)</TableCell>
                  <TableCell
                    align='center'
                    className={diffN <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    {diffN}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffP <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    {diffP}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffK <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    {diffK}
                  </TableCell>
                  <TableCell
                    align='center'
                    className={diffS <= 0 ? classes.goodValueColor : classes.badValueColor}
                  >
                    {diffS}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          {/* <ProductHeader /> */}
        </div>
        <br />
        <div style={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <FormControl style={{ marginLeft: 20 }}>
                <Select
                  style={{ width: 250 }}
                  labelId='select-cropClass'
                  id='cropClassSelector'
                  value={cropClass}
                  onChange={handleCropClassChange}
                >
                  {cropClassFilters.map((cropClassFilter, index) => (
                    <MenuItem value={cropClassFilter} key={index}>
                      {cropClassFilter}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Select Crop Class</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                style={{ marginLeft: 'auto' }}
                color='secondary'
                variant='contained'
                onClick={saveChanges}
                disabled={!saveRequired}
              >
                SUBMIT
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <span>
                In Cycle Area: {totalFarmArea}
                ha{' '}
              </span>
              <br />
              <span> Area Selected: {filteredArea}ha</span>
              <br />
              <span> Not in Cycle: {notInCycleArea}ha</span>
            </Grid>
            <Grid item xs={12} sm={1}>
              <span>Proportion: {filteredProportion}%</span>
            </Grid>
          </Grid>
        </div>
        <div>
          {products?.length > 0 &&
            filteredNutrientGroupedBlocks?.length > 0 &&
            filteredNutrientGroupedBlocks.map((nutrientGroup, index) => (
              <NutrientGroup
                key={index}
                products={products}
                nutrientGroup={nutrientGroup}
                addProductRow={(blockId) => addProductRow(nutrientGroup.ngbIndex, blockId)}
                removeProductRow={(blockid, productIndex) =>
                  removeProductRow(nutrientGroup.ngbIndex, blockid, productIndex)
                }
                newComment={(comment, blockid, productIndex) => 
                  newComment(comment, nutrientGroup.ngbIndex, blockid, productIndex)
                }
                newAppTiming={(appTiming, blockid, productIndex) =>
                  newAppTiming(appTiming, nutrientGroup.ngbIndex, blockid, productIndex)
                }
                newAppMethod={(appMethod, blockid, productIndex) =>
                  newAppMethod(appMethod, nutrientGroup.ngbIndex, blockid, productIndex)
                }
                newProductSelected={(newProduct, blockid, productIndex) =>
                  newProductSelected(newProduct, nutrientGroup.ngbIndex, blockid, productIndex)
                }
                newRateSelected={(newRate, blockid, productIndex) =>
                  newRateSelected(newRate, nutrientGroup.ngbIndex, blockid, productIndex)
                }
              />
            ))}
        </div>
    </div>
  );
}
