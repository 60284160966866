import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

export default function AmeliorantMods(props) {
  const { nutrient, element, tableData, requirement } = props;


  const getModsRow = (byproduct) => {
    const el = tableData.find((el) => el.byproduct === byproduct && el.year_applied_diff === 0);
    if (!el) return null;

    return (
      <TableRow>
        <TableCell>
          {el.byproduct}
        </TableCell>
        <TableCell>
          {`${el.application_rate_min} - ${el.application_rate_max} wet tonnes/ha`}
        </TableCell>
        <TableCell>
          {el.subtraction}kg {element}/ha
        </TableCell>
        <TableCell>
          {tableData.find((el) => el.byproduct === byproduct && el.year_applied_diff === -1).subtraction}kg {element}/ha
        </TableCell>
        <TableCell>
          {tableData.find((el) => el.byproduct === byproduct && el.year_applied_diff === -2).subtraction}kg {element}/ha
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <Typography
        variant='h6'
        style={{ marginBottom: 20, paddingLeft: 20, backgroundColor: 'rgba(0, 157, 72, 0.1)' }}
      >
        {`Modifications to ${nutrient} application rates where mill by-products have been applied`}
      </Typography>
      {requirement && requirement.mill_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_ash_rate} t/ha Mill Ash applied</Typography>}
      {requirement && requirement.mill_mud_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_rate} t/ha Mill Mud applied</Typography>}
      {requirement && requirement.mill_mud_ash_rate > 0 && <Typography style={{ marginBottom: 10 }}>{requirement.mill_mud_ash_rate} t/ha Mill Mud Ash applied</Typography>}
      {requirement && !requirement.mill_mud_ash_rate && !requirement.mill_mud_rate && !requirement.mill_ash_rate && <Typography style={{ marginBottom: 10 }}>No Mill byproduct applied</Typography>}
      <TableContainer style={{ marginBottom: 20 }}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Product</TableCell>
              <TableCell rowSpan={2}>Application rate</TableCell>
              <TableCell colSpan={3}>To be subtracted from the appropriate {element} application rate</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Year 1</TableCell>
              <TableCell>Year 3</TableCell>
              <TableCell>Year 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getModsRow('Mill Ash')}
            {getModsRow('Mill Mud')}
            {getModsRow('Mud/Ash Mixture')}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
