import React, { useState, useContext, useCallback } from 'react';

// Queries
import { requirementToFallow } from '../../api/mutations/requirements/requirementToFallow.js';

// Context
import { useAlertContext } from '../AlertContext';
import { BlockContext } from '../../contexts/BlocksContext';
import { useUserContext } from '../../contexts/UserContext.js';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { set } from 'date-fns';

export default function ToFallowDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const { visible, block, handleClose } = props;

  const appliedProducts = block.productsselected?.filter((product) => product.applied);

  // const [blockYield, setYield] = useState(block.requirements[0].yield || 0);
  // const [blockForcast, setBlockForcast] = useState("Ratoon");
  const [toFallow, setToFallow] = useState(block.requirements[0].to_fallow || 0);
  const [standOver, setStandOver] = useState(block.requirements[0].stand_over || 0);

  // const handleForcastChange = (event) => {
  //   setBlockForcast(event.target.value);
  // };
  const handleChange = (value, functionToSet) => {
    let newArea = parseFloat(value);
    if (newArea > block.hectares) {
      newArea = block.hectares;
    }
    if (newArea < 0) {
      newArea = 0;
    }
    functionToSet(newArea);
  };

  const handleToFallow = useCallback(async () => {
    console.log('Updated fallow with ', toFallow);

    try {
      await requirementToFallow({
        token: user?.idToken?.jwtToken,
        requirementId: block.requirements[0].id,
        toFallow,
        toStandOver: standOver,
      });

      alertContext.success(`Requirement updated`);
      dispatchBlock({
        type: 'MODIFY_REQUIREMENT',
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            to_fallow: toFallow,
            to_stand_over: standOver,
          },
        },
      });

      handleClose();
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block.id, block.requirements, dispatchBlock, handleClose, standOver, toFallow, user?.idToken?.jwtToken]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby='to-fallow-dialog-title'
      aria-describedby='to-fallow-dialog-description'
    >
      <DialogTitle
        style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}
        id='to-fallow-dialog-title'
      >{`Finalise Block ${block.name} (${block.hectares}ha)`}</DialogTitle>
      <DialogContent>
        {appliedProducts?.length > 0 && (
          <>
            <Typography>Products applied to this block:</Typography>
            {appliedProducts.map((el) => (
              <Typography varient='caption'>{`${el.name} on ${el.applied}ha`}</Typography>
            ))}
          </>
        )}
        <Typography color='error'>
          You won't be able to make any changes to products after this
        </Typography>
        <Typography>Confirm area that WILL be fallowed:</Typography>
        <TextField
          autoFocus
          id='fallow'
          label='Hectares'
          type='number'
          value={toFallow}
          onChange={(e) => handleChange(e.target.value, setToFallow)}
        />
        <Typography>Confirm area of Stand Over cane:</Typography>
        <TextField
          autoFocus
          id='stand-over'
          label='Hectares'
          type='number'
          value={standOver}
          onChange={(e) => handleChange(e.target.value, setStandOver)}
        />
        {standOver + toFallow > block.hectares && (
          <Typography color='error'>
            Fallow area + Stand Over area cannot be greater than the block area
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          disabled={standOver + toFallow > block.hectares}
          onClick={handleToFallow}
          color='primary'
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
