import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from '@tanstack/react-query';
import { getSoilSamples } from "../../../graphQL/SoilSample";

export default function useFarmSoilSamples(farmId) {
return useQuery({
    queryKey: ['soilSamples', farmId],
    queryFn: async () => {
        const result = await API.graphql(
            graphqlOperation(getSoilSamples.query, { farmId: farmId })
        );
        console.log( result.data.getSoilSamples.map((item) => JSON.parse(item)));
        return result.data.getSoilSamples;
        },
    });
}

