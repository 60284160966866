import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import FormikForm from '../FormikForm';
import FormText from '../FormText';
import { API, graphqlOperation } from 'aws-amplify';
import { createVarieties, getVarieties } from '../../graphQL/Variety';
import { useSnackbar } from 'notistack';
import { useCallbackRef } from '../../pages/ManageBlocks/useCallbackRef';

const _fetchVarieties = async () => {
  const result = await API.graphql(graphqlOperation(getVarieties.query));
  return JSON.parse(result.data.getVarieties);
};

export const CreateVarietyDialog = (props) => {
  const { open, onClose, onSuccess } = props;

  const invokeOnClose = useCallbackRef(onClose);
  const invokeOnSuccess = useCallbackRef(onSuccess);

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [varieties, setVarieties] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetch = async () => {
      setIsLoading(true);

      try {
        const varieties = await _fetchVarieties();

        if (isCancelled) return;

        setIsLoading(false);
        setVarieties(varieties);
      } catch (err) {
        console.error('Error Fetching Varieties');
        console.error(err);
        enqueueSnackbar('Error Fetching Varieties', { variant: 'error' });
      }
    };
    fetch();

    return () => {
      isCancelled = true;
    };
  }, [enqueueSnackbar]);

  const validate = useCallback(
    async (values) => {
      const errors = {};

      const variety = values.variety?.trim() ?? '';
      const confirm = values.confirm?.trim() ?? '';

      const isVarietyEmpty = variety.length <= 0;

      if (isVarietyEmpty) {
        errors.variety = 'Required';
      }

      if (!isVarietyEmpty && confirm !== variety) {
        errors.confirm = 'Does not match';
      }

      if (varieties.find((v) => v.name === variety)) {
        errors.variety = 'Already exists';
      }

      return errors;
    },
    [varieties]
  );

  const handleClose = useCallback(() => {
    invokeOnClose();
  }, [invokeOnClose]);

  const handleSubmit = useCallback(
    async (values, { setTouched }) => {
      const trimmed = values.variety.trim();

      setIsUploading(true);

      try {
        const varieties = await _fetchVarieties();
        setVarieties(varieties);

        if (varieties.find((v) => v.name === trimmed)) {
          setIsUploading(false);
          setTouched({ variety: true, confirm: true }, true);
          return;
        }

        await API.graphql(
          graphqlOperation(createVarieties.mutation, { varieties: [trimmed] })
        );

        invokeOnSuccess({ variety: trimmed });
        enqueueSnackbar('Variety Created', { variant: 'success' });
        handleClose();
      } catch (err) {
        console.error('Error Creating Variety');
        console.error(err);
        enqueueSnackbar('Error Creating Variety', { variant: 'error' });
      }

      setIsUploading(false);
    },
    [enqueueSnackbar, invokeOnSuccess, handleClose]
  );

  const form = useFormik({
    initialValues: {
      variety: '',
      confirm: '',
    },
    initialTouched: { variety: true, confirm: true },
    validate: validate,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}>
        New Variety
      </DialogTitle>
      <DialogContent>
        {isLoading || isUploading ? (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <CircularProgress color="secondary" style={{ margin: '100%' }} />
            </Grid>
          </Grid>
        ) : (
          <FormikForm formik={form}>
            <FormText name="variety" label="Variety" />
            <Collapse
              in={form.values.variety?.length > 0}
              timeout={{ enter: 400, exit: 200 }}
            >
              <FormText name="confirm" label="Confirm Variety" />
            </Collapse>
          </FormikForm>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!form.isValid}
          onClick={form.submitForm}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
