// Libraries
import React, { useState } from 'react';
// LIBRARIES
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// QUERIES
import { updateRequirementZonesQuery } from './queries/updateRequirementZones';

// MATERIAL
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// COMPONENTS
import BlockCard from './components/BlockCard';

// FUNCTIONS
import getNutrientGroupBlockIdArrays from './functions/getNutrientGroupBlockIdArrays';
import calculateGroupedArea from './functions/calculateGroupedArea';
import calculateGroupedNutrient from './functions/calculateGroupedNutrient';
import { Button } from '@material-ui/core';

export default function ZonesView({ filteredBlocks }) {
  const [groupedBlockIds, setGroupedBlockIds] = useState(
    getNutrientGroupBlockIdArrays(filteredBlocks)
  );
  const [activeGroup, setActiveGroup] = useState(null);
  const [dragging, setDragging] = useState(false);

  function handleOnDrag(event, blockId, index) {
    event.dataTransfer.setData('blockId', blockId);
    event.dataTransfer.setData('fromGroupIndex', index);
    setActiveGroup(index);
    setDragging(true);
  }

  function handleOnDrop(event, groupId) {
    const draggedBlockId = parseInt(event.dataTransfer.getData('blockId'));
    const fromGroupIndex = parseInt(event.dataTransfer.getData('fromGroupIndex'));
    setActiveGroup(null);
    console.log('dropping:', draggedBlockId, ' on:', groupId, ' from:', fromGroupIndex);

    if (groupId !== fromGroupIndex) {
      // remove/add block from previous group
      const newGroupedBlockIds = groupedBlockIds.map((groupedIds, index) => {
        if (index === groupId) {
          return [...groupedIds, draggedBlockId];
        } else if (index === fromGroupIndex) {
          return groupedIds.filter((id) => id !== draggedBlockId);
        } else {
          return groupedIds;
        }
      });
      if (groupId === groupedBlockIds.length) {
        newGroupedBlockIds.push([draggedBlockId]);
      }

      const noEmptyGroups = newGroupedBlockIds.filter((groupedIds) => groupedIds.length > 0);
      console.log(noEmptyGroups);
      setGroupedBlockIds(noEmptyGroups);
    }
    setDragging(false);
  }

  function handleOnDragOver(event, groupId) {
    event.preventDefault();
    setActiveGroup(groupId);
  }
  function handleOnDragLeave(event) {
    event.preventDefault();
    setActiveGroup(null);
  }

  function newNutrientGroup(lastIndex) {
    return (
      <div
        key={lastIndex}
        onDrop={(event) => handleOnDrop(event, lastIndex)}
        onDragOver={(event) => handleOnDragOver(event, lastIndex)}
        onDragLeave={(event) => handleOnDragLeave(event)}
      >
        <Paper
          elevation={activeGroup === lastIndex ? 10 : 1}
          style={{ width: '360px', margin: '10px' }}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ backgroundColor: '#00a1c91a', padding: '0px 10px' }}
          >
            <Typography variant='h5'>New Group</Typography>
          </Box>
          <Box style={{ height: '130px' }}></Box>
        </Paper>
      </div>
    );
  }

  function nutrientGroupedBlocks(filteredBlocks, groupedIds, index) {
    const group = filteredBlocks.filter((block) => groupedIds.includes(block.id));

    const area = calculateGroupedArea(group);
    return (
      <div
        key={index}
        onDrop={(event) => handleOnDrop(event, index)}
        onDragOver={(event) => handleOnDragOver(event, index)}
        onDragLeave={(event) => handleOnDragLeave(event, index)}
      >
        <Paper
          elevation={activeGroup === index ? 10 : 1}
          style={{ width: group.length > 6 ? '740px' : '360px', margin: '10px' }}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ backgroundColor: '#00a1c91a', padding: '0px 10px' }}
          >
            <Typography variant='h5'>Nutrient Group {index + 1}</Typography>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ backgroundColor: '#00a1c91a', padding: '0px 10px' }}
          >
            <Typography variant='h6'>{group.length} Blocks</Typography>
            <Typography variant='h6'>{`Area: ${area}ha`}</Typography>
          </Box>
          {groupedBlockCards(group, index)}
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            style={{ backgroundColor: '#00a1c91a', padding: '0px 10px' }}
          >
            <Typography variant='h6'>
              {`N: ${calculateGroupedNutrient(group, 'nitrogen', area)}`}
            </Typography>
            <Typography variant='h6'>
              {`P: ${calculateGroupedNutrient(group, 'phosphorus', area)}`}
            </Typography>
            <Typography variant='h6'>
              {`K: ${calculateGroupedNutrient(group, 'potassium', area)}`}
            </Typography>
            <Typography variant='h6'>
              {`S: ${calculateGroupedNutrient(group, 'sulphur', area)}`}
            </Typography>
          </Box>
        </Paper>
      </div>
    );
  }

  function groupedBlockCards(groupedBlocks, index) {
    return (
      <Grid container spacing={1} style={{ padding: '10px' }}>
        {groupedBlocks.map(
          (block) =>
            block.requirements?.length === 1 && (
              <div draggable onDragStart={(event) => handleOnDrag(event, block.id, index)}>
                {BlockCard(block, index)}
              </div>
            )
        )}
      </Grid>
    );
  }



  const updateRequirementZones = useMutation({
    mutationKey: ['update-zones'],
    mutationFn: async ( data ) => {
      console.log(data);
      const response = await API.graphql(
        graphqlOperation(updateRequirementZonesQuery.mutation, { data })
      );

      console.log(response);
      // const deletedProducts = JSON.parse(response.data.deleteProduct);
      // if (deletedProducts.length === 0)
      //   throw new Error(`Couldn't delete product with ID: ${productId}`);
    },
    onSuccess: () => {
      // refetchProducts();
      // enqueueSnackbar('Product Deleted', {
      //   variant: 'success',
      // });
    },
    onError: (e) => {
      console.log(e);
      // enqueueSnackbar('Error Deleting Product', {
      //   variant: 'error',
      // });
    },
  });

  function saveZones() {
    console.log('save zones');
    // Map each block to its new group
    const newBlockGroups = groupedBlockIds.flatMap((groupedIds, index) =>
      groupedIds.map((id) => ({
        requirementId: filteredBlocks.find((block) => block.id === id)?.requirements[0].id,
        group: index + 1,
      }))
    );
    updateRequirementZones.mutate({ nutrientGroups: newBlockGroups });
  }

  return (
    <>
      <Button
        onClick={saveZones}
        variant='contained'
        color={'secondary'}
        // className={classes.button}
      >
        Save Management Zones
      </Button>
      <Grid container spacing={1} style={{ padding: '10px' }}>
        {groupedBlockIds.map((groupedIds, index) =>
          nutrientGroupedBlocks(filteredBlocks, groupedIds, index)
        )}
      </Grid>
      {dragging && newNutrientGroup(groupedBlockIds.length)}
    </>
  );
}
