import React, { useCallback } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingBackground from '../components/LoadingBackground';
import LogoCard from '../components/LogoCard';
import PageHeader from '../components/PageHeader';
import ProductTable from '../components/ProductList';
import { getProducts, deleteProduct } from '../graphQL/Product';
import { GenericDeleteDialog } from '../components/GenericDeleteDialog';
import { useSnackbar } from 'notistack';
// import { useAlertContext } from "../components/AlertContext";

export default function Products(props) {
  if (!props.match.params) {
    window.location.href = '/';
  }

  const admin = props.user.idToken.payload.admin === 'true';
  const agronomist = props.user.idToken.payload.agronomist === 'true';

  // const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [query, setQuery] = React.useState('');
  const [productToDelete, setProductToDelete] = React.useState();

  const { enqueueSnackbar } = useSnackbar();

  // const alertContext = useAlertContext();

  // const fetchProducts = useCallback(() => {
  //   setLoading(true);
  //   //2 is General fertilzer, 1 is Ameliorants
  //   API.graphql(graphqlOperation(getProducts.query))
  //     .then(async (result) => {
  //       setProducts(JSON.parse(result.data.getProducts));
  //       setLoading(false);
  //     })
  //     .catch(async (err) => {
  //       console.log(err);
  //       setLoading(false);
  //       alertContext.error(
  //         err,
  //         "Error fetching Products - please check your network connection and try again"
  //       );
  //     });
  // }, [alertContext]);

  // return (
  //   <div>
  //       <LoadingBackground visible={loading} />
  //       <LogoCard>
  //           <PageHeader title={"Products:"} subheader={`Products available on LandHub`}
  //         showSearch={true} setQuery={(q) => setQuery(q)} backPath={"/"} addPath={(admin || agronomist) ? '/addProducts/' : null} />
  //           {
  //               products && products.length > 0 &&
  //                 <ProductTable products={filteredSearch} />
  //           }
  //       </LogoCard>
  //   </div>
  // useEffect(() => {

  //   fetchProducts();
  //     //console.log(JSON.stringify(props));
  // }, [fetchProducts, props]);

  const {
    data: products,
    isLoading: loading,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const result = await API.graphql(graphqlOperation(getProducts.query));
      return JSON.parse(result.data.getProducts);
    },
  });

  const deleteProductMutation = useMutation({
    mutationKey: ['delete-product'],
    mutationFn: async ({ productId }) => {
      const response = await API.graphql(
        graphqlOperation(deleteProduct.mutation, { id: productId })
      );
      const deletedProducts = JSON.parse(response.data.deleteProduct);
      if (deletedProducts.length === 0)
        throw new Error(`Couldn't delete product with ID: ${productId}`);
    },
    onSuccess: () => {
      refetchProducts();
      enqueueSnackbar('Product Deleted', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error Deleting Product', {
        variant: 'error',
      });
    },
  });

  let filteredSearch;
  filteredSearch = products
    ? products.filter((item) => {
        return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;
      })
    : [];

  const handleOpenDeleteDialog = useCallback((product) => {
    setProductToDelete(product);
  }, []);

  const handleDeleteProduct = useCallback(
    async (productId) => {
      deleteProductMutation.mutate({ productId });
      setProductToDelete();
    },
    [deleteProductMutation]
  );

  return (
    <div>
      <LoadingBackground visible={loading || deleteProductMutation.isLoading} />
      <LogoCard>
        <PageHeader
          title={'Products:'}
          subheader={`Products available on LandHub`}
          showSearch={true}
          setQuery={(q) => setQuery(q)}
          backPath={'/'}
          addPath={admin || agronomist ? '/addProducts/' : null}
        />
        {products && (
          <ProductTable products={filteredSearch} onDeleteProduct={handleOpenDeleteDialog} />
        )}
        {productToDelete && (
          <GenericDeleteDialog
            name={productToDelete.name}
            id={productToDelete.id}
            type='Product'
            onAccept={handleDeleteProduct}
            onClose={() => setProductToDelete()}
          />
        )}
      </LogoCard>
    </div>
  );
}
