import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, EditOutlined } from '@material-ui/icons';
import { useCallback, useMemo } from 'react';
import { useCallbackRef } from './useCallbackRef';

export const EditShapeButton = (props) => {
  const { disabled, editing, onClick, ...rest } = props;

  const invokeOnClick = useCallbackRef(onClick);

  const tooltip = useMemo(
    () => (disabled ? '' : editing ? 'Stop Editing' : 'Edit'),
    [disabled, editing]
  );

  const color = useMemo(
    () => (disabled ? 'disabled' : editing ? 'primary' : 'action'),
    [disabled, editing]
  );

  const handleClick = useCallback(() => {
    invokeOnClick();
  }, [invokeOnClick]);

  return (
    <Tooltip title={tooltip}>
      <div>
        <IconButton disabled={disabled} onClick={handleClick} {...rest}>
          {editing ? <EditOutlined color={color} /> : <Edit color={color} />}
        </IconButton>
      </div>
    </Tooltip>
  );
};
