import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CollapsableListItem } from './CollapsableListItem';
import { ListItemError } from './ListItemError';

const tally = (obj, separator = ', ') => {
  return Object.entries(obj)
    .map(([key, arr]) => `${arr.length} ${arr.length === 1 ? key : `${key}s`}`)
    .join(separator);
};

export const Overview = (props) => {
  const { items, itemLabel } = props;

  const { itemsWithoutErrors, itemsWithErrors, errors } = useMemo(
    () =>
      items.reduce(
        ({ itemsWithoutErrors, itemsWithErrors, errors }, item) => {
          if (item.errors === undefined) {
            itemsWithoutErrors.push(item);
          } else {
            itemsWithErrors.push(item);
            errors.push(...item.errors);
          }
          return { itemsWithoutErrors, itemsWithErrors, errors };
        },
        {
          itemsWithoutErrors: [],
          itemsWithErrors: [],
          errors: [],
        }
      ),
    [items]
  );

  return (
    <>
      <ListSubheader style={{ backgroundColor: '#F5FBFD', padding: 0 }}>
        <ListItem component={'div'} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItemText
            primary={`${itemLabel}s Overview - ${tally({
              [itemLabel]: items,
            })}`}
            secondary={errors.length > 0 && `(${tally({ error: errors })})`}
            primaryTypographyProps={{ color: 'textPrimary' }}
            secondaryTypographyProps={{ color: 'error' }}
          />
        </ListItem>
      </ListSubheader>
      <ListItem>
        <List>
          {itemsWithoutErrors.length > 0 && (
            <CollapsableListItem
              primary={tally({ [itemLabel]: itemsWithoutErrors })}
              secondary={'Ready to upload'}
            >
              <List dense>
                {itemsWithoutErrors.map((item) => (
                  <ListItem key={item.value}>
                    <ListItemText primary={item.value} />
                  </ListItem>
                ))}
              </List>
            </CollapsableListItem>
          )}
          {itemsWithErrors.length > 0 && (
            <CollapsableListItem
              primary={tally({ [itemLabel]: itemsWithErrors })}
              secondary={`(${tally({ error: errors })})`}
              secondaryTypographyProps={{ color: 'error' }}
            >
              <List dense>
                {itemsWithErrors.map((item) => (
                  <React.Fragment key={item.value}>
                    <ListItem>
                      <ListItemText primary={item.value} />
                    </ListItem>
                    <ListItem>
                      <List dense>
                        {item.errors.map((error) => (
                          <ListItemError key={error} message={error} />
                        ))}
                      </List>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </CollapsableListItem>
          )}
        </List>
      </ListItem>
    </>
  );
};
