export const updateFarm = {
  mutation: `mutation updateFarm($data: Farm) {
    updateFarm(farm: $data)
  }`
};

export const removeFarmUsers = {
  mutation: `mutation removeFarmUsers($farmId: String, $userIds: [String]) {
    removeFarmUsers(farmId: $farmId, userIds: $userIds)
  }`,
};

export const getWeather = {
  query: `query getWeather($farmId: String) {
    getWeather(farmId: $farmId)
  }`
};

export const getUserFarms = { 
  query: `query getUserFarms {
    getUserFarms
  }`
};

export const deleteFarm = {
  mutation: `mutation deleteFarm($data: Farm) {
    deleteFarm(farm: $data)
  }`,
};

export const getMapLayers = { 
  query: `query getMapLayers($farmId: String) {
    getMapLayers(farmId: $farmId)
  }`
};

export const getMapData = {
  query: `query getMapData($year: String, $farmId: String) {
    getMapData(year: $year, farmId: $farmId)
  }`
};

export const getLayerProperties = { 
  query: `query getLayerProperties($farmId: String) {
    getLayerProperties(farmId: $farmId)
  }`
};

export const getLayerCadastre = { 
  query: `query getLayerCadastre($farmId: String) {
    getLayerCadastre(farmId: $farmId)
  }`
};

export const getLayerSoil = { 
  query: `query getLayerSoil($farmId: String) {
    getLayerSoil(farmId: $farmId)
  }`
};

export const getFarmLatLng = {
  query: `query getFarmLatLng($farmId: String) {
      getFarmLatLng(farmId: $farmId)
  }`
};

export const getFarmFiles = { 
  query: `query getFarmFiles {
    getFarmFiles
  }`
};

export const getFarmBlocks = { 
  query: `query getFarmBlocks($farmId: String) {
      getFarmBlocks(farmId: $farmId)
  }`
};

export const getFarmBlockRequirementsYear = { 
  query: `query getFarmBlockRequirementsYear($farmId: String, $fertYear: String) {
    getFarmBlockRequirementsYear(farmId: $farmId, fertYear: $fertYear)
  }`
};

export const getFarm = {
  query: `query getFarm($farmId: String) {
    getFarm(farmId: $farmId)
  }`
};

