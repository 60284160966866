import React, { useState, useEffect, useContext } from "react";
// Material
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
// Queries
import { requirementApplicationMillmud } from '../../api/mutations/requirements/requirementApplicationMillmud.js';

import { BlockContext } from "../../contexts/BlocksContext";
import { useAlertContext } from "../AlertContext";
import { useUserContext } from "../../contexts/UserContext.js";

const style = makeStyles((theme) => ({
  text: {
    marginLeft: 20,
    marginRight: 20,
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
  },
  boxCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function MillApplicationLine(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const classes = style();
  const { isGroup, block, handleClose } = props;

  let millAsh = block.requirements[0].mill_ash_rate
    ? block.requirements[0].mill_ash_rate
    : 0;

  let millMudAsh = block.requirements[0].mill_mud_ash_rate
    ? block.requirements[0].mill_mud_ash_rate
    : 0;

  let millMud = block.requirements[0].mill_mud_rate
    ? block.requirements[0].mill_mud_rate
    : 0;


  const millProducts = [
    { value: 0, dbname: "mill_mud_rate", name: "Mill Mud" },
    { value: 1, dbname: "mill_ash_rate", name: "Mill Ash" },
    { value: 2, dbname: "mill_mud_ash_rate", name: "Mill Mud Ash" },
  ];

  const [millProduct, setMillProduct] = useState(0);
  const handleProductChange = (event) => {
    setMillProduct(event.target.value);
    setByProductApplied(false);
  };
  const [millAppRate, setMillAppRate] = useState(0);
  const handleRateChange = (event) => {
    setMillAppRate(event.target.value);
    setByProductApplied(false);
  };
  const [yearApplied, setYearApplied] = useState(new Date().getFullYear());

  const [byproductApplied, setByProductApplied] = useState(false);

  const [applicationDate, setApplicationDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleApplicationDateChange = (event) => {
    setApplicationDate(event.target.value);
    setYearApplied(new Date(event.target.value).getFullYear());
    setByProductApplied(false);
  };

  useEffect(() => {
    if (millMud > 0) {
      setMillAppRate(millMud);
      setMillProduct(0);
      setByProductApplied(true);
    } else if (millAsh > 0) {
      setMillAppRate(millAsh);
      setMillProduct(1);
      setByProductApplied(true);
    } else if (millMudAsh > 0) {
      setMillAppRate(millMudAsh);
      setMillProduct(2);
      setByProductApplied(true);
    }
  }, [millAsh, millMud, millMudAsh]);

  
  const handleApplicationData = async () => {
    // console.log(applicationDate, millProduct, millAppRate, yearApplied);
    // if (millAppRate > 0) {
    try {
      await requirementApplicationMillmud({
        token: user?.idToken?.jwtToken,
        requirementId: block.requirements[0].id,
        byProductAppliedYear: yearApplied,
        millAppRate,
        millProductDbName: millProducts[millProduct].dbname,
      });
      
      alertContext.success(`Mill byproduct applied`);
      setByProductApplied(true);

      dispatchBlock({
        type: "MODIFY_REQUIREMENT",
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            byproduct_applied_year: yearApplied,
            mill_mud_rate: millProduct === 0 ? millAppRate : 0,
            mill_ash_rate: millProduct === 1 ? millAppRate : 0,
            mill_mud_ash_rate: millProduct === 2 ? millAppRate : 0,
          },
        },
      });
      if (!isGroup) {
        handleClose();
      }
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error backdating the requirement`);
    }
  }

  return (
    <Box className={classes.boxCol}>
      <Box className={classes.boxRow} style={{ width: "100%" }}>
        {isGroup && (
          <Typography className={classes.text}>Block {block.name}</Typography>
        )}

        <TextField
          id="applicationDate"
          type="date"
          defaultValue={applicationDate}
          onChange={handleApplicationDateChange}
          className={classes.text}
          style={{ width: 150 }}
        />
        <Select
          name="millProduct"
          value={millProduct}
          onChange={handleProductChange}
          className={classes.text}
          style={{ width: 150 }}
        >
          {millProducts.map((product, index) => (
            <MenuItem value={product.value} key={index}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="millAppRate_tha"
          value={millAppRate}
          type="number"
          onChange={handleRateChange}
          className={classes.text}
          style={{ width: 75 }}
        />
        <Typography>App Rate (t/ha)</Typography>
        <Button
          className={classes.button}
          style={{ width: 160 }}
          variant="contained"
          color={"secondary"}
          onClick={() => handleApplicationData()}
          disabled={byproductApplied}
        >
          {millAppRate > 0 ? "APPLY" : "CONFIRM N/A"}
        </Button>
      </Box>
      {byproductApplied ? (
        <Typography color="error">Mill By product already Applied</Typography>
      ) : null}
    </Box>
  );
}
