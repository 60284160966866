const actions = {
  LOAD_BLOCKS: "LOAD_BLOCKS",
  ADD_BLOCK: "ADD_BLOCK",
  REMOVE_BLOCK: "REMOVE_BLOCK",
  EDIT_BLOCK: "EDIT_BLOCK",
  APPLY_PRODUCT: "APPLY_PRODUCT",
  MODIFY_REQUIREMENT: "MODIFY_REQUIREMENT",
  ADD_REQUIREMENT: "ADD_REQUIREMENT",
  PLOUGHOUT_REQUIREMENTS: "PLOUGHOUT_REQUIREMENTS",
  SET_CURRENT_CYCLE_YEAR: "SET_CURRENT_CYCLE_YEAR",
};

export const BlockReducer = (state, action) => {
  console.log(state);
  console.log(action);
  switch (action.type) {
    case actions.LOAD_BLOCKS:
      return action.blocks;
    case actions.ADD_BLOCK:
      return [...state, action.block];
    case actions.REMOVE_BLOCK:
      return state.filter((block) => block.id !== action.id);
    case actions.EDIT_BLOCK:
      return state.map((block) =>
        block.id === action.block.id ? { ...block, ...action.block } : block
      );
    case actions.APPLY_PRODUCT:
      return state.map((block) =>
        block.id === action.blockid
          ? {
              ...block,
              productsselected: block.productsselected.map((ps) =>
                ps.id === action.productid
                  ? {
                      ...ps,
                      applieddate: action.applieddate,
                      applied: action.applied,
                    }
                  : ps
              ),
            }
          : block
      );
    case actions.ADD_REQUIREMENT:
      return state.map((block) =>
        block.id === action.block.id
          ? {
              ...block,
              requirements: block.requirements
                ? [...block.requirements, action.block.requirement]
                : [action.block.requirement],
            }
          : block
      );
    case actions.MODIFY_REQUIREMENT:
      return state.map((block) =>
        block.id === action.block.id
          ? {
              ...block,
              requirements: block.requirements.map((r) =>
                r.id === action.block.requirementid
                  ? {
                      ...r,
                      ...action.block.requirement,
                    }
                  : r
              ),
            }
          : block
      );
    case actions.PLOUGHOUT_REQUIREMENTS:
      return state.map((block) =>
        block.id === action.block.id
          ? {
              ...block,
              requirements: [
                ...block.requirements.flatMap((r) =>
                  r.block_id === action.block.requirement.block_id &&
                  r.primary_sample_id ===
                    action.block.requirement.primary_sample_id &&
                  r.fertilizer_year >= action.block.requirement.fertilizer_year
                    ? []
                    : [{ ...r }]
                ),
                {
                  id: action.block.requirement.id,
                  block_id: action.block.requirement.block_id,
                  fertilizer_year: action.block.requirement.fertilizer_year,
                  crop_class_id: action.block.requirement.crop_class_id,
                  from_date: action.block.requirement.from_date,
                  crop_class_name: action.block.requirement.crop_class_name,
                },
              ],
            }
          : block
      );
    case actions.SET_CURRENT_CYCLE_YEAR:
      return state.map((block) =>
        ({ ...block, current_cycle: parseInt(action.current_cycle) }));
    default:
      return state;
  }
};

// block.id === action.block.id ? { block, ...action.block } : block
