import React, { useState, useContext, useCallback } from "react";

// Queries
import { requirementFallow } from '../../api/mutations/requirements/requirementFallow.js'

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from '../../contexts/UserContext.js';

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";

// functions
import { fallowCrops } from "../../functions/offlineQueries";

// import { setDate } from "date-fns";

export default function FallowDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const { visible, block, handleClose } = props;

  const [fallowPlanted, setFallowPlanted] = useState(false);
  const [fallowCrop, setFallowCrop] = useState(1);

  const [fallowYield, setFallowYield] = useState(0);
  const handleYieldChange = (event) => {
    setFallowYield(event.target.value);
  };

  const handleCropClassChange = (event) => {
    console.log(event.target.value);
    setFallowCrop(event.target.value);
  };

  function toggleFallowCrop(e) {
    if (!e.target.checked) {
      setFallowYield(0);
    }
    setFallowPlanted(e.target.checked);
  }

  const handleFallowData = useCallback(async () => {
    try {
      await requirementFallow({
        token: user?.idToken?.jwtToken,
        requirementId: block.requirements?.[0].id,
        fallowYield: fallowPlanted ? fallowYield : 0,
        fallowCropId: fallowPlanted ? fallowCrop : null,
      });

      alertContext.success(`Requirement updated`);
      dispatchBlock({
        type: "MODIFY_REQUIREMENT",
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            fallow_crop_id: fallowCrop,
            fallow_yield: fallowYield,
            fallow_harvested: fallowPlanted,
          },
        },
      });
      handleClose();
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block.id, block.requirements, dispatchBlock, fallowCrop, fallowPlanted, fallowYield, handleClose, user?.idToken?.jwtToken]);

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="fallow-dialog-title"
      aria-describedby="fallow-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="fallow-dialog-title"
      >{`Fallow actions for Block ${block.name}`}</DialogTitle>
      <DialogContent>
        <FormGroup row>
          <FormControlLabel
            label="Fallow crop harvested?"
            control={
              <Switch
                checked={fallowPlanted}
                onChange={(e) => toggleFallowCrop(e)}
                color="secondary"
                name="fallowPlanted"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
          />
        </FormGroup>
        <Select
          disabled={!fallowPlanted}
          name="fallow_crop_id"
          label="Crop type"
          value={fallowCrop}
          fullWidth
          onChange={handleCropClassChange}
        >
          {fallowCrops.map((fallowCrop, index) => (
            <MenuItem value={fallowCrop.value} key={index}>
              {fallowCrop.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          disabled={!fallowPlanted}
          id="fallow_yeild"
          label="Enter yield (t/ha)?"
          value={fallowYield}
          type="number"
          fullWidth
          onChange={handleYieldChange}
        />
        <Typography>Accept will allow this block to be planted</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleFallowData()} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
