export const getFilePathInfo = (filePath) => {
  const regex = /(?<directory>.+\/)?(?<name>.*)(?:\.(?<extension>.+))/;
  const match = regex.exec(filePath);

  const directory = match?.groups?.directory;
  const name = match?.groups?.name;
  const extension = match?.groups?.extension;

  return {
    directory,
    name,
    extension,
  };
};
