// TODO: Delete this page????
import React, {useCallback, useEffect, useState} from 'react'
import { cropClasses, fallowCrops, states, varieties } from '../functions/offlineQueries'

import FormButton from "../components/FormButton";
import FormCheckbox from "../components/FormCheckbox";
import FormSelect from "../components/FormSelect";
import FormText from "../components/FormText";
import FormikForm from "../components/FormikForm";
import LoadingBackground from "../components/LoadingBackground";
import LogoCard from "../components/LogoCard";
import PageHeader from "../components/PageHeader";
import { RequirementSchema } from "../components/ValidationSchema";
import buildInitialValues from "../functions/buildInitialValues";
// import { createRequirements } from "../graphQL/Requirement";
import { getRequirement as getRequirementAPI } from '../api/queries/requirements/getRequirement.js'
// import updateRequirement from '../graphQL/UpdateRequirement'
import { useAlertContext } from '../components/AlertContext'
import {useFormik} from "formik";

// import { useHistory } from "react-router-dom";

export default function ManageRequirement(props) {
    // const history = useHistory();
    const requirementId = props.match.params.id
    const farmId = props.match.params.farm
    const farmName = props.match.params.name

    const userAuthToken = props?.user?.idToken?.jwtToken;

    const [ requirementStates, setRequirementStates ] = useState(states)
    const [ loading, setLoading ] = useState(false)
    const [ requirement, setRequirement ] = useState(null)

    const alertContext = useAlertContext()

    const fetchRequirement = useCallback(async () => {
        setLoading(true)

        // Note: Can't fully test as page is not maintained / is orphaned.
        try {
            const requirement = await getRequirementAPI({requirementId, token: userAuthToken});
            console.log('MANAGE REGUIREMENT PAGE');
            
            requirement.from_date = requirement.from_date ? new Date(requirement.from_date).toISOString().substring(0, 10) : ''
            requirement.to_date = requirement.to_date ? new Date(requirement.to_date).toISOString().substring(0, 10) : ''
            requirement.soil_sample_list = requirement.avail_soil_samples.map(soilSample =>
                { return { name: soilSample.lab_sample_number, value: soilSample.id} })
            requirement.soil_sample_list.unshift({name: 'NA', value: null})
            setRequirement(requirement)
            
            if(requirement.requirement_state_id === '1' || requirement.requirement_state_id === '2') {
                setRequirementStates(states.filter(r => r.value !== 3))
            }
        } catch (err) {
            console.log(err)
            alertContext.error(err, 'Error fetching requirement')
        }

        // Note: Old code using GraphQL.
        // API.graphql(graphqlOperation(getRequirement.query, { requirementId: requirementId }))
        // .then(result => {
        //     console.log('MANAGE REGUIREMENT PAGE');
        //     if(result.data.getRequirement) {
        //         let res = JSON.parse(result.data.getRequirement)
        //         res.from_date = res.from_date ? new Date(res.from_date).toISOString().substr(0, 10) : ''
        //         res.to_date = res.to_date ? new Date(res.to_date).toISOString().substr(0, 10) : ''
        //         res.soil_sample_list = res.avail_soil_samples.map(soilSample =>
        //             { return { name: soilSample.lab_sample_number, value: soilSample.id} })
        //         res.soil_sample_list.unshift({name: 'NA', value: null})
        //         setRequirement(res)
                
        //         if(res.requirement_state_id === '1' || res.requirement_state_id === '2') {
        //             setRequirementStates(states.filter(r => r.value !== 3))
                    
        //         }
                
        //     }
        // })
        // .catch(async err => {
        //     console.log(err)
        //     alertContext.error(err, 'Error fetching requirement')
        // })
    }, [alertContext, requirementId, userAuthToken])

    useEffect( () => {
        fetchRequirement().then(()=>setLoading(false))
    }, [fetchRequirement]);

    const formik = useFormik({
        initialValues: {...buildInitialValues(requirement, [
                'mill_mud_ash_rate', 'mill_mud_rate', 'mill_ash_rate', 'magnesium', 'silicate', 'nitrogen',
                'lime', 'gypsum', 'phosphorus', 'potassium', 'sulphur', "block_id", "fallow_crop_id", "fallow_yield",
                "crop_class_id", 'from_date', 'to_date', 'requirement_state_id', 'variety_id', 'requirementYield',
                "fertilizer_year", 'target_year', 'primary_sample_id', 'yield'
            ]),
            requirementId: requirement?.id,
            fallow_harvested: requirement?.fallow_harvested === 1,
            from_date: requirement?.from_date ? requirement?.from_date : null
        },
        enableReinitialize: true,
        validationSchema: RequirementSchema,
        onSubmit: (values, actions) => {
            setLoading(true)
            console.log('MANAGE REGUIREMENT PAGE');
            // const data = {
            //     requirementId: values.requirementId,
            //     mill_mud_ash_rate: values.mill_mud_ash_rate,
            //     mill_mud_rate: values.mill_mud_rate,
            //     mill_ash_rate: values.mill_ash_rate,
            //     fallow_crop_id: values.fallow_crop_id,
            //     crop_class_id: values.crop_class_id,
            //     from_date: values.from_date,
            //     to_date: values.to_date,
            //     requirement_state_id: values.requirement_state_id,
            //     variety_id: values.variety_id,
            //     yield: values.yield,
            //     fallow_harvested: values.fallow_harvested === true ? 1 : 0,
            //     fertilizer_year: values.fertilizer_year,
            //     primary_sample_id: values.primary_sample_id === 'NA' ? null : values.primary_sample_id,
            //     block_id: values.block_id
            // }
            // API.graphql(graphqlOperation(updateRequirement.mutation, { requirement: data } ))
            // .then(async (result) => {
            //     if(values.requirement_state_id === '2' && requirement.requirement_state_id === '1') {
            //         //Create NFAs
            //         API.graphql(graphqlOperation(createRequirements.mutation, { requirementId: values.requirementId } ))
            //             .then(async (result) => {
            //                 console.log(result)
            //                 alertContext.success(`Requirement updated and the Farm Nutrient Allowance is being calculated`)
            //             }).catch(err => {
            //             alertContext.success(`Requirement updated but there was an error generating the Farm Nutrient Allowance`)
            //         })
            //     }
            //     else {
            //         alertContext.success(`Requirement updated`);
            //         history.push(`/farm-requirements/${farmName}/${farmId}`);
            //     }
            //     actions.setSubmitting(false);
            //     setLoading(false)
            // })
            // .catch(err => {
            //     console.log(err)
            //     setLoading(false)
            //     actions.setSubmitting(false);
            //     alertContext.error(err, `Error updating the requirement`)
            // })
        }
    });

    const disabled = formik.values?.requirement_state_id === '3';

    return (
        <LogoCard margin={20}>
            <LoadingBackground visible={loading} />
            <PageHeader title={'Update Requirement'}
                subheader={`Use the below form and select 'Update Requirement' out form details to update the requirement. \n
                Updates may take up to 5 minutes to complete if changing the requirement state`}
                backPath={`/farm-requirements/${farmName}/${farmId}`} />
            <div style={{ padding: 20 }}>
                {
                    ((requirementId && requirement && !loading) || (!requirementId)) &&
                    <FormikForm formik={formik}>
                        <FormText name="fertilizer_year" label="Fertilizer Year:" type="number" disabled={disabled}/>
                        <FormText name="block_id" label="Block Id:" disabled />
                        <FormText name="mill_mud_ash_rate" type={'number'} label="Mill Mud Ash Rate:" disabled={disabled}/>
                        <FormText name="mill_mud_rate" type={'number'} label="Mill Mud Rate:" disabled={disabled}/>
                        <FormText name="mill_ash_rate" type={'number'} label="Mill Ash Rate:" disabled={disabled} />
                        <FormText name="magnesium" label="Magnesium:" disabled />
                        <FormText name="silicate" label="Silicate:" disabled />
                        <FormText name="nitrogen" label="Nitrogen:" disabled />
                        <FormText name="lime" label="Lime:" disabled />
                        <FormText name="gypsum" label="Gypsum:" disabled />
                        <FormText name="phosphorus" label="Phosphorus:" disabled />
                        <FormText name="potassium" label="Potassium:" disabled />
                        <FormText name="sulphur" label="Sulphur:" disabled />
                        <FormText name="block_id" label="Block Id:" disabled /> 
                        <FormSelect name="fallow_crop_id" label="Fallow crop id:" options={fallowCrops}  disabled={disabled} />
                        <FormText name="fallow_yield" label="Fallow Yield:" disabled />
                        <FormSelect name="crop_class_id" label="Crop Class:" options={cropClasses} disabled={disabled} />
                        <FormSelect name="primary_sample_id" label="Primary Soil Sample:" disabled={disabled}
                            options={requirement.soil_sample_list }  />
                        <FormText name="from_date" label="Date From:" type={"date"} disabled={disabled}/>
                        <FormSelect name="requirement_state_id" label="State:" options={requirementStates} disabled={disabled} />
                        <FormSelect name="variety_id" label="Variety Id:" options={varieties} disabled={disabled}/>
                        <FormText name="yield" label="Yield:" endText={"t/ha"} type={'number'}  disabled={disabled} />
                        <FormCheckbox name="fallow_harvested" label="Fallow Harvested:" disabled={disabled} />
                        <FormButton text={'Update Requirement'} />
                    </FormikForm>
                }
            </div>
        </LogoCard>
    );
}

