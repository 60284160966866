import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useQueryClient } from '@tanstack/react-query';

// Queries
import { updateBlock } from '../../graphQL/Blocks';

// Context
import { useAlertContext } from '../AlertContext';
import { BlockContext } from '../../contexts/BlocksContext';

// Material
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Switch,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { getVarieties } from '../../graphQL/Variety';
import { VarietyPicker } from '../VarietyPicker/VarietyPicker';

const _fetchVarieties = async () => {
  const result = await API.graphql(graphqlOperation(getVarieties.query));
  return JSON.parse(result.data.getVarieties);
};

const MAX_HECTARES = 1000000000;

export default function ModifyDialog(props) {
  const alertContext = useAlertContext();
  const queryClient = useQueryClient();
  const { blocks } = useContext(BlockContext);
  const { visible, block, fertYear, handleClose } = props;

  const blockNames = useMemo(() => {
    return blocks.flatMap((b) =>
      b.decommissioned_cycle === null ? b.name : []
    );
  }, [blocks]);

  const originalBlockName = block.name;
  const originalSize = block.hectares;

  const [blockState, setBlockState] = useState({
    block_id: block.id,
    blockName: block.name,
    droneDeployLink: block.dronedeploy_link,
    hectares: block.hectares,
    lat: block.lat,
    lng: block.lng,
    map_page: block.map_page,
    fert_year: fertYear,
    variety: block.requirements?.[0]?.variety_name ?? '',
  });

  const [modifyAction, setModifyAction] = useState(null);
  const [modifiedState, setModifiedState] = useState({
    newBlockName: '',
    newHectares: 0.0,
    newFallow: false,
    fallow: false,
  });

  const [varieties, setVarieties] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetch = async () => {
      try {
        const varieties = await _fetchVarieties();
        if (isCancelled) return;

        setVarieties(varieties);
      } catch (err) {
        alertContext.error(err, 'Error Loading Varieties');
      }
    };
    fetch();

    return () => {
      isCancelled = true;
    };
  }, [alertContext]);

  const handleBlockChange = useCallback(
    (event) => {
      let value = event.target.value;
      if (
        modifyAction === 'Resize' &&
        event.target.name === 'hectares' &&
        value !== ''
      ) {
        const valueInt = Math.floor(value * 1000 + 0.01);
        const clampedInt = Math.max(0, valueInt);

        const fixedValueInt =
          value > Number.EPSILON && Math.abs(clampedInt) <= 0 ? 1 : clampedInt;

        value = fixedValueInt / 1000;
      }

      setBlockState((prev) => ({ ...prev, [event.target.name]: value }));
    },
    [modifyAction]
  );

  const handleModifyChange = useCallback(
    (event) => {
      let value = event.target.value;
      if (event.target.name === 'newHectares' && value !== '') {
        const originalInt = Math.floor(originalSize * 1000 + 0.01);
        const maxInt = modifyAction === 'Split' ? originalInt - 1 : Infinity;
        const valueInt = Math.floor(value * 1000 + 0.01);

        const clampedInt = Math.max(0, Math.min(maxInt, valueInt));
        const fixedValueInt =
          value > Number.EPSILON && Math.abs(clampedInt) <= 0 ? 1 : clampedInt;

        value = fixedValueInt / 1000;

        if (modifyAction === 'Split') {
          setBlockState((prev) => ({
            ...prev,
            hectares: (originalInt - fixedValueInt) / 1000,
          }));
        }
      }
      setModifiedState((prev) => ({
        ...prev,
        [event.target.name]: value,
      }));
    },
    [modifyAction, originalSize]
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setModifyAction(event.target.name);
      } else {
        setModifyAction(null);
      }
      setBlockState((prev) => ({
        ...prev,
        hectares: originalSize,
        blockName: originalBlockName,
      }));
      setModifiedState((prev) => ({
        ...prev,
        newHectares: 0,
        newBlockName: '',
      }));
    },
    [originalSize, originalBlockName]
  );

  const handleModifyData = useCallback(async () => {
    try {
      await API.graphql(
        graphqlOperation(updateBlock.mutation, {
          blockData: {
            ...blockState,
            modifyAction: modifyAction,
            ...modifiedState,
          },
        })
      );
      alertContext.success(`Block details updated`);
      queryClient.invalidateQueries(['blocks']);
      handleClose(null);
    } catch (err) {
      console.error(err);
      alertContext.error(err, `Error updating block details`);
    }
  }, [
    modifyAction,
    blockState,
    modifiedState,
    alertContext,
    queryClient,
    handleClose,
  ]);

  const cloneErrors = useMemo(() => {
    const errors = [];

    const blockName = modifiedState.newBlockName?.toString().trim() ?? '';
    if (blockName === '') errors.push('New Block Name cannot be empty');
    if (blockNames.includes(blockName))
      errors.push('New Block Name already exists');

    if (Math.abs(modifiedState.newHectares) < Number.EPSILON)
      errors.push('Must be greater than 0ha');
    if (MAX_HECTARES < modifiedState.newHectares)
      errors.push(`Must be less than ${MAX_HECTARES}`);

    return errors;
  }, [modifiedState, blockNames]);

  const splitErrors = useMemo(() => {
    const errors = [];

    const aName = blockState.blockName?.toString().trim() ?? '';
    const bName = modifiedState.newBlockName?.toString().trim() ?? '';

    if (aName === '') errors.push('Block Name cannot be empty');
    if (bName === '') errors.push('New Block Name cannot be empty');

    if (blockNames.includes(aName) && aName !== originalBlockName)
      errors.push('Block Name already exists');
    if (blockNames.includes(bName))
      errors.push('New Block Name already exists');

    if (Math.abs(modifiedState.newHectares) < Number.EPSILON)
      errors.push('Must be greater than 0ha');
    if (Math.abs(originalSize - modifiedState.newHectares) < Number.EPSILON)
      errors.push('Must be different than original block size');

    return errors;
  }, [blockState, blockNames, modifiedState, originalSize, originalBlockName]);

  const resizeErrors = useMemo(() => {
    const errors = [];

    if (Math.abs(blockState.hectares) < Number.EPSILON)
      errors.push('Must be greater than 0ha');
    if (Math.abs(originalSize - blockState.hectares) < Number.EPSILON)
      errors.push('Must be different than original block size');
    if (MAX_HECTARES < blockState.hectares)
      errors.push(`Must be less than ${MAX_HECTARES}`);

    return errors;
  }, [blockState, originalSize]);

  const validationErrors = useMemo(
    () => ({
      Clone: cloneErrors,
      Split: splitErrors,
      Resize: resizeErrors,
    }),
    [cloneErrors, splitErrors, resizeErrors]
  );

  /*
  Clone ->
    Copy block record to new
  Split ->
      2 x resize
  Resize ->
  blocks table
    Copy the block to new record with parent id of old block add commission_cycle, update size.
    Decommission old block, add child block id
  block_soil_samples table
    copy old blocks samples updating new to new block_id
  requirements table
    Find all future requirements for old block, copy them and update block_id to new block id
  

    COPY BLOCK SQL
    INSERT INTO blocks (farm_id, name, lat, lng, hectares, primary_sample_id, dronedeploy_link, centroid_geom, geom, decommission_date, decommission_by, parent_block_id, map_page, state, current_cycle, crop_type, created_by, creation_date, metadata, commissioned_cycle, decommissioned_cycle)
                            SELECT farm_id, '22-3D', lat, lng, hectares, null, dronedeploy_link, centroid_geom, geom, null, null, 1136, map_page, state, current_cycle, crop_type, created_by, creation_date, metadata, commissioned_cycle, decommissioned_cycle FROM blocks WHERE id = 1136;

    DECOMMISSION BLOCK
    UPDATE blocks SET decommissioned_cycle = ${fert_year}, decommission_by = ${cognito_id}, decommission_date = ${date Now} where id = 1136;

    COPY SOIL SAMPLES
    INSERT INTO blocks_soil_samples (block_id, soil_sample_id) SELECT ${new block id}, soil_sample_id FROM blocks_soil_samples WHERE block_id = ${old block id};
  */

  return (
    <Dialog
      open={visible}
      onClose={() => handleClose(null)}
      aria-labelledby="modify-dialog-title"
      aria-describedby="modify-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: 'rgba(0, 161, 201, 0.1)' }}
        id="modify-dialog-title"
      >{`Modify actions for Block ${blockState.blockName}`}</DialogTitle>
      <DialogContent>
        <TextField
          id="blockName"
          name="blockName"
          label="Block Name"
          type="text"
          value={blockState.blockName ?? ''}
          onChange={handleBlockChange}
          disabled={modifyAction !== 'Split'}
        />
        <TextField
          id="hectares"
          name="hectares"
          label="hectares"
          value={blockState.hectares}
          type="number"
          onChange={handleBlockChange}
          disabled={modifyAction !== 'Resize'}
          style={{ marginLeft: 80 }}
        />
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={modifyAction === 'Clone'}
                onChange={handleChange}
                name="Clone"
              />
            }
            label="Clone to New"
          />
          <FormControlLabel
            control={
              <Switch
                checked={modifyAction === 'Split'}
                onChange={handleChange}
                name="Split"
              />
            }
            label="Split"
          />
          <FormControlLabel
            control={
              <Switch
                checked={modifyAction === 'Resize'}
                onChange={handleChange}
                name="Resize"
              />
            }
            label="Resize"
          />
          <FormControlLabel
            control={
              <Switch
                checked={modifyAction === 'Decommission'}
                onChange={handleChange}
                name="Decommission"
              />
            }
            label="Decommission"
          />
        </FormGroup>
        {(modifyAction === 'Clone' || modifyAction === 'Split') && (
          <>
            <TextField
              id="newBlockName"
              name="newBlockName"
              label="New Block Name"
              type="text"
              value={modifiedState.newBlockName ?? ''}
              onChange={handleModifyChange}
            />
            <TextField
              id="newHectares"
              name="newHectares"
              label="New block hectares"
              value={modifiedState.newHectares}
              type="number"
              onChange={handleModifyChange}
              style={{ marginLeft: 80 }}
            />
          </>
        )}


        {validationErrors[modifyAction]?.map((msg) => (
          <Typography key={msg} variant="body2" color="error">
            {msg}
          </Typography>
        ))}

        {!modifyAction && (
          <>
            <TextField
              id="droneDeployLink"
              name="droneDeployLink"
              label="Drone Deploy Link"
              type="text"
              defaultValue={blockState.droneDeployLink}
              fullWidth
              multiline
              onChange={handleBlockChange}
            />
            <TextField
              id="lat"
              name="lat"
              label="latitude"
              defaultValue={blockState.lat}
              type="number"
              onChange={handleBlockChange}
            />

            <TextField
              id="lng"
              name="lng"
              label="longitude"
              defaultValue={blockState.lng}
              type="number"
              style={{ marginLeft: 80 }}
              onChange={handleBlockChange}
            />
            {/* <TextField
              id="map_page"
              name="map_page"
              label="Map Page"
              defaultValue={blockState.map_page}
              type="number"
              onChange={handleBlockChange}
            /> */}
            {/* {blockState.variety !== '' && ( */}
              <Box style={{ marginTop: 8 }}>
                <VarietyPicker
                  selected={blockState.variety}
                  options={varieties}
                  onChange={(selected) =>
                    setBlockState((prev) => ({ ...prev, variety: selected }))
                  }
                />
              </Box>
            {/* )} */}
            <Box style={{ marginTop: 16 }}>
              <Button
                variant="contained"
                color={'secondary'}
                onClick={() => handleClose('SoilSample')}
              >
                CHANGE SOIL SAMPLE
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(null)} color="primary">
          Cancel
        </Button>
        <Button
          disabled={
            validationErrors[modifyAction]?.length > 0 || varieties?.length <= 0
          }
          onClick={() => handleModifyData()}
          color="primary"
          autoFocus
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
