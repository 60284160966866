import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";

import AlertDialog from "../AlertDialog";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react"; //, { useCallback, useEffect, useState }
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import { Notes } from "@material-ui/icons";
import { CommentRow } from "./CommentRow";

// import ButtonSelect from "../components/ButtonSelect";

const appTimingOptions = [
  "Before out of hand",
  "Pre Planting",
  "At Planting",
  "Standing Crop",
];

const appMethodOptions = [
  "Stool Split",
  "Side Dress",
  "Foliar",
  "Topdress at fill-in",
  "Broadcast and Incorporate",
  "Banded around sett and covered",
  "Banded over row",
  "Topdress Inject"
];

export default function ProductSelectLine(props) {
  const [openDialog, setOpenDialog] = useState(false);
  
  const {
    products,
    productSelected,
    area,
    removeProductRow,
    newComment,
    newAppTiming,
    newAppMethod,
    newProductSelected,
    newRateSelected,
    hideComment,
  } = props;

  // let selectedProduct = products.find()
  const [, setSelectedProduct] = React.useState();
  // console.log('SELECTED PRODUCT', selectedProduct)
  // console.log(area, "-------", productSelected);

  const totalN = Math.round(
    (productSelected.n * productSelected.apprate) / 100
  );
  const totalP = Math.round(
    (productSelected.p * productSelected.apprate) / 100
  );
  const totalK = Math.round(
    (productSelected.k * productSelected.apprate) / 100
  );
  const totalS = Math.round(
    (productSelected.s * productSelected.apprate) / 100
  );

  // function confirmRemoveRow ()

  return (
    <>
      <AlertDialog
        visible={openDialog}
        title={"Are you sure?"}
        subtitle={"This will be permanently deleted once you save"}
        handleAccept={() => removeProductRow()}
        handleClose={() => setOpenDialog(false)}
      />
      <TableRow key={1.22}>
        <TableCell style={{padding: 0, border: 0, width: 24}}>
          {hideComment !== true && 
            <IconButton onClick={() => newComment('')} disabled={productSelected.comment !== null} style={{padding: 0}} title="Add Comment">
              <Notes color={productSelected.comment !== null ? 'secondary' : 'action'} style={{verticalAlign: 'middle'}} />
            </IconButton>
          }
        </TableCell>
        {/* <TableCell colSpan={2} /> */}
        <TableCell align="left" style={{border: 0}}>
          <Autocomplete
            disabled={productSelected.applied > 0}
            value={productSelected.apptiming}
            onChange={(event, newValue) => {
              newAppTiming(newValue);
            }}
            id="appTimingOptions"
            options={appTimingOptions}
            getOptionSelected={(option, value) => option === value}
            style={{ width: 150 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
        <TableCell align="left" style={{border: 0}}>
          <Autocomplete
            disabled={productSelected.applied > 0}
            value={productSelected.appmethod}
            onChange={(event, newValue) => {
              newAppMethod(newValue);
            }}
            id="appMethodOptions"
            options={appMethodOptions}
            getOptionSelected={(option, value) => option === value}
            style={{ width: 150 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
        <TableCell align="left" style={{border: 0}}>
          <Autocomplete
            disabled={productSelected.applied > 0}
            id="productOptions"
            value={productSelected}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                products.filter((product) => {
                  if (product.id === newValue.id) {
                    setSelectedProduct(product);
                  }
                  return null;
                });
                // console.log("-------", selectedProduct);
                newProductSelected(newValue);
              }
            }}
            options={products}
            getOptionLabel={(option) => {
              // console.log("---------------", option);
              let prodName = option.productName
                ? option.productName
                : option.name;
              if (prodName) {
                prodName +=
                  " N" +
                  option.n +
                  " P" +
                  option.p +
                  " K" +
                  option.k +
                  " S" +
                  option.s;
              } else {
                prodName = "Select Product";
              }
              return prodName;
            }}
            getOptionSelected={(option, value) => {
              // console.log({value,option});
              return parseInt(option.id) === parseInt(value.productid);
            }}
            style={{ width: 250 }}
            renderInput={(params) => <TextField {...params} />}
          />
        </TableCell>
        <TableCell align="left" style={{border: 0}}>{productSelected.n}</TableCell>
        <TableCell align="left" style={{border: 0}}>{productSelected.p}</TableCell>
        <TableCell align="left" style={{border: 0}}>{productSelected.k}</TableCell>
        <TableCell align="left" style={{border: 0}}>{productSelected.s}</TableCell>
        <TableCell align="left" style={{border: 0}}>
          <TextField
            disabled={productSelected.applied > 0}
            id="appRate"
            type="number"
            value={productSelected.apprate}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                newRateSelected(parseInt(event.target.value));
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
        <TableCell align="left" style={{border: 0}}>{totalN}</TableCell>
        <TableCell align="left" style={{border: 0}}>{totalP}</TableCell>
        <TableCell align="left" style={{border: 0}}>{totalK}</TableCell>
        <TableCell align="left" style={{border: 0}}>{totalS}</TableCell>
        <TableCell style={{border: 0}}>
          {productSelected.applied && productSelected.applied > 0 ? (
            productSelected.applied === area ? (
              <Tooltip title="Applied">
                <CheckCircleIcon color="primary" />
              </Tooltip>
            ) : (
              <Tooltip title={`Applied to ${productSelected.applied}ha`}>
                <DonutSmallIcon color="primary" />
              </Tooltip>
            )
          ) : (
            <IconButton
              disabled={productSelected.applied > 0}
              color="secondary"
              aria-label="arrow_back"
              onClick={() => setOpenDialog(true)}
              size="small"
            >
              <RemoveCircle />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {hideComment !== true &&
        <CommentRow comment={productSelected.comment} onTypingStopped={(comment) => newComment(comment)} />
      }
    </>
  );
}
