import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SoilSampleSchema } from '../components/ValidationSchema';
import FormikForm from '../components/FormikForm';
import FormText from '../components/FormText';
import FormCheckbox from '../components/FormCheckbox';
import FormMultiSelect from '../components/FormMultiSelect';
import { useFormik } from 'formik';
import FormButton from '../components/FormButton';
import { API, graphqlOperation } from 'aws-amplify';
import { updateSoilSample, createSoilSample } from '../graphQL/SoilSample';
import { useAlertContext } from '../components/AlertContext';
import FormSelect from '../components/FormSelect';
import { getFarmBlocks } from '../graphQL/Farm';
import LoadingBackground from '../components/LoadingBackground';
import PageHeader from '../components/PageHeader';
import { getSoilSample } from '../graphQL/SoilSample';
import { useHistory } from 'react-router-dom';
import buildInitialValues from '../functions/buildInitialValues';
import { soilStates } from '../functions/offlineQueries';
import LogoCard from '../components/LogoCard';
import { FormFileAttachment } from '../components/FormFileAttachment.js';
import { useUserContext } from '../contexts/UserContext.js';
import { getAttachmentSignedURL } from '../api/mutations/soil-sample/getAttachmentSignedURL.js';
import { deleteAttachment } from '../api/mutations/soil-sample/deleteAttachment.js';

export default function ManageSoilSample(props) {
  const { user } = useUserContext();
  const userAuthToken = user?.idToken?.jwtToken;

  const sampleId = props.match.params.id;
  const farmId = props.match.params.farm;
  const farmName = props.match.params.name;

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [soilSample, setSoilSample] = useState(null);

  const alertContext = useAlertContext();

  const { data: ngBlock, isLoading } = useQuery({
    queryKey: ['blocks'],
    queryFn: async () => {
      const result = await API.graphql(
        graphqlOperation(getFarmBlocks.query, { farmId: farmId })
      );
      const ngBlock = JSON.parse(result.data.getFarmBlocks);
      console.log(ngBlock);

      setBlocks(
        ngBlock.flatMap((b) => {
          return b.decommissioned_cycle ? [] : [{ value: b.id, name: b.name }];
        })
      );

      //   console.log('GOT THIS AGAIN...');

      return ngBlock;
    },
  });

  const fetchSoilSample = useCallback(async () => {
    setLoading(true);
    await API.graphql(
      graphqlOperation(getSoilSample.query, { sampleId: sampleId })
    )
      .then((result) => {
        let sample = JSON.parse(result.data.getSoilSample);
        sample.blocks = sample.blocks.map((b) => {
          return b.id;
        });
        setSoilSample(sample);
        console.log(sample);
      })
      .catch(async (err) => {
        console.log(err);
        alertContext.error(
          err,
          'Error fetching soil samples - please check your network connection and try again'
        );
      });
  }, [sampleId, alertContext]);

  useEffect(() => {
    // fetchBlocks()
    if (sampleId) fetchSoilSample().then(() => setLoading(false));
  }, [fetchSoilSample, sampleId]);

  const formik = useFormik({
    initialValues: {
      ...buildInitialValues(soilSample, ['lat', 'lng', 'id']),
      labSampleNumber: sampleId ? soilSample?.lab_sample_number : '',
      state: sampleId ? soilSample?.sample_state_id : '1',
      blocks: sampleId ? soilSample?.blocks : [],
      accreditedLab: sampleId ? soilSample?.accredited_lab : false,
      followedProcedure: sampleId ? soilSample?.followed_procedure : false,
      samplingPath: soilSample?.sampling_path ?? '',
      samplingMethod: soilSample?.sampling_method ?? '',
      targetNumCores: soilSample?.target_num_cores ?? 0,
      labResults:
        (soilSample?.lab_results_key ?? null) !== null
          ? { name: soilSample.lab_results_key }
          : null,
      samplingPlan:
        (soilSample?.sampling_plan_key ?? null) !== null
          ? { name: soilSample.sampling_plan_key }
          : null,
    },
    validationSchema: SoilSampleSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        const { labResults, samplingPlan, targetNumCores, ...rest } = values;

        const labResultsKey = labResults?.name ?? null;
        const samplingPlanKey = samplingPlan?.name ?? null;

        let currentLabResultsKey = soilSample?.lab_results_key ?? null;
        let currentSamplingPlanKey = soilSample?.sampling_plan_key ?? null;

        const doDeleteLabResults =
          currentLabResultsKey !== null &&
          (labResults?.file || labResultsKey === null);
        const doDeleteSamplingPlan =
          currentSamplingPlanKey !== null &&
          (samplingPlan?.file || samplingPlanKey === null);

        let labResultsURL = null;
        let samplingPlanURL = null;

        let attachmentError = null;

        if (sampleId) {
          try {
            // Get Presigned URLs
            try {
              if (labResults?.file) {
                const data = await getAttachmentSignedURL({
                  token: userAuthToken,
                  sampleId,
                  attachmentKey: `lab-results/${labResultsKey}`,
                  operation: 'put',
                });
                labResultsURL = data.url;
              }
              if (samplingPlan?.file) {
                const data = await getAttachmentSignedURL({
                  token: userAuthToken,
                  sampleId,
                  attachmentKey: `sampling-plan/${samplingPlanKey}`,
                  operation: 'put',
                });
                samplingPlanURL = data.url;
              }
            } catch (err) {
              console.error(err);
              throw new Error('Failed to get presigned attachment URLs.');
            }

            // Delete Existing Attachments
            try {
              if (doDeleteLabResults) {
                await deleteAttachment({
                  token: userAuthToken,
                  sampleId,
                  attachmentKey: `lab-results/${currentLabResultsKey}`,
                });
                currentLabResultsKey = null;
              }
              if (doDeleteSamplingPlan) {
                await deleteAttachment({
                  token: userAuthToken,
                  sampleId,
                  attachmentKey: `sampling-plan/${currentSamplingPlanKey}`,
                });
                currentSamplingPlanKey = null;
              }
            } catch (err) {
              console.error(err);
              throw new Error('Failed to delete existing attachments.');
            }

            // Upload New Attachments
            try {
              if (labResultsURL !== null) {
                const uploadResponse = await fetch(labResultsURL, {
                  method: 'PUT',
                  body: labResults.file,
                });
                if (!uploadResponse.ok) {
                  throw new Error('Failed to upload LabResults.');
                }
                currentLabResultsKey = labResultsKey;
              }
              if (samplingPlanURL !== null) {
                const uploadResponse = await fetch(samplingPlanURL, {
                  method: 'PUT',
                  body: samplingPlan.file,
                });
                if (!uploadResponse.ok) {
                  throw new Error('Failed to upload SamplingPlan.');
                }
                currentSamplingPlanKey = samplingPlanKey;
              }
            } catch (err) {
              console.error(err);
              throw new Error('Failed to upload new attachments.');
            }
          } catch (err) {
            attachmentError = err;
          }
        }

        const result = await API.graphql(
          graphqlOperation(
            sampleId ? updateSoilSample.mutation : createSoilSample.mutation,
            {
              data: {
                ...rest,
                targetNumCores: parseInt(targetNumCores),
                labResultsKey: currentLabResultsKey,
                samplingPlanKey: currentSamplingPlanKey,
              },
            }
          )
        );

        if (attachmentError !== null) {
          alertContext.error(
            attachmentError,
            sampleId
              ? 'The soil sample has been updated (Attachment Errors)'
              : 'New soil sample created (Attachment Errors)'
          );
        } else {
          alertContext.success(
            sampleId
              ? 'The soil sample has been updated'
              : 'New soil sample created'
          );
        }

        actions.setSubmitting(false);
        if (!sampleId) {
          const id = JSON.parse(result.data.createSoilSample).id;
          history.push(`/view-soil-sample/${farmName}/${farmId}/${id}`);
        }
      } catch (err) {
        actions.setSubmitting(false);
        alertContext.error(
          err,
          `Error ${sampleId ? 'updating' : 'creating'} the soil sample`
        );
      }

      if (sampleId) {
        await fetchSoilSample();
        setLoading(false);
      }
    },
  });

  return (
    <LogoCard margin={20}>
      <LoadingBackground visible={loading} />
      <PageHeader
        title={
          sampleId
            ? `Update Soil Sample: ${soilSample?.lab_sample_number}`
            : `Request Soil Sample`
        }
        subheader={
          sampleId
            ? `Use the below form and select 'Update Sample' out form details to Update Soil sample`
            : `Use the below form and select 'Create New Sample' to request a new Soil sample`
        }
        backPath={`/farm-soil-samples/${farmName}/${farmId}`}
      />
      <div style={{ padding: 20 }}>
        {((sampleId && soilSample && !loading) || !sampleId) && (
          <FormikForm formik={formik}>
            <FormText
              name="labSampleNumber"
              label="Lab Sample Number:"
              disabled={!!sampleId}
              required
            />
            <FormCheckbox
              name="followedProcedure"
              label="Procedure Followed: The Fertcare procedure for soil collection was followed and carried out by an accredited person."
            />
            <FormCheckbox
              name="accreditedLab"
              label="Accredited Lab: The laboratory that performed the analysis on the soil sample was accredited to do so."
            />
            <FormText
              name="lat"
              label="Lat:"
              inputType={'number'}
              helpText={'lat of the soil sample'}
            />
            <FormText
              name="lng"
              label="Lng:"
              inputType={'number'}
              helpText={'lng of the soil sample'}
            />
            <FormSelect
              name="state"
              label="State:"
              required
              options={soilStates}
              disabled={!sampleId || soilSample.sample_state_id === '5'}
            />
            <FormMultiSelect
              name="blocks"
              label="Blocks:"
              options={blocks}
              sortOptions
              helpText={'Use the checkboxes to select multiple blocks'}
            />
            <FormText name="samplingPath" label="Sampling Path" />
            <FormText name="samplingMethod" label="Sampling Method" />
            <FormText
              name="targetNumCores"
              label="Target Number of Cores:"
              inputType={'number'}
            />
            {sampleId && (
              <>
                <FormFileAttachment
                  name="labResults"
                  label="Lab Results"
                  maxFileSize={Math.pow(10, 7)}
                />
                <FormFileAttachment
                  name="samplingPlan"
                  label="Sampling Plan"
                  maxFileSize={Math.pow(10, 7)}
                />
              </>
            )}
            <FormButton
              text={sampleId ? 'Update Sample' : 'Create New Sample'}
            />
          </FormikForm>
        )}
      </div>
    </LogoCard>
  );
}
