// import { Cache } from "aws-amplify";
//TODO Remove Static values and pull from DB
// async function getBlocks(farmId) {
//   const data = await Cache.getItem("myFarms");
//   return data ? data.filter((farm) => JSON.stringify(farm.id) === farmId)[0].blocks : [];
// }

// async function getBlock(farmId, blockId) {
//   const data = await Cache.getItem("myFarms");
//   const blocks = data.find((farm) => JSON.stringify(farm.id) === farmId).blocks;
//   return blocks.find((block) => JSON.stringify(block.id) === blockId);
// }

// async function getThresholds(farmId) {
//   const data = await Cache.getItem("myFarms");
//   return data.find((farm) => JSON.stringify(farm.id) === farmId).thresholds;
// }

function getRequirementState(id) {
  return states.find((s) => String(s.value) === String(id)).name;
}

function getFallowCrop(id) {
  return fallowCrops.find((s) => String(s.value) === String(id)).name;
}

function getCropClass(id) {
  return cropClasses.find((s) => String(s.value) === String(id)).name;
}

function getVarieties(id) {
  return varieties.find((s) => String(s.value) === String(id)).name;
}

const soilStates = [
  {
    name: "Requested",
    value: 1,
  },
  {
    name: "Sent",
    value: 2,
  },
  {
    name: "Received",
    value: 3,
  },
  {
    name: "Rejected",
    value: 4,
  },
  {
    name: "Accepted",
    value: 5,
  },
];

const states = [
  {
    name: "",
    value: null,
  },
  {
    name: "Linked",
    value: 1,
  },
  {
    name: "Ready",
    value: 2,
  },
  {
    name: "Complete",
    value: 3,
  },
];

const fallowCrops = [
  {
    name: "",
    value: null,
  },
  {
    name: "Soy Bean",
    value: 1,
  },
  {
    name: "Cow Pea",
    value: 2,
  },
  {
    name: "LabLab",
    value: 3,
  },
  {
    name: "Peanut",
    value: 4,
  },
];

const cropClasses = [
  {
    name: "",
    value: null,
  },
  {
    value: 1,
    name: "Fallow",
  },
  {
    value: 2,
    name: "Plant",
  },
  {
    value: 3,
    name: "Ratoon 1",
  },
  {
    value: 4,
    name: "Ratoon 2",
  },
  {
    value: 5,
    name: "Ratoon 3",
  },
  {
    value: 6,
    name: "Ratoon 4",
  },
  {
    value: 7,
    name: "Ratoon 5",
  },
  {
    value: 8,
    name: "Ratoon 6",
  },
  {
    value: 9,
    name: "Ratoon 7",
  },
  {
    value: 10,
    name: "Ratoon 8",
  },
  {
    value: 11,
    name: "Ratoon 9",
  },
  {
    value: 12,
    name: "Ratoon 10",
  },
  {
    value: 13,
    name: "Ratoon 11",
  },
  {
    value: 14,
    name: "Ratoon 12",
  },
  {
    value: 15,
    name: "Ratoon 13",
  },
  {
    value: 16,
    name: "Ratoon 14",
  },
  {
    value: 17,
    name: "PORP",
  },
  {
    value: 18,
    name: "Ratoon 0",
  },
];

const varieties = [
  {
    value: null,
    name: "",
  },
  {
    value: 1,
    name: "MQ239",
  },
  {
    value: 2,
    name: "MXD",
  },
  {
    value: 3,
    name: "Q183",
  },
  {
    value: 4,
    name: "Q200",
  },
  {
    value: 5,
    name: "Q208",
  },
  {
    value: 6,
    name: "Q215",
  },
  {
    value: 7,
    name: "Q219",
  },
  {
    value: 8,
    name: "Q226",
  },
  {
    value: 9,
    name: "Q231",
  },
  {
    value: 10,
    name: "Q232",
  },
  {
    value: 11,
    name: "Q238",
  },
  {
    value: 12,
    name: "Q240",
  },
  {
    value: 13,
    name: "Q242",
  },
  {
    value: 14,
    name: "Q247",
  },
  {
    value: 15,
    name: "Q250",
  },
  {
    value: 16,
    name: "Q253",
  },
  {
    value: 17,
    name: "SRA3",
  },
];

export {
  // getBlocks,
  // getBlock,
  // getThresholds,
  getRequirementState,
  states,
  getFallowCrop,
  soilStates,
  fallowCrops,
  getCropClass,
  cropClasses,
  varieties,
  getVarieties,
};
