import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


export default function LabSampleTable(props) {
    const classes = style()
    //const theme = useTheme();
    const { labSample } = props;
   // const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const columns = [
        { id: 'key', label:'Sample Type', minWidth: 200 },
        { id: 'value', label:'Value', minWidth: 50},
    ]
    const fields = [
                        {name: 'soil_texture', label:'Soil Texture'},
                        {name: 'sampling_date', label:'Sample Date', type:'date'},
                        {name: 'ph_1_5_water', label:'pH (1:5 Water)'}, 
                        {name: 'ph_1_5_cacl2', label:'pH (1:5 Cacl2)'},
                        {name: 'organic_carbon', label:'Organic Carbon (W&B)'},
                        {name: 'electrical_conductivity_1_5_water', label:'Electrical Conductivity (1:5 water)'},
                        {name: 'electrical_conductivity_on_saturation_extract', label:'Elec. Cond. (Sat. Ext.)'},
                        {name: 'chloride', label:'Chloride'},
                        {name: 'nitrate_nitrogen_n03', label:'Nitrate Nitrogen (NO3)'},
                        {name: 'ammonium_nitrogen_kcl', label:'Ammonium Nitrogen'},
                        {name: 'phosphorus_bses_mg_kg', label:'Phosphorus (BSES)mg/kg'},
                        {name: 'phosphorus_colwell_mg_kg', label:'Phosphorus (Colwell)mg/kg'},
                        {name: 'phosphorus_buffer_index', label:'Phosphorus Buffer Index (PBI-Col)'},
                        {name: 'nitric_potassium', label:'Potassium (HNO3)Meq/100g'},
                        {name: 'sulfate_suplfur_mcp_mg_kg', label:'Sulfate Sulfur (MCP)mg/kg'},
                        {name: 'exchangable_calcium', label:'Calcium (Amm-acet.)Meq/100g'},
                        {name: 'magnesium_ammonium_acetate_meq_per100g', label:'Magnesium (Amm-acet.)Meq/100g'},
                        {name: 'potassium_exchange', label:'Potassium (Amm-acet.)Meq/100g'},
                        {name: 'sodium_cmol', label:'Sodium (Amm-acet.)Meq/100g'},
                        {name: 'aluminium_kcl_meq_100g', label:'Aluminium (KCl)Meq/100g'},
                        {name: 'cation_exchange_capacity_meq_100g', label:'Cation Exch. Cap.Meq/100g'},
                        {name: 'calcium_percentage_of_cations', label:'Calcium (Amm-acet.)%'},
                        {name: 'magnesium_percentage_of_cations', label:'Magnesium (Amm-acet.)%'},
                        {name: 'potassium_amm_acet_percentage', label:'Potassium (Amm-acet.)%'},
                        {name: 'exchangable_sodium_percentage', label:'Sodium % of Cations (ESP)%'},
                        {name: 'aluminium_saturation_percentage', label:'Aluminium Saturation%'},
                        {name: 'calcium_magnesium_ratio', label:'Ca:Mg  ratio'},
                        {name: 'copper_dtpa_mg_kg', label:'Copper (DTPA)mg/kg'},
                        {name: 'zink_dtpa_mg_kg', label:'Zinc (DTPA)mg/kg'},
                        {name: 'zinc_hcl', label:'HCl Zinc mg/kg'},
                        {name: 'iron_dtpa', label:'Iron (DTPA)mg/kg'},
                        {name: 'manganese_dtpa_mg_kg', label:'Manganese (DTPA)mg/kg'},
                        {name: 'boron_mg_kg', label:'Boron mg/kg'},
                        {name: 'silicon_bses_mg_kg', label:'Silicon (BSES)mg/kg'},
                        {name: 'silicon_cacl2_mg_kg', label:'Silicon (CaCl2)mg/kg'}
                    ]
    
    return (
        <>
        <TableContainer className={classes.root}>
            <Table stickyHeader>             
                <TableHead>
                    <TableRow>
                        {columns.map((column => ( 
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth}}
                                >
                                    {column.label}
                                </TableCell>
                        )))}
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {fields.map((f) => (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {`${f.label}`}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {`${labSample[f.name] === null || labSample[f.name] === undefined ? "N/A" : f.type === 'date' ? labSample[f.name].substring(0,10) : labSample[f.name]}`}
                                </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    
    </>
    );
}

const style = makeStyles(theme => ({
    actionIcon: {
        marginRight: 25
    },
    root: {
        height: '100%'
    }
}))
