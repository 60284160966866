import * as Yup from "yup"

const validator = {
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
    phone: /^((?!(0))[0-9]{5,15})$/,
    latLng: /-?\d+\.\d+/,
    farmName: /[F][0-9]+[A-Z]/,
    block: /[0-9]+[A-z,-][0-9]*|[0-9]+|[0-9]+-[0-9]+|^\d+[A-Z]*-?\d*[A-z]?$/
}

export const SoilSampleSchema = (data) => Yup.object().shape({
    labSampleNumber: Yup.string().required('Please enter a lab sample number'),
    lat: Yup.string(),
    lng: Yup.string(),
    state: Yup.string(),
    blocks: Yup.array().min(1, "At least 1 block is required").required("required"),
    attachments: Yup.array().max(1)
});

export const RequirementSchema = Yup.object().shape({
    mill_mud_ash_rate: Yup.string(),
    mill_mud_rate: Yup.string(),
    mill_ash_rate: Yup.string(),
    magnesium: Yup.string(),
    silicate: Yup.string(),
    nitrogen: Yup.string(),
    lime: Yup.string(),
    gypsum: Yup.string(),
    phosphorus: Yup.string(),
    potassium: Yup.string(),
    sulphur: Yup.string(),
    block_id: Yup.string(),
    fallow_crop_id: Yup.string().nullable(),
    fallow_yield: Yup.string(),
    crop_class_id: Yup.string(),
    from_date: Yup.string(),
    to_date: Yup.string(),
    requirement_state_id: Yup.string(),
    variety_id: Yup.string().nullable(),
    requirementYield: Yup.string(),
    fertilizer_year: Yup.string(),
    fallow_harvested: Yup.boolean().nullable()
});

export const ProfilePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().min(8, 'Password must be at least 8 characters')
        .required('Please enter your current password')
        .matches(validator.password, 'password does not meet the policy requirements'),
    newPassword: Yup.string().min(8, 'Password must be at least 8 characters')
        .required('Please enter your new password')
        .matches(validator.password, 'password does not meet the policy requirements'),
});

export const ProfileSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string()
});

export const UserSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string()
});

export const LoginFormSchema = (open) => Yup.object().shape({
    username: Yup.string(),
    password: Yup.string().min(8, 'Password must be at least 8 characters')
    .matches(validator.password, 'password does not meet the policy requirements'),
    confirmPassword: Yup.string().min(8, 'Password must be at least 8 characters')
    .matches(validator.password, 'password does not meet the policy requirements'),
    verify: Yup.string(),
})

export const LabSampleSpreadsheet = (data) => Yup.object().shape({
    'Farm no.': Yup.string().trim().matches(validator.farmName , 'Incorrect format for Farm Name')
        .oneOf([data.farmName], 'Farm name is incorrect').required('A farm name is required'),
    'Latitude': Yup.string().trim().matches(validator.latLng , 'Incorrect format for Latitude').required(),
    'Longitude': Yup.string().trim().matches(validator.latLng , 'Incorrect format for Longitude').required(),
    'Block no.': Yup.string().trim().matches(validator.block , 'Block Name is Invalid')
        .oneOf(data.blocks, 'No matching block found').required('Block no. is a required value'),
    'Soil texture': Yup.string().trim().oneOf(data.soilTextures, 'Soil Texture must be Clay, Loam or Sand').required('Soil texture is a required value'),
    'Crop class at sampling': Yup.string().trim().oneOf(data.cropClasses, 'Invalid Crop Class').required(),
    'Soil test number': Yup.string(),
    'Aluminium % of CEC': Yup.string(),
    'Ammonium N': Yup.string(),
    'Boron': Yup.string(),
    'Calcium % of CEC': Yup.string(),
    'Calcium exch cmol/kg': Yup.string(),
    'Calcium/Magnesium ratio': Yup.string(),
    'Cation Exchange Capacity': Yup.string(),
    'Chloride ppm': Yup.string(),
    'Copper mg/kg DTPA': Yup.string(),
    'Electrical conductivity 1:5 water': Yup.string(),
    'Electrical conductivity se': Yup.string(),
    'Iron mg/kg DTPA': Yup.string(),
    'Magnesium % of CEC': Yup.string(),
    'Magnesium exch cmol/kg': Yup.string(),
    'Manganese mg/kg DTPA': Yup.string(),
    'Nitrate N': Yup.string(),
    'Organic carbon %': Yup.string(),
    'Phosphorus BSES': Yup.string(),
    'Phosphorus Buffer Index': Yup.string(),
    'Phosphorus Colwell': Yup.string(),
    'Potassium % of CEC': Yup.string(),
    'Potassium HNO3': Yup.string(),
    'Potassium exch cmol/kg': Yup.string(),
    'Sample date': Yup.string(), //Yup.date().max(new Date(), 'Sample date cannot be in the future').required('Sample date is a required value'),
    'Silicon BSES': Yup.string(),
    'Silicon CaCl2': Yup.string(),
    'Sodium % of CEC': Yup.string(),
    'Sodium exch cmol/kg': Yup.string(),
    'Sulfate S': Yup.string(),
    'Zinc mg/kg BSES': Yup.string(),
    'Zinc mg/kg DTPA': Yup.string(),
    'al meq 100ml': Yup.string(),
    'pH 1:5 water': Yup.string(),
    'pH Ca Cl2': Yup.string()
})
