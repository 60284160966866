import React, { useContext } from 'react';

export const UserContext = React.createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvier = ({ children, user }) => {
  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
