// import {useMemo } from 'react';
// Uses query to filters through all blocks and returns relevant data for that fertilizer year
const filterFertYear = (blockArray, yearToFilter) => {
    // console.log(blockArray, yearToFilter);
    const yearToFilterInt = parseInt(yearToFilter);
    // console.log(blockArray.filter((b) => b.blockName == "12-1"));
  
    let filteredSearch = blockArray.flatMap((block) =>
      (parseInt(block.decommissioned_cycle) || yearToFilterInt + 1) > yearToFilterInt &&
      parseInt(block.commissioned_cycle) <= yearToFilterInt
        ? [
            {
              ...block,
              requirementCount: block.requirements?.length,
              requirements: block.requirements?.filter((requirement) => {
                if (
                  requirement.fertilizer_year?.toString() === yearToFilter.toString() /*&&
                  requirement.primary_sample_id?.toString() ===
                    block.primary_sample_id?.toString()*/ //TODO SORT BLOCKS WITH ORPHANED REQUIREMENTS
                ) {
                  return true;
                } else return false;
              }),
              productsselected: block.productsselected?.filter((product) => {
                if (product.fertilizer_year?.toString() === yearToFilter.toString()) {
                  return true;
                }
                return false;
              }),
            },
          ]
        : []
    );

  
    return filteredSearch.sort(
      (a, b) => parseFloat(a.name.replace('-', '.')) - parseFloat(b.name.replace('-', '.'))
    );
  };

export { filterFertYear };