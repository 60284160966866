export const updateBlocksCycle = {
    mutation: `mutation updateBlocksCycle($cycleBlocks: CycleBlocks) {
        updateBlocksCycle(cycleBlocks: $cycleBlocks) 
    }`,
};

export const ploughOutBlock = {
    mutation: `mutation ploughOutBlock($requirement: Requirement) {
        ploughOutBlock(requirement: $requirement) 
    }`,
};
  
export const getNotes = {
    query: `query getNotes($block_id: Int) {
        getNotes(block_id: $block_id)
    }`
};

export const createNote = {
    mutation: `mutation createNote($noteData: NoteData) {
        createNote(noteData: $noteData)
    }`,
};

export const updateBlock = {
    mutation: `mutation updateBlock($blockData: BlockData) {
        updateBlock(blockData: $blockData) 
    }`,
};

export const updateBlocksGeometries = {
    mutation: `mutation updateBlocksGeometries($pairs: [BlockGeometryPair]) {
        updateBlocksGeometries(pairs: $pairs)
    }`
}
  