import React, { useState, useContext, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useQueryClient } from '@tanstack/react-query';

// Queries
import { createRequirements } from "../../graphQL/Requirement";
import { requirementConfirmed } from '../../api/mutations/requirements/requirementConfirmed.js';

// Context
import { useAlertContext } from "../AlertContext";
import { BlockContext } from "../../contexts/BlocksContext";
import { useUserContext } from "../../contexts/UserContext.js";

// Material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

// TODO: Display requirement data, confirm and run the generateFNA process (createRequirements)

export default function CalculateDialog(props) {
  const alertContext = useAlertContext();
  const { dispatchBlock } = useContext(BlockContext);
  const { user } = useUserContext();
  const { visible, fertYear, block, handleClose } = props;

  const queryClient = useQueryClient();

  // const soilSampleDate =
  //   block.primary_sample_id == null
  //     ? undefined
  //     : block.soil_samples?.filter((ss) => {
  //         if (ss.id?.toString() === block.primary_sample_id) {
  //           return true;
  //         }
  //         return false;
  //       })[0].sampling_date;

  // console.log(soilSampleDate);

  // const currentDate = new Date();
  // let currentDateStr = currentDate.toISOString().split("T")[0];

  const [confirmed, setConfirmed] = useState(
    block.requirements[0].requirement_state_id >= 2
  );

  const inProgress = block.requirements[0].crop_class_id === 2

  // const soilDate = new Date(soilSampleDate);
  // const soilDateStr =
  //   soilDate.getDate() +
  //   "/" +
  //   (soilDate.getMonth() + 1) +
  //   "/" +
  //   soilDate.getFullYear();

  // let soilSampleTime = soilDate.getTime();
  // let differenceInDays =
  //   (new Date(estimatePlantDate).getTime() - soilSampleTime) /
  //   (1000 * 3600 * 24);

  // const handleDateChange = (event) => {
  //   setEstimatePlantDate(event.target.value);
  // };

  // const [variety, setVariety] = useState(1);
  // const handleVarietyChange = (event) => {
  //   setVariety(event.target.value);
  // };

  // const acceptDisabled =
  //   soilSampleDate == null ? true : differenceInDays > 365 ? true : false;

  // input Requirement {
  //   requirementId: String
  //   requirement_state_id: String
  //   plant_date: String
  //   block_id: String
  // }

  const handleConfirmation = useCallback(async () => {
    console.log("Confirm Data");
    console.log(block);

    try {
      await requirementConfirmed({
        token: user?.idToken?.jwtToken,
        requirementId: block.requirements[0].id
      });

      alertContext.success(`Requirement updated`);
      setConfirmed(true);
      dispatchBlock({
        type: "MODIFY_REQUIREMENT",
        block: {
          id: block.id,
          requirementid: block.requirements[0].id,
          requirement: {
            requirement_state_id: 2,
          },
        },
      });
    } catch (err) {
      console.log(err);
      alertContext.error(err, `Error updating the requirement`);
    }
  }, [alertContext, block, dispatchBlock, user?.idToken?.jwtToken]);

  function handleCalculate() {
    console.log("Trigger Calculations");

    //Create NFAs
    API.graphql(
      graphqlOperation(createRequirements.mutation, {
        requirementId: block.requirements[0].id,
      })
    )
      .then(async (result) => {
        // console.log(result);
        alertContext.success(`Farm Nutrient Allowances are being calculated`);
        // dispatchBlock({
        //   type: "MODIFY_REQUIREMENT",
        //   block: {
        //     id: block.id,
        //     requirementid: block.requirements[0].id,
        //     requirement: {
        //       crop_class_id: 2,
        //       crop_class_name: "Plant",
        //     },
        //   },
        // });
        // fetchBlocks();

        queryClient.invalidateQueries(['blocks']);

        handleClose();
      })
      .catch((err) => {
        alertContext.success(
          `There was an error generating the Farm Nutrient Allowances`
        );
      });
  }

  return (
    <Dialog
      open={visible}
      onClose={handleClose}
      aria-labelledby="calculate-dialog-title"
      aria-describedby="calculate-dialog-description"
    >
      <DialogTitle
        style={{ backgroundColor: "rgba(0, 161, 201, 0.1)" }}
        id="calculate-dialog-title"
      >
        {`Confirm Requirements for ${block.name}`}
      </DialogTitle>
      <DialogContent>
        {block.requirements[0].requirement_state_id === 2 ?
          <Typography>Click 'Calculate' to generate the requirements</Typography>
        :
        <>        
          <Typography>Confirm you are planting in {fertYear}</Typography>
          <Typography>Changes the fallow requirement to a plant!</Typography>
          <Button
            style={{ width: 160 }}
            variant="contained"
            color={"secondary"}
            onClick={() => handleConfirmation()}
            disabled={confirmed}
          >
            {confirmed ? "Confirmed" : "Confirm"}
          </Button></>
      
      }

        {inProgress && (
          <Typography color="error">
            In Progress, Please click refresh.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button
          disabled={false}
          onClick={() => handleCalculate()}
          color="primary"
          autoFocus
        >
          Calculate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*
{soilSampleDate == null ? null : (
          <Typography>Soil Sample Date: {soilDateStr}</Typography>
        )}
        {soilSampleDate == null ? (
          <Typography color="error">
            A valid soil sample will have to be selected
          </Typography>
        ) : differenceInDays > 365 ? (
          <Typography color="error">
            Planting must occur within 365 days
          </Typography>
        ) : null}
        <TextField
          id="date"
          label={"Approximate plant date"}
          type="date"
          defaultValue={estimatePlantDate} //"2021-02-24" //
          fullWidth
          onChange={handleDateChange}
        />

        <FormControl fullWidth>
          <InputLabel id="variety_label">Crop Type</InputLabel>
          <Select
            name="fallow_crop_id"
            label="Crop type"
            value={variety}
            fullWidth
            onChange={handleVarietyChange}
          >
            {varieties.map((variety, index) => (
              <MenuItem value={variety.value} key={index}>
                {variety.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>*/
