import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Paper, Typography, Box } from '@material-ui/core';
import {
  GeoJSON,
  MapContainer,
  TileLayer,
  LayersControl,
  SVGOverlay,
  useMap,
  Pane
} from 'react-leaflet';
// import { useMap } from 'react-leaflet/hooks'
import { makeStyles } from '@material-ui/core/styles';
import { toPng } from 'dom-to-image';
import { el } from 'date-fns/locale';
import ProductLegend from './ProductLegend';

const { BaseLayer, Overlay } = LayersControl;

const useStyle = makeStyles((theme) => ({
  legend: {
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    backgroundColor: '#FFF',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },

  legendColor: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
}));

export default function ReportMap(props) {
  const mapRef = useRef();

  // const handleWhenCreated = useCallback((map) => (mapRef.current = map), []);

  const handleCaptureClick = useCallback(async () => {
    const map = mapRef.current;
    if (map === undefined) return;

    console.log(mapRef)

    console.log(map);

    const mapContainer = map.getContainer();
    // const mapPane = map.getPane('mapPane');
    const { clientWidth: width, clientHeight: height } = mapContainer;

    const dataURL = await toPng(mapContainer, { width, height });

    if (props.onCapture !== undefined) props.onCapture(dataURL);
  }, [props]);

  // console.log(props);
  const classes = useStyle();

  const farmBlocks = props.mapData;



  const qualitativeColorArray = [
    '#8dd3c7',
    '#ffffb3',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',
  ];


  function getColor(feature) {
    const v = product?.apprates.find((x) => x.blockIds.includes(feature.properties.id));
    if (v === undefined || v === null) return '#FFF';
    return v.color; 
  }

  const style = (feature) => {
    return {
      weight: 2,
      opacity: 1,
      color: 'blue',
      fillColor: getColor(feature),
      dashArray: '3',
      fillOpacity: 0.5,
    };
  };

  const [product, setProduct] = useState(null);



  const productButtons = () => {
    return <Box display='flex' alignItems='center' justifyContent='space-around' style={{padding: '10px 0px'}}>
    {transformedData.map((element) => (
      <Button
        onClick={() => setProduct(element)}
        color={product?.id === element.id ? 'primary' : 'secondary'}
        variant='contained'
      >
        {element.name}
      </Button>
    ))}
    </Box>
  };

  // Get all the unique Product Names and Id from the farmBlocks (features[0].properties.products[0].productid)
  const products = [];
  farmBlocks.features.forEach((feature) =>
    feature.properties.products?.forEach((product) => {
      products.push({
        blockId: feature.properties.id,
        productId: product.productid,
        name: product.name,
        apprate: product.apprate,
      });
    })
  );


  let colorId = 0;
  const transformedData = products.reduce((result, item) => {
    const existingItem = result.find((x) => x.name === item.name);

    if (existingItem) {
      const existingRate = existingItem.apprates.find((x) => x.rate === item.apprate);

      if (existingRate) {
        existingRate.blockIds.push(item.blockId);
      } else {
        existingItem.apprates.push({
          rate: item.apprate,
          color: qualitativeColorArray[colorId++],
          // colorId: colorId++,

          blockIds: [item.blockId],
        });
      }
    } else {
      result.push({
        name: item.name,
        id: item.productId,
        apprates: [{ rate: item.apprate, color: qualitativeColorArray[colorId++], blockIds: [item.blockId] }],
      });
    }

    return result;
  }, []);

  let bbox = [...farmBlocks.features[0].geometry.bbox];
  farmBlocks.features.forEach((feature) => {
    // Calc bounding box max/min
    if (feature.geometry.bbox[0] < bbox[0]) bbox[0] = feature.geometry.bbox[0];
    if (feature.geometry.bbox[1] < bbox[1]) bbox[1] = feature.geometry.bbox[1];
    if (feature.geometry.bbox[2] > bbox[2]) bbox[2] = feature.geometry.bbox[2];
    if (feature.geometry.bbox[3] > bbox[3]) bbox[3] = feature.geometry.bbox[3];
  });


  const [map, setMap] = useState(null);

  return (
    <>
      <MapContainer
        center={[-25.2744, 135.7751]}
        zoom={4}
        zoomControl={false}
        style={{ height: '600px', padding: 30 }}
        // whenCreated={(map) => (mapRef.current = map)}
        whenCreated={setMap}
        whenReady={(e) => {
          mapRef.current = e.target;
          e.target.fitBounds(
            [
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ],
            { padding: [1, 1] }
          );
        }}
      >

        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
        {product && product.apprates.length > 0 && <ProductLegend map={map} products={product} legendPosition={'bottomright'}/>}
        <Overlay checked name='Groups'>
          <GeoJSON key={Math.random()} data={farmBlocks} style={style} />
        </Overlay>
        {farmBlocks.features.map((block) => (
          <SVGOverlay
            key={block.properties.id}
            name='names'
            attributes={{ stroke: 'red' }}
            bounds={[
              [block.geometry.bbox[1], block.geometry.bbox[0]],
              [block.geometry.bbox[3], block.geometry.bbox[2]],
            ]}
          >
            <text x='48%' y='48%' stroke='black'>
              {block.properties.name}
            </text>
          </SVGOverlay>
        ))}


        {farmBlocks && <GeoJSON data={farmBlocks} style={style} />}
          
      </MapContainer>
      <div>{productButtons()}</div>
      <Button variant='contained' fullWidth={true} onClick={handleCaptureClick} color={'secondary'}>
        Capture
      </Button>
    </>
  );
}
