import React, { useCallback, useEffect, useMemo, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

// Libraries
import html2PDF from "jspdf-html2canvas";
import * as XLSX from "xlsx";
// Contexts
import { useAlertContext } from "../../components/AlertContext";
// Queries
import { getReport } from "../../graphQL/Report";
// CSS
import "./main.css";

// Material UI
import { IconButton, Tooltip, Typography } from "@material-ui/core";

// Icons
import { GetApp, PictureAsPdf } from "@material-ui/icons";
// Components
import LabSampleTable from "../../components/Reports/LabSampleTable";
import LoadingBackground from "../../components/LoadingBackground";
import PageHeader from "../../components/PageHeader";
import ProductSelectedTable from "../../components/Reports/ProductSelected";
import FertTotalsTable from "../../components/Reports/FertTotalsTable";
// Assets
import coverPage from "../../assets/report_cover.jpeg";

export default function ViewReports(props) {
  const year = props.match.params.year;
  const farmName = props.match.params.name;
  const fileType = props.match.params.type || "";
  const fileName = props.match.params.filename;
  // const user = props.user;
  const [loading, setLoading] = useState(true);
  const alertContext = useAlertContext();
  const [report, setReport] = useState([]);
  const [plantProductSelection, setPlantProductSelection] = useState([]);
  const [ratoonProductSelection, setRatoonProductSelection] = useState([]);
  const [totalRequirementsData, setTotalRequirementsData] = useState([]);
  const [labSamples, setLabSamples] = useState([]);
  const [finalReport, setFinalReport] = useState(false);

  const reportKey = useMemo(() => {
    return `${report.farmId}_${report.finalReport ? 'F' : 'P'}_${fileName}`;
  }, [report, fileName]);

  console.log(farmName, year, fileType, fileName);
  const [options] = useState({
    farmId: farmName,
    year: year,
    fileName: `${fileType !== "" ? `${fileType}/` : ""}${fileName}.json`,
  });

  const fetchReport = useCallback(() => {
    setLoading(true);
    console.log(options);
    API.graphql(
      graphqlOperation(getReport.query, { data: JSON.stringify(options) })
    )
      .then((result) => {
        const tempReport = JSON.parse(result.data.getReport);
        setReport(tempReport);
        console.log(tempReport);
        // ************************************ LOAD SEGMENTS HERE SO ANY VERSION CHANGES CAN BE SORTED HERE ******************************
        setFinalReport(tempReport.finalReport || false);

        setPlantProductSelection(
          tempReport.plantFertRecommendedTable.plantProductSelection
        );
        setRatoonProductSelection(
          tempReport.plantFertRecommendedTable.ratoonProductSelection
        );
        setTotalRequirementsData(
          tempReport.totalFarmRequirements.totalRequirementsData
            .farmFertTotals ||
            tempReport.totalFarmRequirements.totalRequirementsData
        );
        setLabSamples(
          tempReport.labSampleTable.labSamples.samples ||
            tempReport.labSampleTable.labSamples
        );
        console.log(
          tempReport.labSampleTable.labSamples.samples,
          tempReport.labSampleTable.labSamples
        );
        setLoading(false);
        alertContext.success("Report Loaded");
      })
      .catch(async (err) => {
        console.log("ERR: 89:", err);
        setLoading(false);
        alertContext.error(err, "Error fetching reports list");
      });
  }, [options, alertContext]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const pages = document.getElementsByClassName("page");

  const handleClick = () => {
    // let opt = {
    //   jsPDF: {
    //     format: "a4",
    //     orientation: "landscape",
    //   },
    //   imageType: "image/png",
    //   output: `./${fileName}.pdf`,
    // };
    // html2PDF().set(opt).from(pages).save();
    html2PDF(pages, {
      jsPDF: {
        format: "a4",
        orientation: "landscape",
      },
      // imageType: "image/jpeg",
      // imageQuality: 1,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2 },
      pagebreak: { after: ".page" },
      output: `./${fileName}.pdf`,
    });
  };

  // Slices a normal array into chunks
  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  // Slices the plantFertRecommendation arrays into chunks based on the amount of products selected
  function sliceIntoPSchunks(arr, chunkSize) {
    console.log("START:", arr);
    const titleHeight = 10;
    const productHeight = 15;
    const commentHeight = 10;
    const pageHeight = 150;
    let totalHeight = 0;
    const res = [];
    let chunkStart = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].productsselected && arr[i].productsselected.length > 0) {
        const commentCount = arr[i].productsselected.reduce((count, product) => count + (product.comment !== null ? 1 : 0), 0);
        totalHeight +=
          titleHeight + productHeight * arr[i].productsselected.length + commentHeight * commentCount; // TODO fix Error here!
      }
      // console.log(i, arr.length, totalHeight);
      if (totalHeight > pageHeight) {
        const chunk = arr.slice(chunkStart, i);
        // console.log(chunkStart, i, chunk);
        res.push(chunk);
        chunkStart = i;
        i--;
        totalHeight = 0;
      }
    }
    // Push the left over
    if (chunkStart < arr.length) {
      const chunk = arr.slice(chunkStart, arr.length);
      // console.log(chunkStart, arr.length, chunk);
      res.push(chunk);
    }
    return res;
  }
  console.log(plantProductSelection)

  const downloadNBD = () => {
    let xlsxRows = [];
    plantProductSelection.forEach((plantBlocks) => {
      plantBlocks.productsselected && plantBlocks.productsselected.forEach((product) => {
        let row = {
          requirement: "PLANT",
          block_name: plantBlocks.block_name,
          block_area: plantBlocks.block_area,
          crop_class: plantBlocks.crop_class_name,
          ...product,
        };
        xlsxRows.push(row);
      });
    });
    ratoonProductSelection.forEach((ratoonBlocks) => {
      ratoonBlocks.productsselected && ratoonBlocks.productsselected.forEach((product) => {
        let row = {
          requirement: "RATOON",
          block_name: ratoonBlocks.block_name,
          block_area: ratoonBlocks.block_area,
          crop_class: ratoonBlocks.crop_class_name,
          ...product,
        };
        xlsxRows.push(row);
      });
    });
    const wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(xlsxRows);
    XLSX.utils.book_append_sheet(wb, ws, "requirements");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <>
      <LoadingBackground visible={loading} />
      <PageHeader
        title={"Farm NMP Report"}
        subheader={`Viewing Report for ${farmName}`}
        backPath={"back"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left ",
          padding: 20,
        }}
      >
        <span>
          <Tooltip title="Download NMP Report PDF">
            <IconButton
              onClick={() => handleClick()}
              edge="end"
              color="primary"
            >
              <PictureAsPdf id={`icon_${report.farmId}`} color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download NMP Report Excel">
            <IconButton
              onClick={() => downloadNBD()}
              edge="end"
              color="primary"
            >
              <GetApp id={`icon_${report.farmId}`} color="primary" />
            </IconButton>
          </Tooltip>
        </span>
      </div>
      <div class="reportpage" id="page">
        <div class="page">
          <img src={coverPage} alt="cover-page" class="cover"></img>
        </div>

        <div class="page">
          <h3 class="header">
            {finalReport ? "Final" : "Preliminary"} Nutrient Budget
            <span class="farm">{farmName}</span>
          </h3>
          <div style={{ marginLeft: 60, marginRight: 60 }}>
            <div
              style={{
                display: "inline-grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                justifyItems: "left",
                width: "100%",
                marginBottom: 24
              }}
            >

              <div>
                <span style={{ fontWeight: "bold" }}>Report ID: </span>
                <span>
                  {reportKey}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Date Effective: </span>
                <span style={{ color: !report.dateEffective && "#00000000" }}>
                  {report.dateEffective ?? "00-00-0000"}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Region: </span>
                {!loading && (
                  <span style={{ color: !report?.regionName && "red" }}>
                    {report?.regionName ?? "N/A"}
                  </span>
                )}
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Potential Yield: </span>
                {!loading && (
                  <span style={{ color: !report?.regionName && "red" }}>
                    {report?.potentialYield
                      ? `${report.potentialYield}t/ha`
                      : "N/A"}
                  </span>
                )}
              </div>
            </div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Grower</th>
                  <th>Agronomist</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Name of Sugarcane Producer: {report.sugarcaneProducerName}
                  </td>
                  <td>Name of Record Producer: {report.recordProducerName}</td>
                </tr>
                <tr>
                  <td>Company Name: {report.companyName}</td>
                  <td>
                    {report.recordProducerCompanyName
                      ? `Company Name:  ${report.recordProducerCompanyName}`
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>ABN: {report.companyABN || ""}</td>
                  <td>
                    {report.recordProducerCompanyABN
                      ? `ABN: ${report.recordProducerCompanyABN}`
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>Property Address(es): {report.propertyAddress}</td>
                  <td>
                    {report.recordProducerCompanyAddress
                      ? `Address: ${report.recordProducerCompanyAddress}`
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>Postal Address: {report.postalAddress}</td>
                  <td>
                    {report.recordProducerCompanyPostal
                      ? `Postal: ${report.recordProducerCompanyPostal}`
                      : null}
                  </td>
                </tr>

                <tr>
                  <td>Email: {report.companyEmail}</td>
                  <td>
                    {report.recordProducerCompanyEmail
                      ? `Email: ${report.recordProducerCompanyEmail}`
                      : null}
                  </td>
                </tr>
                <tr>
                  <td>Phone: {report.companyPhone}</td>
                  <td>
                    {report.recordProducerCompanyPhone
                      ? `Phone: ${report.recordProducerCompanyPhone}`
                      : null}
                  </td>
                </tr>

                <tr>
                  <td>Farm Identification Number: {report.farmId}</td>
                </tr>
                {(!!report.fertcareAdvisorType || !!report.fertcareAdvisor) && (
                  <>
                    <tr>
                      <td />
                      <td>
                        {`Accreditation: ${[
                          !!report.fertcareAdvisorType
                            ? report.fertcareAdvisorType
                            : [],
                          !!report.fertcareAdvisor
                            ? report.fertcareAdvisor
                            : [],
                        ]
                          .flat()
                          .join(' - ')}`}
                      </td>
                    </tr>
                    {!!report.fertcareAdvisorExpiry && (
                      <tr>
                        <td />
                        <td>{`Expiry: ${report.fertcareAdvisorExpiry}`}</td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            {report.attachmentsUploaded && (
              <Typography variant="caption">
                * All available sampling plans and lab results were uploaded and are retrievable through LandHub.
              </Typography>
            )}
          </div>
          {/* </div>
        <div class="page"> */}
          <h3 class="header">Total Fertiliser Requirements</h3>
          <FertTotalsTable
            totalRequirementsData={totalRequirementsData}
            areaFallowed={report.areaFallowed}
            areaStandOver={report.areaStandOver}
            finalReport={finalReport}
          />
          <div style={{ marginTop: 8 }}>
            <Typography variant="caption">
              *Nutrient Requirements calculated from 6ES and Reef Water
              Quality guidelines
            </Typography>
          </div>
        </div>
        {report?.comments && Array.isArray(report.comments) && report.comments?.length > 0 && (
                  <div class="page">
                  <h3 class="header">Notes / Environmental risk mitigation strategies</h3>
                  {report.comments.map((el, index) => (
                  <div style={{ marginTop: 8 }}>
                <Typography variant="h6">{`${index + 1}) ${el.title}`}</Typography>
                <Typography variant="body2">{el.text}</Typography>
                  </div>
                  ))}
                </div>
        )}

        {plantProductSelection &&
          sliceIntoPSchunks(plantProductSelection, 8).map((chunk, index) => (
            <div class="page" key={index}>
              <h3 class="header">
                1.{index + 1} - Plant Fertiliser Requirements
                <span class="farm">{farmName}</span>
              </h3>

              <ProductSelectedTable
                productsSelected={chunk}
                finalReport={finalReport}
                producePDF={true}
              />
            </div>
          ))}

        {ratoonProductSelection &&
          sliceIntoPSchunks(ratoonProductSelection, 8).map((chunk, index) => (
            <div class="page" key={index}>
              <h3 class="header">
                2.{index + 1} - Ratoon Fertiliser Requirements
                <span class="farm">{farmName}</span>
              </h3>
              <ProductSelectedTable
                productsSelected={chunk}
                finalReport={finalReport}
                producePDF={true}
              />
            </div>
          ))}

        {/* //########################################################################### */}

        {labSamples &&
          sliceIntoChunks(labSamples, 17).map((chunk, index) => (
            <div class="page" key={index}>
              <h3 class="header">
                3.{index + 1} - Lab Sample and Block Data
                <span class="farm">{farmName}</span>
              </h3>
              <Typography variant="caption">
                * Laboratory results and Sample plans are available for download through LandHub.
              </Typography>
              <LabSampleTable labSamples={chunk} />
            </div>
          ))}
        {report?.mapImages &&
          report.mapImages.map((dataURL, index) => (
            <div class="page" key={dataURL}>
              <h3 class="header">
                4.{index + 1} - Map<span class="farm">{farmName}</span>
              </h3>
              <img
                src={dataURL}
                alt={`Map Capture #${index + 1}`}
                style={{ maxWidth: "100%", display: "block" }}
              />
            </div>
          ))}
      </div>
    </>
  );
}
