import React from 'react';

// Material
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

export default function DataTable(props) {
  const { sample } = props;

  const allKeys = Object.keys(sample);
  const sampleKeys = allKeys.filter((el => el !== 'lab_sample_number'))
  const firstColumn = sampleKeys.slice(0, sampleKeys.length / 2);
  const secondColumn = sampleKeys.slice(sampleKeys.length / 2, sampleKeys.length);

  return (
        <div style={{ display: 'flex' }}>
          <TableContainer >
            <Table stickyHeader size='small'>
              <TableHead>
                <TableCell component='th' scope='row'>
                  Item
                </TableCell>
                <TableCell component='th' scope='row'>
                  Value
                </TableCell>
              </TableHead>
              <TableBody>
                {firstColumn.map((el) => (
                  <>
                    <TableRow>
                      <TableCell
                        component='th'
                        scope='row'
                      >
                        {el}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                      >
                        {sample[el]}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer style={{ marginLeft: 20 }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableCell component='th' scope='row'>
                  Item
                </TableCell>
                <TableCell component='th' scope='row'>
                  Value
                </TableCell>
              </TableHead>
              <TableBody>
                {secondColumn.map((el) => (
                  <TableRow>
                    <TableCell component='th' scope='row'>
                      {el}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                    {sample[el]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
  );
}
