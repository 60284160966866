  // ****************************************** THIS IS THE CALC'S FOR THE TOP TOTAL FARM BUDGET **************************************************************

const calcFarmTotalNutrient = (blocks, nutrientToTotal) => {
    return Math.round(
        blocks.reduce(
        (prev, cur) =>
            prev +
            (cur.requirements && cur.requirements.length === 1
            ? cur.requirements[0][nutrientToTotal]
            : 0) *
            parseFloat(cur.hectares),
        0
        )
    );
}

  // Loops through all groups.blocks.productsSelected and calculates total kg's of Nutrient selected
const calcSelectedTotalNutrient = (blocks, nutrientToTotal) => {
    return Math.round(
        blocks.reduce(
        (prev, cur) =>
            prev +
            (cur.productsselected
            ? cur.productsselected.reduce(
                (prevProd, curProd) =>
                    prevProd +
                    (((curProd[nutrientToTotal] || 0) * (curProd.apprate || 0)) /
                    100) *
                    parseFloat(cur.hectares),
                0
                )
            : 0),
        0
        ),
        0
    );
}





  // Loops through all groups.blocks.productsSelected and calculates total kg's of Nutrient applied
const calcActuals = (blocks, nutrientToTotal) => {
    return Math.round(
        blocks.reduce(
        (prev, cur) =>
            prev +
            (cur.productsselected
            ? cur.productsselected.reduce(
                (prevProd, curProd) =>
                    prevProd +
                    (((curProd[nutrientToTotal] || 0) * (curProd.apprate || 0)) /
                    100) *
                    (curProd.applied || 0),
                0
                )
            : 0),
        0
        ),
        0
    );
}

// Loops through all groups.blocks.productsSelected and calculates total kg's of Nutrient Not applied due to partial applications
  // function calcPartcialNutrientDeductions(blocks, nutrientToTotal) {
  //   return Math.round(
  //     blocks.reduce(
  //       (prev, cur) =>
  //         prev +
  //         (cur.productsselected
  //           ? cur.productsselected.reduce(
  //               (prevProd, curProd) =>
  //                 prevProd +
  //                 (((curProd[nutrientToTotal] || 0) * (curProd.apprate || 0)) /
  //                   100) *
  //                   (parseFloat(cur.hectares) - (curProd.applied || 0)),
  //               0
  //             )
  //           : 0),
  //       0
  //     ),
  //     0
  //   );
  // }

const calcPartcialNutrientDeductions = (blocks, nutrientToTotal, attrib) => {
    const total = blocks.reduce(
        (prev, cur) =>
        prev +
        (cur.requirements && cur.requirements.length === 1
            ? (cur.requirements[0][attrib] ? cur.requirements[0][attrib] : 0) *
            (cur.requirements[0][nutrientToTotal] || 0)
            : 0),
        0
    );

    // console.log(nutrientToTotal, blocks, total);
    return Math.round(total);
};

const farmFertTotals = (blocks) => {
    // console.log("farmFertTotals", blocks)
    return {
        // SES Nutrient Allowance Totals (Regulation)
        totalN: calcFarmTotalNutrient(blocks, "nitrogen"),
        totalP: calcFarmTotalNutrient(blocks, "phosphorus"),
        totalK: calcFarmTotalNutrient(blocks, "potassium"),
        totalS: calcFarmTotalNutrient(blocks, "sulphur"),

        // Planned Nutrient Totals (from products selected)
        calculatedN: calcSelectedTotalNutrient(blocks, "n"),
        calculatedP: calcSelectedTotalNutrient(blocks, "p"),
        calculatedK: calcSelectedTotalNutrient(blocks, "k"),
        calculatedS: calcSelectedTotalNutrient(blocks, "s"),

        // Actual Applied Nutrient Totals
        actualN: calcActuals(blocks, "n"),
        actualP: calcActuals(blocks, "p"),
        actualK: calcActuals(blocks, "k"),
        actualS: calcActuals(blocks, "s"),
        
        // Fallow Deductions from SES Allowance
        PartialN: calcPartcialNutrientDeductions(blocks, "nitrogen", "to_fallow"),
        PartialP: calcPartcialNutrientDeductions(blocks, "phosphorus", "to_fallow"),
        PartialK: calcPartcialNutrientDeductions(blocks, "potassium", "to_fallow"),
        PartialS: calcPartcialNutrientDeductions(blocks, "sulphur", "to_fallow"),

        // Stand Over Deductions from SES Allowance
        SOPartialN: calcPartcialNutrientDeductions(blocks, "nitrogen", "to_stand_over"),
        SOPartialP: calcPartcialNutrientDeductions(blocks, "phosphorus", "to_stand_over"),
        SOPartialK: calcPartcialNutrientDeductions(blocks, "potassium", "to_stand_over"),
        SOPartialS: calcPartcialNutrientDeductions(blocks, "sulphur", "to_fto_stand_overallow"),
    };
}

export { farmFertTotals };
