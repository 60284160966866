import React, { useMemo } from 'react';
import { FormControl, FormHelperText,InputLabel, Select, MenuItem, Checkbox, ListItemText, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik'

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    },
    label: {
        textAlign: 'left',
        color: '#282828'
    },
    disabledLabel: {
        textAlign: 'left',
        color: '#828282'
    }
}));

export default function FormMultiSelect(props) {
    const classes =  style()
    const { name, label, options, sortOptions, helpText, disabled } = props

    const [field, meta] = useField(props);
    const hasError = Boolean(meta.touched && meta.error)
    let values = field.value

    const preparedOptions = useMemo(() => {
      return sortOptions
        ? options.sort((a, b) => a.name.localeCompare(b.name))
        : options;
    }, [options, sortOptions]);
    
    return (
        <FormControl className={classes.formControl} error={hasError}>
            <InputLabel shrink={true} htmlFor={`input-${name}`}
                className={disabled ? classes.disabledLabel : classes.label }
            >{label}</InputLabel>
            <Select
                disabled={disabled}
                multiple={true}
                MenuProps={MenuProps}
                input={<Input />}
                inputProps={{name: name, id: `input-${name}`}}
                renderValue={(selectedValues) => {
                    return preparedOptions
                      .filter((o) => selectedValues.includes(o.value))
                      .map((o) => o.name)
                      .join(', ');
                }}
                {...field} >
                {
                    preparedOptions.map((option, index) => <MenuItem key={index} value={option.value}>
                        <Checkbox checked={values.includes(option.value)} color={"primary"} />
                        <ListItemText primary={option.name} />
                    </MenuItem> )
                }
            </Select>
            <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

