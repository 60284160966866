import { APIFetch } from '../../fetch.js';

export const applyProduct = async ({
  token,
  id,
  appliedDate,
  appliedArea,
  recordedDate,
}) => {
  const response = await APIFetch({
    route: 'products/apply',
    method: 'POST',
    authToken: token,
    body: {
      id,
      appliedDate,
      appliedArea,
      recordedDate,
    },
  });

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('An error occured during Apply request!');
  }

  if (response.status === 200) {
    return await response.json();
  }

  return null;
};
