import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { CheckCircle, ErrorOutlineSharp, Warning } from '@material-ui/icons';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import CreateBlocks from '../../../graphQL/CreateBlocks';
import CreateFarms from '../../../graphQL/CreateFarms';
import InitNewBlocks from '../../../graphQL/InitNewBlocks';
import { addLabSample } from '../../../graphQL/SoilSample';
import {
  BlocksTabContent,
  BlocksTabValue,
  BlocksValidator,
} from './BlocksTabContent';
import {
  FarmsTabContent,
  FarmsTabValue,
  FarmsValidator,
} from './FarmsTabContent';
import {
  ReviewTabContent,
  ReviewTabValue,
  ReviewValidator,
} from './ReviewTabContent/ReviewTabContent';
import {
  SamplesTabContent,
  SamplesTabValue,
  SamplesValidator,
} from './SamplesTabContent';
import {
  cropClasses,
  fallowCrops,
  varieties,
} from '../../../functions/offlineQueries';
import CreateVarieties from '../../../graphQL/CreateVarieties';
import { createRequirements } from '../../../graphQL/Requirement';

const tabs = [
  {
    label: 'Farms',
    key: FarmsTabValue,
    content: FarmsTabContent,
    validator: FarmsValidator,
  },
  {
    label: 'Blocks',
    key: BlocksTabValue,
    content: BlocksTabContent,
    validator: BlocksValidator,
  },
  {
    label: 'Samples',
    key: SamplesTabValue,
    content: SamplesTabContent,
    validator: SamplesValidator,
  },
  {
    label: 'Review',
    key: ReviewTabValue,
    content: ReviewTabContent,
    validator: ReviewValidator,
  },
];

const TabLabel = ({ label, hasErrors }) => (
  <Typography variant="button">
    {hasErrors && (
      <ErrorOutlineSharp
        color="error"
        style={{ verticalAlign: 'text-bottom', paddingRight: 4 }}
      />
    )}
    {label}
  </Typography>
);

const UPLOAD_STATUS = {
  WAITING: 'waiting',
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  FAILED: 'failed',
};

const VIEWS = {
  FARMS: 'farms',
  STATUS: 'status',
};

const _uploadFarms = async (farms) => {
  try {
    const preparedFarms = farms.map((farm) => ({
      name: farm.name,
      description: farm.description,
      region: farm.region,
    }));

    const farmResults = await API.graphql(
      graphqlOperation(CreateFarms.mutation, { farms: preparedFarms })
    );

    const uploadedFarms = JSON.parse(farmResults.data.createFarms);
    return { success: true, uploadedFarms: uploadedFarms };
  } catch (err) {
    return { success: false, error: err };
  }
};

const _uploadBlocks = async (blocks, farmIDs) => {
  try {
    const preparedBlocks = blocks.map((block) => ({
      farmID: farmIDs[block.farm],
      name: block.name,
      lat: block.lat,
      lng: block.lng,
      hectares: block.hectares,
    }));

    const blockResults = await API.graphql(
      graphqlOperation(CreateBlocks.mutation, { blocks: preparedBlocks })
    );

    const uploadedBlocks = JSON.parse(blockResults.data.createBlocks);
    return { success: true, uploadedBlocks: uploadedBlocks };
  } catch (err) {
    return { success: false, error: err };
  }
};

const _uploadVarieties = async (varieties) => {
  try {
    const varietiesResult = await API.graphql(
      graphqlOperation(CreateVarieties.mutation, { varieties: varieties })
    );
    const createdVarieties = JSON.parse(varietiesResult.data.createVarieties);
    return { success: true, createdVarieties: createdVarieties };
  } catch (err) {
    return { success: false, error: err };
  }
};

const _uploadSamples = async (samples, farmBlockIDs) => {
  try {
    const preparedSamples = samples.map((sample) => {
      const farmName = sample.farm;
      const blockName = sample.block;
      const blockID = farmBlockIDs[farmName][blockName];
      if (blockID === undefined) {
        throw new Error(
          `Cannot Find Block (${blockName}) In Farm (${farmName}).`
        );
      }
      const cropClassName = sample['Crop class at sampling'];
      const cropClassId = cropClasses.find(
        (cropClass) => cropClass.name === cropClassName
      ).value;
      const sampleDate = sample['Sample date'].split('/').reverse().join('-');
      return {
        ...sample,
        blockId: blockID,
        'Sample date': sampleDate,
        cropClassId: cropClassId,
        state: 5,
      };
    });

    const uploadPromises = preparedSamples.map((sample) =>
      API.graphql(
        graphqlOperation(addLabSample.mutation, {
          labSample: JSON.stringify(sample),
        })
      )
    );

    const samplesResults = await Promise.all(uploadPromises);
    const uploadedSamples = samplesResults.map((sampleResult) =>
      JSON.parse(sampleResult.data.addLabSample)
    );

    return { success: true, uploadedSamples: uploadedSamples };
  } catch (err) {
    return { success: false, error: err };
  }
};

const _initNewBlocks = async (samples, farmBlockIDs) => {
  try {
    const preparedBlocks = samples.map((sample) => {
      const farmName = sample.farm;
      const blockName = sample.block;
      const blockID = farmBlockIDs[farmName][blockName];
      if (blockID === undefined) {
        throw new Error(
          `Cannot Find Block (${blockName}) In Farm (${farmName}).`
        );
      }
      const cropClassName = sample['Crop class at sampling'];
      const cropClassId = cropClasses.find(
        (cropClass) => cropClass.name === cropClassName
      )?.value;
      const fallowCropName = sample['Legume Crop'];
      const fallowCropId = fallowCrops.find(
        (fallowCrop) => fallowCrop.name === fallowCropName
      )?.value;
      const plantDate = sample['Plant Date']?.split('/').reverse().join('-');
      const byproductDate = sample['Byproduct applied date']
        ?.split('/')
        .reverse()
        .join('-');
      return {
        id: blockID,
        sample: {
          ...sample,
          'Plant Date': plantDate,
          'Byproduct applied date': byproductDate,
          Variety: sample['Variety'].trim(),
          cropClassId: cropClassId,
          fallowCropId: fallowCropId,
        },
      };
    });

    const initNewBlocksResult = await API.graphql(
      graphqlOperation(InitNewBlocks.mutation, {
        blocks: JSON.stringify(preparedBlocks),
      })
    );

    const blockRequirementPairs = JSON.parse(
      initNewBlocksResult.data.initNewBlocks
    );

    const createRequirementPromises = blockRequirementPairs.map(({ id }) =>
      API.graphql(
        graphqlOperation(createRequirements.mutation, {
          requirementId: id,
        })
      )
    );
    await Promise.all(createRequirementPromises);

    return { success: true };
  } catch (err) {
    return { success: false, error: err };
  }
};

const UploadStatusIndicator = ({ title, status, isUploading }) => {
  const textColor = useMemo(() => {
    switch (status) {
      case UPLOAD_STATUS.WAITING:
        return 'textSecondary';
      case UPLOAD_STATUS.UPLOADING:
        return 'secondary';
      case UPLOAD_STATUS.SUCCESS:
        return 'primary';
      case UPLOAD_STATUS.FAILED:
        return 'error';
      default:
        return 'inherit';
    }
  }, [status]);

  const icon = useMemo(() => {
    switch (status) {
      case UPLOAD_STATUS.WAITING:
        return (
          isUploading && (
            <CircularProgress style={{ color: '#5B5B6699' }} size={24} />
          )
        );
      case UPLOAD_STATUS.UPLOADING:
        return <CircularProgress color="secondary" size={24} />;
      case UPLOAD_STATUS.SUCCESS:
        return <CheckCircle color="primary" />;
      case UPLOAD_STATUS.FAILED:
        return <Warning color="error" />;
      default:
        return <></>;
    }
  }, [status, isUploading]);

  return (
    <ListItem divider={status === UPLOAD_STATUS.UPLOADING}>
      <Box sx={{ width: 24 }} mr={2}>
        {icon}
      </Box>
      <Typography color={textColor}>{title}</Typography>
    </ListItem>
  );
};

export const UploadCSVDialog = ({ data, onUploaded, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [view, setView] = useState(VIEWS.FARMS);
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.WAITING);

  const [farmUploadStatus, setFarmUploadStatus] = useState(
    UPLOAD_STATUS.WAITING
  );
  const [blockUploadStatus, setBlockUploadStatus] = useState(
    UPLOAD_STATUS.WAITING
  );
  const [sampleUploadStatus, setSampleUploadStatus] = useState(
    UPLOAD_STATUS.WAITING
  );

  const parsedData = useMemo(() => {
    return data.map((rawFarm) => ({
      name: rawFarm.name,
      description: rawFarm.description,
      region: rawFarm.region,
      blocks: rawFarm.blocks.map((rawBlock) => ({
        name: rawBlock.Name,
        farm: rawBlock.Farm,
        lat: rawBlock.Lat,
        lng: rawBlock.Lng,
        hectares: rawBlock.Hectares,
      })),
      samples: rawFarm.samples.map((rawSample) => {
        const {
          'Farm no.': farm,
          'Block no.': block,
          ...properties
        } = rawSample;
        return { farm, block, ...properties };
      }),
    }));
  }, [data]);

  const validatedTabs = useMemo(
    () =>
      tabs.map((tab) => ({
        ...tab,
        hasErrors: tab.validator(parsedData).success === false,
      })),
    [parsedData]
  );

  const hasErrors = useMemo(
    () => validatedTabs.some((tab) => tab.hasErrors),
    [validatedTabs]
  );

  const [currentTab, setCurrentTab] = useState(validatedTabs[0]);

  const previousTab = useMemo(() => {
    const currentIndex = validatedTabs.findIndex(
      (tab) => tab.key === currentTab.key
    );
    if (currentIndex === -1 || currentIndex === 0) return undefined;
    return validatedTabs[currentIndex - 1];
  }, [currentTab, validatedTabs]);

  const nextTab = useMemo(() => {
    const currentIndex = validatedTabs.findIndex(
      (tab) => tab.key === currentTab.key
    );
    if (currentIndex === -1 || currentIndex === tabs.length - 1)
      return undefined;
    return validatedTabs[currentIndex + 1];
  }, [currentTab, validatedTabs]);

  const handleTabChange = useCallback(
    (_, newTabKey) => {
      const newTab = validatedTabs.find((tab) => tab.key === newTabKey);
      if (newTab === undefined) return;
      setCurrentTab(newTab);
    },
    [validatedTabs]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleBack = useCallback(() => {
    setCurrentTab(previousTab);
  }, [previousTab]);

  const handleNext = useCallback(() => {
    setCurrentTab(nextTab);
  }, [nextTab]);

  const startFarmsUpload = useCallback(async () => {
    try {
      setFarmUploadStatus(UPLOAD_STATUS.WAITING);
      setBlockUploadStatus(UPLOAD_STATUS.WAITING);
      setSampleUploadStatus(UPLOAD_STATUS.WAITING);

      setView(VIEWS.STATUS);
      setUploadStatus(UPLOAD_STATUS.UPLOADING);

      setFarmUploadStatus(UPLOAD_STATUS.UPLOADING);
      const farmsResult = await _uploadFarms(parsedData);
      if (farmsResult.success === false) {
        setFarmUploadStatus(UPLOAD_STATUS.FAILED);
        throw farmsResult.error;
      }
      setFarmUploadStatus(UPLOAD_STATUS.SUCCESS);

      const farmIDs = farmsResult.uploadedFarms.reduce((ids, farm) => {
        ids[farm.name] = farm.id;
        return ids;
      }, {});

      const farmNames = farmsResult.uploadedFarms.reduce((names, farm) => {
        names[farm.id] = farm.name;
        return names;
      }, {});

      setBlockUploadStatus(UPLOAD_STATUS.UPLOADING);
      const blocks = parsedData.reduce((blocks, farm) => {
        blocks.push(...farm.blocks);
        return blocks;
      }, []);
      const blocksResult = await _uploadBlocks(blocks, farmIDs);
      if (blocksResult.success === false) {
        setBlockUploadStatus(UPLOAD_STATUS.FAILED);
        throw blocksResult.error;
      }
      setBlockUploadStatus(UPLOAD_STATUS.SUCCESS);

      const farmBlockIDs = blocksResult.uploadedBlocks.reduce((ids, block) => {
        const farmName = farmNames[block.farm_id];
        if (ids[farmName] === undefined) ids[farmName] = {};
        ids[farmName][block.name] = block.id;
        return ids;
      }, {});

      setSampleUploadStatus(UPLOAD_STATUS.UPLOADING);
      const samples = parsedData.reduce((samples, farm) => {
        samples.push(...farm.samples);
        return samples;
      }, []);

      const newVarieties = samples.flatMap((sample) => {
        const sampleVariety = sample['Variety'].trim();
        const exists = varieties.some(({ name }) => name === sampleVariety);
        return exists ? [] : sampleVariety;
      });
      const uniqueVarieties = [...new Set(newVarieties)];

      if (uniqueVarieties.length >= 1) {
        const varietiesResult = await _uploadVarieties(uniqueVarieties);
        if (varietiesResult.success === false) {
          setSampleUploadStatus(UPLOAD_STATUS.FAILED);
          throw varietiesResult.error;
        }
      }

      const samplesResult = await _uploadSamples(samples, farmBlockIDs);
      if (samplesResult.success === false) {
        setSampleUploadStatus(UPLOAD_STATUS.FAILED);
        throw samplesResult.error;
      }

      const initBlocksResult = await _initNewBlocks(samples, farmBlockIDs);
      if (initBlocksResult.success === false) {
        setSampleUploadStatus(UPLOAD_STATUS.FAILED);
        throw initBlocksResult.error;
      }

      setSampleUploadStatus(UPLOAD_STATUS.SUCCESS);

      setUploadStatus(UPLOAD_STATUS.SUCCESS);
      enqueueSnackbar('Upload Successful', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);

      setFarmUploadStatus((status) =>
        status === UPLOAD_STATUS.SUCCESS ? status : UPLOAD_STATUS.FAILED
      );
      setBlockUploadStatus((status) =>
        status === UPLOAD_STATUS.SUCCESS ? status : UPLOAD_STATUS.FAILED
      );
      setSampleUploadStatus((status) =>
        status === UPLOAD_STATUS.SUCCESS ? status : UPLOAD_STATUS.FAILED
      );

      setUploadStatus(UPLOAD_STATUS.FAILED);
      enqueueSnackbar('Upload Failed', {
        variant: 'error',
      });
    } finally {
      onUploaded?.();
    }
  }, [onUploaded, parsedData, enqueueSnackbar]);

  const handleUploadClick = useCallback(() => {
    startFarmsUpload();
  }, [startFarmsUpload]);

  return view === VIEWS.FARMS ? (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="xl"
      aria-labelledby="upload-dialog-title"
      aria-describedby="upload-dialog-description"
    >
      <DialogTitle
        id="upload-dialog-title"
        style={{ backgroundColor: '#00A1C90A' }}
      >
        Farm Upload
      </DialogTitle>
      <DialogContent>
        <Tabs value={currentTab.key} onChange={handleTabChange}>
          {validatedTabs.map((tab) => (
            <Tab
              key={tab.key}
              label={<TabLabel label={tab.label} hasErrors={tab.hasErrors} />}
              value={tab.key}
            />
          ))}
        </Tabs>
        {validatedTabs.map((tab) => (
          <tab.content key={tab.key} value={currentTab.key} data={parsedData} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {previousTab && <Button onClick={handleBack}>Back</Button>}
        {nextTab && <Button onClick={handleNext}>Next</Button>}
        <Button
          color="primary"
          disabled={nextTab !== undefined || hasErrors}
          onClick={handleUploadClick}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    view === VIEWS.STATUS && (
      <Dialog open maxWidth={'xl'}>
        <DialogTitle>
          {uploadStatus === UPLOAD_STATUS.UPLOADING
            ? 'Upload In Progress'
            : uploadStatus === UPLOAD_STATUS.SUCCESS
            ? 'Upload Complete'
            : uploadStatus === UPLOAD_STATUS.FAILED && 'Upload Failed'}
        </DialogTitle>
        <DialogContent>
          <List>
            <UploadStatusIndicator
              title="Farms"
              status={farmUploadStatus}
              isUploading={uploadStatus === UPLOAD_STATUS.UPLOADING}
            />
            <UploadStatusIndicator
              title="Blocks"
              status={blockUploadStatus}
              isUploading={uploadStatus === UPLOAD_STATUS.UPLOADING}
            />
            <UploadStatusIndicator
              title="Samples"
              status={sampleUploadStatus}
              isUploading={uploadStatus === UPLOAD_STATUS.UPLOADING}
            />
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={uploadStatus !== UPLOAD_STATUS.FAILED}
            color="primary"
            onClick={() => setView(VIEWS.FARMS)}
          >
            Back
          </Button>
          <Button
            disabled={uploadStatus === UPLOAD_STATUS.UPLOADING}
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
};
